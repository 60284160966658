export type RoleAccessPermissions =
  | 'me:edit'
  | 'me:view'
  | 'orders:fulfill'
  | 'orders:edit'
  | 'orders:items-edit'
  | 'orders:list'
  | 'orders:create'
  | 'orders:approve-cancellation'
  | 'print-jobs:list'
  | 'products:create'
  | 'products:delete'
  | 'products:edit'
  | 'products:list'
  | 'fulfillment:submit'
  | 'fulfillment:list'
  | 'split-tests:create'
  | 'split-tests:edit'
  | 'split-tests:list'
  | 'vouchers:create'
  | 'vouchers:delete'
  | 'vouchers:edit'
  | 'vouchers:list'
  | 'users:create'
  | 'users:delete'
  | 'users:edit'
  | 'users:list';

export type Roles = {
  [key: string]: RoleAccessPermissions[];
};

export const roles: Roles = {
  admin: [
    'me:edit',
    'me:view',
    'orders:fulfill',
    'orders:edit',
    'orders:items-edit',
    'orders:approve-cancellation',
    'orders:list',
    'orders:create',
    'print-jobs:list',
    'products:create',
    'products:delete',
    'products:edit',
    'products:list',
    'fulfillment:submit',
    'fulfillment:list',
    'split-tests:create',
    'split-tests:edit',
    'split-tests:list',
    'vouchers:create',
    'vouchers:delete',
    'vouchers:edit',
    'vouchers:list',
    'users:create',
    'users:delete',
    'users:edit',
    'users:list',
  ],
  support_tier1: [
    'me:edit',
    'me:view',
    'orders:fulfill',
    'orders:edit',
    'orders:list',
    'fulfillment:submit',
    'fulfillment:list',
    'vouchers:list',
  ],
  support_tier2: [
    'me:edit',
    'me:view',
    'orders:fulfill',
    'orders:edit',
    'orders:list',
    'orders:approve-cancellation',
    'fulfillment:submit',
    'fulfillment:list',
    'vouchers:list',
  ],
  support_manager: [
    'me:edit',
    'me:view',
    'orders:fulfill',
    'orders:edit',
    'orders:list',
    'orders:items-edit',
    'orders:approve-cancellation',
    'fulfillment:submit',
    'fulfillment:list',
    'vouchers:list',
  ],
  fulfillment_manager: [
    'me:edit',
    'me:view',
    'orders:list',
    'print-jobs:list',
    'fulfillment:list',
  ],
};
