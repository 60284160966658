import { BaseTimeline } from './Base';
import { getDiff } from './utils';

export interface NotesTimeline extends BaseTimeline {
  message?: string;
}

export class Timelime extends BaseTimeline {
  getDiff() {
    return getDiff(this.prevSnapshot, this.snapshot);
  }
}

export default Timelime;
