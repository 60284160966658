import React from 'react';
import { RoleAccessPermissions, useAuth } from 'lib/auth';

interface RoleAccessProps {
  fallback?: React.ReactNode;
  permission: RoleAccessPermissions;
}

export const RoleAccess: React.FC<RoleAccessProps> = ({
  children,
  fallback,
  permission,
}) => {
  const { user } = useAuth();

  return (
    <>
      {user.permissions().indexOf(permission) > -1
        ? children || null
        : fallback || null}
    </>
  );
};
