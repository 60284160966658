import React from 'react';
import config from 'config';
import fetch from 'isomorphic-unfetch';
import LocalStorageCache from '@andalways/localstorage-cache';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client';

const cache = new LocalStorageCache();
const isServer = typeof window === 'undefined';
const disabledNormalizationTypes = [
  'Adjustment',
  'Fulfillment',
  'Item',
  'TimelineSnapshot',
];

const cacheConfig = (): InMemoryCacheConfig => {
  const typePolicies = {
    Item: { keyFields: ['id', 'metaUpdatedAt'] },
  };

  disabledNormalizationTypes.forEach((type) => {
    typePolicies[type] = { keyFields: false as false };
  });

  return { typePolicies };
};

const authLink = setContext((_, { headers }) => {
  const token = cache.get<string>(config.authCacheKey);

  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : null },
  };
});

export const EcommerceApiClient = new ApolloClient({
  cache: new InMemoryCache(cacheConfig()),
  connectToDevTools: !isServer,
  ssrMode: false,
  link: authLink.concat(
    createHttpLink({
      uri: process.env.REACT_APP_ECOMMERCE_API_URL?.toString(),
      fetch,
    })
  ),
});

export const EcommerceApiProvider: React.FC = ({ children }) => (
  <ApolloProvider client={EcommerceApiClient}>{children}</ApolloProvider>
);
