import config from 'config';
import slugify from 'slugify';
import { get } from 'object-path';
import { Order } from 'models';
import { BaseOrderItem, OrderItemTypes, OrderItemProduct } from '.';
import { getShippingRateFileName } from './utils';

export class StaticPrint extends BaseOrderItem implements OrderItemProduct {
  public type = OrderItemTypes.static_print;

  get printSize(): { width: number; height: number } {
    const { width, height } = get(this, 'product.meta.andalways:static');

    return { width, height };
  }

  getDownloadUrl() {
    return this.getPrintUrl();
  }

  getPrintUrl() {
    return get(this, 'product.meta.andalways:static.file');
  }

  getPreviewUrl() {
    return get(this, 'product.meta.featuredImage.src');
  }

  getPrinterFilename(order: Order, format: 'png' | 'pdf' = 'pdf'): string {
    return this.getFulfillmentPrintFilename(order, 1, 1, format);
  }

  getFulfillmentPrintFilename(
    order: Order,
    quantityIndex: number,
    itemQuantity: number,
    format: 'png' | 'pdf' = 'pdf'
  ): string {
    const shippingMethodPrefix = getShippingRateFileName(order);
    const isRushOrder = order.isRushOrder;
    const customerName = slugify(`${order.firstName} ${order.lastName}`);
    const testPrefix = !config.isProduction ? 'TEST_' : '';

    return `${testPrefix}${shippingMethodPrefix}${order.id}_${
      this.id
    }_${customerName}${isRushOrder ? '_rush' : ''}${
      itemQuantity > 1 ? `_${quantityIndex}-of-${itemQuantity}` : ''
    }.${format}`;
  }

  setPrintSize(size: { width: number; height: number }) {
    this.meta['andalways:static'].width = size.width;
    this.meta['andalways:static'].height = size.height;
  }
}
