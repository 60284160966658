import React from 'react';
import * as Grid from 'react-awesome-styled-grid';
import { Sidebar } from './Sidebar';
import { Body, Container } from './Layout.styles';

export const Layout: React.FC = ({ children }) => (
  <Container>
    <Sidebar />

    <Body>
      <Grid.Container fluid>
        <Grid.Row>
          <Grid.Col>{children}</Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Body>
  </Container>
);
