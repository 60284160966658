import { useMemo } from 'react';
import { plainToClass } from 'class-transformer';
import { CartsQueryVariables, useCartsQuery } from 'lib/api/ecommerce/schema';
import { BaseOrder } from 'models';

export interface UseOrdersResult {
  orders: BaseOrder[];
  error: any;
  loading: boolean;
  refetch: any;
}

export interface UseOrdersArgs {
  variables?: CartsQueryVariables;
  onCompleted?: (data: any) => void;
}

export function useOrders({
  variables,
  onCompleted = () => {},
}: UseOrdersArgs): UseOrdersResult {
  const { data, loading, error, refetch } = useCartsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables,
    onCompleted,
  });

  const orders = useMemo(() => {
    if (data) {
      return plainToClass(BaseOrder, data.carts || []);
    }

    return [];
  }, [data]);

  return { orders, loading, error, refetch };
}
