import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth } from 'lib/auth';
import config from 'config';

Sentry.init({ dsn: config.sentryDsn, environment: process.env.NODE_ENV });

export const ErrorProvider = ({ children }) => {
  const { user } = useAuth();

  useEffect(() => {
    if (user.id) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user.id, email: user.email });
      });
    }
  }, [user]);

  return children;
};
