import type { Order, BaseOrder } from '..';

const DARK_PRINT_COLORS = [
  '#000000',
  '#223849',
  '#1c2832',
  '#a10459',
  '#83164d',
  '#051c2c',
];

const LIGHT_PRINT_COLORS = ['#ffffff', '#FFFFFF'];

export function orderHasFrame(order: Order): boolean {
  return order.getHasFrame();
}

export function orderIsNotFramed(order: Order): boolean {
  return order.getHasUnframed();
}

export function orderHasBlackFrame(order: Order): boolean {
  return (
    order.getPrints().filter((item) => item.sku.indexOf('FBLK') > -1).length > 0
  );
}

export function orderHasWhiteFrame(order: Order): boolean {
  return (
    order.getPrints().filter((item) => item.sku.indexOf('FWHT') > -1).length > 0
  );
}

export function orderHasHangerFrame(order: Order): boolean {
  return order.getHasHangerFrame();
}

export function orderHasWhiteHangerFrame(order: Order): boolean {
  return (
    order.getHasHangerFrame() &&
    order.items.some((item) => /HF[0-9]{1,2}-WHT/gm.test(item?.sku))
  );
}

export function orderHasBlackHangerFrame(order: Order): boolean {
  return (
    order.getHasHangerFrame() &&
    order.items.some((item) => /HF[0-9]{1,2}-BLK/gm.test(item?.sku))
  );
}

export function orderIsPriorityShipping(order: Order): boolean {
  return (
    ['priority', 'fedex-hd'].indexOf(
      order.shippingMethod?.toLowerCase() || ''
    ) > -1
  );
}

export function orderIsStandardShipping(order: Order): boolean {
  return order.shippingMethod?.toLowerCase() === 'standard';
}

export function orderIsExpressShipping(order: Order): boolean {
  return order.shippingMethod?.toLowerCase() === 'express';
}

export const hasFrame = (order: Order): boolean => {
  return order.getHasFrame();
};

export const orderIsInternational = (order: Order): boolean => {
  return order.isInternational;
};

export const orderIsEastCoast = (order: Order): boolean => {
  const westCoastStates = [
    'AZ',
    'CA',
    'HI',
    'AK',
    'NV',
    'ID',
    'WA',
    'OR',
    'MT',
    'WY',
    'CO',
    'NM',
    'TX',
    'UT',
  ];

  return !westCoastStates.includes(order.shippingState);
};

export const orderIsRushOrder = (order: Order | BaseOrder): boolean => {
  return order.items.some((item) => /RSH-ORDER/gm.test(item.sku));
};

export function orderHasMulticolorPosters(order: Order): boolean {
  let hasDarkPrint = false;
  let hasLightPrint = false;

  order.getPrints().forEach((item) => {
    const meta = item.printMeta;

    if (DARK_PRINT_COLORS.includes(meta?.theme.primaryColor))
      hasDarkPrint = true;

    if (LIGHT_PRINT_COLORS.includes(meta?.theme.primaryColor))
      hasLightPrint = true;
  });

  return hasDarkPrint && hasLightPrint;
}

export function orderContainsWhitePoster(order: Order): boolean {
  let hasWhitePoster = false;

  order.getPrints().forEach((item) => {
    const meta = item.printMeta;

    if (LIGHT_PRINT_COLORS.includes(meta?.theme.primaryColor))
      hasWhitePoster = true;
  });

  return hasWhitePoster;
}

export function orderContainsDarkPoster(order: Order): boolean {
  let hasDarkPoster = false;

  order.getPrints().forEach((item) => {
    const meta = item.printMeta;

    if (DARK_PRINT_COLORS.includes(meta?.theme.primaryColor))
      hasDarkPoster = true;
  });

  return hasDarkPoster;
}

export const orderIsTwinkleInTime = (order: Order): boolean => {
  return order.store === 'twinkle';
};

export const orderIsOverOurMoon = (order: Order): boolean => {
  return order.store === 'overourmoon';
};

export const orderIsAndAlways = (order: Order): boolean => {
  return order.store === 'andalways';
};

const orderHasSKU = (order: Order | BaseOrder, sku: string): boolean => {
  return order.items.some((item) => item.sku.indexOf(sku) > -1);
};

export const orderHasDigitalPrint = (order: Order | BaseOrder): boolean => {
  return orderHasSKU(order, 'P-DIGITAL');
};

export const orderHas12X16Print = (order: Order | BaseOrder): boolean => {
  return orderHasSKU(order, 'P12X16');
};

export const orderHas18X24Print = (order: Order | BaseOrder): boolean => {
  return orderHasSKU(order, 'P18X24');
};

export const orderHas24X36Print = (order: Order | BaseOrder): boolean => {
  return orderHasSKU(order, 'P24X36');
};
