import React from 'react';
import UserAgentParser from 'ua-parser-js';
import { Heading } from 'evergreen-ui';

interface UserAgentProps {
  userAgent: string;
}

export const UserAgent: React.FC<UserAgentProps> = ({ userAgent }) => {
  if (!userAgent) return <Heading size={400}>n/a</Heading>;

  const parsedParts: React.ReactNode[] = [];
  // @ts-ignore
  const UAParser = new UserAgentParser();

  UAParser.setUA(userAgent);

  if (UAParser.getDevice().vendor) {
    parsedParts.push(
      <Heading key="ua-device" size={400}>
        {UAParser.getDevice().vendor} {UAParser.getDevice().model}
      </Heading>
    );
  }

  if (UAParser.getBrowser().name) {
    parsedParts.push(
      <Heading key="ua-browser" size={400}>
        {UAParser.getBrowser().name} {UAParser.getBrowser().version}
      </Heading>
    );
  }

  if (UAParser.getOS().name) {
    parsedParts.push(
      <Heading key="ua-os" size={400}>
        {UAParser.getOS().name} {UAParser.getOS().version}
      </Heading>
    );
  }

  return <>{parsedParts}</>;
};
