import React from 'react';
import { Table } from 'evergreen-ui';

export const Header: React.FC = () => (
  <Table.Head borderRadius={6} elevation={1}>
    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
    <Table.TextHeaderCell>Email</Table.TextHeaderCell>
    <Table.TextHeaderCell>Status</Table.TextHeaderCell>
    <Table.HeaderCell
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexBasis={320}
      flexShrink={0}
      flexGrow={0}
    >
      Role(s)
    </Table.HeaderCell>
  </Table.Head>
);
