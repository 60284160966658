import {
  DesignForm,
  LocationForm,
  MessageForm,
  PersonalizeForm,
  StyleForm,
  StyleFormMoon,
  ZodiacForm,
} from './components';

export const StarTabs = [
  { key: 'location', value: 'Location', component: LocationForm },
  { key: 'message', value: 'Message', component: MessageForm },
  { key: 'style', value: 'Style', component: StyleForm },
];

export const MoonTabs = [
  { key: 'location', value: 'Location', component: LocationForm },
  { key: 'message', value: 'Message', component: MessageForm },
  { key: 'style', value: 'Style', component: StyleFormMoon },
];

export const ZodiacTabs = [
  { key: 'design', value: 'Design', component: DesignForm },
  { key: 'zodiac', value: 'Zodiac', component: ZodiacForm },
  { key: 'personalize', value: 'Personalize', component: PersonalizeForm },
];
