import dayjs from 'dayjs';
import { shippingHolidays } from 'config/shipping';
import { isSaturday, isSunday } from './isDates';

export const getDeliveryDate = (
  productionDate: Date,
  deliveryDateOffset: number,
  method: string
): Date => {
  let deliveryDate = dayjs(productionDate).add(deliveryDateOffset, 'day');

  // if SUNDAY set MONDAY
  if (isSunday(deliveryDate)) deliveryDate = deliveryDate.add(1, 'day');

  // if SATURDAY & NOT EXPRESS SHIPPING set MONDAY
  // EXPRESS shipping delivers SATURDAYS
  if (isSaturday(deliveryDate) && method !== 'express')
    deliveryDate = deliveryDate.add(2, 'day');

  // if DELIVERY_DATE === HOLIDAY, increase DELIVERY_DATE++
  shippingHolidays.forEach((holiday) => {
    if (deliveryDate.isSame(dayjs(holiday), 'day'))
      deliveryDate = deliveryDate.add(1, 'day');
  });

  return deliveryDate.toDate();
};

export default getDeliveryDate;
