import React from 'react';
import isEqual from 'react-fast-compare';
import { Pane, Textarea } from 'evergreen-ui';

export class MessageForm extends React.Component<any> {
  public shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.print.theme, nextProps.print.theme) ||
      !isEqual(this.props.print.subject, nextProps.print.subject) ||
      !isEqual(this.props.print.timezone, nextProps.print.timezone) ||
      !isEqual(this.props.print.subject.date, nextProps.print.subject.date)
    );
  }

  private handlePrintChange = (e) =>
    this.props.handlePrintChange({
      theme: { [e.target.name]: e.target.value },
    });

  public render() {
    const { theme } = this.props.print;
    const { headline, tagline } = theme;

    return (
      <Pane width="100%">
        <Pane width="100%">
          <Textarea
            name="headline"
            value={headline}
            placeholder="Write a message"
            onChange={this.handlePrintChange.bind(this)}
            width="100%"
          />
        </Pane>
        <Pane width="100%">
          <Textarea
            name="tagline"
            value={tagline}
            placeholder="Footer message"
            onChange={this.handlePrintChange.bind(this)}
            width="100%"
          />
        </Pane>
      </Pane>
    );
  }
}
