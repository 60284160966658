import config from 'config';
import { isBefore, endOfYesterday } from 'date-fns';
import { get } from 'object-path';
import { roles } from 'lib/auth';
import { runMutation } from 'lib/api/runMutation';
import { EcommerceApiClient } from 'lib/api/ecommerce';
import LocalStorageCache from '@andalways/localstorage-cache';
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
} from 'lib/api/ecommerce/schema';
import { BaseUser } from './Base';

const cache = new LocalStorageCache();

export class User extends BaseUser {
  public isLoggedIn: boolean = false;
  private ecommerceApi = EcommerceApiClient;

  // run auth/login mutation
  // store token & issued unix time in local storage
  async login(data: LoginMutationVariables) {
    const res = await runMutation<LoginMutation['auth']>(
      this.ecommerceApi,
      LoginDocument,
      { ...data }
    );

    if (res.ok) {
      cache.set(config.authCacheKey, res.data?.token);
      cache.set(config.authLastLoggedCacheKey, new Date().getTime());
    }

    return res;
  }

  logout() {
    cache.remove(config.authCacheKey);
    cache.remove(config.authLastLoggedCacheKey);

    this.isLoggedIn = false;
  }

  permissions() {
    const perms: string[] = [];

    this.roles.forEach((role) => {
      perms.push(...get(roles, role, []));
    });

    return perms.filter((v, i, s) => s.indexOf(v) === i);
  }

  isTokenExpired() {
    const lastLoginUnixTime = cache.get<string>(config.authLastLoggedCacheKey);

    if (!lastLoginUnixTime) return false;

    const isExpired = isBefore(new Date(lastLoginUnixTime), endOfYesterday());

    if (isExpired) {
      this.logout();
    }

    return isExpired;
  }
}

export default User;
