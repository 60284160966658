import React from 'react';
import Fade from 'react-reveal/Fade';
import { LogoIcon } from 'components/LogoIcon';
import { Pane } from 'evergreen-ui';
import { LogoContainer } from './AppLoader.styles';

export const AppLoader: React.FC = () => (
  <Pane
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    height="100vh"
  >
    <Fade distance="0px" duration={200}>
      <LogoContainer>
        <LogoIcon />
      </LogoContainer>
    </Fade>
  </Pane>
);
