import React from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { RoleAccess } from 'lib/auth';
import { Table } from './Table';
import { Button, Heading, Pane } from 'evergreen-ui';

export const UsersPage: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Users | {config.siteName}</title>
      </Helmet>

      <Fade key="Users" left distance="30px" duration={500}>
        <Pane>
          <Pane display="flex">
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={700} fontWeight={300}>
                Users
              </Heading>
            </Pane>

            <RoleAccess permission="users:list">
              <Pane alignItems="center" display="flex">
                <Button onClick={() => history.push('/user')}>Add User</Button>
              </Pane>
            </RoleAccess>
          </Pane>

          <RoleAccess permission="users:list">
            <Pane display="flex" flexDirection="column" flexGrow={0}>
              <Table />
            </Pane>
          </RoleAccess>
        </Pane>
      </Fade>
    </>
  );
};
