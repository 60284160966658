import React from 'react';
import isEqual from 'react-fast-compare';
import { Checkbox, Pane, SegmentedControl } from 'evergreen-ui';
import { options } from './options';

export class StyleForm extends React.Component<any> {
  public shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.print.subject, nextProps.print.subject) ||
      !isEqual(this.props.print.subject.date, nextProps.print.subject.date) ||
      !isEqual(this.props.print.timezone, nextProps.print.timezone) ||
      !isEqual(this.props.print.theme, nextProps.print.theme)
    );
  }

  private isWhiteBackground = () =>
    this.props.print.theme.primaryColor === '#ffffff';

  private handleColorChange(key): void {
    const { handlePrintChange, print } = this.props;
    const { hideConstellations, hideGraticule } = print.subject;
    const {
      primaryColor,
      secondaryColor,
      isImageBackground,
    }: any = options.styles.find((item) => item.key === key);

    handlePrintChange({
      subject: {
        primaryColor: isImageBackground ? 'none' : primaryColor,
        hideGraticule,
        hideConstellations,
        whiteBackground: isImageBackground ? false : this.isWhiteBackground(),
      },
      theme: {
        primaryColor: isImageBackground
          ? primaryColor
          : this.isWhiteBackground()
          ? secondaryColor
          : primaryColor,
        secondaryColor: isImageBackground
          ? secondaryColor
          : this.isWhiteBackground()
          ? primaryColor
          : secondaryColor,
        whiteBackground: isImageBackground ? false : this.isWhiteBackground(),
      },
    });
  }

  private handleUseWhiteBackground(): void {
    const { handlePrintChange } = this.props;
    const { primaryColor, secondaryColor } = this.getActiveColors();

    handlePrintChange({
      subject: {
        whiteBackground: !this.isWhiteBackground(),
      },
      theme: {
        primaryColor: !this.isWhiteBackground() ? secondaryColor : primaryColor,
        secondaryColor: !this.isWhiteBackground()
          ? primaryColor
          : secondaryColor,
        whiteBackground: !this.isWhiteBackground(),
      },
    });
  }

  private handleShapeChange(shape: string): void {
    const { handlePrintChange } = this.props;

    handlePrintChange({
      subject: { shape, whiteBackground: this.isWhiteBackground() },
      theme: { whiteBackground: this.isWhiteBackground() },
    });
  }

  private getActiveColors(): any {
    const { theme } = this.props.print;

    return options.styles.find(
      (item) =>
        (theme.primaryColor === item.primaryColor &&
          theme.secondaryColor === item.secondaryColor) ||
        (theme.primaryColor === item.secondaryColor &&
          theme.secondaryColor === item.primaryColor)
    );
  }

  private renderStyleButtons(): any {
    const { theme } = this.props.print;

    const activeStyle = options.styles.find(
      (style) =>
        (theme.primaryColor === style.primaryColor &&
          theme.secondaryColor === style.secondaryColor) ||
        (theme.primaryColor === style.secondaryColor &&
          theme.secondaryColor === style.primaryColor)
    );

    return (
      <SegmentedControl
        width="100%"
        options={options.styles.map((item) => {
          return { label: item.name, value: item.key };
        })}
        value={(activeStyle as any)?.key || options.styles[0]?.key}
        onChange={(value) => this.handleColorChange(value)}
        style={{ textAlign: 'center', height: '42px' }}
      />
    );
  }

  private renderShapeButtons(): any {
    const { print } = this.props;
    const { shape } = print.subject;

    return (
      <SegmentedControl
        width="100%"
        options={[
          { label: 'Circle', value: 'circle' },
          { label: 'Heart', value: 'heart' },
          { label: 'Star', value: 'star' },
        ]}
        value={shape || 'circle'}
        onChange={(value) => this.handleShapeChange(value.toString())}
        style={{ textAlign: 'center' }}
      />
    );
  }

  public render() {
    const { handlePrintChange, print } = this.props;
    const { hideConstellations, hideGraticule } = print.subject;

    return (
      <>
        <Pane width="100%">{this.renderStyleButtons()}</Pane>
        <Pane width="100%">{this.renderShapeButtons()}</Pane>
        <Pane width="100%">
          <Checkbox
            label="Hide Constellations"
            name="hideConstellations"
            onChange={() =>
              handlePrintChange({
                subject: {
                  hideConstellations: !hideConstellations,
                },
              })
            }
            checked={hideConstellations}
          >
            Show Constellations
          </Checkbox>
        </Pane>
        <Pane width="100%">
          <Checkbox
            label="Hide Grid"
            name="hideGraticule"
            onChange={() =>
              handlePrintChange({
                subject: {
                  hideGraticule: !hideGraticule,
                },
              })
            }
            checked={hideGraticule}
          >
            Show Grid
          </Checkbox>
        </Pane>
        {print.theme.primaryColor.includes('#') && (
          <Pane width="100%">
            <Checkbox
              label="White Background"
              name="useWhiteBackground"
              checked={this.isWhiteBackground()}
              onChange={() => this.handleUseWhiteBackground()}
            >
              On White Background
            </Checkbox>
          </Pane>
        )}
      </>
    );
  }
}
