import * as Sentry from '@sentry/react';
import { ApolloClient, DocumentNode, MutationUpdaterFn } from '@apollo/client';
import { RefetchQueryDescription } from '@apollo/client/core/watchQueryOptions';
import { get } from 'object-path';

export interface MutationResult<T> {
  ok: boolean;
  data: T | null | undefined;
  error: any;
}

export async function runMutation<K>(
  client: ApolloClient<any>,
  mutation: DocumentNode,
  variables: any,
  update?: MutationUpdaterFn<any>,
  refetchQueries?: RefetchQueryDescription
): Promise<MutationResult<K>> {
  try {
    const res = await client.mutate({
      mutation,
      variables,
      update,
      refetchQueries,
      awaitRefetchQueries: true,
    });

    if (res.errors && !res.data) {
      throw new Error(
        get(res, 'errors.0.message', 'Error. Please contact skeen.')
      );
    }

    const resKey = operationNameFromDefinition(mutation.definitions);
    const data = get(res, ['data', resKey || ''], null);

    return { ok: true, data, error: null };
  } catch (e) {
    Sentry.captureException(e);
    return { ok: false, data: null, error: e };
  }
}

function operationNameFromDefinition(definitions: any): string | null {
  const operationDefinition = definitions.find(
    (v) => v.kind === 'OperationDefinition'
  );

  if (!operationDefinition) {
    return null;
  }

  let resultKeyName = get(operationDefinition, 'name.value', null);

  const selections: any = get(
    operationDefinition,
    'selectionSet.selections',
    []
  );

  for (const selection of selections) {
    if (selection.kind === 'Field') {
      resultKeyName = selection.alias
        ? selection.alias.value
        : selection.name.value;
    }
  }

  return resultKeyName;
}
