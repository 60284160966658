import React, { useState } from 'react';
import dateFormat from 'date-fns/format';
import { Order } from 'models';
import { RoleAccess } from 'lib/auth';
import { NotesModal } from 'components/NotesModal';
import {
  Avatar,
  Button,
  Heading,
  Pane,
  Paragraph,
  Position,
  Text,
  Tooltip,
} from 'evergreen-ui';

interface NotesProps {
  order: Order;
}

export const Notes: React.FC<NotesProps> = ({ order }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const notes = order.getNotes();

  return (
    <>
      <Pane marginTop={40}>
        <Pane display="inline-flex" paddingBottom={5}>
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600} fontWeight={300}>
              Notes
            </Heading>
          </Pane>

          <Pane
            flex={1}
            display="flex"
            alignItems="center"
            marginLeft={16}
            paddingX={12}
          >
            <RoleAccess permission="orders:edit">
              <Button
                height={32}
                minWidth={90}
                onClick={() => setModalOpen(true)}
              >
                Add Note
              </Button>
            </RoleAccess>
          </Pane>
        </Pane>

        <Pane
          elevation={1}
          marginTop={5}
          borderRadius={6}
          display="flex"
          flexDirection="column"
          backgroundColor="#fdfdfd"
        >
          {notes.length < 1 && (
            <Pane
              flex={1}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Heading
                size={100}
                marginTop={10}
                marginBottom={10}
                textAlign="center"
              >
                No notes.
              </Heading>
            </Pane>
          )}

          {notes.map((note, i) => {
            if (!showAll && i > 1) return null;

            return (
              <Pane
                padding={12}
                display="flex"
                flexDirection="row"
                borderBottom="1px solid #eee"
                key={note.id}
              >
                <Pane display="flex" flexBasis={40} flexGrow={0}>
                  <Tooltip content={note.user?.name} position={Position.LEFT}>
                    <Avatar name={note.user?.name} size={28} />
                  </Tooltip>
                </Pane>
                <Pane flex={1} paddingX={14}>
                  <Paragraph
                    marginBottom={5}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {note.message}
                  </Paragraph>
                  <Text size={300} color="#8f95b2">
                    {note.user?.name} - {` `}
                    {note?.createdAt &&
                      dateFormat(
                        new Date(note?.createdAt),
                        "MMMM do yyyy 'at' h:mm a"
                      )}
                  </Text>
                </Pane>
              </Pane>
            );
          })}

          {notes.length > 2 && (
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="#f9f9f9"
              paddingY={8}
            >
              <Button
                intent="none"
                appearance="minimal"
                height={24}
                marginRight={5}
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? 'Hide' : 'Show More'}
              </Button>
            </Pane>
          )}
        </Pane>
      </Pane>

      {isModalOpen && (
        <NotesModal order={order} onClose={() => setModalOpen(false)} />
      )}
    </>
  );
};
