import React from 'react';
import { Order } from 'models';
import { Print } from './Print';
import { Sidebar } from './Sidebar';
import { Pane, SideSheet } from 'evergreen-ui';

interface OrderSidesheetProps {
  order: Order;
  onClose: () => void;
}

export const OrderSidesheet: React.FC<OrderSidesheetProps> = ({
  order,
  onClose = () => {},
}) => (
  <SideSheet
    width="80%"
    isShown={true}
    containerProps={{
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      style: { width: '100%' },
    }}
    onCloseComplete={() => onClose()}
  >
    <Pane
      flex="1"
      display="flex"
      flexDirection="row"
      alignItems="center"
      background="tint1"
    >
      <Print order={order} />
      <Sidebar order={order} />
    </Pane>
  </SideSheet>
);
