import React, { useState } from 'react';
import { Order, OrderItem } from 'models';
import { Dialog, Pane, Select, Heading, toaster } from 'evergreen-ui';

interface PrinterSubmissionModalProps {
  item: OrderItem;
  order: Order;
  onClose: () => void;
}

export const ChangeQuantityModal: React.FC<PrinterSubmissionModalProps> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(item.quantity);

  const setAmounts = () => {
    const amounts: number[] = [];

    for (let i = 1; i < 100; i++) {
      amounts.push(i);
    }

    return amounts;
  };

  const onSubmit = async () => {
    setLoading(true);

    const res = await order.updateItem(item.id, { quantity: quantity });

    if (!res.ok) {
      setLoading(false);
      toaster.danger(res.error?.message || 'Error changing quantity.');
      return;
    }

    toaster.success(`Successfully changed quantity.`);
    onClose();
  };

  return (
    <Dialog
      width={380}
      isShown={true}
      title="Change Quantity"
      shouldCloseOnOverlayClick={false}
      confirmLabel={isLoading ? 'Loading...' : 'Submit'}
      isConfirmLoading={isLoading}
      onConfirm={onSubmit}
      onCloseComplete={onClose}
    >
      <Pane>
        <Heading marginTop={20}>Quantity</Heading>
      </Pane>
      <Pane>
        <Select
          width="100%"
          height={52}
          marginTop={5}
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        >
          {setAmounts().map((amount) => (
            <option value={amount}>{amount}</option>
          ))}
        </Select>
      </Pane>
    </Dialog>
  );
};
