import { Type } from 'class-transformer';
import { get } from 'object-path';
import { formatCurrency } from 'lib/utils';
import { getPrintMeta } from './utils';
import Transaction from '../Transaction';
import { FulfillmentStatus, Item, ProductType } from 'lib/api/ecommerce/schema';
import type { AddOn } from './AddOn';
import type { CustomPrint } from './CustomPrint';
import type { StaticPrint } from './StaticPrint';

export enum CustomPrintType {
  MOON_MAP = 'moon_map',
  STAR_MAP = 'star_map',
  ZODIAC = 'zodiac',
}

export enum OrderItemTypes {
  add_on = 'add_on',
  custom_print = 'custom_print',
  static_print = 'static_print',
}

export interface OrderItemProduct {
  colorMap?: OrderItemColorMap;
  customPrintType?: CustomPrintType;
  printColor?: string;
  printShade?: OrderItemPrintShadeTypes;
  type?: OrderItemTypes;
}

export enum OrderItemPrintShadeTypes {
  dark = 'dark',
  white = 'white',
}

export interface OrderItemColorMap {
  [key: string]: {
    name: string;
    printShade: OrderItemPrintShadeTypes;
    shipstationCode: string;
  };
}

export type OrderItem = AddOn | CustomPrint | StaticPrint;

export class BaseOrderItem {
  public id!: Item['id'];
  public name!: Item['name'];
  public sku!: Item['sku'];
  public category!: Item['category'];
  public unitPrice!: Item['unitPrice'];
  public commodityCode!: Item['commodityCode'];
  public upc!: Item['upc'];
  public product!: Item['product'];
  public quantity!: Item['quantity'];
  public total!: Item['total'];
  public meta!: Item['meta'];
  public isPaid!: Item['isPaid'];
  public isRefunded!: Item['isRefunded'];
  public fulfillments!: any;
  public history!: any;
  public orderId!: string;

  @Type(() => Date)
  public addedAt;

  @Type(() => Date)
  public metaUpdatedAt;

  @Type(() => Transaction)
  public payment;

  @Type(() => Transaction)
  public refund;

  get formattedTotal() {
    return formatCurrency(this.total);
  }

  get formattedUnitPrice() {
    return formatCurrency(this.unitPrice);
  }

  get fulfillmentStatus() {
    if (!this.fulfillments) return null;

    const fulfillments = this.fulfillments?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    if (
      this.fulfillments.length < 1 ||
      fulfillments[0] === FulfillmentStatus.Cancelled
    )
      return 'WAITING_FULFILLMENT';

    return fulfillments[0].status;
  }

  get isPrint() {
    return this.category.indexOf('print') > -1;
  }

  get isDigital() {
    return (
      this.sku.indexOf('DIGITAL') > -1 ||
      this.product?.type === ProductType.Digital
    );
  }

  get isFulfilled() {
    return this.fulfillmentStatus === FulfillmentStatus.Fulfilled;
  }

  get isFulfillable() {
    return !this.isAddOn && !this.isDigital;
  }

  get isAddOn() {
    return this.category === 'addons';
  }

  get hasFrame() {
    return this.category.indexOf('frame') > -1;
  }

  get printMeta(): any {
    return getPrintMeta(this);
  }

  get printSize(): { width: number; height: number } {
    const { width, height } = get(this, 'meta.andalways:print');

    return { width, height };
  }

  get printFilename() {
    return `${this.orderId}_${this.id}_${this.metaUpdatedAt.getTime()}.pdf`;
  }
}

export default BaseOrderItem;
