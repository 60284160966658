import _groupBy from 'lodash/groupBy';
import { Order } from 'models';

export function groupOrdersByCustomer(orders: Order[]): Order[] {
  let groupedOrders: Order[] = [];
  let groupedArrOrders = _groupBy(orders, (order) => order.email as string);

  Object.keys(groupedArrOrders).forEach((key) => {
    groupedOrders = [...groupedOrders, ...groupedArrOrders[key]];
  });

  return groupedOrders;
}
