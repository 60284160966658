import { formatDate } from 'lib/utils';

const date = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
  zone: 'America/New_York',
};

export const options = {
  styles: [
    {
      name: 'Midnight Blue',
      label: 'Midnight Blue',
      screenColor: '#051c2c',
      primaryColor: '#051c2c',
      secondaryColor: '#ffffff',
      whiteBackground: false,
      isImageBackground: false,
      key: 'blue-blue',
    },
    {
      name: 'Black',
      label: 'Black',
      screenColor: '#000000',
      primaryColor: '#000000',
      secondaryColor: '#ffffff',
      whiteBackground: false,
      isImageBackground: false,
      key: 'black-black',
    },
    {
      name: 'Nebula',
      label: 'Nebula',
      primaryColor: 'nebula',
      secondaryColor: '#ffffff',
      whiteBackground: false,
      isImageBackground: true,
      key: 'nebula',
    },
    {
      name: 'Kids Astronaut',
      label: 'Kids Astronaut',
      primaryColor: 'kids-astronaut',
      secondaryColor: '#ffffff',
      whiteBackground: false,
      isImageBackground: true,
      key: 'kids-astronaut',
    },
  ],
  defaults: {
    meta: {
      timezone: {},
      subject: {
        kind: 'moon',
        address: '',
        lat: 40.713,
        lon: -74.006,
        date,
      },
      theme: {
        divider: '',
        tagline: `Over Our Moon\n${formatDate(
          date
        )} \nNew York, NY, USA\n40.713°N / 74.006°W`,
        headline: '',
        kind: 'legend',
        primaryColor: '#051c2c',
        secondaryColor: '#ffffff',
      },
    },
  },
};
