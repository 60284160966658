import _pick from 'lodash/pick';

export type User = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  password: string;
  roles: string[];
  isDisabled: boolean;
};

export const cleanUserArgs = (user: Partial<User>): Partial<User> =>
  _pick(user, ['id', 'name', 'email', 'password', 'roles', 'isDisabled']);

export const cleanUserArgsNoPassword = (user: Partial<User>): Partial<User> =>
  _pick(user, ['id', 'name', 'email', 'roles', 'isDisabled']);
