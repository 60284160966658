import React, { useMemo, useState, useEffect } from 'react';
import { Button, SelectMenu } from 'evergreen-ui';
import { ORDER_FILTERS, OrderFilterType } from './filter-types';

interface OrderFilterSelectProps {
  exclude?: OrderFilterType[];
  hasFilter?: boolean;
  isMultiSelect?: boolean;
  selected: any;
  title?: string;
  onChange: (selected: string[]) => void;
}

interface OrderFilterSelectItem {
  label: string;
  value: string;
}

export const OrderFilterStoreSelect: React.FC<OrderFilterSelectProps> = ({
  exclude,
  hasFilter = false,
  isMultiSelect = false,
  selected = [],
  title = 'Store',
  onChange = () => {},
}) => {
  const [storeName, setStoreName] = useState('All Stores');

  useEffect(() => {
    if (selected.includes('twinkle-in-time')) {
      setStoreName('Twinkle In Time');
    } else if (selected.includes('over-our-moon')) {
      setStoreName('Over Our Moon');
    } else {
      setStoreName('All Stores');
    }
  }, [selected]);

  const options = useMemo(() => {
    let filters = ORDER_FILTERS.filter((filter) => filter.storeFilter);

    if (exclude) {
      filters = filters.filter(({ type }) => !exclude.includes(type));
    }

    return filters.map(({ label, type }) => ({ label, value: type }));
  }, [exclude]);

  const onSelect = (item: OrderFilterSelectItem) => {
    if (selected.includes('twinkle-in-time')) {
      let selectedArr = selected.filter((item) => item !== 'twinkle-in-time');
      onChange([...(selectedArr as any), item.value]);
    } else if (selected.includes('over-our-moon')) {
      let selectedArr = selected.filter((item) => item !== 'over-our-moon');
      onChange([...(selectedArr as any), item.value]);
    } else {
      onChange([...(selected as any), item.value]);
    }
  };

  const onDeselect = (item: OrderFilterSelectItem) => {
    const deselectedItemIndex = (selected as any).indexOf(item.value);
    const selectedItems = (selected as any).filter(
      (_, i) => i !== deselectedItemIndex
    );

    onChange(selectedItems);
  };

  return (
    <SelectMenu
      isMultiSelect={isMultiSelect}
      hasFilter={hasFilter}
      title={title}
      options={options}
      selected={selected}
      onSelect={onSelect}
      onDeselect={onDeselect}
    >
      <Button iconAfter="caret-down">{storeName}</Button>
    </SelectMenu>
  );
};
