import React, { useState } from 'react';
import config from 'config';
import { CustomPrint, CustomPrintType, Order } from 'models';
import deepmerge from 'deepmerge';
import _cloneDeep from 'lodash/cloneDeep';
import { Product } from 'lib/api/ecommerce/schema';
import { Print } from '@andalways/react-print';
import { LegendTheme } from '@andalways/react-print-theme-legend';
import { Moon as MoonSubject } from '@andalways/react-print-subject-moon';
import { Stars as StarsSubject } from '@andalways/react-print-subject-stars';
import { StarTabs, MoonTabs, ZodiacTabs } from './tabs';
import { Dialog, Pane, Select, Tab, Tablist } from 'evergreen-ui';
import { PrintPreview } from './PrintPreview';
import { Wrapper, Inner, PrintWrapper } from './CustomPrintEditorModal.styles';

interface CustomPrintEditorModalProps {
  loading?: boolean;
  order: Order;
  print?: CustomPrint;
  product?: Product;
  onClose: () => void;
  onComplete: (printMeta: any) => void;
}

const CustomPrints: Record<CustomPrintType, any> = {
  moon_map: {
    name: 'Moon Map',
    subject: MoonSubject,
    tabs: MoonTabs,
    defaultMeta: config.moonmaps.defaults,
    defaultTagLine: 'Over Our Moon',
  },
  star_map: {
    name: 'Star Map',
    subject: StarsSubject,
    tabs: StarTabs,
    defaultMeta: config.starmaps.defaults,
    defaultTagLine: 'A Twinkle In Time',
  },
  zodiac: {
    name: 'Zodiac Print',
    subject: null,
    tabs: ZodiacTabs,
    defaultMeta: null,
    defaultTagLine: null,
  },
};

export const CustomPrintEditorModal: React.FC<CustomPrintEditorModalProps> = ({
  print,
  product,
  loading,
  onClose,
  onComplete,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [printMeta, setPrintMeta] = useState<any>(print?.printMeta);
  const [printType, setPrintType] = useState(print?.customPrintType);
  const aspectRatio = product?.meta?.printSizes
    ? product.meta.printSizes.width / product.meta.printSizes.height
    : printMeta?.size
    ? printMeta?.size?.width / printMeta?.size?.height
    : 0.75;

  const handlePrintChange = (values) =>
    setPrintMeta(_cloneDeep(deepmerge(printMeta, values)));

  const customPrint = CustomPrints?.[printType!];
  const tabs = customPrint?.tabs;

  return (
    <Dialog
      isShown={true}
      width={375 + 400}
      topOffset="5vmin"
      title={`Map Editor`}
      confirmLabel="Save"
      isConfirmLoading={loading}
      onCancel={onClose}
      onConfirm={() => onComplete(printMeta)}
    >
      <Wrapper>
        <Inner>
          <Pane
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Pane
              as="main"
              flex={1}
              width="100%"
              padding={15}
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="#f9f9f9"
              position="relative"
            >
              <PrintWrapper>
                {!customPrint?.subject ? (
                  <PrintPreview
                    width={aspectRatio * 500}
                    height={500}
                    print={printMeta}
                  />
                ) : (
                  <Print width={aspectRatio * 500} height={500}>
                    <LegendTheme kind="legend" {...printMeta.theme}>
                      {React.createElement(customPrint.subject, {
                        ...printMeta.subject,
                      })}
                    </LegendTheme>
                  </Print>
                )}
              </PrintWrapper>
            </Pane>
            <Pane
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              as="aside"
              flex={0}
              flexBasis={400}
              paddingTop={20}
              paddingLeft={30}
              paddingRight={10}
            >
              <Pane
                display="flex"
                flexDirection="column"
                flex={0}
                flexBasis={50}
              >
                <Tablist flexBasis={50}>
                  <Select
                    name="custom-print-type"
                    width="100%"
                    value={printType}
                    disabled={!!print?.customPrintType}
                    onChange={(e: any) => {
                      setPrintMeta({
                        ...CustomPrints[e.target.value].defaultMeta,
                        width: product?.meta?.printSizes?.width,
                        height: product?.meta?.printSizes?.height,
                      });
                      setPrintType(e.target?.value);
                    }}
                  >
                    <option>Select Custom Print</option>
                    {Object.keys(CustomPrints).map((key) => (
                      <option key={key} value={key}>
                        {CustomPrints[key].name}
                      </option>
                    ))}
                  </Select>
                </Tablist>
              </Pane>
              {tabs && (
                <Pane
                  display="flex"
                  flexDirection="column"
                  flex={0}
                  flexBasis={50}
                >
                  <Tablist flexBasis={50}>
                    {tabs.map((tab, index) => (
                      <Tab
                        key={tab.key}
                        id={tab.key}
                        aria-controls={`panel-${tab.key}`}
                        isSelected={index === activeTab}
                        onSelect={() => setActiveTab(index)}
                      >
                        {tab.value}
                      </Tab>
                    ))}
                  </Tablist>
                </Pane>
              )}

              <Pane display="flex" flex={1} width="100%">
                {tabs &&
                  tabs.map((tab, index) => (
                    <Pane
                      key={tab.key}
                      width="100%"
                      id={`panel-${tab.key}`}
                      role="tabpanel"
                      aria-labelledby={tab.value}
                      aria-hidden={index !== activeTab}
                      display={index === activeTab ? 'block' : 'none'}
                    >
                      <tab.component
                        print={printMeta}
                        storeTagLine={customPrint?.defaultTagLine}
                        handlePrintChange={handlePrintChange}
                      />
                    </Pane>
                  ))}
              </Pane>
            </Pane>
          </Pane>
        </Inner>
      </Wrapper>
    </Dialog>
  );
};

export default CustomPrintEditorModal;
