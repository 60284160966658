import React from 'react';
import { Order } from 'models';
import { Heading, Pane, Table } from 'evergreen-ui';
import { FulfillmentRow } from './FulfillmentRow';

interface FulfillmentProps {
  order: Order;
}

export const Fulfillments: React.FC<FulfillmentProps> = ({ order }) => {
  const fulfillments = order.fulfillments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <Pane marginTop={40}>
      <Pane display="flex" paddingBottom={15}>
        <Pane flex={1} alignItems="center" display="flex">
          <Heading size={600} fontWeight={300}>
            Fulfillments
          </Heading>
        </Pane>
      </Pane>

      <Pane
        display="flex"
        flexDirection="row"
        elevation={1}
        borderRadius={6}
        backgroundColor="#fdfdfd"
      >
        {fulfillments.length < 1 && (
          <Pane
            flex={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Heading
              size={100}
              marginTop={10}
              marginBottom={10}
              textAlign="center"
            >
              Awaiting fulfillment...
            </Heading>
          </Pane>
        )}

        {fulfillments.length > 0 && (
          <Pane
            flex={1}
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
          >
            <Table width="100%">
              <Table.Head>
                <Table.TextHeaderCell>Source</Table.TextHeaderCell>
                <Table.TextHeaderCell>Date Created</Table.TextHeaderCell>
                <Table.TextHeaderCell>Date Updated</Table.TextHeaderCell>
                <Table.TextHeaderCell>ID</Table.TextHeaderCell>
                <Table.HeaderCell justifyContent="flex-end">
                  Status
                </Table.HeaderCell>
              </Table.Head>

              <Table.Body>
                {fulfillments.map((fulfillment) => (
                  <FulfillmentRow
                    key={fulfillment.id}
                    fulfillment={fulfillment}
                    order={order}
                  />
                ))}
              </Table.Body>
            </Table>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
};
