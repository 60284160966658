import React, { useCallback, useState } from 'react';
import { Button, Dialog, Heading, Pane, Text, toaster } from 'evergreen-ui';
import { Order } from 'models';

interface SendReviewRequestButtonProps {
  order: Order;
}

export const SendReviewRequestButton: React.FC<SendReviewRequestButtonProps> = ({
  order,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogActive, setDialogActive] = useState<boolean>(false);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);

    const res = await order.sendCustomerReviewRequest();

    if (!res.ok) {
      toaster.danger(res.error?.message || 'Error sending review request.', {
        duration: 2,
      });
    } else {
      toaster.success('Review request email sent.', { duration: 2 });
    }

    setDialogActive(false);
    setIsLoading(false);
  }, [order]);

  const hasSentReview = !!order?.meta?.requestedCustomerReview;

  return (
    <>
      <Button
        marginRight={12}
        disabled={hasSentReview}
        iconBefore={hasSentReview ? 'tick' : null}
        onClick={() => setDialogActive(true)}
      >
        {hasSentReview ? 'Review Request Sent' : 'Send Review Request'}
      </Button>

      <Dialog
        hasHeader={false}
        confirmLabel="Send Request"
        isShown={isDialogActive}
        isConfirmLoading={isLoading}
        onConfirm={onSubmit}
        onCancel={() => setDialogActive(false)}
      >
        <Pane padding={20} textAlign="center">
          <Heading size={700} fontWeight={700} marginBottom={20}>
            Send Review Request
          </Heading>
          <Text size={500}>
            This will send an email to the customer requesting a review.
          </Text>
        </Pane>
      </Dialog>
    </>
  );
};
