import React from 'react';
import redaxios from 'redaxios';
import isEqual from 'react-fast-compare';
import GooglePlaces, {
  getLatLng,
  geocodeByAddress,
} from 'react-places-autocomplete';
import { formatDate } from 'lib/utils';
import { DateForm } from '../DateForm';
import { TextInput } from 'evergreen-ui';
import {
  LocationWrapper,
  Suggestion,
  SuggestionsContainer,
} from './LocationForm.styles';

export class LocationForm extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const address =
      props.print && props.print.subject && props.print.subject.address;

    this.state = {
      address: address || '',
      date: '',
      isGeocoding: false,
    };
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.state, nextState) ||
      !isEqual(this.state.date, nextState.date) ||
      !isEqual(this.props.print.subject, nextProps.print.subject) ||
      !isEqual(this.props.print.subject.date, nextProps.print.subject.date) ||
      !isEqual(this.props.print.timezone, nextProps.print.timezone) ||
      !isEqual(this.props.print.theme, nextProps.print.theme)
    );
  }

  private fetchTimezoneFromCoordinates = async ({
    latitude,
    longitude,
    timestamp,
  }: {
    latitude?: string | number;
    longitude?: string | number;
    timestamp?: string | number;
  }): Promise<any> => {
    try {
      return await redaxios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=AIzaSyBiaYbwH5PzK8hmbH07Jf0a9adPb5qXwmA`
      );
    } catch (e) {}
  };

  private handleChange = (address) => this.setState({ address });
  private handleSelect(address) {
    const { subject } = this.props.print;
    const { date } = subject;

    this.setState({ isGeocoding: true, address });

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.setState(
          {
            isGeocoding: false,
          },
          async () => {
            const timestamp =
              new Date(date.year, date.month, date.day).getTime() / 1000;
            const { data } = await this.fetchTimezoneFromCoordinates({
              latitude: lat,
              longitude: lng,
              timestamp,
            });

            this.handleLocationChange({
              address,
              lat: lat,
              lon: lng,
              timezone: data,
            });
          }
        );
      })
      .catch(() => {
        this.setState({ isGeocoding: false });
      });
  }

  public handleLocationChange({ address, lat, lon, timezone }) {
    const { handlePrintChange, print, storeTagLine } = this.props;
    const latDirection = lat > 0 ? 'N' : 'S';
    const lngDirection = lon > 0 ? 'E' : 'W';

    return handlePrintChange({
      subject: {
        address,
        lon,
        lat,
        date: {
          ...print.subject.date,
          zone: timezone.timeZoneId,
        },
      },
      theme: {
        tagline: `${storeTagLine}\n${formatDate(
          print.subject.date
        )}\n${address}\n${Math.abs(lat).toFixed(
          3
        )}°${latDirection} / ${Math.abs(lon).toFixed(3)}°${lngDirection}`,
      },
    });
  }

  public render() {
    const { address } = this.state;
    const { showLocationEmptyError = false } = this.props;
    const showError = showLocationEmptyError && address === '';

    return (
      <>
        <GooglePlaces
          value={address}
          onChange={this.handleChange.bind(this)}
          onSelect={this.handleSelect.bind(this)}
          debounce={600}
        >
          {({
            getInputProps,
            getSuggestionItemProps,
            loading,
            suggestions,
          }) => {
            return (
              <LocationWrapper>
                <TextInput
                  width="100%"
                  {...getInputProps({
                    placeholder: 'Choose A Location',
                    className: 'location-search-input',
                  })}
                />
                {showError && <>Choose a location!</>}

                <SuggestionsContainer>
                  {loading && <div>Loading...</div>}

                  {suggestions.map((suggestion) => {
                    return (
                      <Suggestion {...getSuggestionItemProps(suggestion, {})}>
                        {suggestion.description}
                      </Suggestion>
                    );
                  })}
                </SuggestionsContainer>
              </LocationWrapper>
            );
          }}
        </GooglePlaces>
        <DateForm {...this.props} />
      </>
    );
  }
}
