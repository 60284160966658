import React from 'react';
import isEqual from 'react-fast-compare';
import { Pane, Textarea } from 'evergreen-ui';

export class PersonalizeForm extends React.Component<any> {
  public shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.print, nextProps.print);
  }

  private handlePrintChange = (e) =>
    this.props.handlePrintChange({
      labels: { [e.target.name]: e.target.value },
    });

  public render() {
    const { labels } = this.props.print;
    const { headline, divider, tagline, subline } = labels;

    return (
      <Pane width="100%">
        <Pane width="100%">
          <Textarea
            name="headline"
            value={headline}
            placeholder="Headline message"
            onChange={this.handlePrintChange.bind(this)}
            width="100%"
          />
        </Pane>
        <Pane width="100%">
          <Textarea
            name="divider"
            value={divider}
            placeholder="Divider message"
            onChange={this.handlePrintChange.bind(this)}
            width="100%"
          />
        </Pane>
        <Pane width="100%">
          <Textarea
            name="tagline"
            value={tagline}
            placeholder="Tagline message"
            onChange={this.handlePrintChange.bind(this)}
            width="100%"
          />
        </Pane>
        <Pane width="100%">
          <Textarea
            name="subline"
            value={subline}
            placeholder="Subline message"
            onChange={this.handlePrintChange.bind(this)}
            width="100%"
          />
        </Pane>
      </Pane>
    );
  }
}
