import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import dateFormat from 'date-fns/format';
import { BaseOrder, Order } from 'models';
import { get } from 'object-path';
import { OrderStatus } from 'models';
import { useFulfillment } from '../../../FulfillmentProvider';
import { useFulfillmentOrders } from 'lib/api/ecommerce/hooks';
import { SidebarShippingInfo } from './SidebarShippingInfo';
import { NotesModal } from 'components/NotesModal';
import { ViewNotesModal } from 'components/ViewNotesModal';
import { OrderPaidMismatchAlert } from 'components/OrderPaidMismatchAlert';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { OrderReadinessBadge } from 'components/OrderReadinessBadge';
import { OtherPendingCancelAlert } from 'components/OtherPendingCancelAlert';
import { hasPrevPendingCancellationOrder } from 'models/Order/utils/hasPrevPendingCancellationOrder';
import { RushOrderBadge } from './RushOrderBadge';
import {
  Alert,
  Button,
  Icon,
  InlineAlert,
  Heading,
  Pane,
  Tooltip,
  Paragraph,
  Text,
} from 'evergreen-ui';
import { plainToClass } from 'class-transformer';
import { connect } from 'redux-zero/react';
import { StoreProps } from 'lib/store';
import {
  OrdersTableQuery,
  OrdersTableFilters,
} from 'components/OrdersTable/OrdersTableFilters';
import { CartStatus, OrdersQuery } from 'lib/api/ecommerce/schema';

export interface SidebarProps {
  ordersTable: { query: OrdersTableQuery; filters: OrdersTableFilters };
}

export const Sidebar: React.FC<SidebarProps> = ({ ordersTable }) => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isNotesModalOpen, setNotesModalOpen] = useState<boolean>(false);
  const [isViewNotesModalOpen, setViewNotesModalOpen] = useState<boolean>(
    false
  );

  const { order, orderIndex, ordersList, insertOrder } = useFulfillment();
  const { orders = [], fetch } = useFulfillmentOrders(
    {
      status: CartStatus.Draft,
      filter: { email: order?.email },
      query: OrdersQuery.All,
      reverse: true,
    },
    (data) => {
      const results = data.orders?.results || [];
      const orders = results.filter(
        (o) => !ordersList?.some((order) => order.id === o.id)
      );

      if (orders?.length > 0) {
        insertOrder(orderIndex + 1, plainToClass(BaseOrder, orders[0]));
      }
    }
  );

  const firstNote = order?.getFirstNote();
  const customerOrders = useMemo(
    () =>
      orders.filter(
        (o) => o.status === OrderStatus.Draft && o.id !== order?.id && o.paidAt
      ) ?? [],
    [orders, order]
  );

  useEffect(() => {
    if (order) {
      setShowAlert(hasPrevPendingCancellationOrder(order, orders));
    }
  }, [setShowAlert, order, orders]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (!order) return null;

  return (
    <Pane
      flex={0}
      flexBasis={360}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      padding={16}
      height="100%"
      overflowY="scroll"
      backgroundColor="white"
      borderLeft="1px solid #eee"
    >
      <Pane
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        marginTop={8}
      >
        {customerOrders.map((customerOrder: BaseOrder, i: number) => (
          <Pane
            key={customerOrder.id}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            marginBottom={10}
          >
            <InlineAlert
              intent="success"
              marginTop={0}
              marginBottom={0}
              style={{ cursor: 'pointer', width: '100%' }}
              onClick={() =>
                window.open('/orders/' + customerOrder.id, '_blank')
              }
            >
              Draft Order #{i + 1}{' '}
              {<OrderReadinessBadge order={customerOrder as Order} />}
            </InlineAlert>
          </Pane>
        ))}

        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="row"
          marginTop={10}
          marginBottom={20}
        >
          <OrderStatusBadge status={order.status} />

          {order.isRushOrder && <RushOrderBadge />}
          {order.isCustomerApproved && (
            <Tooltip content="Customer Approved">
              <Icon icon="endorsed" color="muted" size={14} marginLeft={10} />
            </Tooltip>
          )}
        </Pane>

        {order.getHasHangerFrame() && (
          <Alert
            marginBottom={16}
            appearance="card"
            intent="danger"
            title="Order has Hanger Frames!!"
          />
        )}

        <Pane marginBottom={20}>
          {showAlert && (
            <OtherPendingCancelAlert
              onClick={() => history.push(`/orders/${order.id}`)}
            />
          )}
        </Pane>

        <Pane marginBottom={20}>
          <OrderPaidMismatchAlert order={order} />
        </Pane>

        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Full Name
          </Heading>
          <Heading size={500}>{order.fullName}</Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Order ID
          </Heading>
          <Heading size={500}>{order.id}</Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Date
          </Heading>
          <Heading size={500}>
            {dateFormat(new Date(order.paidAt), 'E, MMM do yyyy h:mm a')}
          </Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            E-mail Address
          </Heading>
          <Heading size={500}>{order.email}</Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100}>Notes</Heading>

          {firstNote && (
            <Pane
              paddingY={8}
              display="flex"
              flexDirection="row"
              key={firstNote.id}
            >
              <Pane flex={1}>
                <Paragraph marginBottom={5} style={{ whiteSpace: 'pre-wrap' }}>
                  {firstNote.message}
                </Paragraph>

                <Text size={300} color="#8f95b2">
                  {firstNote.user?.name} - {` `}
                  {firstNote?.createdAt &&
                    dateFormat(
                      new Date(firstNote?.createdAt),
                      "MMMM do yyyy 'at' h:mm a"
                    )}
                </Text>
              </Pane>
            </Pane>
          )}

          <Pane display="flex" flexDirection="row">
            <Button
              height={28}
              marginTop={5}
              onClick={() => setNotesModalOpen(true)}
            >
              Add Note
            </Button>

            {order?.getNotes().length > 0 && (
              <Button
                height={28}
                marginTop={5}
                marginLeft={8}
                onClick={() => setViewNotesModalOpen(true)}
              >
                View All
              </Button>
            )}
          </Pane>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Shipping Rate
          </Heading>
          <Heading size={500}>
            {get(order?.getShipping(), 'meta.method', '--')}
          </Heading>
        </Pane>

        <SidebarShippingInfo order={order} />
      </Pane>

      {isNotesModalOpen && (
        <NotesModal
          order={order as Order}
          onClose={() => setNotesModalOpen(false)}
        />
      )}

      {isViewNotesModalOpen && (
        <ViewNotesModal
          order={order as Order}
          onClose={() => setViewNotesModalOpen(false)}
        />
      )}
    </Pane>
  );
};

export default connect<StoreProps>(({ fulfillmentPage }) => ({
  ordersTable: fulfillmentPage.ordersTable,
}))(Sidebar);
