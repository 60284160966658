import * as Sentry from '@sentry/browser';
import redaxios from 'redaxios';
import config from 'config';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import { BaseOrder } from './Base';
import { OrderItem } from '../OrderItem';
import { FulfillmentOptions } from 'config/fulfillment';
import * as GraphQLSchema from 'lib/api/ecommerce/schema';
import { PrinterApiClient } from 'lib/api/printing';
import { EcommerceApiClient } from 'lib/api/ecommerce';
import { runMutation, MutationResult } from 'lib/api/runMutation';
import type { FulfillmentItem, CustomPrint, StaticPrint } from 'models';
import {
  PrinterJobState,
  CreatePrinterJobDocument,
  CreatePrinterJobMutation,
} from 'lib/api/printing/schema';
import {
  composePrinterFiles,
  composePrintfulArgs,
  composeShipstationArgs,
} from './utils';

export class Order extends BaseOrder {
  private ecommerceApi = EcommerceApiClient;
  private printingApi = PrinterApiClient;

  async updateOrder(data: GraphQLSchema.UpdateCartPatchInput) {
    return await runMutation<GraphQLSchema.UpdateCartMutation['updateCart']>(
      this.ecommerceApi,
      GraphQLSchema.UpdateCartDocument,
      { input: { id: this.id, data } }
    );
  }

  async updateOrderStatus(status: GraphQLSchema.CartStatus) {
    return await runMutation<
      GraphQLSchema.UpdateCartStatusMutation['updateCartStatus']
    >(this.ecommerceApi, GraphQLSchema.UpdateCartStatusDocument, {
      input: { id: this.id, status },
    });
  }

  async addItems(items: GraphQLSchema.CartItemInput[]) {
    return await runMutation<
      GraphQLSchema.AddItemsToCartMutation['addItemsToCart']
    >(this.ecommerceApi, GraphQLSchema.AddItemsToCartDocument, {
      input: { id: this.id, items },
    });
  }

  async addAdjustment(data: GraphQLSchema.AddAdjustmentToCartInput) {
    return await runMutation<
      GraphQLSchema.AddAdjustmentToCartMutation['addAdjustmentToCart']
    >(this.ecommerceApi, GraphQLSchema.AddAdjustmentToCartDocument, {
      input: { id: this.id, data },
    });
  }

  async updateShipping(
    data: Omit<GraphQLSchema.UpdateAdjustmentForCartInput['data'], 'type'>
  ) {
    return await runMutation<
      GraphQLSchema.UpdateAdjustmentForCartMutation['updateAdjustmentForCart']
    >(this.ecommerceApi, GraphQLSchema.UpdateAdjustmentForCartDocument, {
      input: {
        id: this.id,
        adjustment: this.getShipping().id,
        data: { type: GraphQLSchema.AdjustmentType.Shipping, ...data },
      },
    });
  }

  async updateItem(item: string, data: GraphQLSchema.UpdateCartItemDataInput) {
    return await runMutation<
      GraphQLSchema.UpdateCartItemMutation['updateCartItem']
    >(this.ecommerceApi, GraphQLSchema.UpdateCartItemDocument, {
      input: { id: this.id, item, data },
    });
  }

  async cancel(reason: string, comments: string) {
    return await runMutation<
      GraphQLSchema.UpdateTimelineMutation['updateTimeline']
    >(
      this.ecommerceApi,
      GraphQLSchema.UpdateCartStatusDocument,
      {
        input: {
          id: this.id,
          status: GraphQLSchema.CartStatus.Cancelled,
          timeline: {
            text: 'Order was cancelled.',
            meta: { reason, comments },
          },
        },
      },
      (_, { data: { updateTimeline } }) => {
        this.timelines = [updateTimeline, ...this.timelines];
      }
    );
  }

  async addNote(note: string) {
    return await runMutation<
      GraphQLSchema.UpdateTimelineMutation['updateTimeline']
    >(
      this.ecommerceApi,
      GraphQLSchema.UpdateTimelineDocument,
      {
        input: {
          cart: this.id,
          data: { type: 'NOTE', text: 'A note was added', meta: { note } },
        },
      },
      (_, { data: { updateTimeline } }) => {
        this.timelines = [updateTimeline, ...this.timelines];
      },
      ['cartAndTimelines']
    );
  }

  async updateFulfillment(
    id: string,
    data: GraphQLSchema.UpdateFulfillmentInput
  ) {
    return await runMutation<
      GraphQLSchema.UpdateFulfillmentForCartMutation['updateFulfillmentForCart']
    >(this.ecommerceApi, GraphQLSchema.UpdateFulfillmentForCartDocument, {
      input: { id: this.id, fulfillment: id, data },
    });
  }

  async removeItems(itemIds: string[]) {
    return await runMutation<
      GraphQLSchema.RemoveItemsFromCartMutation['removeItemsFromCart']
    >(this.ecommerceApi, GraphQLSchema.RemoveItemsFromCartDocument, {
      input: { id: this.id, items: itemIds },
    });
  }

  async validateAndUpdateAddress() {
    let address = _omit(this.shippingAddress, ['__typename']);
    address = _omitBy(address, _isNil) as any;

    return await runMutation<
      GraphQLSchema.ValidateAddressMutation['validateAddress']
    >(this.ecommerceApi, GraphQLSchema.ValidateAddressDocument, {
      input: { ...address },
    });
  }

  async printFileExists(printId: string): Promise<boolean> {
    const prints = this.getPrints();
    const print = prints.find((print) => print.id === printId);

    try {
      const res = await redaxios.get(
        `${process.env.REACT_APP_IMAGE_GENERATOR_API_URL?.toString()}/exists/${
          print?.printFilename
        }`,
        { responseType: 'json' }
      );

      return res?.data?.exists;
    } catch (e) {
      return false;
    }
  }

  async regeneratePrint(print: OrderItem) {
    const { id, printMeta, composePrintMeta } = print as CustomPrint;

    return this.updateItem(id, { meta: composePrintMeta(printMeta) });
  }

  async sendCustomerReviewRequest() {
    try {
      const response: any = await redaxios.post(
        '/api/reviews/sendInvite',
        {
          order_id: this.id,
          email: this.email,
          name: this.fullName,
          store: 'twinkleintime-com',
          template_id: '20621',
        },
        { responseType: 'json' }
      );

      const data = response.data;

      if (data?.status !== 'success') {
        throw new Error(data?.message || 'Error sending review request.');
      }

      return await this.updateOrder({
        meta: { ...this.meta, requestedCustomerReview: true },
      });
    } catch (e: any) {
      let error;

      // reviews.io sends back non Error object
      if (!(e instanceof Error)) {
        error = new Error(e?.statusText || 'Problem sending review request.');
        error.name = 'FetchError';
      } else {
        error = e;
      }

      Sentry.captureException(error);

      return { ok: false, data: null, error };
    }
  }

  /*
   * TODO: make more elegant
   */
  async fulfill(items: FulfillmentItem[]): Promise<MutationResult<any>> {
    try {
      const fulfillableItems = items.filter(({ item }) => item.isFulfillable);

      if (fulfillableItems.length > 0) {
        await this.fulfillItems(fulfillableItems);
      } else {
        await this.updateOrderStatus(GraphQLSchema.CartStatus.Fulfilled);
      }

      return { ok: true, data: null, error: null };
    } catch (e) {
      return { ok: false, data: null, error: e };
    }
  }

  private async fulfillItems(
    items: FulfillmentItem[]
  ): Promise<{ printful: any; andalways: any }> {
    const printfulItems = items
      .filter((item) => item.source === FulfillmentOptions.PRINTFUL)
      .map(({ item }) => item);
    const andalwaysItems = items.filter(
      (item) => item.source === FulfillmentOptions.ANDALWAYS
    );

    let printfulResponse;
    let andalwaysResponse;

    if (printfulItems?.length) {
      printfulResponse = await this.fulfillPrintful(printfulItems);

      if (!printfulResponse.ok) {
        throw new Error('Error submitting to Printful.');
      }
    }

    if (andalwaysItems?.length) {
      const pod = andalwaysItems[0].pod;
      const aaItems = andalwaysItems.map(({ item }) => item);
      const addonItems = this.items.filter((k) => k.isAddOn && !k.isFulfilled);

      if (!pod) {
        throw new Error('No POD is assigned.');
      }

      andalwaysResponse = await this.fulfillAndAlways(
        [...aaItems, ...addonItems],
        pod as any
      );

      if (!andalwaysResponse.ok) {
        throw new Error(andalwaysResponse.error);
      }
    }

    return {
      printful: printfulResponse,
      andalways: andalwaysResponse,
    };
  }

  async sendItemsToPrinter(
    items: (CustomPrint | StaticPrint)[],
    pod?: string,
    quantity?: number
  ) {
    // temporary until we have production printer service
    if (!config.isProduction) {
      return { ok: true, error: null };
    }

    const files = composePrinterFiles(items, this, quantity);
    const timestamp = new Date().getTime();

    return await runMutation<CreatePrinterJobMutation['createPrinterJob']>(
      this.printingApi,
      CreatePrinterJobDocument,
      {
        input: {
          files,
          assignedTo: pod,
          key: `${this.id}-${timestamp}`,
          meta: { order: this.id },
          order: timestamp,
          state: PrinterJobState.Ready,
        },
      }
    );
  }

  private async sendItemsToShipstation(
    items: OrderItem[],
    pod?: string | null
  ) {
    const input = composeShipstationArgs(this, items, pod);

    return await runMutation<
      GraphQLSchema.FulfillShipStationMutation['temp__fulfillShipStation']
    >(this.ecommerceApi, GraphQLSchema.FulfillShipStationDocument, {
      input: { id: this.id, ...input },
    });
  }

  private async fulfillAndAlways(
    items: OrderItem[],
    pod: string
  ): Promise<MutationResult<{ printer: any; shipstation: any }>> {
    try {
      let printerJobResponse: any = {};
      let shipstationResponse: any = {};

      shipstationResponse = await this.sendItemsToShipstation(items, pod);

      if (!shipstationResponse.ok) {
        throw new Error('Error submitting to Shipstation');
      }

      const prints = items.filter((item) => item.isPrint) as (
        | CustomPrint
        | StaticPrint
      )[];

      if (prints.length) {
        printerJobResponse = await this.sendItemsToPrinter(prints, pod);

        if (!printerJobResponse.ok) {
          throw new Error('Error creating printer job.');
        }
      }

      return {
        ok: true,
        error: null,
        data: {
          printer: printerJobResponse,
          shipstation: shipstationResponse,
        },
      };
    } catch (e) {
      return { ok: false, data: null, error: e };
    }
  }

  private async fulfillPrintful(items: (CustomPrint | StaticPrint)[]) {
    const input = composePrintfulArgs(items);

    return await runMutation<
      GraphQLSchema.FulfillPrintfulMutation['temp__fulfillPrintful']
    >(this.ecommerceApi, GraphQLSchema.FulfillPrintfulDocument, {
      input: { id: this.id, ...input },
    });
  }
}
