import React, { useState } from 'react';
import { Order, OrderItem } from 'models';
import {
  Pane,
  Heading,
  Paragraph,
  Button,
  Select,
  toaster,
} from 'evergreen-ui';

type Props = {
  item: OrderItem;
  order: Order;
  onClose: () => void;
};

const setAmounts = (item: OrderItem) => {
  const amounts: number[] = [];

  for (let i = 0; i < item.quantity; i++) {
    amounts.push(i + 1);
  }

  return amounts;
};

export const RemoveFrame: React.FC<Props> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [quantityToRemove, setQuantityToRemove] = useState<number>(
    item.quantity
  );

  return (
    <>
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16}>
          <Heading size={600}>Remove Frame</Heading>

          <Paragraph size={400}>
            Are you sure you want to remove frame?
          </Paragraph>
        </Pane>
      </Pane>
      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Pane>
          <Heading>Quantity to Remove:</Heading>
        </Pane>
        <Pane paddingBottom={16}>
          <Select
            width="20%"
            height={52}
            marginTop={5}
            value={quantityToRemove}
            onChange={(e) => setQuantityToRemove(parseInt(e.target.value))}
          >
            {setAmounts(item).map((amount) => (
              <option key={amount} value={amount}>
                {amount}
              </option>
            ))}
          </Select>
        </Pane>
        <Button
          disabled={loading}
          marginTop={14}
          appearance="primary"
          isLoading={loading}
          intent="danger"
          onClick={async () => {
            setLoading(true);

            let printSku;

            if (item.sku.includes('P12X16')) {
              printSku = 'P12X16';
            } else if (item.sku.includes('P18X24')) {
              printSku = 'P18X24';
            } else if (item.sku.includes('P24X36')) {
              printSku = 'P24X36';
            }

            // if removing all frames, just change item sku
            if (quantityToRemove === item.quantity) {
              const res = await order.updateItem(item.id, { sku: printSku });

              if (!res.ok) {
                setLoading(false);
                toaster.danger(res.error?.message);

                return;
              }

              toaster.success(
                `Frame${
                  quantityToRemove > 1 ? 's' : null
                } successfully removed.`
              );
              setLoading(false);
              onClose();
            } else {
              // if removing some frames, dupe item
              const newFramedQuantity = item.quantity - quantityToRemove;
              const printMeta = {
                meta: item.meta,
                quantity: quantityToRemove,
                sku: printSku,
              };

              const isResponseOk = (res: PromiseSettledResult<any>) =>
                res.status === 'fulfilled' && res.value.ok;

              try {
                const [addItems, updateItems] = await Promise.allSettled([
                  order.addItems([{ ...printMeta }]),
                  order.updateItem(item.id, {
                    quantity: newFramedQuantity,
                  }),
                ]);

                if (!isResponseOk(addItems)) {
                  if (isResponseOk(updateItems)) {
                    // reset original quantity
                    await order.updateItem(item.id, {
                      quantity: item.quantity,
                    });
                  }

                  throw new Error();
                }

                if (!isResponseOk(updateItems)) {
                  if (isResponseOk(addItems)) {
                    const items = (addItems as PromiseFulfilledResult<any>)
                      .value.data?.items;
                    const itemId = items[items.length - 1].id;

                    await order.removeItems([itemId]);
                  }

                  throw new Error();
                }

                toaster.success('Frame removed successfully.');
              } catch (e) {
                toaster.danger(
                  'There was a problem removing the frame. Please reload the page before trying again.'
                );
              } finally {
                setLoading(false);
                onClose();
              }
            }
          }}
        >
          Remove frame
        </Button>
        <Button
          disabled={loading}
          marginTop={14}
          marginLeft={14}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </Pane>
    </>
  );
};

export default RemoveFrame;
