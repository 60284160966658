import React from 'react';
import getDayOfYear from 'date-fns/getDayOfYear';
import { formatCurrency } from 'lib/utils';
import { Order, BaseOrder, OrderItem, OrderItemTypes } from 'models';
import { Icon, Tooltip } from 'evergreen-ui';
import { orderIsRushOrder } from 'models/Order/utils/filters';

export const orderToWords = (order: Order | BaseOrder) => {
  let numFrames = 0;
  const numPosters = order.getPosterQuantity();
  const numDigitalPrints = order.getDigitalQuantity();

  order?.items.forEach((item: OrderItem, i: number) => {
    if (!item.isPrint) return;
    if (item.hasFrame) numFrames += item.quantity;
  });

  const postersText = `${
    numPosters > 0 ? `${numPosters} poster${numPosters > 1 ? 's' : ''}` : ''
  }`;

  const framesText = `${
    numFrames > 0 ? `, ${numFrames} frame${numFrames > 1 ? 's' : ''}` : ''
  }`;

  const digitalPrintsText = `${
    numDigitalPrints > 0
      ? `${numPosters > 0 ? ',' : ''} ${numDigitalPrints} digital print${
          numDigitalPrints > 1 ? 's' : ''
        }`
      : ''
  }`;

  return `${postersText}${framesText}${digitalPrintsText}`;
};

export const printErgency = (order: Order | BaseOrder) => {
  let dayDifference;

  if (order.store !== 'twinkle') return null;

  const isPosterDateSoon = order?.items.some((item: OrderItem) => {
    if (!item.isPrint || !item.printMeta?.subject?.date) return false;
    const date = item.printMeta !== null && item.printMeta?.subject?.date;
    const { month, day, year } = date;

    dayDifference =
      getDayOfYear(new Date(year, month - 1, day)) - getDayOfYear(new Date());

    return -1 < dayDifference && dayDifference < 15;
  });

  if (!isPosterDateSoon) return null;

  return (
    <Tooltip content={`Poster date is soon! ${dayDifference} days`}>
      <Icon icon="info-sign" color="muted" size={14} marginLeft={16} />
    </Tooltip>
  );
};

export const shippingUpgrade = (order: Order | BaseOrder) => {
  if (
    !order?.shippingMethod ||
    order?.shippingMethod?.toLowerCase() === 'standard'
  )
    return null;

  return (
    <Tooltip
      content={`${order.shippingName || order.shippingMethod} shipping!`}
    >
      <Icon icon="box" color="muted" size={14} marginLeft={10} />
    </Tooltip>
  );
};

export const hasHangerFrame = (order: Order | BaseOrder) => {
  return order.getHasHangerFrame() ? (
    <Tooltip content="Hanger Frame">
      <Icon icon="widget-header" color="muted" size={12} marginLeft={10} />
    </Tooltip>
  ) : null;
};

export const customerApprovedIcon = (order: Order | BaseOrder) => {
  if (!order.isCustomerApproved) return null;

  return (
    <Tooltip content="Customer Approved">
      <Icon icon="endorsed" color="muted" size={12} marginLeft={10} />
    </Tooltip>
  );
};

export const isOldMagenta = (order: BaseOrder) => {
  const isOldMagenta = order.items.filter(
    (item) =>
      (item as any).type === OrderItemTypes.custom_print &&
      (item as any)?.isOldMagenta
  );

  if (!isOldMagenta.length) return null;

  return (
    <Tooltip content="Update print with new magenta.">
      <Icon icon="help" color="muted" size={12} marginLeft={10} />
    </Tooltip>
  );
};

export const voucherCode = (order: Order | BaseOrder) => {
  const discount = order.getDiscount();

  if (!discount) return null;

  const formattedDiscount =
    discount.meta.type === 'percentage'
      ? `${Math.abs(discount.amount)}%`
      : formatCurrency(Math.abs(discount.amount));

  return (
    <Tooltip
      content={`${discount.meta.code.toUpperCase()} (${formattedDiscount})`}
    >
      <Icon icon="tag" color="muted" size={12} marginLeft={10} />
    </Tooltip>
  );
};

export const isRushOrder = (order: Order | BaseOrder) => {
  if (!orderIsRushOrder(order)) return null;

  return (
    <Tooltip content="Rush Order">
      <Icon icon="stopwatch" color="muted" size={14} marginLeft={10} />
    </Tooltip>
  );
};

export const getShopIcon = (order: Order | BaseOrder) => {
  let icon: string | null;

  switch (order.store) {
    case 'overourmoon':
      icon = 'moon';
      break;
    case 'twinkle':
      icon = 'star';
      break;
    case 'andalways':
      icon = 'shop';
      break;
    default:
      icon = null;
  }

  if (!icon) return null;

  return (
    <Tooltip content={order.store}>
      <Icon
        size={12}
        marginRight={10}
        color="muted"
        style={{ opacity: '0.4' }}
        icon={icon as any}
      />
    </Tooltip>
  );
};
