import type { CustomPrint, StaticPrint } from '../..';

interface PrintfulArgsItem {
  id: string;
  image: string;
}

interface PrintfulArgs {
  items: PrintfulArgsItem[];
}

export function composePrintfulArgs(
  items: (CustomPrint | StaticPrint)[]
): PrintfulArgs {
  return {
    items: items.map((item) => ({ id: item.id, image: item.getPrintUrl() })),
  };
}
