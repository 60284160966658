import React, { useEffect, useState } from 'react';
import { Alert, Pane, Paragraph, IconButton } from 'evergreen-ui';
import { Order } from 'models';

interface OrderPaidMismatchAlertProps {
  order: Order;
}

export const OrderPaidMismatchAlert: React.FC<OrderPaidMismatchAlertProps> = ({
  order,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen && order.paid !== order.total) {
      setIsOpen(true);
    }
  }, [order.paid, order.total]);

  if (!isOpen) {
    return null;
  }

  return (
    <Pane>
      <Alert
        appearance="card"
        intent="warning"
        title="Order Price Mismatch"
        paddingRight={36}
      >
        <Paragraph marginRight={36} marginTop={5}>
          This order has a mismatch between the paid amount and order item total
          suggesting the customer made unwarranted changes to their order after
          they have purchased. Please take the necessary steps to fix.
        </Paragraph>
        <IconButton
          icon="cross"
          cursor="pointer"
          position="absolute"
          top={12}
          right={12}
          onClick={() => setIsOpen(false)}
        />
      </Alert>
    </Pane>
  );
};
