import styled from 'styled-components';

export const ProductList = styled.div`
  width: 100%;
  list-style: none;
  margin: 0;
`;

export const ProductListItem = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eeeeee;
  opacity: ${(props: any) => (props.isHidden ? '60%' : '100%')};
`;
