import React from 'react';
import { useHistory } from 'react-router-dom';
import { RoleAccess } from 'lib/auth';
import { Button, Pane, SearchInput } from 'evergreen-ui';

interface FiltersProps {
  searchInput: string;
  setSearchInput: (value: string) => void;
}

export const Filters: React.FC<FiltersProps> = ({
  searchInput,
  setSearchInput,
}) => {
  const history = useHistory();

  return (
    <Pane alignItems="center" display="flex">
      <SearchInput
        placeholder="Search vouchers..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />

      <RoleAccess permission="vouchers:create">
        <Pane alignItems="center" display="flex" marginLeft={16}>
          <Button onClick={() => history.push('/voucher')}>Add Voucher</Button>
        </Pane>
      </RoleAccess>
    </Pane>
  );
};
