import styled from 'styled-components';

interface PrintWrapperProps {
  isClickable: boolean;
}

export const PrintWrapper = styled.div<PrintWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ${(props) => props.isClickable && `cursor: pointer;`}

  img {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  }
`;

export const DownloadPDF = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: white;
    background-image: -webkit-linear-gradient(to bottom, #0788de, #116ab8);
    background-image: -moz-linear-gradient(to bottom, #0788de, #116ab8);
    background-image: linear-gradient(to bottom, #0788de, #116ab8);
    box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06);
    padding: 0.36rem 1rem;
    border-radius: 4px;
    font-size: 0.8rem;

    &:hover {
      color: white !important;
    }
  }
`;

interface ImageContainerProps {
  hasLoaded: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  img {
    display: ${(props) => (props.hasLoaded ? 'block' : 'none')};
  }
`;
