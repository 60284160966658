import React from 'react';
import config from 'config';
import { Helmet } from 'react-helmet';
import { Form } from './Form';

export const CreateOrderPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Create Order | {config.siteName}</title>
      </Helmet>

      <Form />
    </>
  );
};
