import fetch from 'isomorphic-unfetch';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

const isBrowser = typeof window !== 'undefined';

export const PrinterApiClient = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: isBrowser,
  link: createHttpLink({
    uri: process.env.REACT_APP_PRINTING_API_URL?.toString(),
    fetch,
  }),
  ssrMode: false,
});
