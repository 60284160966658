import { BaseOrder } from '../..';
import { get } from 'object-path';
import { getDeliveryDate } from './getDeliveryDate';

/**
 * NOTE: Orders prior to August 2020 may not contain shipping range
 * inside order shipping meta. NULL is return for said orders as we
 * are not sure the shipping range they chose at purchase as these
 * ranges have changed over time.
 *
 */
export const getDeliveryDueDate = (order: BaseOrder): Date | null => {
  let slowestDayRate: any = get(order.getShipping(), 'meta.range.1', null);
  let shippingMethod: any = get(order.getShipping(), 'meta.method', null);

  // backwards shit
  if (!slowestDayRate) {
    return null;
  }

  return getDeliveryDate(
    order.getProductionDateEstimate(),
    slowestDayRate,
    shippingMethod.toLowerCase()
  );
};
