import dayjs from 'dayjs';

export const isFriday = (date: dayjs.Dayjs): boolean => date.day() === 5;
export const isSaturday = (date: dayjs.Dayjs): boolean => date.day() === 6;
export const isSunday = (date: dayjs.Dayjs): boolean => date.day() === 0;

export const isWeekend = (date: dayjs.Dayjs): boolean => {
  const dayOfWeek = date.day();

  return dayOfWeek === 0 || dayOfWeek === 6;
};
