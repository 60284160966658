import { User } from 'lib/api/ecommerce/schema';

export class BaseUser {
  public id!: User['id'];
  public name!: User['name'];
  public email!: User['email'];
  public roles!: User['roles'];
}

export default BaseUser;
