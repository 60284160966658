import React from 'react';
import { Order } from 'models';
import dateFormat from 'date-fns/format';
import {
  Dialog,
  Pane,
  Tooltip,
  Avatar,
  Text,
  Paragraph,
  Position,
} from 'evergreen-ui';

interface ViewNotesModalProps {
  order: Order;
  onClose: () => void;
}

export const ViewNotesModal: React.FC<ViewNotesModalProps> = ({
  order,
  onClose = () => {},
}) => {
  const notes = order.getNotes();

  return (
    <Dialog
      width={520}
      isShown={true}
      title="Order Notes"
      onCloseComplete={onClose}
      hasCancel={false}
      hasClose={true}
      intent="none"
      confirmLabel="Close"
    >
      {notes.map((note) => (
        <Pane
          padding={12}
          display="flex"
          flexDirection="row"
          borderBottom="1px solid #eee"
          key={note.id}
        >
          <Pane display="flex" flexBasis={40} flexGrow={0}>
            <Tooltip content={note.user?.name} position={Position.LEFT}>
              <Avatar name={note.user?.name} size={28} />
            </Tooltip>
          </Pane>
          <Pane flex={1} paddingX={14}>
            <Paragraph marginBottom={5} style={{ whiteSpace: 'pre-wrap' }}>
              {note.message}
            </Paragraph>
            <Text size={300} color="#8f95b2">
              {note.user?.name} - {` `}
              {note?.createdAt &&
                dateFormat(
                  new Date(note?.createdAt),
                  "MMMM do yyyy 'at' h:mm a"
                )}
            </Text>
          </Pane>
        </Pane>
      ))}
    </Dialog>
  );
};
