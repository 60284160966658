import React, { useState } from 'react';
import { useProductsQuery } from 'lib/api/ecommerce/schema';
import { Order, OrderItem } from 'models';
import { formatCurrency } from 'lib/utils';
import {
  Button,
  Paragraph,
  Card,
  Pane,
  Heading,
  toaster,
  Table,
  Text,
  Spinner,
  Select,
} from 'evergreen-ui';

export type Props = {
  item: OrderItem;
  order: Order;
  onClose: () => void;
};

const setAmounts = (item: OrderItem) => {
  const amounts: number[] = [];

  for (let i = 0; i < item.quantity; i++) {
    amounts.push(i + 1);
  }

  return amounts;
};

export const AddFrame: React.FC<Props> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFrame, setSelectedFrame] = useState<string>(null!);
  const [quantityToAdd, setQuantityToAdd] = useState<number>(item.quantity);
  const { data, loading: loadingProducts } = useProductsQuery();

  let framedSkus: string[] = [];

  if (item.sku.includes('P12X16')) {
    framedSkus = ['P12X16-FBLK', 'P12X16-FWHT'];
  } else if (item.sku.includes('P18X24')) {
    framedSkus = ['P18X24-FBLK', 'P18X24-FWHT'];
  } else if (item.sku.includes('-6000')) {
    // for split test Price_Increase
    framedSkus = ['P18X24-FBLK-13500', 'P18X24-FWHT-13500'];
  } else if (item.sku.includes('P24X36')) {
    framedSkus = ['P24X36-FBLK', 'P24X36-FWHT'];
  }

  const frames = data?.products?.filter((product) =>
    framedSkus.includes(product.sku)
  );

  return (
    <>
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16}>
          <Heading size={600}>Add Frame</Heading>
          <Paragraph size={400}>
            Choose a frame below to add to poster.
          </Paragraph>
        </Pane>
      </Pane>
      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Text size={300} marginTop={10}>
          Do not forget to charge the customer for the appropriate amount in the
          processor dashboard.
        </Text>

        {loadingProducts && <Spinner />}

        {frames && (
          <Card
            marginTop={10}
            backgroundColor="white"
            elevation={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Table flex={1}>
              <Table.Body>
                {frames.map((frame: any) => (
                  <Table.Row
                    key={frame.id}
                    isSelectable
                    isSelected={selectedFrame === frame.sku}
                    isHighlighted={selectedFrame === frame.sku}
                    onSelect={() => setSelectedFrame(frame.sku)}
                  >
                    <Table.Cell>{frame.name}</Table.Cell>
                    <Table.Cell>{frame.sku}</Table.Cell>
                    <Table.Cell>{formatCurrency(frame.unitPrice)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card>
        )}

        <Pane marginTop={16}>
          <Heading>Quantity:</Heading>
        </Pane>
        <Pane paddingBottom={16}>
          <Select
            width="33%"
            height={52}
            marginTop={5}
            value={quantityToAdd}
            onChange={(e) => setQuantityToAdd(parseInt(e.target.value))}
          >
            {setAmounts(item).map((amount) => (
              <option key={amount} value={amount}>
                {amount}
              </option>
            ))}
          </Select>
        </Pane>

        <Button
          disabled={!selectedFrame || loading}
          marginTop={14}
          appearance="primary"
          isLoading={loading}
          intent="success"
          onClick={async () => {
            setLoading(true);

            // if adding frames to total quantity, just change item sku
            if (quantityToAdd === item.quantity) {
              const res = await order.updateItem(item.id, {
                sku: selectedFrame,
                meta: item.meta,
              });

              if (!res.ok) {
                toaster.danger(res.error?.message);
              } else {
                toaster.success('Frame successfully added.');
                onClose();
              }

              return setLoading(false);
            } else {
              // if adding some frames, dupe item
              const newUnframedQuantity = item.quantity - quantityToAdd;
              const printMeta = {
                meta: item.meta,
                quantity: quantityToAdd,
                sku: selectedFrame,
              };

              const isResponseOk = (res: PromiseSettledResult<any>) =>
                res.status === 'fulfilled' && res.value.ok;

              try {
                const [addItems, updateItems] = await Promise.allSettled([
                  order.addItems([{ ...printMeta }]),
                  order.updateItem(item.id, {
                    quantity: newUnframedQuantity,
                  }),
                ]);

                if (!isResponseOk(addItems)) {
                  if (isResponseOk(updateItems)) {
                    // reset original quantity
                    await order.updateItem(item.id, {
                      quantity: item.quantity,
                    });
                  }

                  throw new Error();
                }

                if (!isResponseOk(updateItems)) {
                  if (isResponseOk(addItems)) {
                    const items = (addItems as PromiseFulfilledResult<any>)
                      .value.data?.items;
                    const itemId = items[items.length - 1].id;

                    await order.removeItems([itemId]);
                  }

                  throw new Error();
                }

                toaster.success('Frame added successfully.');
              } catch (e) {
                toaster.danger(
                  'There was a problem adding the frame. Please reload the page before trying again.'
                );
              } finally {
                setLoading(false);
                onClose();
              }
            }
          }}
        >
          Add Frame To Poster
        </Button>
      </Pane>
    </>
  );
};

export default AddFrame;
