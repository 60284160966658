import { createContext, useContext } from 'react';
import { BaseOrder, CustomPrint, Order, StaticPrint } from 'models';

export interface FulfillmentContextType {
  isNextOrderSameCustomer: boolean;
  numOrders: number;
  numPrints: number;
  nextOrder?: BaseOrder | Order | null;
  order?: Order | null;
  ordersList?: (BaseOrder | Order)[];
  print?: CustomPrint | StaticPrint | null;
  orderIndex: number;
  printIndex: number;
  loading: boolean;
  onClose: () => void;
  onNextOrder: () => void;
  onPrevOrder: () => void;
  onNextPrint: () => void;
  onPrevPrint: () => void;
  insertOrder: (index: number, order: BaseOrder | Order) => void;
}

export const FulfillmentContext = createContext<FulfillmentContextType>(null!);

FulfillmentContext.displayName = 'FulfillmentProviderContext';

export const FulfillmentProvider = FulfillmentContext.Provider;
export const FulfillmentConsumer = FulfillmentContext.Consumer;

export const useFulfillment = (): FulfillmentContextType => {
  const state = useContext<FulfillmentContextType>(FulfillmentContext);

  if (!state) {
    throw new Error(
      'Could not find "state" in the context or passed in as a prop. ' +
        'Wrap the root component in an <FulfillmentProvider>, or pass an ' +
        'settings instance in via props.'
    );
  }

  return state;
};
