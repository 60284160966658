import { formatDate } from 'lib/utils';

const date = {
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
  zone: 'America/New_York',
};

export const options = {
  styles: [
    {
      name: 'Black',
      primaryColor: '#000000',
      secondaryColor: '#ffffff',
      key: 'black',
      isImageBackground: false,
    },
    {
      name: 'Night Blue',
      primaryColor: '#223849',
      secondaryColor: '#ffffff',
      key: 'night-blue',
      isImageBackground: false,
    },
    {
      name: 'Magenta',
      primaryColor: '#83164d',
      secondaryColor: '#ffffff',
      key: 'magenta-red',
      isImageBackground: false,
    },
    {
      name: 'Nebula',
      primaryColor: 'nebula',
      secondaryColor: '#ffffff',
      key: 'nebula',
      isImageBackground: true,
    },
    {
      name: 'Kids Astronaut',
      primaryColor: 'kids-astronaut',
      secondaryColor: '#ffffff',
      key: 'kids-astronaut',
      isImageBackground: true,
    },
    // {
    //   name: "Dark Blue (old)",
    //   primaryColor: "#1c2832",
    //   secondaryColor: "#ffffff",
    //   key: "dark-blue-old"
    // }
  ],
  defaults: {
    meta: {
      timezone: {},
      subject: {
        kind: 'stars',
        address: '',
        lat: 40.713,
        lon: 73.006,
        date,
        primaryColor: '#223849',
        secondaryColor: '#ffffff',
        hideConstellations: false,
        hideGraticule: false,
      },
      theme: {
        divider: '',
        tagline: `${formatDate(
          date
        )} \n New York, NY, USA \n 40.713°N / 74.006°W`,
        headline: '',
        kind: 'legend',
        primaryColor: '#ffffff',
        secondaryColor: '#223849',
      },
    },
  },
};
