import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type FindAndLockPrinterJobsInput = {
  for: Scalars['String'];
};

export type GetPrinterJobsInput = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  state?: Maybe<PrinterJobState>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createPrinterJob?: Maybe<PrinterJob>;
  updatePrinterJob?: Maybe<PrinterJob>;
};

export type MutationCreatePrinterJobArgs = {
  input: PrinterJobInput;
};

export type MutationUpdatePrinterJobArgs = {
  input: UpdatePrinterJobInput;
};

export type PrinterJob = {
  __typename?: 'PrinterJob';
  assignedTo?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  files: Array<PrinterJobFile>;
  id: Scalars['String'];
  importedAt?: Maybe<Scalars['DateTime']>;
  importingAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
  order: Scalars['Float'];
  state: PrinterJobState;
  updatedAt: Scalars['DateTime'];
};

export type PrinterJobFile = {
  __typename?: 'PrinterJobFile';
  directory: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  meta: Scalars['JSONObject'];
  url: Scalars['String'];
};

export type PrinterJobFilesInput = {
  directory: Scalars['String'];
  fileName: Scalars['String'];
  meta: Scalars['JSONObject'];
  url: Scalars['String'];
};

export type PrinterJobInput = {
  assignedTo?: Maybe<Scalars['String']>;
  files: Array<PrinterJobFilesInput>;
  key: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
  order: Scalars['Float'];
  state: PrinterJobState;
};

export enum PrinterJobState {
  Done = 'DONE',
  Error = 'ERROR',
  Imported = 'IMPORTED',
  Importing = 'IMPORTING',
  Pending = 'PENDING',
  Ready = 'READY',
}

export type Query = {
  __typename?: 'Query';
  findAndLockPrinterJobs: Array<PrinterJob>;
  ping: Scalars['String'];
  printerJobs: Array<PrinterJob>;
};

export type QueryFindAndLockPrinterJobsArgs = {
  input: FindAndLockPrinterJobsInput;
};

export type QueryPrinterJobsArgs = {
  input: GetPrinterJobsInput;
};

export type UpdatePrinterJobDataInput = {
  assignedTo?: Maybe<Scalars['String']>;
  files?: Maybe<Array<PrinterJobFilesInput>>;
  key?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
  order?: Maybe<Scalars['Float']>;
  state?: Maybe<PrinterJobState>;
};

export type UpdatePrinterJobInput = {
  data: UpdatePrinterJobDataInput;
  id: Scalars['String'];
};

export type PrinterJobFragment = { __typename?: 'PrinterJob' } & Pick<
  PrinterJob,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'key'
  | 'order'
  | 'assignedTo'
  | 'state'
  | 'meta'
  | 'importingAt'
  | 'importedAt'
  | 'completedAt'
> & {
    files: Array<{ __typename?: 'PrinterJobFile' } & PrinterJobFileFragment>;
  };

export type PrinterJobFileFragment = { __typename?: 'PrinterJobFile' } & Pick<
  PrinterJobFile,
  'id' | 'url' | 'fileName' | 'directory' | 'meta'
>;

export type CreatePrinterJobMutationVariables = Exact<{
  input: PrinterJobInput;
}>;

export type CreatePrinterJobMutation = { __typename?: 'Mutation' } & {
  createPrinterJob?: Maybe<{ __typename?: 'PrinterJob' } & PrinterJobFragment>;
};

export type UpdatePrinterJobMutationVariables = Exact<{
  input: UpdatePrinterJobInput;
}>;

export type UpdatePrinterJobMutation = { __typename?: 'Mutation' } & {
  updatePrinterJob?: Maybe<{ __typename?: 'PrinterJob' } & PrinterJobFragment>;
};

export const PrinterJobFileFragmentDoc = gql`
  fragment PrinterJobFile on PrinterJobFile {
    id
    url
    fileName
    directory
    meta
  }
`;
export const PrinterJobFragmentDoc = gql`
  fragment PrinterJob on PrinterJob {
    id
    createdAt
    updatedAt
    key
    order
    assignedTo
    state
    files {
      ...PrinterJobFile
    }
    meta
    importingAt
    importedAt
    completedAt
  }
  ${PrinterJobFileFragmentDoc}
`;
export const CreatePrinterJobDocument = gql`
  mutation createPrinterJob($input: PrinterJobInput!) {
    createPrinterJob(input: $input) {
      ...PrinterJob
    }
  }
  ${PrinterJobFragmentDoc}
`;
export type CreatePrinterJobMutationFn = Apollo.MutationFunction<
  CreatePrinterJobMutation,
  CreatePrinterJobMutationVariables
>;

/**
 * __useCreatePrinterJobMutation__
 *
 * To run a mutation, you first call `useCreatePrinterJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrinterJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrinterJobMutation, { data, loading, error }] = useCreatePrinterJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrinterJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePrinterJobMutation,
    CreatePrinterJobMutationVariables
  >
) {
  return Apollo.useMutation<
    CreatePrinterJobMutation,
    CreatePrinterJobMutationVariables
  >(CreatePrinterJobDocument, baseOptions);
}
export type CreatePrinterJobMutationHookResult = ReturnType<
  typeof useCreatePrinterJobMutation
>;
export type CreatePrinterJobMutationResult = Apollo.MutationResult<CreatePrinterJobMutation>;
export type CreatePrinterJobMutationOptions = Apollo.BaseMutationOptions<
  CreatePrinterJobMutation,
  CreatePrinterJobMutationVariables
>;
export const UpdatePrinterJobDocument = gql`
  mutation updatePrinterJob($input: UpdatePrinterJobInput!) {
    updatePrinterJob(input: $input) {
      ...PrinterJob
    }
  }
  ${PrinterJobFragmentDoc}
`;
export type UpdatePrinterJobMutationFn = Apollo.MutationFunction<
  UpdatePrinterJobMutation,
  UpdatePrinterJobMutationVariables
>;

/**
 * __useUpdatePrinterJobMutation__
 *
 * To run a mutation, you first call `useUpdatePrinterJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrinterJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrinterJobMutation, { data, loading, error }] = useUpdatePrinterJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrinterJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrinterJobMutation,
    UpdatePrinterJobMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePrinterJobMutation,
    UpdatePrinterJobMutationVariables
  >(UpdatePrinterJobDocument, baseOptions);
}
export type UpdatePrinterJobMutationHookResult = ReturnType<
  typeof useUpdatePrinterJobMutation
>;
export type UpdatePrinterJobMutationResult = Apollo.MutationResult<UpdatePrinterJobMutation>;
export type UpdatePrinterJobMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrinterJobMutation,
  UpdatePrinterJobMutationVariables
>;
