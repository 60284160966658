import React, { useRef } from 'react';
import dateFormat from 'date-fns/format';
import { Order } from 'models';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { Heading, Pane, Textarea } from 'evergreen-ui';

interface SidebarProps {
  order: Order;
}

export const Sidebar: React.FC<SidebarProps> = ({ order }) => {
  const shippingAddressRef = useRef<Textarea>();

  if (!order) return null;

  return (
    <Pane
      flex={0}
      flexBasis={360}
      height="100%"
      overflowY="scroll"
      padding={16}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor="white"
      borderLeft="1px solid #eee"
    >
      <Pane
        marginTop={8}
        width="100%"
        flex={1}
        display="flex"
        flexDirection="column"
      >
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <OrderStatusBadge status={order.status} />
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Full Name
          </Heading>
          <Heading size={500}>{order.fullName}</Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Order ID
          </Heading>
          <Heading size={500}>{order.id}</Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            Date
          </Heading>
          <Heading size={500}>
            {dateFormat(order.paidAt, 'E, MMM do h:mm a')}
          </Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Heading size={100} marginBottom={5}>
            E-mail Address
          </Heading>
          <Heading size={500}>{order.email}</Heading>
        </Pane>
        <Pane
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          marginBottom={20}
        >
          <Pane
            flex={1}
            display="flex"
            alignItems="center"
            flexDirection="row"
            marginBottom={10}
          >
            <Heading size={100} marginRight={15}>
              Shipping Address
            </Heading>
          </Pane>

          {order?.shippingAddress && (
            <Textarea
              height={120}
              readOnly={true}
              innerRef={shippingAddressRef}
              name="shippingAddress"
              value={`${
                order?.shippingAddress?.recipient || order.fullName || ''
              }\n${order.shippingAddress.line1}\n${
                order.shippingAddress.line2
                  ? `${order.shippingAddress.line2}\n`
                  : ''
              }${order.shippingAddress.city}, ${order.shippingAddress.state}, ${
                order.shippingAddress.postalCode
              }\n${order.shippingAddress.country}`}
            />
          )}
        </Pane>
      </Pane>
    </Pane>
  );
};
