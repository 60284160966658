import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import store from 'lib/store';
import { App } from 'app';
import { Provider } from 'redux-zero/react';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from 'lib/auth';
import { ErrorProvider } from 'lib/error-reporting';
import { EcommerceApiProvider } from 'lib/api/ecommerce';
import { GridConfig, GlobalStyle } from 'styles';

ReactDOM.render(
  <EcommerceApiProvider>
    <AuthProvider>
      <Provider store={store}>
        <ErrorProvider>
          <ThemeProvider theme={{ awesomegrid: GridConfig }}>
            <>
              <GlobalStyle />
              <App />
            </>
          </ThemeProvider>
        </ErrorProvider>
      </Provider>
    </AuthProvider>
  </EcommerceApiProvider>,
  document.getElementById('root')
);
