import React from 'react';
import formatDate from 'date-fns/format';
import { Order } from 'models';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { Heading, Icon, Pane, Position, Tooltip } from 'evergreen-ui';

interface HeaderProps {
  order: Order;
}

export const Header: React.FC<HeaderProps> = ({ order }) => {
  const deliveryDueDate = order.getDeliveryDueDate();

  return (
    <Pane display="flex" marginTop={30} paddingBottom={10}>
      <Pane flex={1} display="flex" flexDirection="column">
        <Pane flex={1} alignItems="center" display="flex" marginBottom={10}>
          <Heading size={700} fontWeight={300}>
            Order
          </Heading>
          <Heading size={500} marginLeft="20px">
            <OrderStatusBadge status={order?.status} />
          </Heading>
          <Heading size={500} marginLeft={10}>
            <small style={{ opacity: 0.5 }}>#{order.id}</small>
          </Heading>
        </Pane>

        <Pane flex={1} display="flex" flexDirection="row">
          <Heading size={400}>
            {formatDate(new Date(order.paidAt), 'E, MMM do yyyy h:mm a')} -{' '}
            {order.store}
          </Heading>

          {order.isCustomerApproved && (
            <Heading
              size={400}
              display="flex"
              flexDirection="row"
              alignItems="center"
              color="#7B8B9A"
            >
              <Tooltip
                position={Position.LEFT}
                content={
                  order?.approvedAt
                    ? formatDate(
                        new Date(order.approvedAt),
                        'E, MMM do yyyy h:mm a'
                      )
                    : 'n/a'
                }
              >
                <Icon
                  icon="endorsed"
                  color="muted"
                  size={12}
                  marginLeft={24}
                  marginRight={8}
                />
              </Tooltip>
              Customer Approved
            </Heading>
          )}
        </Pane>
      </Pane>

      <Pane
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <Pane
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Heading size={100} fontWeight={300}>
            Shipping Method
          </Heading>
          <Heading size={400} marginLeft="10px" opacity={0.8} fontWeight={600}>
            {order.shippingName || order.shippingMethod}
          </Heading>
        </Pane>
        <Pane
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Heading size={100} fontWeight={300}>
            Due Date
          </Heading>
          <Heading size={400} marginLeft="10px" opacity={0.8} fontWeight={600}>
            {deliveryDueDate
              ? formatDate(new Date(deliveryDueDate), 'E, MMM do yyyy')
              : '---'}
          </Heading>
        </Pane>
      </Pane>
    </Pane>
  );
};
