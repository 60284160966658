import React, { useState } from 'react';
import useInterval from 'use-interval';
import { RoleAccess } from 'lib/auth';
import { useHotkeys } from 'react-hotkeys-hook';
import { CustomPrint, Order, OrderItemTypes, StaticPrint } from 'models';
import { Image } from './Image';
import { CustomPrintEditorModal } from 'components/CustomPrintEditorModal';
import { DownloadPDF, PrintWrapper } from './Print.styles';
import {
  Alert,
  Button,
  Pane,
  Heading,
  Paragraph,
  Link,
  Icon,
  IconButton,
  Popover,
  Position,
  Menu,
  Tooltip,
  toaster,
} from 'evergreen-ui';

interface PrintProps {
  order: Order;
}

export const Print: React.FC<PrintProps> = ({ order }) => {
  const [printIndex, setPrintIndex] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMapEditorOpen, setMapEditorOpen] = useState<boolean>(false);
  const [isRegeneratingPrint, setRegeneratingPrint] = useState<boolean>(false);

  const prints = order.getPrints() as (CustomPrint | StaticPrint)[];
  const print = prints[printIndex];
  const numPrints = prints.length;

  // clean up
  const printPreviewUrl = print.getPreviewUrl();
  const printDownloadUrlPDF = print.getPrintUrl();
  const printDownloadUrlPNG = print.getDownloadUrl(
    'png',
    print.getPrinterFilename(order, 'png')
  );

  const onPrevPrint = () => {
    if (printIndex > 0) {
      setPrintIndex(printIndex - 1);
    }
  };

  const onNextPrint = () => {
    if (printIndex !== numPrints - 1) {
      setPrintIndex(printIndex + 1);
    }
  };

  const onRegeneratePrint = async () => {
    const res = (await order?.regeneratePrint(print!)) as any;

    if (!res.ok) {
      toaster.danger(res.error.message || 'Error regenerating print');
      return;
    }

    setRegeneratingPrint(true);
  };

  // hotkeys
  useHotkeys(
    'right',
    () => {
      onNextPrint();
    },
    [printIndex]
  );
  useHotkeys(
    'p',
    () => {
      onNextPrint();
    },
    [printIndex]
  );
  useHotkeys(
    'left',
    () => {
      onPrevPrint();
    },
    [printIndex]
  );
  useHotkeys(
    'o',
    () => {
      onPrevPrint();
    },
    [printIndex]
  );
  useHotkeys(
    'd',
    () => {
      window.open(printDownloadUrlPDF, '_blank');
    },
    [printIndex]
  );

  // interval check when print file exists after update
  useInterval(
    async () => {
      const printFileExists = await order.printFileExists(print.id);

      if (printFileExists) {
        setRegeneratingPrint(false);
      }
    },
    isRegeneratingPrint ? 1000 : null
  );

  return (
    <>
      <Pane flex={1} display="flex" flexDirection="column" height="100%">
        <Pane
          zIndex={1}
          flexShrink={0}
          display="flex"
          alignItems="center"
          flexDirection="row"
          borderBottom="1px solid #eee"
          backgroundColor="white"
        >
          <Pane padding={16} flex={1} display="flex" flexDirection="row">
            <DownloadPDF>
              <Link
                target="_blank"
                type="application/octet-stream"
                href={printDownloadUrlPDF}
              >
                Download
              </Link>
            </DownloadPDF>

            {print.type === OrderItemTypes.custom_print && (
              <RoleAccess permission="orders:edit">
                <Button
                  height={32}
                  marginLeft={12}
                  onClick={() => setMapEditorOpen(true)}
                >
                  Edit Print
                </Button>
              </RoleAccess>
            )}

            <Popover
              position={Position.BOTTOM_LEFT}
              content={({ close }) => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() =>
                        window?.open(printDownloadUrlPNG, '_blank')
                      }
                    >
                      Download PNG
                    </Menu.Item>

                    {print.type === OrderItemTypes.custom_print && (
                      <Menu.Item
                        onSelect={() => {
                          close();
                          onRegeneratePrint();
                        }}
                      >
                        Regenerate Print
                      </Menu.Item>
                    )}
                  </Menu.Group>
                </Menu>
              )}
            >
              <IconButton icon="more" height={32} marginLeft={12} />
            </Popover>
            <Tooltip
              appearance="card"
              content={
                <Pane margin={10}>
                  <Heading size={400} marginBottom={5}>
                    Hotkeys
                  </Heading>
                  <Paragraph marginBottom={2}>right/p: Next Print</Paragraph>
                  <Paragraph marginBottom={2}>left/o: Previous Print</Paragraph>
                  <Paragraph>d: Download Print</Paragraph>
                </Pane>
              }
            >
              <Icon
                size={14}
                color="#999999"
                icon="info-sign"
                marginLeft={12}
                marginTop={10}
              />
            </Tooltip>
          </Pane>

          {printPreviewUrl && numPrints > 1 && (
            <Pane
              padding={16}
              flex={1}
              display="flex"
              flexDirection="row"
              justifySelf="flex-end"
              justifyContent="flex-end"
            >
              <Button
                marginRight={10}
                iconBefore="arrow-left"
                disabled={printIndex === 0}
                onClick={() => onPrevPrint()}
              >
                Prev
              </Button>
              <Button
                iconAfter="arrow-right"
                disabled={printIndex === numPrints - 1}
                onClick={() => onNextPrint()}
              >
                Next
              </Button>
            </Pane>
          )}
        </Pane>
        <Pane
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          overflowY="scroll"
        >
          {printPreviewUrl && (
            <>
              <PrintWrapper>
                <Image
                  key={printPreviewUrl}
                  src={printPreviewUrl}
                  isRegenerating={isRegeneratingPrint}
                />

                <Pane position="absolute" top="10px" right="10px">
                  {print.hasFrame && (
                    <Alert
                      appearance="card"
                      intent="none"
                      elevation={3}
                      title={`${
                        print.sku.indexOf('BLK') > -1 ? 'Black' : 'White'
                      } Frame`}
                    />
                  )}

                  {print.quantity > 1 && (
                    <Alert
                      appearance="card"
                      intent="none"
                      elevation={3}
                      marginTop={5}
                      title={`Quantity: ${print.quantity}`}
                    />
                  )}
                </Pane>
              </PrintWrapper>
            </>
          )}
        </Pane>
      </Pane>

      {print.type === OrderItemTypes.custom_print && isMapEditorOpen && (
        <CustomPrintEditorModal
          order={order}
          loading={isLoading}
          print={print as CustomPrint}
          onClose={() => setMapEditorOpen(false)}
          onComplete={async (printMeta) => {
            setLoading(true);

            const meta = (print as CustomPrint).composePrintMeta(
              printMeta,
              print
            );
            const res = await order.updateItem(print.id, { meta });

            if (!res.ok) {
              toaster.danger(res.error?.message);
              setLoading(false);
              return;
            }

            toaster.success('Poster successfully updated.');

            setLoading(false);
            setMapEditorOpen(false);
            setRegeneratingPrint(true);
          }}
        />
      )}
    </>
  );
};
