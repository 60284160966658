import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { AddressFragment } from 'lib/api/ecommerce/schema';
import { Pane, TextInputField, TextInput } from 'evergreen-ui';
import { SelectContainer } from './Form.styles';

type ShippingAddressKeys = keyof Omit<AddressFragment, '__typename'>;

interface CreateOrderShippingAddressFormProps {
  address: AddressFragment;
  loading: boolean;
  onChange: (e: Record<ShippingAddressKeys, string>) => void;
}

export const CreateOrderShippingAddressForm: React.FC<CreateOrderShippingAddressFormProps> = ({
  address,
  loading,
  onChange,
}) => {
  const handleChange = (e) => {
    const name: ShippingAddressKeys = e.target.name.split('.')[1];
    const value: string = e.target.value;

    onChange({ [name]: value } as Record<ShippingAddressKeys, string>);
  };

  return (
    <>
      <Pane display="flex" marginTop={0}>
        <TextInputField
          label="Shipping Information"
          placeholder="Recipient Name"
          name="shippingAddress.recipient"
          width="100%"
          inputHeight={40}
          disabled={loading}
          value={address?.recipient}
          onChange={handleChange}
        />
      </Pane>

      <Pane display="flex" marginTop={-14}>
        <TextInputField
          label={null}
          placeholder="Street Address"
          name="shippingAddress.line1"
          inputWidth="100%"
          width="100%"
          inputHeight={40}
          disabled={loading}
          value={address.line1}
          onChange={handleChange}
        />
      </Pane>

      <Pane display="flex" marginTop={-12} marginBottom={10}>
        <TextInput
          label={null}
          placeholder="Line 2"
          name="shippingAddress.line2"
          width="100%"
          height={40}
          disabled={loading}
          value={address.line2}
          onChange={handleChange}
        />
      </Pane>

      <Pane display="flex" marginBottom={10}>
        <TextInput
          label={null}
          placeholder="City / Town"
          name="shippingAddress.city"
          width="100%"
          height={40}
          disabled={loading}
          value={address.city}
          onChange={handleChange}
        />
      </Pane>

      <Pane display="flex" marginBottom={10}>
        <SelectContainer>
          <RegionDropdown
            country={address?.country as string}
            countryValueType="short"
            defaultOptionLabel="Select State"
            value={address?.state || ''}
            name="shippingAddress.state"
            valueType="short"
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'shippingAddress.state',
                  value,
                },
              })
            }
          />
        </SelectContainer>

        <TextInput
          label={null}
          placeholder="Zip Code"
          name="shippingAddress.postalCode"
          width="100%"
          height={40}
          marginLeft={10}
          disabled={loading}
          value={address?.postalCode}
          onChange={handleChange}
        />
      </Pane>

      <Pane display="flex" marginBottom={10}>
        <SelectContainer>
          <CountryDropdown
            defaultOptionLabel="Select Country"
            name="shippingAddress.country"
            valueType="short"
            disabled={loading}
            value={address?.country as string}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'shippingAddress.country',
                  value,
                },
              })
            }
          />
        </SelectContainer>
      </Pane>
    </>
  );
};
