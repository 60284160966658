import React, { useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useFulfillment } from '../../../FulfillmentProvider';
import { fetchImageRetry } from './utils';
import { Loader } from 'components/Loader';
import { Alert, Button, Link, Pane, toaster } from 'evergreen-ui';
import { DownloadPDF, DownloadPNG, ImageContainer } from './Print.styles';

interface ImageProps {
  isRegeneratingPrint: boolean;
  setRegeneratingPrint: (value: boolean) => void;
  setEditorModalOpen: (value: boolean) => void;
}

export const Image: React.FC<ImageProps> = ({
  isRegeneratingPrint,
  setRegeneratingPrint,
  setEditorModalOpen,
}) => {
  const { order, print } = useFulfillment();

  const DownloadButtonRef = useRef<HTMLAnchorElement>();
  const [error, setError] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);
  const [retrySuccess, setRetrySuccess] = useState<boolean>(false);

  useHotkeys('down', () => {
    DownloadButtonRef?.current?.click();
  });

  if (!print || !order) return null;

  const onError = async () => {
    try {
      await fetchImageRetry(printPreviewUrl, { maxTimeout: 3200, retries: 10 });

      setRetrySuccess(true);
    } catch (e) {
      setError(true);
      Sentry.withScope(function (scope) {
        scope.setTag('image', printPreviewUrl);
        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureException(new Error('Error Rendering Image'));
      });
    }
  };

  const onRegeneratePrint = async () => {
    const res = (await order?.regeneratePrint(print!)) as any;

    if (!res.ok) {
      toaster.danger(res.error.message || 'Error regenerating print');
      return;
    }

    setReady(false);
    setRegeneratingPrint(true);
  };

  const printPreviewUrl = print.getPreviewUrl();
  const printDownloadUrlPDF = print.getPrintUrl();
  const printDownloadUrlPNG = print.getDownloadUrl(
    'png',
    print.getPrinterFilename(order, 'png')
  );
  const { width, height } = print?.printSize;

  if (isRegeneratingPrint) {
    return (
      <ImageContainer>
        <Loader />
        <h4>Regenerating Image...</h4>
      </ImageContainer>
    );
  }

  return (
    <ImageContainer hasLoaded={isReady}>
      {!isReady && !error && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Loader />
          <h4>Loading Image...</h4>
        </Pane>
      )}

      {error && (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <h4>
            Failed to render print. Try regenerating below, if that fails
            message skeen with the order ID.
          </h4>
          <Button
            onClick={() => {
              onRegeneratePrint();
            }}
          >
            Regenerate Image
          </Button>
        </Pane>
      )}

      <Pane position="absolute" top="10px" right="10px">
        {isReady && !isRegeneratingPrint && (
          <Alert
            elevation={3}
            appearance="card"
            intent="none"
            marginTop={5}
            title={`${width}x${height}`}
          />
        )}

        {isReady && !isRegeneratingPrint && print.hasFrame && (
          <Alert
            elevation={3}
            appearance="card"
            intent="none"
            marginTop={5}
            title={`${print.sku.indexOf('BLK') > -1 ? 'Black' : 'White'} Frame`}
          />
        )}
      </Pane>

      <Pane position="absolute" top="10px" left="10px">
        {isReady && !isRegeneratingPrint && print.quantity > 1 && (
          <Alert
            elevation={3}
            appearance="card"
            intent="none"
            marginTop={5}
            title={`Quantity: ${print.quantity}`}
          />
        )}
      </Pane>

      <img
        width="auto"
        height="620px"
        key={`${printPreviewUrl}_${retrySuccess.toString()}`}
        alt={printPreviewUrl}
        src={printPreviewUrl}
        onError={onError}
        onContextMenu={(e) => e.preventDefault()}
        onLoad={() => {
          setReady(true);
          setError(false);
        }}
      />

      {isReady && !isRegeneratingPrint && !error && (
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Pane
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            marginTop={10}
            marginBottom={10}
          >
            <Button
              height={38}
              marginTop={10}
              marginRight={12}
              onClick={() => setEditorModalOpen(true)}
            >
              Edit Poster
            </Button>

            <DownloadPDF>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                type="application/octet-stream"
                href={printDownloadUrlPDF}
                innerRef={DownloadButtonRef}
              >
                Download
              </Link>
            </DownloadPDF>
          </Pane>

          <DownloadPNG>
            <Link
              target="_blank"
              type="application/octet-stream"
              href={printDownloadUrlPNG}
            >
              Download PNG
            </Link>
          </DownloadPNG>
        </Pane>
      )}
    </ImageContainer>
  );
};
