import React from 'react';
import { useHistory } from 'react-router-dom';
import dateFormat from 'date-fns/format';
import { BaseOrder } from 'models';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { OrderReadinessBadge } from 'components/OrderReadinessBadge';
import { Avatar, Position, Table, Text, Tooltip } from 'evergreen-ui';
import {
  isOldMagenta,
  orderToWords,
  customerApprovedIcon,
  printErgency,
  hasHangerFrame,
  voucherCode,
  shippingUpgrade,
  isRushOrder,
  getShopIcon,
} from './utils';

interface BodyProps {
  orders: BaseOrder[];
}

export const Body: React.FC<BodyProps> = ({ orders = [] }) => {
  const history = useHistory();

  return (
    <Table.Body>
      {orders.map((order) => {
        let deliveryDueDate: Date | null = order.getDeliveryDueDate();
        const formattedDate: string = deliveryDueDate
          ? dateFormat(deliveryDueDate, 'E, MMM do')
          : '--';

        return (
          <Table.Row
            key={order.id}
            height={52}
            isSelectable
            onSelect={() => history.push(`/orders/${order.id}`)}
          >
            <Table.Cell
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Text>{getShopIcon(order)}</Text>
              <Tooltip content={order.email} position={Position.LEFT}>
                <Avatar name={order.fullName} size={28} />
              </Tooltip>
              <Text marginLeft={12} size={300} fontWeight={500}>
                {order.fullName}
              </Text>
            </Table.Cell>
            <Table.Cell display="flex" alignItems="center">
              <Text size={300} fontWeight={500}>
                {orderToWords(order)}
              </Text>
              {printErgency(order)}
              {hasHangerFrame(order)}
              {isOldMagenta(order)}
            </Table.Cell>
            <Table.Cell
              display="flex"
              alignItems="center"
              flex={0}
              flexBasis={120}
              width={120}
            >
              <Tooltip
                content={dateFormat(
                  new Date(order.paidAt),
                  'E, MMM do yyyy h:mm a'
                )}
                position={Position.TOP}
              >
                <Text size={300}>
                  {dateFormat(new Date(order.paidAt), 'MM/dd/yyyy')}
                </Text>
              </Tooltip>
            </Table.Cell>
            <Table.Cell
              display="flex"
              alignItems="center"
              flexBasis={150}
              width={150}
              flex={0}
            >
              {order.formattedTotal}
              {voucherCode(order)}
              {shippingUpgrade(order)}
              {customerApprovedIcon(order)}
              {isRushOrder(order)}
            </Table.Cell>
            <Table.Cell
              display="flex"
              alignItems="center"
              flexBasis={120}
              width={120}
              flex={0}
            >
              <Tooltip content={formattedDate} position={Position.TOP}>
                <Text size={300}>{formattedDate}</Text>
              </Tooltip>
            </Table.Cell>
            <Table.Cell
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexBasis={260}
              width={260}
              flex={0}
            >
              <OrderStatusBadge status={order.status} />
              <OrderReadinessBadge order={order} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );
};
