import { Type } from 'class-transformer';
import { Timeline } from 'lib/api/ecommerce/schema';
import { Order, User } from '..';

export class BaseTimeline {
  public id!: Timeline['id'];
  public type!: Timeline['type'];
  public text!: Timeline['text'];
  public meta!: Timeline['meta'];
  public snapshot!: Order;
  public prevSnapshot!: Order;

  @Type(() => User)
  public user;

  @Type(() => Date)
  public createdAt;

  @Type(() => Date)
  public updatedAt;
}

export default BaseTimeline;
