import React from 'react';
import formatDate from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import { formatCurrency } from 'lib/utils';
import {
  VoucherDiscountType,
  VoucherFragment as Voucher,
} from 'lib/api/ecommerce/schema';
import { Pill, Table } from 'evergreen-ui';

interface BodyProps {
  vouchers: Voucher[];
}

export const Body: React.FC<BodyProps> = ({ vouchers }) => {
  const history = useHistory();

  return (
    <Table>
      {vouchers.map((voucher) => {
        const isPercentage = voucher.type === VoucherDiscountType.Percent;

        return (
          <Table.Row
            isSelectable
            height={60}
            key={voucher.id}
            onSelect={() => history.push(`/voucher/${voucher.code}`)}
          >
            <Table.Cell display="flex" alignItems="center">
              <Pill color="blue">{voucher.code}</Pill>
            </Table.Cell>

            <Table.Cell display="flex" alignItems="center">
              {voucher.type}
            </Table.Cell>

            <Table.Cell
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              flexBasis={280}
              flex={0}
              width={280}
            >
              {isPercentage &&
                voucher.rules.length < 1 &&
                `${voucher.discount * 100}%`}

              {!isPercentage &&
                voucher.rules.length < 1 &&
                `${formatCurrency(voucher.discount)}`}

              {voucher.rules.length > 0 && 'BOGO'}
            </Table.Cell>

            <Table.Cell display="flex" alignItems="center">
              {formatDate(new Date(voucher.startsAt), 'MM/dd/yyyy')}
            </Table.Cell>

            <Table.Cell display="flex" alignItems="center">
              {voucher.expiresAt
                ? formatDate(new Date(voucher.expiresAt), 'MM/dd/yyyy')
                : '-'}
            </Table.Cell>

            <Table.Cell
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {voucher.useCount}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
};
