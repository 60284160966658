import React, { useEffect } from 'react';
import { useFulfillment } from '../FulfillmentProvider';
import { prefetchPrintImages } from './utils/prefetchPrintImages';
import { HotKeys, Print, Sidebar, TopBar } from './components';
import { Loader } from 'components/Loader';
import { Pane, SideSheet } from 'evergreen-ui';

export const SlideOver: React.FC = () => {
  const { loading, onClose, nextOrder } = useFulfillment();

  useEffect(() => {
    // prefetch next order's print images
    // imgix png renderer is slow
    if (nextOrder) {
      prefetchPrintImages(nextOrder);
    }
  }, [nextOrder]);

  return (
    <>
      <SideSheet
        width="80%"
        isShown={true}
        containerProps={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          style: { width: '100%' },
        }}
        onCloseComplete={() => onClose()}
      >
        {loading && <Loader />}
        {!loading && (
          <>
            <HotKeys />
            <TopBar />

            <Pane
              flex="1"
              display="flex"
              flexDirection="row"
              alignItems="center"
              background="tint1"
            >
              <Print />
              <Sidebar />
            </Pane>
          </>
        )}
      </SideSheet>
    </>
  );
};
