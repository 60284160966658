import React from 'react';
import { useHistory } from 'react-router-dom';
import { Product } from 'lib/api/ecommerce/schema';
import { Table } from 'evergreen-ui';
import { formatCurrency } from 'lib/utils';

interface ProductsTableProps {
  products: Product[];
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
  products = [],
}) => {
  const history = useHistory();

  return (
    <Table borderRadius={6} marginTop="25px">
      <Table.Head borderRadius={6} elevation={1}>
        <Table.TextHeaderCell
          display="flex"
          alignItems="center"
          flex={1}
          flexBasis={160}
          width={160}
        >
          Name
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>SKU</Table.TextHeaderCell>
        <Table.HeaderCell>Category</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
      </Table.Head>

      <Table.Body>
        {products.map((product) => (
          <Table.Row
            key={product.id}
            height={60}
            isSelectable
            onSelect={() => history.push(`/product/${product.sku}`)}
          >
            <Table.Cell
              display="flex"
              alignItems="center"
              flex={1}
              flexBasis={160}
              width={0}
            >
              {product.name}
            </Table.Cell>
            <Table.Cell display="flex" alignItems="center">
              {product.sku}
            </Table.Cell>
            <Table.Cell>{product.category}</Table.Cell>
            <Table.Cell>{formatCurrency(product.unitPrice)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
