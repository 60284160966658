import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useFulfillment } from '../../FulfillmentProvider';

export const HotKeys: React.FC = () => {
  const { onNextOrder, onPrevOrder } = useFulfillment();

  // next order
  useHotkeys(
    'p',
    () => {
      onNextOrder();
    },
    [onNextOrder]
  );
  useHotkeys(
    'right',
    () => {
      onNextOrder();
    },
    [onNextOrder]
  );

  // previous order
  useHotkeys(
    'o',
    () => {
      onPrevOrder();
    },
    [onPrevOrder]
  );
  useHotkeys(
    'left',
    () => {
      onPrevOrder();
    },
    [onPrevOrder]
  );

  return null;
};
