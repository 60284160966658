import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(249, 250, 251);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
  width: 400px;
  text-align: center;
  line-height: 1.8;
  margin-top: -2rem;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.5rem;
  width: 400px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

export const LogoContainer = styled.div`
  width: 80px;
  margin-bottom: 1.5rem;

  svg {
    max-width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;

  > button {
    justify-content: center;
  }
`;
