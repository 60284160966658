import React from 'react';
import { Order } from 'models';
import { PaymentRow } from './PaymentRow';
import { Heading, Pane, Table } from 'evergreen-ui';

interface PaymentsProps {
  order: Order;
}

export const Payments: React.FC<PaymentsProps> = ({ order }) => (
  <Pane marginTop={40}>
    <Pane display="flex" paddingBottom={15}>
      <Pane flex={1} alignItems="center" display="flex">
        <Heading size={600} fontWeight={300}>
          Payments
        </Heading>
      </Pane>
    </Pane>

    <Pane
      display="flex"
      flexDirection="row"
      elevation={1}
      borderRadius={6}
      backgroundColor="#fdfdfd"
    >
      <Pane
        flex={1}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Table width="100%">
          <Table.Head>
            <Table.TextHeaderCell>Processor</Table.TextHeaderCell>
            <Table.TextHeaderCell>Type</Table.TextHeaderCell>
            <Table.TextHeaderCell>Status</Table.TextHeaderCell>
            <Table.TextHeaderCell>ID</Table.TextHeaderCell>
            <Table.HeaderCell justifyContent="flex-end">Paid</Table.HeaderCell>
          </Table.Head>

          <Table.Body>
            {order.payments.map((payment) => (
              <PaymentRow key={payment.id} payment={payment} order={order} />
            ))}
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  </Pane>
);
