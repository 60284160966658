import React, { useState } from 'react';
import { SideSheet } from 'evergreen-ui';
import { Order, OrderItem } from 'models';
import { AddFrame, Shipping } from './utils';

export interface AddFrameModalProps {
  item: OrderItem;
  order: Order;
  onClose: () => void;
}

const AddFrameModal: React.FC<AddFrameModalProps> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [activeTab, setActiveTab] = useState<string>('frame');

  const onAddFrameClose = () => {
    if (order.getShipping()) setActiveTab('shipping');
    else onClose();
  };

  return (
    <SideSheet
      isShown={true}
      onCloseComplete={() => onClose()}
      containerProps={{ display: 'flex', flex: '1', flexDirection: 'column' }}
    >
      {activeTab === 'frame' && (
        <AddFrame item={item} order={order} onClose={onAddFrameClose} />
      )}

      {activeTab === 'shipping' && (
        <Shipping order={order} onClose={() => onClose()} />
      )}
    </SideSheet>
  );
};

export default AddFrameModal;
