import styled from 'styled-components';

export const Textarea = styled.textarea`
  padding: 8px 10px;
  line-height: 1.3;
  border-radius: 6px;
  border: 1px solid #dddddd;
  min-height: 84px;
  min-width: 80%;
  font-size: 0.85rem;
`;
