import React from 'react';
import isEqual from 'react-fast-compare';
import { Pane, SegmentedControl } from 'evergreen-ui';
import { options } from './options';

export class StyleFormMoon extends React.Component<any> {
  public shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.print.subject, nextProps.print.subject) ||
      !isEqual(this.props.print.subject.date, nextProps.print.subject.date) ||
      !isEqual(this.props.print.timezone, nextProps.print.timezone) ||
      !isEqual(this.props.print.theme, nextProps.print.theme)
    );
  }

  private handleColorChange(key): void {
    const { handlePrintChange } = this.props;
    const { primaryColor, secondaryColor }: any = options.styles.find(
      (item) => item.key === key
    );

    handlePrintChange({
      subject: {
        primaryColor,
      },
      theme: {
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
      },
    });
  }

  private renderStyleButtons(): any {
    const { theme } = this.props.print;

    const activeStyle = options.styles.find(
      (style) =>
        (theme.primaryColor === style.primaryColor &&
          theme.secondaryColor === style.secondaryColor) ||
        (theme.primaryColor === style.secondaryColor &&
          theme.secondaryColor === style.primaryColor)
    );

    return (
      <SegmentedControl
        width="100%"
        options={options.styles.map((item) => {
          return { label: item.name, value: item.key };
        })}
        value={(activeStyle as any)?.key || options.styles[0]?.key}
        onChange={(value) => this.handleColorChange(value)}
        style={{ textAlign: 'center' }}
      />
    );
  }

  public render() {
    return <Pane width="100%">{this.renderStyleButtons()}</Pane>;
  }
}
