import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import _omit from 'lodash/omit';
import _merge from 'lodash/merge';
import * as Grid from 'react-awesome-styled-grid';
import Fade from 'react-reveal/Fade';
import { shippingRates } from 'config/shipping';
import {
  useCreateProductMutation,
  useUpdateProductMutation,
  ProductFragment as Product,
  ProductType,
} from 'lib/api/ecommerce/schema';
import { validate } from './validation';
import {
  Button,
  Heading,
  Pane,
  SelectField,
  SelectMenu,
  Switch,
  Textarea,
  TextInputField,
  toaster,
  Label,
  Text,
} from 'evergreen-ui';

type ProductInput = Pick<
  Product,
  | 'name'
  | 'sku'
  | 'type'
  | 'category'
  | 'commodityCode'
  | 'upc'
  | 'unitPrice'
  | 'requiresApproval'
  | 'meta'
>;

interface FormProps {
  product?: Product | null;
}

interface FormState extends Omit<ProductInput, 'requiresApproval'> {
  requiresApproval: boolean; // force existence
}

export const Form: React.FC<FormProps> = ({ product }) => {
  const history = useHistory();
  const [createProduct, { loading: isCreating }] = useCreateProductMutation();
  const [updateProduct, { loading: isUpdating }] = useUpdateProductMutation();
  const [errors, setErrors] = useState<any>();
  const [selectedShipping, setSelectedShipping] = useState<any>(
    product?.meta.availableShipping ? product?.meta.availableShipping : []
  );
  const [form, setForm] = useState<FormState>({
    name: product?.name ?? '',
    sku: product?.sku ?? '',
    type: product?.type ?? ProductType.Physical,
    category: product?.category ?? '',
    commodityCode: product?.commodityCode ?? '',
    upc: product?.upc ?? '',
    unitPrice: product?.unitPrice ?? 0,
    requiresApproval: product?.requiresApproval ?? true,
    meta: {
      availableShipping:
        product?.meta?.availableShipping ??
        shippingRates.map((option) => option.key),
      cartName: product?.meta?.cartName ?? '',
      compareAtPrice: product?.meta?.compareAtPrice ?? '',
      description: product?.meta?.description ?? '',
      internalDescription: product?.meta?.internalDescription ?? '',
      isActive: product?.meta?.isActive ?? true,
      printful: {
        variant: product?.meta?.printful?.variant ?? '',
      },
      printSizes: {
        width: product?.meta?.printSizes?.width ?? '',
        height: product?.meta?.printSizes?.height ?? '',
      },
      summary: product?.meta?.summary ?? '',
    },
  });

  const isLoading = isUpdating || isCreating;
  const isNewProduct = !product?.id;

  return (
    <Fade key="SplitTest" left distance="30px" duration={500}>
      <>
        <Pane display="flex" marginBottom={40}>
          <Pane flex={1} alignItems="center" display="flex">
            <Button
              iconBefore="arrow-left"
              onClick={() => history.push('/products')}
            >
              Back
            </Button>
          </Pane>
          <Pane alignItems="center" display="flex">
            <Button
              intent="success"
              appearance="primary"
              isLoading={isLoading}
              onClick={async () => {
                const errors = await validate(form);

                if (errors) {
                  return setErrors(errors);
                }

                try {
                  const input = { ..._merge({ ...product }, { ...form }) };
                  const submit = !isNewProduct ? updateProduct : createProduct;

                  const data: any = isNewProduct
                    ? { input }
                    : {
                        input: {
                          id: product?.id,
                          data: _omit(input, [
                            'id',
                            '__typename',
                            'updatedAt',
                            'createdAt',
                          ]),
                        },
                      };

                  await submit({ variables: { ...data } });

                  toaster.success(
                    `Product ${form.sku} ${
                      isNewProduct ? 'created' : 'saved'
                    }.`,
                    { duration: 3 }
                  );

                  history.push('/products');
                } catch (e: any) {
                  toaster.danger(e?.message);
                }
              }}
            >
              Save Product
            </Button>
          </Pane>
        </Pane>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={8} offset={{ lg: 2 }}>
              <Pane
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={20}
              >
                <Heading size={700} style={{ fontWeight: '600' }}>
                  {isNewProduct ? 'Add ' : 'Edit '} Product
                </Heading>
                <Pane display="flex">
                  <Label marginRight={8}>Active</Label>
                  <Switch
                    height={20}
                    disabled={isLoading}
                    checked={!!form.meta.isActive}
                    name="isActive"
                    onChange={() => {
                      setForm({
                        ...form,
                        meta: { ...form.meta, isActive: !form.meta.isActive },
                      });
                    }}
                  />
                </Pane>
              </Pane>

              <Pane
                padding={22}
                elevation={1}
                borderRadius="8px"
                backgroundColor="#fdfdfd"
              >
                <Pane
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={20}
                >
                  <Heading size={500} style={{ fontWeight: '700' }}>
                    Product Information
                  </Heading>
                </Pane>

                <Pane display="flex">
                  <Pane display="flex" flexDirection="column">
                    <Pane display="flex">
                      <TextInputField
                        required
                        label="Name"
                        width="100%"
                        placeholder="Name"
                        inputWidth="100%"
                        inputHeight={40}
                        disabled={isLoading}
                        isInvalid={!!errors?.name}
                        validationMessage={errors?.name?.message}
                        value={form.name}
                        onChange={(e) =>
                          setForm({ ...form, name: e.target.value })
                        }
                      />
                    </Pane>

                    <Pane display="flex">
                      <TextInputField
                        width="100%"
                        label="Display Name"
                        placeholder="Display Name"
                        description="This field will be displayed on the store to our customers. If empty, the Name field above will be used."
                        inputWidth="100%"
                        inputHeight={40}
                        value={form.meta.cartName}
                        disabled={isLoading}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            meta: { ...form.meta, cartName: e.target.value },
                          })
                        }
                      />
                    </Pane>
                  </Pane>
                </Pane>

                <Pane display="flex" flexDirection="column" marginBottom={20}>
                  <Label marginBottom={4} display="block">
                    Description
                  </Label>
                  <Textarea
                    width="100%"
                    placeholder="Description"
                    value={form.meta.description}
                    disabled={isLoading}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meta: { ...form.meta, description: e.target.value },
                      })
                    }
                  />
                </Pane>

                <Pane display="flex" flexDirection="column" marginBottom={20}>
                  <Label marginBottom={4} display="block">
                    Internal Description
                  </Label>
                  <Textarea
                    width="100%"
                    placeholder="Internal description"
                    description="This field describes the print and is added to the packing slip."
                    value={form.meta.internalDescription}
                    disabled={isLoading}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meta: {
                          ...form.meta,
                          internalDescription: e.target.value,
                        },
                      })
                    }
                  />
                </Pane>

                <Pane display="flex" flexDirection="column" marginBottom={20}>
                  <Label display="block">Summary</Label>
                  <Text marginBottom={5}>
                    This summary is a short description that will be shown in
                    the cart with the product item (excludes AndAlways store).
                  </Text>
                  <Textarea
                    width="100%"
                    placeholder="Summary"
                    value={form.meta.summary}
                    disabled={isLoading}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meta: {
                          ...form.meta,
                          summary: e.target.value,
                        },
                      })
                    }
                  />
                </Pane>

                <Pane
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={20}
                >
                  <Heading size={500} style={{ fontWeight: '700' }}>
                    Product Details
                  </Heading>
                </Pane>

                <Pane display="flex">
                  <TextInputField
                    required
                    label="SKU"
                    placeholder="SKU"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={isLoading}
                    isInvalid={!!errors?.sku}
                    validationMessage={errors?.sku?.message}
                    value={form.sku}
                    onChange={(e) => setForm({ ...form, sku: e.target.value })}
                  />
                </Pane>

                <Pane display="flex" width="100%">
                  <TextInputField
                    required
                    label="Price"
                    placeholder="Price"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={isLoading}
                    isInvalid={!!errors?.unitPrice}
                    validationMessage={errors?.unitPrice && 'Required.'}
                    value={
                      form.unitPrice ? (form.unitPrice / 100).toFixed(2) : ''
                    }
                    onChange={(e) => {
                      let { value } = e.target;

                      if (value.length < 1) {
                        value = '0';
                      }

                      setForm({
                        ...form,
                        unitPrice: parseInt(value.replace(/\D/g, '')),
                      });
                    }}
                  />

                  <TextInputField
                    label="Compare at Price"
                    placeholder="Compare at Price"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    marginLeft={16}
                    disabled={isLoading}
                    value={
                      form.meta.compareAtPrice
                        ? (form.meta.compareAtPrice / 100).toFixed(2)
                        : ''
                    }
                    onChange={(e) => {
                      let { value } = e.target;

                      if (value.length < 1) {
                        value = '0';
                      }

                      setForm({
                        ...form,
                        meta: {
                          ...form.meta,
                          compareAtPrice: parseInt(value.replace(/\D/g, '')),
                        },
                      });
                    }}
                  />
                </Pane>

                <Pane display="flex">
                  <SelectField
                    required
                    width="100%"
                    inputHeight={40}
                    marginRight={16}
                    disabled={isLoading}
                    label="Type"
                    placeholder="Type"
                    value={form.type}
                    isInvalid={!!errors?.type}
                    onChange={(e) => setForm({ ...form, type: e.target.value })}
                  >
                    <option value="">Select Type</option>
                    {Object.keys(ProductType).map((key) => (
                      <option value={ProductType[key]}>{key}</option>
                    ))}
                  </SelectField>

                  <SelectField
                    required
                    width="100%"
                    inputHeight={40}
                    disabled={isLoading}
                    label="Category"
                    placeholder="Category"
                    value={form.category}
                    isInvalid={!!errors?.category}
                    validationMessage={errors?.category?.message}
                    onChange={(e) =>
                      setForm({ ...form, category: e.target.value })
                    }
                  >
                    <option value="">Select Category</option>
                    <option value="prints">Prints</option>
                    <option value="frames">Frames</option>
                    <option value="framed-prints">Framed Prints</option>
                    <option value="addons">Add-Ons</option>
                  </SelectField>
                </Pane>

                <Pane display="flex" width="50%">
                  <TextInputField
                    type="number"
                    label="Width"
                    placeholder="Width"
                    min={0}
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={isLoading}
                    value={form.meta.printSizes.width}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meta: {
                          ...form.meta,
                          printSizes: {
                            ...form.meta.printSizes,
                            width: parseInt(e.target.value),
                          },
                        },
                      })
                    }
                  />

                  <TextInputField
                    type="number"
                    label="Height"
                    placeholder="Height"
                    min={0}
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    marginLeft={16}
                    disabled={isLoading}
                    value={form.meta.printSizes.height}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meta: {
                          ...form.meta,
                          printSizes: {
                            ...form.meta.printSizes,
                            height: parseInt(e.target.value),
                          },
                        },
                      })
                    }
                  />
                </Pane>

                <Pane
                  display="flex"
                  flexDirection="column"
                  marginBottom={20}
                  width="50%"
                >
                  <Label width="100%" marginBottom={4}>
                    Shipping Options
                  </Label>

                  <SelectMenu
                    isMultiSelect
                    title="Select shipping options"
                    options={shippingRates.map((option) => ({
                      label: option.name,
                      value: option.key,
                    }))}
                    selected={selectedShipping}
                    hasFilter={false}
                    onSelect={(item) => {
                      const selected = [...selectedShipping, item.value];
                      setSelectedShipping(selected.sort().reverse());
                      setForm({
                        ...form,
                        meta: { ...form.meta, availableShipping: selected },
                      });
                    }}
                    onDeselect={(item) => {
                      const deselectedItemIndex = selectedShipping.indexOf(
                        item.value
                      );
                      const selected = selectedShipping.filter(
                        (_item, i) => i !== deselectedItemIndex
                      );
                      setSelectedShipping(selected.sort().reverse());
                      setForm({
                        ...form,
                        meta: { ...form.meta, availableShipping: selected },
                      });
                    }}
                  >
                    <Button disabled={!form.requiresApproval}>
                      {form.meta.availableShipping?.length > 0
                        ? form.meta.availableShipping.map((i) => i + ' ')
                        : 'Select shipping options...'}
                    </Button>
                  </SelectMenu>
                </Pane>

                <Pane
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={20}
                >
                  <Heading size={500} style={{ fontWeight: '700' }}>
                    Additional Information
                  </Heading>
                </Pane>

                <Pane display="flex">
                  <TextInputField
                    label="Commodity Code"
                    placeholder="Commodity Code"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={isLoading}
                    value={form.commodityCode}
                    onChange={(e) =>
                      setForm({ ...form, commodityCode: e.target.value })
                    }
                  />

                  <TextInputField
                    label="UPC"
                    placeholder="UPC"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    marginLeft={16}
                    disabled={isLoading}
                    value={form.upc}
                    onChange={(e) => setForm({ ...form, upc: e.target.value })}
                  />
                </Pane>

                <Pane width="50%">
                  <TextInputField
                    type="number"
                    width="100%"
                    label="Printful Variant ID"
                    placeholder="Printful Variant ID"
                    min={0}
                    inputWidth="100%"
                    inputHeight={40}
                    disabled={isLoading}
                    value={form.meta.printful.variant}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        meta: {
                          ...form.meta,
                          printful: {
                            ...form.meta.printful,
                            variant: parseInt(e.target.value),
                          },
                        },
                      })
                    }
                  />
                </Pane>

                <Pane display="flex" flexDirection="column">
                  <Label width="100%">Requires Approval</Label>
                  <Text marginTop={2}>
                    Orders containing a product that requires approval will be
                    sent through the approval flow.
                  </Text>

                  <Switch
                    marginTop={10}
                    height={20}
                    placeholder="Requires Approval"
                    disabled={isLoading}
                    checked={form.requiresApproval}
                    name="Requires Approval"
                    onChange={() => {
                      const shipping = shippingRates.map(
                        (option) => option.key
                      );
                      const availableShipping = !form.requiresApproval
                        ? shipping
                        : [];

                      setForm({
                        ...form,
                        requiresApproval: !form.requiresApproval,
                        meta: { ...form.meta, availableShipping },
                      });
                      setSelectedShipping(availableShipping);
                    }}
                  />
                </Pane>
              </Pane>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </>
    </Fade>
  );
};
