import React, { useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { ProductFragment, useProductsQuery } from 'lib/api/ecommerce/schema';
import { formatCurrency } from 'lib/utils';
import { Dialog, Pane, Select, Heading, toaster, Spinner } from 'evergreen-ui';
import { CustomPrint, Order, StaticPrint } from 'models';

export interface AddNewFromPrintModalProps {
  item: CustomPrint | StaticPrint;
  order: Order;
  onClose: () => void;
}

export const AddNewFromPrintModal: React.FC<AddNewFromPrintModalProps> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    data: { products = [] } = {},
    loading: fetchingProducts,
  } = useProductsQuery();

  const nonDeprecatedProducts = products.filter(
    (product) => !product.name.includes('OLD')
  );

  const sortProducts = (products: ProductFragment[]) => {
    const prints: ProductFragment[] = [];
    const framedPrints: ProductFragment[] = [];

    for (let i = 0; i < products.length; i++) {
      if (products[i].category === 'prints') {
        prints.push(products[i]);
      } else if (products[i].category === 'framed-prints') {
        framedPrints.push(products[i]);
      }
    }

    return prints.concat(framedPrints);
  };

  const sortedProducts = sortProducts(nonDeprecatedProducts);

  const onSubmit = async () => {
    setLoading(true);

    // update print meta to new size
    const newProduct = products.find((product) => product.sku === selectedItem);
    const newPrintSize = newProduct?.meta?.printSizes;
    const currentPrintSize = item.printSize;

    if (!!newPrintSize && !_isEqual(newPrintSize, currentPrintSize)) {
      item.setPrintSize(newPrintSize);
    }

    const res = await order.addItems([
      { quantity: 1, meta: item.meta, sku: selectedItem },
    ]);

    if (!res.ok) {
      setLoading(false);
      toaster.danger(res.error?.message || 'Error adding item.');
      return;
    }

    setLoading(false);
    toaster.success('Successfully added item.');
    onClose();
  };

  return (
    <Dialog
      width={380}
      isShown={true}
      title="Add New From Print"
      shouldCloseOnOverlayClick={false}
      confirmLabel={isLoading ? 'Loading...' : 'Submit'}
      isConfirmLoading={isLoading || fetchingProducts}
      onConfirm={onSubmit}
      onCloseComplete={onClose}
    >
      {fetchingProducts && (
        <Pane>
          <Spinner marginX="auto" marginY={20} />
        </Pane>
      )}

      {!fetchingProducts && (
        <>
          <Pane>
            <Heading marginTop={20}>Product</Heading>
          </Pane>
          <Pane>
            <Select
              width="100%"
              height={52}
              marginTop={5}
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
            >
              <option value="">Select A Product</option>

              {sortedProducts
                .filter((product) => {
                  if (!!product.meta.isStaticPrint) {
                    return !!product.meta.isVariant;
                  }

                  return true;
                })
                .map((product) => (
                  <option key={product.sku} value={product.sku}>
                    {product.name} - {formatCurrency(product.unitPrice)}
                  </option>
                ))}
            </Select>
          </Pane>
        </>
      )}
    </Dialog>
  );
};
