import React, { useContext } from 'react';
import { User } from 'models';

export interface AuthContext {
  user: User;
  error: any;
  isReady: boolean;
  checkValidAuth: () => void;
  logout: () => void;
}

export const AuthProviderContext = React.createContext<AuthContext>(null!);

AuthProviderContext.displayName = 'AuthProviderContext';

export const AuthProviderContextProvider = AuthProviderContext.Provider;
export const AuthProviderContextConsumer = AuthProviderContext.Consumer;

export const useAuth = (): AuthContext => {
  const auth = useContext<AuthContext>(AuthProviderContext);

  if (!auth) {
    throw new Error(
      'Could not find "auth" in the context or passed in as a prop. ' +
        'Wrap the root component in an <AuthProvider>, or pass an ' +
        'settings instance in via props.'
    );
  }

  return auth;
};
