import React from 'react';

export const LogoIcon: React.FC = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.000000 342.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,342.000000) scale(0.100000,-0.100000)"
      fill="#000044"
      stroke="none"
    >
      <path
        d="M1265 3360 c-11 -4 -54 -13 -95 -19 -41 -7 -120 -27 -176 -46 -217
-72 -392 -183 -552 -350 -189 -195 -303 -410 -365 -684 -30 -132 -31 -422 -3
-556 37 -177 114 -359 216 -510 65 -97 219 -258 312 -327 208 -153 449 -243
710 -263 99 -8 128 -2 128 26 0 24 -15 30 -156 63 -92 22 -246 75 -261 90 -2
2 18 46 45 97 28 52 73 146 102 211 l53 116 61 -48 c72 -56 274 -190 287 -190
5 0 9 -17 9 -38 0 -59 28 -245 54 -363 62 -275 171 -436 331 -490 22 -7 44
-17 49 -21 13 -11 142 -10 164 0 9 5 39 19 65 32 100 49 157 148 157 276 0 67
-36 208 -72 277 -73 144 -215 289 -418 427 l-57 39 -2 158 c-2 156 13 764 24
978 20 378 82 608 217 808 21 31 80 100 130 152 107 112 107 126 4 107 -208
-39 -379 -142 -527 -318 -197 -236 -345 -542 -524 -1081 -37 -112 -68 -203
-70 -203 -10 0 -90 142 -110 194 -75 201 -47 431 77 639 17 29 19 40 10 49
-34 34 -194 -120 -255 -244 -57 -116 -72 -184 -72 -333 0 -108 4 -142 23 -205
39 -122 122 -273 202 -363 l28 -33 -47 -114 c-59 -141 -159 -362 -169 -372
-12 -12 -175 136 -243 221 -125 155 -206 318 -257 514 -24 93 -27 119 -27 297
0 213 10 276 67 440 83 233 242 451 439 600 223 168 505 265 784 271 92 2 118
11 113 42 -2 17 -13 24 -43 30 -22 5 -54 13 -71 18 -37 11 -261 11 -289 -1z
m330 -1102 c-11 -62 -32 -513 -34 -723 -1 -115 -3 -211 -5 -212 -4 -4 -145
101 -193 144 l-31 28 131 397 c73 219 133 398 135 398 2 0 0 -15 -3 -32z m383
-1531 c259 -178 365 -383 260 -502 -79 -91 -203 -61 -271 64 -46 84 -68 184
-82 376 -3 44 -8 91 -11 104 -7 31 6 26 104 -42z"
      />
      <path
        d="M2313 3053 c-26 -10 -12 -46 41 -106 174 -200 300 -447 353 -697 26
-120 26 -440 0 -558 -52 -242 -174 -490 -331 -676 -92 -108 -58 -141 59 -58
219 154 394 414 465 689 43 168 61 364 41 458 -5 28 -15 79 -21 115 -51 303
-247 611 -502 790 -70 49 -80 53 -105 43z"
      />
    </g>
  </svg>
);
