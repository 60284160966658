import styled from 'styled-components';

export const Container = styled.div`
  background: #f9fafb;
  border: 1px solid #e1e5ea;
  border-bottom-width: 2px;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

export const ImageContainer = styled.div`
  border: 1px solid #eeeeee;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;
