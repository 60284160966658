import { ValidationError } from 'yup';

export const formatYupErrors = (e: ValidationError): any => {
  const errors = e?.inner || [];
  let formattedErrors: any = {};

  errors.forEach((error) => {
    formattedErrors[error.path] = {
      name: error.path,
      message: error.message,
    };
  });

  return formattedErrors;
};
