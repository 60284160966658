import _merge from 'lodash/merge';
import _set from 'lodash/set';
import { fulfillmentWestCoastStates } from 'config/fulfillment';
import { OrdersTableFilters } from './OrdersTableFilters';

export interface OrdersTableFilterQueryDefinition {
  query: Record<string, any>;
  valuePath: string[] | null;
}

export type OrdersTableFilterDefinitionValue =
  | OrdersTableFilterQueryDefinition
  | Record<string, OrdersTableFilterQueryDefinition>;

export type OrdersTableFiltersQueryMap = Record<
  string,
  OrdersTableFilterDefinitionValue
>;

// Map filter options to filter queries (in shape of mongo queries)
export const queryMap: OrdersTableFiltersQueryMap = {
  addons: {
    query: { addons: { $elemMatch: { sku: { $in: [] } } } },
    valuePath: ['addons', '$elemMatch', 'sku', '$in'],
  },
  productsByCategory: {
    query: {
      items: { $all: [{ $elemMatch: { category: { $in: [] } } }] },
    },
    valuePath: ['items', '$all', '0', '$elemMatch', 'category', '$in'],
  },
  productsBySize: {
    query: {
      items: { $all: [{ $elemMatch: { sku: { $regex: '', $options: 'i' } } }] },
    },
    valuePath: ['items', '$all', '0', '$elemMatch', 'sku', '$regex'],
  },
  products: {
    query: { items: { $all: [{ $elemMatch: { sku: { $in: [] } } }] } },
    valuePath: ['items', '$all', '0', '$elemMatch', 'sku', '$in'],
  },
  regions: {
    international: {
      query: { 'shippingAddress.country': { $ne: 'US' } },
      valuePath: null,
    },
    unitedStates: {
      query: { 'shippingAddress.country': { $eq: 'US' } },
      valuePath: null,
    },
    eastCoast: {
      query: {
        'shippingAddress.state': {
          $nin: fulfillmentWestCoastStates,
        },
      },
      valuePath: null,
    },
    westCoast: {
      query: {
        'shippingAddress.state': {
          $in: fulfillmentWestCoastStates,
        },
      },
      valuePath: null,
    },
  },
  shippingRates: {
    query: {
      adjustments: {
        $elemMatch: {
          type: { $regex: 'shipping', $options: 'i' },
          'meta.method': { $in: [] },
        },
      },
    },
    valuePath: ['adjustments', '$elemMatch', 'meta.method', '$in'],
  },
  stores: {
    query: { store: { $in: [] } },
    valuePath: ['store', '$in'],
  },
};

export function buildFilterQueryFromFilters(
  filters: OrdersTableFilters['filter']
): Record<string, any> | undefined {
  let query: any = {};

  Object.keys(filters).forEach((key) => {
    const fieldValues = filters[key];
    const fieldDefinition = queryMap[key];

    if (!fieldDefinition) return;

    let fieldQuery = {};

    if (fieldValues.length > 0) {
      if ('query' in fieldDefinition && 'valuePath' in fieldDefinition) {
        _set(fieldQuery, (fieldDefinition as any)?.valuePath, fieldValues);
        _merge(fieldQuery, query);
      } else {
        let tmpQuery = {};

        fieldValues.forEach((value) => {
          if (value in queryMap[key]) {
            tmpQuery = { ...tmpQuery, ...queryMap[key][value].query };
          }
        });

        fieldQuery = { ...fieldQuery, ...tmpQuery };
      }
    }

    query = { ...query, ...fieldQuery };
  });

  // run $all with addon products
  if (query.addons) {
    if (query.items) {
      query.items.$all.push(query.addons);
    } else {
      query.items = { $all: [{ ...query.addons }] };
    }

    delete query.addons;
  }

  return Object.keys(query).length > 0 ? query : undefined;
}
