import { useMemo } from 'react';
import { plainToClass } from 'class-transformer';
import { Order } from 'models';
import {
  FulfillmentOrdersQueryVariables,
  OrdersQueryResultsPagination,
  useFulfillmentOrdersLazyQuery,
} from 'lib/api/ecommerce/schema';

interface UseFulfillmentOrdersResult {
  error: any;
  fetch: any;
  loading: boolean;
  orders: Order[];
  pagination?: OrdersQueryResultsPagination;
}

// this hook uses lazy query by default
export function useFulfillmentOrders(
  input: FulfillmentOrdersQueryVariables = {},
  onCompleted: (data: any) => void | undefined = () => {}
): UseFulfillmentOrdersResult {
  const [fetch, { data, loading, error }] = useFulfillmentOrdersLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { ...input },
    onCompleted,
  });

  const orders = useMemo<Order[]>(() => {
    if (data) {
      return plainToClass(Order, data.orders?.results ?? []);
    }

    return [];
  }, [data]);

  return {
    error,
    fetch,
    loading,
    orders,
    pagination: data?.orders?.pagination,
  };
}
