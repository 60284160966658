import React from 'react';
import { BaseOrder } from 'models';
import Header from './Header';
import { Body } from './Body';
import { Table as StyledTable } from 'evergreen-ui';

interface TableProps {
  orders: BaseOrder[];
}

export const Table: React.FC<TableProps> = ({ orders }) => (
  <StyledTable borderRadius={6} marginTop="25px">
    <Header />
    <Body orders={orders} />
  </StyledTable>
);
