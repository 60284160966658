import React from 'react';
import dateFormat from 'date-fns/format';
import { Order } from 'models';
import { Transaction } from 'lib/api/ecommerce/schema';
import { formatCurrency } from 'lib/utils';
import { Pane, Table, Link } from 'evergreen-ui';

interface PaymentRowProps {
  order: Order;
  payment: Transaction;
}

export const PaymentRow: React.FC<PaymentRowProps> = ({ order, payment }) => {
  if (!payment) return null;

  const paymentLink =
    payment.source === 'STRIPE'
      ? `https://dashboard.stripe.com/payments/${payment.sourceId}`
      : `${process.env.REACT_APP_BRAINTREE_URL?.toString()}/merchants/${
          order.braintreeMerchantId
        }/transactions/${payment.sourceId}`;

  return (
    <Pane>
      <Table.Row>
        <Table.TextCell>
          <strong>{payment.source}</strong> (
          {dateFormat(new Date(payment.createdAt), 'MMM do yyyy h:mm:ss a')})
        </Table.TextCell>
        <Table.TextCell>{payment.type}</Table.TextCell>
        <Table.TextCell>{payment.sourceStatus}</Table.TextCell>
        <Table.TextCell>
          <Link target="_blank" href={paymentLink}>
            {payment.sourceId}
          </Link>
        </Table.TextCell>
        <Table.Cell justifyContent="flex-end">
          {formatCurrency(payment.amount)}
        </Table.Cell>
      </Table.Row>
    </Pane>
  );
};
