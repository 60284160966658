import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f9fafc;
  border-right: 1px solid #eeeeee;
  min-height: 100vh;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 230px;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.h1`
  flex-basis: 60px;
  align-self: flex-start;
  font-size: 2.2em;
  text-align: right;
  width: 100%;
  margin: 1em 0;

  svg {
    width: 120px;
    margin-right: 12px;
  }
`;

export const Nav = styled.ul`
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
  width: 100%;
  justify-self: flex-end;
  text-align: right;
`;

export const NavItem = styled.li`
  margin: 0;
  width: 100%;

  a {
    text-align: right;
    font-size: 1.5em;
    font-weight: 200;
    text-decoration: none;
    padding: 0.7rem 0.6em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    transition: all 200ms ease-in-out;
    color: #234361;
    letter-spacing: 0.5px;

    &.active-route {
      font-weight: 500;
      margin-right: 6px;
      background: rgba(255, 255, 255, 0.14);
      transition: all 200ms ease-in-out;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.14);
      transition: all 200ms ease-in-out;
      margin-right: 6px;
    }
  }
`;
