import { BaseTransaction } from './Base';
import { formatCurrency } from 'lib/utils';

export class Transaction extends BaseTransaction {
  get formattedAmount() {
    return formatCurrency(this.amount);
  }
}

export default Transaction;
