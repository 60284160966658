import {
  ShipstationFrameTag,
  ShipstationHangerFrameTag,
  ShipstationRushOrderTag,
  ShipstationPodTags,
  ShipstationShippingTags,
  ShipstationSkuColorCodes,
} from 'config/fulfillment';
import { format } from 'date-fns';
import { getHasFrame, getHasHangerFrame } from '.';
import { Order, OrderItem, OrderItemTypes, StaticPrint } from '../..';

// helper
function isOnlyHangerFrames(items: OrderItem[]): boolean {
  return items.every((item) => /HF[0-9]{1,2}/gm.test(item?.sku));
}

interface ShipstationArgsItem {
  id: string;
  meta: unknown;
}

interface ShipstationArgs {
  items: ShipstationArgsItem[];
  tagIds: number[];
  advancedOptions: {
    customField1?: string;
    customField2?: string;
    customField3?: string;
  };
}

export function composeShipstationArgs(
  order: Order,
  items: OrderItem[],
  pod?: string | null
): ShipstationArgs {
  pod = pod?.toUpperCase();

  let advancedOptions: any = {};
  let inputItems: ShipstationArgsItem[] = [];

  if (pod) {
    advancedOptions.customField2 = pod;
  }

  items.forEach((item) => {
    inputItems.push({ id: item.id, meta: composeShipstationMeta(item) });
  });

  return {
    advancedOptions,
    items: inputItems,
    tagIds: composeTagIds(order, items, pod),
  };
}

function composeTagIds(
  order: Order,
  items: OrderItem[],
  pod?: string | null
): number[] {
  const tagIds: number[] = [];

  let shippingMethod = order?.shippingMethod?.toUpperCase();

  if (shippingMethod && ShipstationShippingTags[shippingMethod]) {
    tagIds.push(ShipstationShippingTags[shippingMethod]);
  }

  const podKey = pod?.replace(' ', '_');

  if (podKey && ShipstationPodTags[podKey] && !isOnlyHangerFrames(items)) {
    tagIds.push(ShipstationPodTags[podKey]);
  }

  if (getHasFrame(items)) {
    tagIds.push(ShipstationFrameTag);
  }

  if (getHasHangerFrame(items)) {
    tagIds.push(ShipstationHangerFrameTag);
  }

  if (order.isRushOrder) {
    tagIds.push(ShipstationRushOrderTag);
  }

  return tagIds;
}

function composeShipstationMeta(item: OrderItem) {
  if (
    item.type !== OrderItemTypes.custom_print &&
    item.type !== OrderItemTypes.static_print
  ) {
    return { fulfillmentSku: item.sku, options: [] };
  }

  if (item.type === OrderItemTypes.static_print) {
    const options = item?.product?.meta?.variantOptions ?? [];

    // add internal description if exists
    if (item.product?.meta?.internalDescription) {
      options.push({
        type: 'description',
        value: item.product.meta.internalDescription,
      });
    }

    return {
      fulfillmentSku: item.sku,
      imageUrl: (item as StaticPrint).getPreviewUrl(),
      options: options.map(({ name, value }) => ({ name: name, value })),
    };
  }

  if (!item.sku.includes('ZODIAC')) {
    return composeStarMapShipstationOptions(item);
  }

  return composeZodiacMapShipstationOptions(item);
}

function composeZodiacMapShipstationOptions(item: OrderItem) {
  const preset = item.printMeta?.preset;
  const zodiac = item.printMeta?.subject?.zodiac;

  const options: { name: string; value: any }[] = [];

  options.push({ name: 'preset', value: preset });
  for (const [key, value] of Object.entries({
    ...item.printMeta.labels,
    ...item.printMeta.subject,
  })) {
    if (key && value) {
      options.push({ name: key, value: `${value}` });
    }
  }

  const fulfillmentSku = `ZODIAC_${zodiac}_${preset
    .replace('-', '')
    .replace(/[aeiou]/gi, '')}`.toUpperCase();

  return { fulfillmentSku, options };
}

function composeStarMapShipstationOptions(item: OrderItem) {
  const { date } = item.printMeta.subject;
  const { headline = '', tagline = '', primaryColor } = item.printMeta?.theme;

  let fulfillmentSku = '';
  let options: any = [];

  if (primaryColor) {
    const color = primaryColor.substring(0, 4).toLowerCase();

    if (typeof ShipstationSkuColorCodes[color] !== 'undefined') {
      fulfillmentSku += ShipstationSkuColorCodes[color];
    }
  }

  let datetime;
  if (typeof date === 'string') {
    datetime = new Date(date);
  } else {
    datetime = new Date(date.year, date.month - 1, date.day);
  }

  fulfillmentSku += format(datetime, 'MMMddyy').toUpperCase();

  // build options
  if (headline) {
    options.push({
      name: 'Headline',
      value: headline?.substring(0, 100),
    });
  }

  if (tagline) {
    options.push({
      name: 'Tagline',
      value: tagline?.substring(0, 100),
    });
  }

  return { fulfillmentSku, options };
}
