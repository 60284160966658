import React, { useEffect, useState } from 'react';
import { Order } from 'models';
import { Pane, Heading, Textarea, Icon } from 'evergreen-ui';

interface SidebarShippingInfoProps {
  order: Order;
}

export const SidebarShippingInfo: React.FC<SidebarShippingInfoProps> = ({
  order,
}) => {
  const [isVerified, setIsVerified] = useState<Boolean>(false);
  const [hasRunVerificatioon, setHasRunVerification] = useState<Boolean>(false);

  useEffect(() => {
    const fetchVerifyAddress = async () => {
      const res = await order.validateAndUpdateAddress();

      if (!res.ok) {
        setIsVerified(false);
      } else {
        setIsVerified(true);
      }

      setHasRunVerification(true);
    };

    if (!hasRunVerificatioon) fetchVerifyAddress();
  }, [hasRunVerificatioon, order]);

  if (!hasRunVerificatioon) return <>Verifying address...</>;

  return (
    <>
      <Pane
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        marginBottom={20}
      >
        <Pane
          flex={1}
          display="flex"
          alignItems="center"
          flexDirection="row"
          marginBottom={10}
        >
          <Heading size={100} marginRight={15}>
            Shipping Address
          </Heading>
        </Pane>
        <Pane
          flex={1}
          display="flex"
          alignItems="center"
          flexDirection="row"
          marginBottom={10}
        >
          {hasRunVerificatioon && isVerified && (
            <div>
              <Icon icon="tick" color="success" marginRight={8} size={14} />
              Valid
            </div>
          )}

          {hasRunVerificatioon && !isVerified && (
            <div>
              <Icon
                icon="ban-circle"
                color="danger"
                marginRight={8}
                size={14}
              />
              Invalid Address. Double check via Google or contact customer.
            </div>
          )}
        </Pane>

        {order?.shippingAddress && (
          <Textarea
            height={120}
            readOnly={true}
            name="shippingAddress"
            value={`${
              (order.shippingAddress && order.shippingAddress.recipient) || ''
            }\n${order.shippingAddress.line1}\n${
              order.shippingAddress.line2
                ? `${order?.shippingAddress?.line2}\n`
                : ''
            }${order.shippingAddress.city}, ${order.shippingAddress.state}, ${
              order.shippingAddress.postalCode
            }\n${order.shippingAddress.country}`}
          />
        )}
      </Pane>
    </>
  );
};
