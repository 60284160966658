import React, { useState, useEffect } from 'react';
import { Order } from 'models';
import { Alert, Pane, Paragraph, IconButton } from 'evergreen-ui';

interface Props {
  order: Order;
}

export const DigitalPrintAlert: React.FC<Props> = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    for (let i = 0; i < order.items.length; i++) {
      if (order.items[i].sku === 'P-DIGITAL') {
        setIsOpen(true);
      }
    }
  }, [order.items]);

  if (!isOpen) {
    return null;
  }

  return (
    <Pane marginTop={10}>
      <Alert
        appearance="card"
        intent="warning"
        title="Digital Print"
        paddingRight={36}
      >
        <Paragraph marginRight={36} marginTop={5}>
          This order contains a digital print.
        </Paragraph>

        <IconButton
          icon="cross"
          cursor="pointer"
          position="absolute"
          top={12}
          right={12}
          onClick={() => setIsOpen(false)}
        />
      </Alert>
    </Pane>
  );
};
