import React from 'react';
import { UserFragment } from 'lib/api/ecommerce/schema';
import { Alert, Heading, Pane, TextInputField } from 'evergreen-ui';

interface FormProps {
  user?: UserFragment;
}

export const Form: React.FC<FormProps> = ({ user }) => (
  <Pane
    marginTop={20}
    elevation={1}
    backgroundColor="#fdfdfd"
    padding={28}
    borderRadius="8px"
  >
    <Pane display="flex" marginBottom={20}>
      <Pane alignItems="center" display="flex">
        <Heading size={600}>Profile</Heading>
      </Pane>
    </Pane>
    <Pane display="flex" marginBottom={20}>
      <Alert
        width="100%"
        marginBottom={12}
        appearance="card"
        intent="none"
        title="If you need to make changes, please contact an admin."
      />
    </Pane>

    <Pane display="flex">
      <TextInputField
        required
        label="Name"
        placeholder="Name"
        inputWidth="100%"
        width="100%"
        inputHeight={40}
        disabled={true}
        value={user?.name}
      />
    </Pane>

    <Pane display="flex">
      <TextInputField
        required
        label="Email Address"
        placeholder="Email Address"
        inputWidth="100%"
        width="100%"
        inputHeight={40}
        disabled={true}
        value={user?.email}
      />
    </Pane>
  </Pane>
);
