import React, { useState } from 'react';
import formatDate from 'date-fns/format';
import { formatCurrency } from 'lib/utils';
import { get } from 'object-path';
import { FulfillmentStatus } from 'lib/api/ecommerce/schema';
import { RoleAccess } from 'lib/auth';
import { CustomPrint, Order, OrderItem, OrderItemTypes } from 'models';
import { FulfillmentStatusBadge } from 'components/FulfillmentStatusBadge';
import { FulfillOrderModal } from 'components/FulfillOrderModal';
import { PrinterSubmissionModal } from './modals/PrinterSubmission';
import { ChangeQuantityModal } from './modals/ChangeQuantity';
import { AddNewFromPrintModal } from './modals/AddNewFromPrint';
import AddFrameModal from './modals/AddFrame';
import RemoveFrameModal from './modals/RemoveFrame';
import RemovePosterModal from './modals/RemovePoster';
import CancelFulfillmentDialog from './modals/CancelFulfillment';
import FulfillmentListDialog from './modals/FulfillmentList';
import {
  Icon,
  IconButton,
  Menu,
  Popover,
  Position,
  Table,
  Tooltip,
} from 'evergreen-ui';

// ugliest file in the repo
// clean at some point

interface OrderPageCartTableItemProps {
  item: OrderItem;
  order: Order;
}

export const OrderPageCartTableItem: React.FC<OrderPageCartTableItemProps> = ({
  item,
  order,
}) => {
  const [
    showPrinterSubmissionModal,
    setPrinterSubmissionModal,
  ] = useState<boolean>(false);
  const [showChangeQuantityModal, setChangeQuantityModal] = useState<boolean>(
    false
  );
  const [showAddNewFromPrintModal, setAddNewPrintFromModal] = useState<boolean>(
    false
  );
  const [showAddFrameModal, setShowAddFrameModal] = useState<boolean>(false);
  const [showFulfillOrderModal, setShowFulfillOrderModal] = useState<boolean>(
    false
  );
  const [
    showCancelFulfillmentModal,
    setShowCancelFulfillmentModal,
  ] = useState<boolean>(false);
  const [
    showFulfillmentListModal,
    setShowFulfillmentListModal,
  ] = useState<boolean>(false);
  const [showRemoveFrameModal, setShowRemoveFrameModal] = useState<boolean>(
    false
  );
  const [showRemoveItemModal, setShowRemoveItemModal] = useState<boolean>(
    false
  );

  let date: Date | string = get(item.printMeta, 'subject.date');
  const hasFulfilled = isItemFulfilled(item, order.fulfillments);

  if (typeof date === 'string') {
    date = new Date(date);
  } else if (date) {
    const { month, day, year } = date as any;
    date = new Date(year, month - 1, day);
  }

  const width = () => {
    let width = '75px';

    if (item.isPrint && item.printSize) {
      const num = item?.printSize?.width / item?.printSize?.height;
      width = `${num * 100}px`;
    }

    return width;
  };

  return (
    <>
      <Table.Row key={item.id} height="auto" minHeight={60} paddingY={6}>
        <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>
          {item.type !== OrderItemTypes.add_on && (
            <img
              alt={`Print ${item.id}`}
              src={(item as CustomPrint).getPreviewUrl()}
              style={{ width: width(), height: '100px' }}
            />
          )}
        </Table.TextCell>
        <Table.TextCell>
          <span className="wordWrap">
            {item.name}

            {item.type === OrderItemTypes?.custom_print && date && (
              <Tooltip
                content={`${item?.printMeta?.theme?.headline} (${formatDate(
                  date,
                  'MMMM do yyyy'
                )})`}
              >
                <Icon
                  marginTop={5}
                  marginLeft={10}
                  icon="info-sign"
                  size={14}
                  color="#aaaaaa"
                />
              </Tooltip>
            )}
          </span>
        </Table.TextCell>

        <Table.TextCell>{item.quantity}</Table.TextCell>
        <Table.TextCell>
          <span className="wordWrap">{item.sku}</span>
        </Table.TextCell>

        {item.isFulfillable ? (
          <Table.TextCell
            isSelectable
            style={{ cursor: 'pointer' }}
            onClick={() => setShowFulfillmentListModal(true)}
          >
            <FulfillmentStatusBadge status={item.fulfillmentStatus} />
          </Table.TextCell>
        ) : (
          <Table.TextCell />
        )}

        <Table.Cell justifyContent="flex-end">
          {formatCurrency(item.unitPrice || 0)}
        </Table.Cell>

        <Table.Cell width={48} flex="none">
          <RoleAccess permission="orders:items-edit">
            {(item.isPrint || item.isAddOn || item.isDigital) && (
              <Popover
                position={Position.BOTTOM_RIGHT}
                content={
                  <Menu>
                    <Menu.Group>
                      {item.isPrint &&
                        !item.hasFrame &&
                        !item.isDigital &&
                        item.type === OrderItemTypes.custom_print &&
                        !hasFulfilled && (
                          <Menu.Item
                            onSelect={() => setShowAddFrameModal(true)}
                          >
                            Add Frame
                          </Menu.Item>
                        )}

                      {!hasFulfilled &&
                        item.isPrint &&
                        item.isFulfillable &&
                        !item.isDigital && (
                          <Menu.Item
                            onSelect={() => setShowFulfillOrderModal(true)}
                          >
                            Submit Fulfillment
                          </Menu.Item>
                        )}

                      {item.isPrint && (
                        <Menu.Item
                          onSelect={() => setPrinterSubmissionModal(true)}
                        >
                          Send to Printer
                        </Menu.Item>
                      )}

                      {item.isPrint && !item.isDigital && (
                        <Menu.Item
                          onSelect={() => setChangeQuantityModal(true)}
                        >
                          Change Quantity
                        </Menu.Item>
                      )}

                      {item.isPrint &&
                        item.type === OrderItemTypes.custom_print && (
                          <Menu.Item
                            onSelect={() => setAddNewPrintFromModal(true)}
                          >
                            Add New From Print
                          </Menu.Item>
                        )}

                      {hasFulfilled && !item.isDigital && (
                        <Menu.Item
                          intent="danger"
                          onSelect={() => setShowCancelFulfillmentModal(true)}
                        >
                          Cancel Fulfillment
                        </Menu.Item>
                      )}

                      {order.items?.length > 1 && !hasFulfilled && (
                        <Menu.Item
                          intent="danger"
                          onSelect={() => setShowRemoveItemModal(true)}
                        >
                          Remove {item.isPrint && ' Poster'}
                        </Menu.Item>
                      )}

                      {item.isPrint &&
                        item.hasFrame &&
                        !hasFulfilled &&
                        item instanceof CustomPrint && (
                          <Menu.Item
                            intent="danger"
                            onSelect={() => setShowRemoveFrameModal(true)}
                          >
                            Remove Frame
                          </Menu.Item>
                        )}
                    </Menu.Group>
                  </Menu>
                }
              >
                <IconButton icon="more" height={32} appearance="minimal" />
              </Popover>
            )}
          </RoleAccess>
        </Table.Cell>
      </Table.Row>

      {showPrinterSubmissionModal && (
        <PrinterSubmissionModal
          item={item as CustomPrint}
          order={order}
          onClose={() => setPrinterSubmissionModal(false)}
        />
      )}

      {showChangeQuantityModal && (
        <ChangeQuantityModal
          item={item}
          order={order}
          onClose={() => setChangeQuantityModal(false)}
        />
      )}

      {showAddNewFromPrintModal && (
        <AddNewFromPrintModal
          item={item as any}
          order={order}
          onClose={() => setAddNewPrintFromModal(false)}
        />
      )}

      {showFulfillmentListModal && (
        <FulfillmentListDialog
          item={item}
          order={order}
          onClose={() => setShowFulfillmentListModal(false)}
        />
      )}

      {showCancelFulfillmentModal && (
        <CancelFulfillmentDialog
          item={item}
          order={order}
          onClose={() => setShowCancelFulfillmentModal(false)}
        />
      )}

      {showFulfillOrderModal && (
        <FulfillOrderModal
          order={order}
          onClose={() => setShowFulfillOrderModal(false)}
        />
      )}

      {showRemoveItemModal && (
        <RemovePosterModal
          item={item}
          order={order}
          onClose={() => setShowRemoveItemModal(false)}
        />
      )}

      {showRemoveFrameModal && (
        <RemoveFrameModal
          item={item}
          order={order}
          onClose={() => setShowRemoveFrameModal(false)}
        />
      )}

      {showAddFrameModal && (
        <AddFrameModal
          item={item}
          order={order}
          onClose={() => setShowAddFrameModal(false)}
        />
      )}
    </>
  );
};

function isItemFulfilled(item, fulfillments) {
  let itemFulfillments = fulfillments.filter((fulfillment) =>
    fulfillment.items.includes(item.id)
  );
  itemFulfillments = itemFulfillments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const latestStatus = get(itemFulfillments, '0.status', null);

  return latestStatus === FulfillmentStatus.Fulfilled;
}
