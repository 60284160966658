import React, { useState } from 'react';
import { Order } from 'models';
import { Dialog, Pane, Textarea, toaster } from 'evergreen-ui';

interface NotesModalProps {
  order: Order;
  onClose: () => void;
}

export const NotesModal: React.FC<NotesModalProps> = ({
  order,
  onClose = () => {},
}) => {
  const [note, setNote] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <Dialog
      isShown={true}
      width={520}
      title="Add Note"
      confirmLabel={isLoading ? 'Loading...' : 'Submit'}
      isConfirmLoading={isLoading}
      onCloseComplete={onClose}
      onConfirm={async () => {
        setLoading(true);

        const res = await order.addNote(note);

        if (!res.ok) {
          toaster.danger(res.error?.message);
          setLoading(false);
          return;
        }

        toaster.success('Order notes saved.');
        setLoading(false);
        onClose();
      }}
    >
      <Pane display="flex">
        <Textarea
          label="Note"
          placeholder="Note"
          width="100%"
          rows={6}
          disabled={isLoading}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Pane>
    </Dialog>
  );
};
