import React, { useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqBy from 'lodash/uniqBy';
import { BaseOrder, CustomPrint, Order, StaticPrint } from 'models';
import { useOrderAndTimelinesById } from 'lib/api/ecommerce/hooks';
import { SlideOver } from './SlideOver';
import {
  FulfillmentContextType,
  FulfillmentProvider,
} from './FulfillmentProvider';

interface FulfillmentSlideOverProps {
  orders: (BaseOrder | Order)[];
  onClose: () => void;
}

export const FulfillmentSlideOver: React.FC<FulfillmentSlideOverProps> = ({
  orders = [],
  onClose = () => {},
}) => {
  const [ordersList, setOrdersList] = useState<(BaseOrder | Order)[]>(orders);
  const [orderIndex, setOrderIndex] = useState<number>(0);
  const [printIndex, setPrintIndex] = useState<number>(0);

  const { order, loading } = useOrderAndTimelinesById(
    ordersList[orderIndex].id as string
  );

  const prints = order?.getPrints() as (CustomPrint | StaticPrint)[];
  const print = prints?.[printIndex];

  const numOrders = ordersList.length;
  const numPrints = prints?.length ?? 0;
  const isFirstOrder = orderIndex === 0;
  const isFirstPrint = printIndex === 0;
  const isLastOrder = orderIndex === ordersList.length - 1;
  const isLastPrint = printIndex === (prints?.length ?? 0) - 1;

  // next order info
  const nextOrder = !isLastOrder ? ordersList?.[orderIndex + 1] : null;
  const isNextOrderSameCustomer =
    !isLastOrder && order?.email === nextOrder?.email;

  const onPrevOrder = () => {
    setOrderIndex(isFirstOrder ? orderIndex : orderIndex - 1);
    setPrintIndex(0);
  };

  const onNextOrder = () => {
    if (isLastOrder) {
      onClose();
    } else {
      setOrderIndex(orderIndex + 1);
      setPrintIndex(0);
    }
  };

  const onPrevPrint = () =>
    setPrintIndex(isFirstPrint ? printIndex : printIndex - 1);

  const onNextPrint = () => {
    if (!isLastPrint) {
      setPrintIndex(printIndex + 1);
    }
  };

  const insertOrder = (index: number, order: BaseOrder | Order) => {
    const temp = _cloneDeep(ordersList);

    temp.splice(index, 0, order);
    setOrdersList(_uniqBy(temp, (o) => o.id));
  };

  const context: FulfillmentContextType = {
    numOrders,
    numPrints,
    isNextOrderSameCustomer,
    nextOrder,
    order,
    ordersList,
    print,
    orderIndex,
    printIndex,
    loading,
    insertOrder,
    onClose,
    onPrevOrder,
    onNextOrder,
    onPrevPrint,
    onNextPrint,
  };

  return (
    <FulfillmentProvider value={context}>
      <SlideOver />
    </FulfillmentProvider>
  );
};
