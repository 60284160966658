import slugify from 'slugify';
import config from 'config';
import { Order } from 'models';
import {
  composePrintMeta,
  getIsMultiColorFileSuffix,
  getPrintColor,
  getPrintShade,
  getShippingRateFileName,
} from './utils';
import {
  BaseOrderItem,
  OrderItemTypes,
  OrderItemProduct,
  OrderItemColorMap,
  OrderItemPrintShadeTypes,
  OrderItem,
  CustomPrintType,
} from '.';

const COLOR_MAP: OrderItemColorMap = {
  '#000000': {
    name: 'black',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'B',
  },
  '#223849': {
    name: 'blue',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'BL',
  },
  '#1c2832': {
    name: 'blue',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'BL',
  },
  '#a10459': {
    name: 'magenta',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'M',
  },
  '#83164d': {
    name: 'magenta',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'M',
  },
  '#ffffff': {
    name: 'white',
    printShade: OrderItemPrintShadeTypes.white,
    shipstationCode: 'W',
  },
  nebula: {
    name: 'nebula',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'NB',
  },
  'kids-astronaut': {
    name: 'kids-astronaut',
    printShade: OrderItemPrintShadeTypes.dark,
    shipstationCode: 'KA',
  },
};

export class CustomPrint extends BaseOrderItem implements OrderItemProduct {
  public type = OrderItemTypes.custom_print;
  public colorMap = COLOR_MAP;

  get customPrintType(): CustomPrintType | undefined {
    const { subject } = this.printMeta;

    let type;
    switch (subject.kind) {
      case 'stars':
        type = CustomPrintType.STAR_MAP;
        break;
      case 'moon':
        type = CustomPrintType.MOON_MAP;
        break;
      default:
    }

    if (this.sku?.toUpperCase()?.includes('ZODIAC')) {
      type = CustomPrintType.ZODIAC;
    }

    return type;
  }

  get printColor() {
    return getPrintColor(this as any);
  }

  get printShade() {
    return getPrintShade(this);
  }

  getPrintUrl() {
    return `${process.env.REACT_APP_IMAGE_GENERATOR_API_URL?.toString()}/${
      this.printFilename
    }`;
  }

  getPreviewUrl(format: 'pdf' | 'png' = 'png') {
    return `${process.env.REACT_APP_IMGIX_URL?.toString()}/${
      this.printFilename
    }?fm=${format}&q=75&w=0.25&h=0.25`;
  }

  getDownloadUrl(format: 'pdf' | 'png' = 'png', downloadFilename: string) {
    return `${process.env.REACT_APP_IMGIX_URL?.toString()}/${
      this.printFilename
    }?fm=${format}&q=100&w=1&h=1&dl=${downloadFilename}`;
  }

  composePrintMeta(printMeta: Record<string, any>, print?: OrderItem) {
    return composePrintMeta(printMeta, print);
  }

  getPrinterFilename(order: Order, format: 'png' | 'pdf' = 'pdf'): string {
    return this.getFulfillmentPrintFilename(order, 1, 1, format);
  }

  getFulfillmentPrintFilename(
    order: Order,
    quantityIndex: number,
    itemQuantity: number,
    format: 'png' | 'pdf' = 'pdf'
  ): string {
    const multiColorSuffix = getIsMultiColorFileSuffix(order);
    const shippingMethodPrefix = getShippingRateFileName(order);
    const isRushOrder = order.isRushOrder;
    const printShade = getPrintShade(this);
    const customerName = slugify(`${order.firstName} ${order.lastName}`);
    const testPrefix = !config.isProduction ? 'TEST_' : '';

    return `${testPrefix}${shippingMethodPrefix}${order.id}_${
      this.id
    }_${printShade}_${customerName}${multiColorSuffix}${
      isRushOrder ? '_rush' : ''
    }${
      itemQuantity > 1 ? `_${quantityIndex}-of-${itemQuantity}` : ''
    }.${format}`;
  }

  setPrintSize(size: { width: number; height: number }) {
    this.meta['andalways:print'].width = size.width;
    this.meta['andalways:print'].height = size.height;

    // backwards compat :: old
    if (this.meta['andalways:print']?.options?.size) {
      this.meta['andalways:print'].options.size.width = size.width;
      this.meta['andalways:print'].options.size.height = size.height;
    }
  }
}
