import React, { useState } from 'react';
import { shippingRates } from 'config/shipping';
import isEqual from 'react-fast-compare';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import _sortBy from 'lodash/sortBy';
import { OrderStatus } from 'models';
import {
  useProductsQuery,
  useStoresQuery,
  OrdersQuery,
  QueryOrdersArgs,
} from 'lib/api/ecommerce/schema';
import { Button, Pane, SelectMenu } from 'evergreen-ui';
import { MultiSelect } from 'components/MultiSelect';
import { buildFilterQueryFromFilters } from './utils';

export type OrdersTableQuery = Partial<QueryOrdersArgs>;
export type OrdersTableFilters = Partial<Omit<QueryOrdersArgs, 'filter'>> & {
  filter: {
    addons: (string | number)[];
    products: (string | number)[];
    productsByCategory: (string | number)[];
    productsBySize: string | number;
    regions: (string | number)[];
    shippingRates: (string | number)[];
    stores: (string | number)[];
    hoursAgo: string; // orders that were placed more than X hours ago
  };
};

export interface OrdersTableFiltersOnFilterApplyArgs {
  filters: OrdersTableFilters;
  query: OrdersTableQuery;
}

export interface OrdersTableFiltersProps {
  filters: OrdersTableFilters;
  onFilterApply: (data: OrdersTableFiltersOnFilterApplyArgs) => void;
}

export const DEFAULT_FILTERS: OrdersTableFilters = {
  query: OrdersQuery.ApprovedOnly,
  limit: 350,
  reverse: false,
  status: [OrderStatus.Draft],
  next: undefined,
  filter: {
    addons: [],
    products: [],
    productsByCategory: [],
    productsBySize: '',
    regions: [],
    shippingRates: [],
    stores: [],
    hoursAgo: '24',
  },
};

export const OrdersTableFilters: React.FC<OrdersTableFiltersProps> = ({
  filters = DEFAULT_FILTERS,
  onFilterApply = () => {},
}) => {
  // hold state internally until onFilterApply
  const [state, setState] = useState<OrdersTableFilters>(_cloneDeep(filters));

  const { data: { products = [] } = {} } = useProductsQuery();
  const { data: { stores = [] } = {} } = useStoresQuery();

  const onChange = (
    values: { key: keyof OrdersTableQuery['filter']; value: any }[] = []
  ) => {
    const temp = _cloneDeep(state);

    values.forEach(({ key, value }) => {
      _set(temp, key, value);
    });

    setState(temp);
  };

  // filters changed but not yet applied
  const isDirty = !isEqual(filters, state);
  // current state is default settings
  const isDefault = isEqual(state, DEFAULT_FILTERS);

  return (
    <Pane
      marginY={12}
      display="flex"
      background="#f9f9f9"
      border="light-800"
      borderRadius={6}
    >
      <Pane
        paddingBottom={12}
        paddingX={12}
        width="100%"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
      >
        <Pane marginRight={12} marginTop={12}>
          <MultiSelect
            buttonTitle="Stores"
            menuTitle="Stores"
            options={stores.map((store) => ({
              label: store.name,
              value: store.code,
            }))}
            selected={state.filter.stores}
            onChange={(values) =>
              onChange([{ key: 'filter.stores', value: values }])
            }
          />
        </Pane>

        <Pane marginRight={12} marginTop={12}>
          <MultiSelect
            buttonTitle="Products"
            menuTitle="Products"
            options={_sortBy(products, ['name'])
              .filter((product) => {
                if (!!product.meta.isStaticPrint) {
                  return !!product.meta.isVariant;
                }

                return true;
              })
              .map((product) => ({
                label: product.name,
                value: product.sku,
              }))}
            selected={[...state.filter.products, ...state.filter.addons]}
            onChange={(allSelected, selectedSku) => {
              // TODO: clean
              const product = products.find(({ sku }) => sku === selectedSku);
              const selectedProducts = products.filter(({ sku }) =>
                allSelected.includes(sku)
              );

              const addons = selectedProducts
                .filter((p) => p.category === 'addons')
                .map((p) => p.sku);
              const nonAddOns = selectedProducts
                .filter((p) => p.category !== 'addons')
                .map((p) => p.sku);

              if (product?.category === 'addons') {
                onChange([{ key: 'filter.addons', value: addons }]);
              } else {
                onChange([{ key: 'filter.products', value: nonAddOns }]);
              }
            }}
          />
        </Pane>

        <Pane marginRight={12} marginTop={12}>
          <MultiSelect
            buttonTitle="Shipping"
            menuTitle="Shipping"
            options={shippingRates.map((rate) => ({
              label: rate.name,
              value: rate.key,
            }))}
            selected={state.filter.shippingRates}
            onChange={(values) =>
              onChange([{ key: 'filter.shippingRates', value: values }])
            }
          />
        </Pane>

        <Pane marginRight={12} marginTop={12}>
          <SelectMenu
            title="Sizes"
            hasFilter={true}
            isMultiSelect={false}
            options={[
              { label: 'All', value: '' },
              { label: '12 x 16 in', value: '12X16' },
              { label: '18 x 24 in', value: '18X24' },
              { label: '24 x 36 in', value: '24X36' },
            ]}
            selected={state.filter.productsBySize as any}
            onSelect={(item) => {
              onChange([{ key: 'filter.productsBySize', value: item.value }]);
            }}
            onDeselect={(item) => {
              onChange([{ key: 'filter.productsBySize', value: item.value }]);
            }}
          >
            <Button iconAfter="caret-down">
              {state.filter.productsBySize
                ? state.filter.productsBySize
                : 'All Sizes'}
            </Button>
          </SelectMenu>
        </Pane>

        <Pane marginRight={12} marginTop={12}>
          <MultiSelect
            buttonTitle="Framing"
            menuTitle="Framing"
            options={[
              { label: 'Framed', value: 'framed-prints' },
              { label: 'Unframed', value: 'prints' },
            ]}
            selected={state.filter.productsByCategory}
            onChange={(values) => {
              onChange([{ key: 'filter.productsByCategory', value: values }]);
            }}
          />
        </Pane>

        <Pane marginRight={12} marginTop={12}>
          <MultiSelect
            buttonTitle="Regions"
            menuTitle="Regions"
            options={[
              {
                label: 'International',
                value: 'international',
              },
              {
                label: 'United States',
                value: 'unitedStates',
              },
              {
                label: 'East Coast',
                value: 'eastCoast',
              },
              {
                label: 'West Coast',
                value: 'westCoast',
              },
            ]}
            selected={state.filter.regions}
            onChange={(values) =>
              onChange([{ key: 'filter.regions', value: values }])
            }
          />
        </Pane>

        <Pane marginRight={12} marginTop={12}>
          <SelectMenu
            title="Type"
            hasFilter={true}
            isMultiSelect={false}
            options={[
              { label: 'Ready', value: OrdersQuery.ApprovedOnly },
              { label: 'Not Ready', value: OrdersQuery.UnapprovedOnly },
            ]}
            selected={state.query as any}
            onSelect={(item) => onChange([{ key: 'query', value: item.value }])}
            onDeselect={(item) =>
              onChange([{ key: 'query', value: item.value }])
            }
          >
            <Button iconAfter="caret-down">
              {state.query === OrdersQuery.ApprovedOnly ? 'Ready' : 'Not Ready'}
            </Button>
          </SelectMenu>
        </Pane>

        {state.query === OrdersQuery.UnapprovedOnly && (
          <Pane marginRight={12} marginTop={12}>
            <SelectMenu
              title="Hours Ago"
              isMultiSelect={false}
              options={[...Array(25)]
                .map((_, i) => ({
                  label: i.toString(),
                  value: i.toString(),
                }))
                .reverse()}
              selected={state.filter.hoursAgo as any}
              onSelect={(item) =>
                onChange([{ key: 'filter.hoursAgo', value: item.value }])
              }
              onDeselect={(item) =>
                onChange([{ key: 'filter.hoursAgo', value: item.value }])
              }
            >
              <Button iconAfter="caret-down">
                {state.filter.hoursAgo} Hours
              </Button>
            </SelectMenu>
          </Pane>
        )}

        <Pane marginTop={12}>
          <Button
            marginRight={12}
            disabled={isDefault}
            onClick={() => {
              setState(DEFAULT_FILTERS);
            }}
          >
            Reset
          </Button>

          <Button
            appearance="primary"
            intent="success"
            disabled={!isDirty}
            onClick={() => {
              onFilterApply({
                filters: state,
                query: {
                  ..._omit(state),
                  filter: buildFilterQueryFromFilters(state.filter),
                },
              });
            }}
          >
            Apply
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default OrdersTableFilters;
