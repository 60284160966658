import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import * as Grid from 'react-awesome-styled-grid';
import {
  useCreateCartMutation,
  CartFragment as Cart,
  AddressFragment,
  TransactionFragment,
  TransactionType,
  useAddPaymentMutation,
  useAddAdjustmentToCartMutation,
  AdjustmentType,
} from 'lib/api/ecommerce/schema';
import {
  Button,
  Heading,
  Pane,
  toaster,
  TextInputField,
  SelectField,
} from 'evergreen-ui';
import { ItemsTable } from './ItemsTable';
import { CreateOrderPaymentForm } from './PaymentForm';
import { CreateOrderShippingAddressForm } from './ShippingAddressForm';
import { CreateOrderShippingMethodForm } from './ShippingMethodForm';

export type CreateOrderPageFormState = {
  payment: Partial<TransactionFragment>;
  shippingMethod: any;
} & Pick<
  Cart,
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'billingAddress'
  | 'shippingAddress'
  | 'store'
  | 'items'
>;

export const Form: React.FC = () => {
  const history = useHistory();
  const [addPayment, { loading: isAddingPayment }] = useAddPaymentMutation();
  const [createOrder, { loading: isCreatingOrder }] = useCreateCartMutation();
  const [
    addAdjustment,
    { loading: isAddingAdjustment },
  ] = useAddAdjustmentToCartMutation();

  const [form, setForm] = useState<CreateOrderPageFormState>(
    Object.assign(
      {},
      {
        email: '',
        firstName: '',
        lastName: null,
        phone: null,
        billingAddress: null,
        store: 'andalways',
        items: [],
        payment: {
          source: 'AMAZON',
          sourceId: '',
          type: TransactionType.CreditCard,
          amount: '' as any,
        },
        shippingMethod: {
          name: 'Standard',
          method: 'STANDARD',
          price: 0,
        },
        shippingAddress: {
          recipient: '',
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: 'US',
          postalCode: '',
        },
      }
    )
  );

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  const isLoading = isCreatingOrder || isAddingPayment || isAddingAdjustment;

  return (
    <Fade key="CreateOrderPage-Fade" left distance="30px" duration={500}>
      <>
        <Pane display="flex" marginBottom={40}>
          <Pane flex={1} alignItems="center" display="flex">
            <Button
              iconBefore="arrow-left"
              onClick={() => history.push('/orders')}
            >
              Back
            </Button>
          </Pane>
          <Pane alignItems="center" display="flex">
            <Button
              isLoading={isLoading}
              appearance="primary"
              intent="success"
              onClick={async () => {
                try {
                  const { payment, shippingMethod, ...rest } = form;

                  const { data } = await createOrder({
                    variables: { input: { ...(rest as any) } },
                  });

                  payment.amount = parseFloat(payment.amount as any) * 100;

                  await addAdjustment({
                    variables: {
                      input: {
                        id: data?.createCart?.id as string,
                        data: {
                          type: AdjustmentType.Shipping,
                          amount: shippingMethod.price,
                          meta: {
                            name: shippingMethod.name,
                            method: shippingMethod.method,
                          },
                        },
                      },
                    },
                  });

                  await addPayment({
                    variables: {
                      input: {
                        id: data?.createCart?.id,
                        ...(payment as any),
                      },
                    },
                  });

                  toaster.success(`Order created.`, { duration: 3 });

                  history.push('/orders');
                } catch (e: any) {
                  toaster.danger(e.message);
                }
              }}
            >
              Create Order
            </Button>
          </Pane>
        </Pane>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={8} offset={{ lg: 2 }}>
              <Pane
                padding={22}
                elevation={1}
                borderRadius="8px"
                backgroundColor="#fdfdfd"
              >
                <Pane display="flex" marginBottom={20}>
                  <Heading size={600} style={{ fontWeight: '400' }}>
                    Create Order
                  </Heading>
                </Pane>

                <Pane display="inline-block">
                  <SelectField
                    name="store"
                    label="Store"
                    width="100%"
                    height={52}
                    value={form.store as string}
                    onChange={handleChange}
                  >
                    <option value="andalways">AndAlways</option>
                    <option value="twinkle">TwinkleInTime</option>
                    <option value="overourmoon">OverOurMoon</option>
                  </SelectField>
                </Pane>

                <Pane display="flex">
                  <Pane width="100%">
                    <TextInputField
                      required
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      width="100%"
                      inputWidth="100%"
                      inputHeight={40}
                      value={form.firstName}
                      disabled={isLoading}
                      onChange={handleChange}
                    />
                  </Pane>

                  <Pane width="100%" marginLeft={10}>
                    <TextInputField
                      required
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      width="100%"
                      inputWidth="100%"
                      inputHeight={40}
                      value={form.lastName}
                      disabled={isLoading}
                      onChange={handleChange}
                    />
                  </Pane>
                </Pane>

                <Pane display="flex">
                  <TextInputField
                    required
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    width="100%"
                    inputWidth="100%"
                    inputHeight={40}
                    value={form.email}
                    disabled={isLoading}
                    onChange={handleChange}
                  />
                </Pane>

                <Pane display="flex">
                  <TextInputField
                    required
                    name="phone"
                    label="Phone Number"
                    placeholder="Phone Number"
                    width="100%"
                    inputWidth="100%"
                    inputHeight={40}
                    value={form.phone}
                    disabled={isLoading}
                    onChange={handleChange}
                  />
                </Pane>

                <CreateOrderShippingAddressForm
                  loading={isLoading}
                  address={form.shippingAddress as AddressFragment}
                  onChange={(value) =>
                    setForm({
                      ...form,
                      shippingAddress: { ...form.shippingAddress, ...value },
                    })
                  }
                />

                <CreateOrderShippingMethodForm
                  form={form}
                  onChange={(value) =>
                    setForm({ ...form, shippingMethod: value })
                  }
                />

                <CreateOrderPaymentForm
                  loading={isLoading}
                  payment={form.payment as any}
                  onChange={(value: any) =>
                    setForm({ ...form, payment: { ...form.payment, ...value } })
                  }
                />
              </Pane>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col lg={8} offset={{ lg: 2 }}>
              <ItemsTable
                order={form}
                setItems={(items) => setForm({ ...form, items })}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </>
    </Fade>
  );
};
