import React, { useState } from 'react';
import config from 'config';
import { get } from 'object-path';
import Helmet from 'react-helmet';
import _sortBy from 'lodash/sortBy';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { RoleAccess } from 'lib/auth';
import { Button, Heading, IconButton, Pane, SearchInput } from 'evergreen-ui';
import { Product, useProductsQuery } from 'lib/api/ecommerce/schema';
import { Loader } from 'components/Loader';
import { ProductsTable } from './ProductsTable';

export const ProductsPage: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const { data = {}, loading } = useProductsQuery({
    fetchPolicy: 'network-only',
  });

  const products = _sortBy(
    get<Product[]>(data, 'products', []).filter((product) =>
      search.length > 0
        ? `${product.name} ${product.sku}`
            .toLowerCase()
            .indexOf(search.toLowerCase()) > -1
        : true
    ),
    ['name']
  );
  const productsByPage = products.slice(page * 20, (page + 1) * 20);

  return (
    <>
      <Helmet>
        <title>Products | {config.siteName}</title>
      </Helmet>

      <Fade key="Products" left distance="30px" duration={500}>
        <Pane>
          <Pane display="flex">
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={700} fontWeight={300}>
                Products
              </Heading>
            </Pane>

            <SearchInput
              marginRight={12}
              placeholder="Search products"
              value={search}
              onChange={(e) => {
                setPage(0);
                setSearch(e.target?.value?.toLowerCase());
              }}
            />

            <RoleAccess permission="products:create">
              <Pane alignItems="center" display="flex">
                <Button onClick={() => history.push('/product')}>
                  Add Product
                </Button>
              </Pane>
            </RoleAccess>

            <IconButton
              icon="arrow-left"
              marginLeft={10}
              marginRight={10}
              disabled={page === 0}
              onClick={() => setPage(page - 1)}
            />

            <IconButton
              icon="arrow-right"
              disabled={(page + 1) * 20 > products.length}
              onClick={() => setPage(page + 1)}
            />
          </Pane>

          <RoleAccess permission="products:list">
            <Pane display="flex" flexDirection="column" flexGrow={0}>
              {loading && <Loader />}
              {!loading && <ProductsTable products={productsByPage} />}
            </Pane>
          </RoleAccess>
        </Pane>
      </Fade>
    </>
  );
};
