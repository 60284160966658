import { Order } from 'models';
import {
  orderContainsDarkPoster,
  orderContainsWhitePoster,
  orderIsPriorityShipping,
  orderIsStandardShipping,
  orderIsExpressShipping,
  orderHasMulticolorPosters,
  orderHasFrame,
  orderIsNotFramed,
  orderHasBlackFrame,
  orderHasWhiteFrame,
  orderHasHangerFrame,
  orderHasWhiteHangerFrame,
  orderHasBlackHangerFrame,
  orderIsInternational,
  orderIsEastCoast,
  orderIsRushOrder,
  orderIsTwinkleInTime,
  orderIsOverOurMoon,
  orderHasDigitalPrint,
  orderHas12X16Print,
  orderHas18X24Print,
  orderHas24X36Print,
  orderIsAndAlways,
} from 'models/Order/utils/filters';

export enum OrderFilterType {
  WHITE_POSTER = 'white-poster',
  DARK_POSTER = 'dark-poster',
  FRAMED = 'framed',
  UNFRAMED = 'unframed',
  BLACK_FRAME = 'black-frame',
  WHITE_FRAME = 'white-frame',
  HANGER_FRAME = 'hanger-frame',
  WHITE_HANGER_FRAME = 'white-hanger-frame',
  BLACK_HANGER_FRAME = 'black-hanger-frame',
  STANDARD_SHIPPING = 'standard-shipping',
  PRIORITY_SHIPPING = 'priority-shipping',
  EXPRESS_SHIPPING = 'express-shipping',
  MULTIPLE_ORDERS = 'multiple-orders',
  MULTICOLOR = 'multicolor',
  INTERNATIONAL = 'international',
  EAST_COAST = 'east-coast',
  RUSH_ORDER = 'rush-order',
  TWINKLE_IN_TIME = 'twinkle-in-time',
  OVER_OUR_MOON = 'over-our-moon',
  AND_ALWAYS = 'andalways',
  DIGITAL_PRINT = 'digital-print',
  PRINT_12X16 = '12x16-print',
  PRINT_18X24 = '18x24-print',
  PRINT_24X36 = '24x36-print',
}

export interface OrderFilter {
  label: string;
  type: OrderFilterType;
  filterFunction: (order: Order) => boolean;
  storeFilter: boolean;
}

export const ORDER_FILTERS: OrderFilter[] = [
  {
    type: OrderFilterType.WHITE_POSTER,
    label: 'White Poster(s)',
    filterFunction: orderContainsWhitePoster,
    storeFilter: false,
  },
  {
    type: OrderFilterType.DARK_POSTER,
    label: 'Dark Poster(s)',
    filterFunction: orderContainsDarkPoster,
    storeFilter: false,
  },
  {
    type: OrderFilterType.FRAMED,
    label: 'Framed',
    filterFunction: orderHasFrame,
    storeFilter: false,
  },
  {
    type: OrderFilterType.UNFRAMED,
    label: 'Unframed',
    filterFunction: orderIsNotFramed,
    storeFilter: false,
  },
  {
    type: OrderFilterType.BLACK_FRAME,
    label: 'Black Frame',
    filterFunction: orderHasBlackFrame,
    storeFilter: false,
  },
  {
    type: OrderFilterType.WHITE_FRAME,
    label: 'White Frame',
    filterFunction: orderHasWhiteFrame,
    storeFilter: false,
  },
  {
    type: OrderFilterType.DIGITAL_PRINT,
    label: 'Digital Print',
    filterFunction: orderHasDigitalPrint,
    storeFilter: false,
  },
  {
    type: OrderFilterType.HANGER_FRAME,
    label: 'Hanger Frame',
    filterFunction: orderHasHangerFrame,
    storeFilter: false,
  },
  {
    type: OrderFilterType.WHITE_HANGER_FRAME,
    label: 'White Hanger Frame',
    filterFunction: orderHasWhiteHangerFrame,
    storeFilter: false,
  },
  {
    type: OrderFilterType.BLACK_HANGER_FRAME,
    label: 'Black Hanger Frame',
    filterFunction: orderHasBlackHangerFrame,
    storeFilter: false,
  },
  {
    type: OrderFilterType.PRINT_12X16,
    label: '12X16 Print',
    filterFunction: orderHas12X16Print,
    storeFilter: false,
  },
  {
    type: OrderFilterType.PRINT_18X24,
    label: '18X24 Print',
    filterFunction: orderHas18X24Print,
    storeFilter: false,
  },
  {
    type: OrderFilterType.PRINT_24X36,
    label: '24X36 Print',
    filterFunction: orderHas24X36Print,
    storeFilter: false,
  },
  {
    type: OrderFilterType.RUSH_ORDER,
    label: 'Rush Order',
    filterFunction: orderIsRushOrder,
    storeFilter: false,
  },
  {
    type: OrderFilterType.STANDARD_SHIPPING,
    label: 'Standard Shipping',
    filterFunction: orderIsStandardShipping,
    storeFilter: false,
  },
  {
    type: OrderFilterType.PRIORITY_SHIPPING,
    label: 'Priority Shipping',
    filterFunction: orderIsPriorityShipping,
    storeFilter: false,
  },
  {
    type: OrderFilterType.EXPRESS_SHIPPING,
    label: 'Express Shipping',
    filterFunction: orderIsExpressShipping,
    storeFilter: false,
  },
  {
    type: OrderFilterType.MULTIPLE_ORDERS,
    label: 'Multiple Orders',
    filterFunction: () => true, // noop
    storeFilter: false,
  },
  {
    type: OrderFilterType.MULTICOLOR,
    label: 'Multicolor',
    filterFunction: orderHasMulticolorPosters,
    storeFilter: false,
  },
  {
    type: OrderFilterType.INTERNATIONAL,
    label: 'International',
    filterFunction: orderIsInternational,
    storeFilter: false,
  },
  {
    type: OrderFilterType.EAST_COAST,
    label: 'East Coast',
    filterFunction: orderIsEastCoast,
    storeFilter: false,
  },
  {
    type: OrderFilterType.TWINKLE_IN_TIME,
    label: 'Twinkle In Time',
    filterFunction: orderIsTwinkleInTime,
    storeFilter: true,
  },
  {
    type: OrderFilterType.OVER_OUR_MOON,
    label: 'Over Our Moon',
    filterFunction: orderIsOverOurMoon,
    storeFilter: true,
  },
  {
    type: OrderFilterType.AND_ALWAYS,
    label: 'AndAlways',
    filterFunction: orderIsAndAlways,
    storeFilter: true,
  },
];
