export interface ShippingRateOrderType {
  fastest: number;
  slowest: number;
  price: number;
  holidayOffset: number;
}

export type ShippingRateCountries = 'us' | 'au' | 'nz' | 'other';
export interface ShippingRate {
  name: string;
  method: string;
  key: string;
  rates: {
    [K in ShippingRateCountries]: {
      framed: ShippingRateOrderType | null;
      unframed: ShippingRateOrderType;
    } | null;
  };
}

/*
 *  Shipping Rates / Prices / Times (in days) by country
 */
export const shippingRates: ShippingRate[] = [
  {
    name: 'Standard',
    method: 'STANDARD',
    key: 'STANDARD',
    rates: {
      us: {
        framed: { fastest: 8, slowest: 12, holidayOffset: -3, price: 0 },
        unframed: { fastest: 7, slowest: 12, holidayOffset: -3, price: 0 },
      },
      au: {
        framed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
      },
      nz: {
        framed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
        unframed: { fastest: 7, slowest: 15, holidayOffset: 0, price: 0 },
      },
      other: {
        framed: { fastest: 10, slowest: 20, holidayOffset: 0, price: 0 },
        unframed: { fastest: 10, slowest: 20, holidayOffset: 0, price: 0 },
      },
    },
  },
  {
    name: 'Priority',
    method: 'PRIORITY',
    key: 'PRIORITY',
    rates: {
      us: {
        framed: { fastest: 3, slowest: 7, holidayOffset: -1, price: 3500 },
        unframed: { fastest: 3, slowest: 7, holidayOffset: -2, price: 1000 },
      },
      au: null,
      nz: null,
      other: null,
    },
  },
  {
    name: 'Express',
    method: 'EXPRESS',
    key: 'EXPRESS',
    rates: {
      us: {
        framed: { fastest: 2, slowest: 4, holidayOffset: 0, price: 6500 },
        unframed: { fastest: 2, slowest: 4, holidayOffset: 0, price: 2500 },
      },
      au: null,
      nz: null,
      other: null,
    },
  },
];

export const shippingHolidays: Date[] = [
  new Date(2021, 0, 1), // New Year's Day
  new Date(2021, 0, 20), // Martin Luther King Jr. Day
  new Date(2021, 1, 17), // President's Day
  new Date(2021, 4, 25), // Memorial Day
  new Date(2021, 6, 3), // Independence Day
  new Date(2021, 8, 6), // Labor Day
  new Date(2021, 9, 11), // Columbus Day
  new Date(2021, 10, 11), // Veterans Day
  new Date(2021, 10, 25), // Thanksgiving Day
  // new Date(2020, 11, 24), // Christmas Eve
  new Date(2020, 11, 25), // Christmas Day
  new Date(2021, 11, 31), // New Years Eve
];

export default shippingRates;
