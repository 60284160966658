import React, { useState, useEffect, forwardRef } from 'react';
import { Order, BaseOrder } from 'models';
import dateFormat from 'date-fns/format';
import { formatCurrency } from 'lib/utils';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { OrderReadinessBadge } from 'components/OrderReadinessBadge';
import { Loader } from 'components/Loader';
import { hasPrevPendingCancellationOrder } from 'models/Order/utils/hasPrevPendingCancellationOrder';
import { Button, Heading, Pane, Table, Text, Link, Icon } from 'evergreen-ui';
import { useOrders } from 'lib/api/ecommerce/hooks';
import {
  orderToWords,
  customerApprovedIcon,
  printErgency,
  hasHangerFrame,
  voucherCode,
  shippingUpgrade,
  isRushOrder,
  getShopIcon,
} from '../../../orders/components/Table/utils';

interface OtherOrderProps {
  ref: React.Ref<any>;
  order: Order;
  setShowAlert: (value: boolean) => void;
}

export const OtherOrders: React.FC<OtherOrderProps> = forwardRef(
  ({ order, setShowAlert }, ref) => {
    const [showAll, setShowAll] = useState<boolean>(false);
    const [dateSort, setDateSort] = useState<number>(-1);
    const [filteredOrders, setFilteredOrders] = useState<BaseOrder[]>([]);

    const { orders, loading } = useOrders({
      variables: {
        limit: 20,
        search: order?.email,
        sort: { paidAt: dateSort },
      },
    });

    useEffect(() => {
      setFilteredOrders(orders.filter((o) => o.isPaid && o.id !== order.id));
    }, [orders, order.id]);

    useEffect(() => {
      setShowAlert(hasPrevPendingCancellationOrder(order, orders));
    }, [setShowAlert, order, orders]);

    const sortArrow = () => (
      <Icon
        size={14}
        icon={dateSort === 1 ? 'caret-up' : 'caret-down'}
        color="muted"
        marginTop={1}
        marginLeft={5}
      />
    );

    if (!orders) return null;

    return (
      <div ref={ref}>
        <Pane marginTop={40}>
          <Pane display="flex" paddingBottom={10}>
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={600} fontWeight={300}>
                Other Orders ({filteredOrders.length} total)
              </Heading>
            </Pane>
          </Pane>

          {loading && <Loader />}

          <Pane
            display="flex"
            flexDirection="column"
            elevation={1}
            marginTop={5}
            borderRadius={6}
            backgroundColor="#fdfdfd"
          >
            {filteredOrders?.length < 1 && !loading && (
              <Pane
                flex={1}
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <Heading
                  size={100}
                  marginTop={10}
                  marginBottom={10}
                  textAlign="center"
                >
                  No other orders by this customer found.
                </Heading>
              </Pane>
            )}

            {filteredOrders?.length > 0 && !loading && (
              <Table flex={1} borderRadius={6}>
                <Table.Head>
                  <Table.HeaderCell flexBasis={220} flexGrow={0}>
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell>Order</Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ cursor: 'pointer' }}
                    onClick={() => setDateSort(dateSort === -1 ? 1 : -1)}
                    flexBasis={130}
                    flexGrow={0}
                  >
                    Purchase Date {sortArrow()}
                  </Table.HeaderCell>
                  <Table.HeaderCell flexBasis={160} flexGrow={0}>
                    Amount
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    Status
                  </Table.HeaderCell>
                </Table.Head>

                <Table.Body>
                  {filteredOrders?.map((o, i) => {
                    if (!showAll && i > 1) return null;

                    return (
                      <Table.Row key={o.id}>
                        <Table.Cell flexBasis={220} flexGrow={0}>
                          <Text>{getShopIcon(o)}</Text>
                          <Link target="_blank" href={`/orders/${o.id}`}>
                            <Text size={300}>{o.id}</Text>
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          <Text size={300} fontWeight={500}>
                            {orderToWords(o)}
                          </Text>
                          <Text>
                            {printErgency(o)}
                            {hasHangerFrame(o)}
                          </Text>
                        </Table.Cell>
                        <Table.Cell flexBasis={130} flexGrow={0}>
                          {dateFormat(new Date(o.paidAt), 'MM/dd/yyyy')}
                        </Table.Cell>
                        <Table.Cell flexBasis={160} flexGrow={0}>
                          <Text>{formatCurrency(o.total)}</Text>
                          <Text>
                            {voucherCode(o)}
                            {o.shippingMethod && shippingUpgrade(o)}
                            {customerApprovedIcon(o)}
                            {isRushOrder(o)}
                          </Text>
                        </Table.Cell>
                        <Table.Cell
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Text>
                            <OrderStatusBadge status={o.status} />
                            <OrderReadinessBadge order={o} />
                          </Text>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}

            {filteredOrders?.length > 2 && !loading && (
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="center"
                background="#f9f9f9"
                paddingY={8}
              >
                <Button
                  intent="none"
                  appearance="minimal"
                  height={24}
                  marginRight={5}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? 'Hide' : 'Show More'}
                </Button>
              </Pane>
            )}
          </Pane>
        </Pane>
      </div>
    );
  }
);
