import React, { useEffect, useState } from 'react';
import loadScript from 'react-async-script-loader';

export interface PrintPreviewProps {
  width: number;
  height: number;
  print: any;
  onComplete?: () => void;
}

export const PrintPreview: React.FC<PrintPreviewProps> = loadScript([
  process.env.REACT_APP_PRINT_CLIENT_JS,
])((props) => {
  const [id] = useState(
    `print-${(Math.random() + 1).toString(36).substring(7)}`
  );

  const { width = 300, height = 400, print } = props;
  const isReady = props.isScriptLoaded && props.isScriptLoadSucceed;

  useEffect(() => {
    const loadClient = async () => {
      const container = document.querySelector(`#${id}`);
      const client = await (window as any).PrintClient?.load(
        process.env?.REACT_APP_PRINTS_SERVER + '/packages/zodiac/manifest.json',
        container
      );

      if (client) {
        client.render(print);
      }
    };

    if (isReady) {
      try {
        loadClient();
      } catch (e) {
        console.error(e);
      }
    }
  }, [isReady, id, print]);

  if (!print || !isReady) return null;

  return <div id={id} style={{ width, height }} />;
});

export default PrintPreview;
