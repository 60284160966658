import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Address = {
  __typename?: 'Address';
  recipient: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  /** ISO 3166-1 Alpha 2 Country Code (https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements) */
  country: Scalars['String'];
  countryAsAlpha3: Scalars['String'];
};

export type Adjustment = {
  __typename?: 'Adjustment';
  id: Scalars['String'];
  type: AdjustmentType;
  amount: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export enum AdjustmentType {
  Discount = 'DISCOUNT',
  Shipping = 'SHIPPING',
  Tax = 'TAX',
  Addon = 'ADDON',
}

export type Destination = {
  __typename?: 'Destination';
  url: Scalars['String'];
  method: DestinationMethod;
  headers?: Maybe<Scalars['JSONObject']>;
};

export enum DestinationMethod {
  Post = 'POST',
  Get = 'GET',
}

export type Hook = {
  __typename?: 'Hook';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event: HookEvent;
  destinations: Array<Destination>;
};

export enum HookEvent {
  CartCreated = 'CART_CREATED',
  CartUpdated = 'CART_UPDATED',
  CartPayment = 'CART_PAYMENT',
  ItemsAdded = 'ITEMS_ADDED',
  ItemsRemoved = 'ITEMS_REMOVED',
  ItemUpdated = 'ITEM_UPDATED',
}

export type PrinterJobFile = {
  __typename?: 'PrinterJobFile';
  id: Scalars['String'];
  url: Scalars['String'];
  fileName: Scalars['String'];
  directory: Scalars['String'];
  meta: Scalars['JSONObject'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  sku: Scalars['String'];
  type: ProductType;
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  requiresApproval?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSONObject']>;
  children?: Maybe<Array<Product>>;
};

export enum ProductType {
  Physical = 'PHYSICAL',
  Digital = 'DIGITAL',
  Other = 'OTHER',
}

export type VoucherApplicableTo = {
  __typename?: 'VoucherApplicableTo';
  method: VoucherApplicableToMethod;
  match: Scalars['JSON'];
};

export enum VoucherApplicableToMethod {
  All = 'ALL',
  First = 'FIRST',
}

export type VoucherRule = {
  __typename?: 'VoucherRule';
  discount?: Maybe<Scalars['Float']>;
  match: Scalars['JSON'];
};

export type Voucher = {
  __typename?: 'Voucher';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  applicableTo?: Maybe<VoucherApplicableTo>;
  rules: Array<VoucherRule>;
  code: Scalars['String'];
  type: VoucherDiscountType;
  discount: Scalars['Float'];
  startsAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  useCount: Scalars['Float'];
  maxUses?: Maybe<Scalars['Float']>;
  isUsable: Scalars['Boolean'];
};

export enum VoucherDiscountType {
  Amount = 'AMOUNT',
  Percent = 'PERCENT',
}

export type Fulfillment = {
  __typename?: 'Fulfillment';
  id: Scalars['String'];
  items: Array<Scalars['String']>;
  source: Scalars['String'];
  status: FulfillmentStatus;
  meta?: Maybe<Scalars['JSONObject']>;
  notes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  internalMeta: Scalars['JSONObject'];
};

export enum FulfillmentStatus {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Cancelled = 'CANCELLED',
}

export type Event = {
  __typename?: 'Event';
  type: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  source: Scalars['String'];
  sourceId?: Maybe<Scalars['String']>;
  sourceStatus?: Maybe<Scalars['String']>;
  type: TransactionType;
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
};

export enum TransactionType {
  AndroidPay = 'ANDROID_PAY',
  ApplePay = 'APPLE_PAY',
  CreditCard = 'CREDIT_CARD',
  Masterpass = 'MASTERPASS',
  Paypal = 'PAYPAL',
  SamsungPay = 'SAMSUNG_PAY',
  UsBankAccount = 'US_BANK_ACCOUNT',
  VenmoAccount = 'VENMO_ACCOUNT',
  VisaCheckout = 'VISA_CHECKOUT',
  Unknown = 'UNKNOWN',
}

export type Item = {
  __typename?: 'Item';
  id: Scalars['String'];
  name: Scalars['String'];
  sku: Scalars['String'];
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  total: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  metaUpdatedAt?: Maybe<Scalars['DateTime']>;
  addedAt: Scalars['DateTime'];
  isPaid: Scalars['Boolean'];
  isRefunded: Scalars['Boolean'];
  payment?: Maybe<Transaction>;
  refund?: Maybe<Transaction>;
  product?: Maybe<Product>;
};

export type Shipment = {
  __typename?: 'Shipment';
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  status: ShipmentStatus;
  source: Scalars['String'];
  sourceId: Scalars['String'];
  carrier: Scalars['String'];
  trackingNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ShipmentStatus {
  Pending = 'PENDING',
  InfoReceived = 'INFO_RECEIVED',
  InTransit = 'IN_TRANSIT',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  AttemptFail = 'ATTEMPT_FAIL',
  Delivered = 'DELIVERED',
  Exception = 'EXCEPTION',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
}

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  store?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  status: CartStatus;
  items: Array<Item>;
  paid: Scalars['Float'];
  events: Array<Event>;
  payments: Array<Transaction>;
  refunds: Array<Transaction>;
  fulfillments: Array<Fulfillment>;
  shipments: Array<Shipment>;
  notes?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<ApprovedBy>;
  /** Total of items including adjustments */
  total: Scalars['Float'];
  adjustmentsTotal: Scalars['Float'];
  itemsTotal: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  isPaid: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  adjustments: Array<Adjustment>;
  history: Array<CartHistoryItem>;
  customer?: Maybe<Customer>;
  voucher?: Maybe<Adjustment>;
};

export type CartAdjustmentsArgs = {
  type?: Maybe<AdjustmentType>;
};

export enum CartStatus {
  Draft = 'DRAFT',
  Hold = 'HOLD',
  CustomerHold = 'CUSTOMER_HOLD',
  Ready = 'READY',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  Fulfilled = 'FULFILLED',
  Shipping = 'SHIPPING',
  PendingCancel = 'PENDING_CANCEL',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
}

export enum ApprovedBy {
  System = 'SYSTEM',
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
}

export type CartHistoryItem = {
  __typename?: 'CartHistoryItem';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  store?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  status: CartStatus;
  items: Array<Item>;
  paid: Scalars['Float'];
  events: Array<Event>;
  payments: Array<Transaction>;
  refunds: Array<Transaction>;
  fulfillments: Array<Fulfillment>;
  shipments: Array<Shipment>;
  notes?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<ApprovedBy>;
  /** Total of items including adjustments */
  total: Scalars['Float'];
  adjustmentsTotal: Scalars['Float'];
  itemsTotal: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  isPaid: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  adjustments: Array<Adjustment>;
  history: Array<CartHistoryItem>;
  customer?: Maybe<Customer>;
  voucher?: Maybe<Adjustment>;
};

export type CartHistoryItemAdjustmentsArgs = {
  type?: Maybe<AdjustmentType>;
};

export type Collection = {
  __typename?: 'Collection';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  products?: Maybe<Array<Product>>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type TimelineSnapshot = {
  __typename?: 'TimelineSnapshot';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  store?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  status: CartStatus;
  items: Array<Item>;
  paid: Scalars['Float'];
  events: Array<Event>;
  payments: Array<Transaction>;
  refunds: Array<Transaction>;
  fulfillments: Array<Fulfillment>;
  shipments: Array<Shipment>;
  notes?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<ApprovedBy>;
  /** Total of items including adjustments */
  total: Scalars['Float'];
  adjustmentsTotal: Scalars['Float'];
  itemsTotal: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  isPaid: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  adjustments: Array<Adjustment>;
  history: Array<CartHistoryItem>;
  customer?: Maybe<Customer>;
  voucher?: Maybe<Adjustment>;
};

export type TimelineSnapshotAdjustmentsArgs = {
  type?: Maybe<AdjustmentType>;
};

export type Timeline = {
  __typename?: 'Timeline';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  text: Scalars['String'];
  snapshot: TimelineSnapshot;
  meta?: Maybe<Scalars['JSONObject']>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  email: Scalars['String'];
  roles: Array<Scalars['String']>;
  isDisabled: Scalars['Boolean'];
};

export type Store = {
  __typename?: 'Store';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  cartURL: Scalars['String'];
  orderURL: Scalars['String'];
  orderEmail?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  meta: Scalars['JSONObject'];
};

export type UserAndTokenType = {
  __typename?: 'UserAndTokenType';
  user: User;
  token: Scalars['String'];
};

export type OrdersQueryResultsPagination = {
  __typename?: 'OrdersQueryResultsPagination';
  next?: Maybe<Scalars['String']>;
};

export type OrdersQueryResults = {
  __typename?: 'OrdersQueryResults';
  pagination: OrdersQueryResultsPagination;
  results: Array<Cart>;
};

export type CreateBraintreeClientTokenResponseType = {
  __typename?: 'CreateBraintreeClientTokenResponseType';
  clientToken: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  me: User;
  cart?: Maybe<Cart>;
  cartFromFulfillment?: Maybe<Cart>;
  timelines: Array<Timeline>;
  carts: Array<Cart>;
  orders: OrdersQueryResults;
  hooks: Array<Hook>;
  braintreeCustomer?: Maybe<Scalars['JSON']>;
  ping: Scalars['JSONObject'];
  httpException: Scalars['JSONObject'];
  errorException: Scalars['JSONObject'];
  authError: Scalars['JSONObject'];
  internalError: Scalars['JSONObject'];
  inputValidation: Scalars['JSONObject'];
  collections: Array<Collection>;
  collection: Collection;
  product?: Maybe<Product>;
  products: Array<Product>;
  stores: Array<Store>;
  vouchers: Array<Voucher>;
  voucher: Voucher;
  taxRates?: Maybe<Scalars['JSON']>;
};

export type QueryCartArgs = {
  id: Scalars['String'];
};

export type QueryCartFromFulfillmentArgs = {
  id: Scalars['String'];
};

export type QueryTimelinesArgs = {
  cart: Scalars['String'];
};

export type QueryCartsArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<CartStatus>>;
  limit?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type QueryOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  paidAt?: Maybe<DateRangeInput>;
  status?: Maybe<Array<CartStatus>>;
  filter?: Maybe<Scalars['JSONObject']>;
  next?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  reverse?: Maybe<Scalars['Boolean']>;
};

export type QueryBraintreeCustomerArgs = {
  input: FindBraintreeCustomerInput;
};

export type QueryInputValidationArgs = {
  input: SomeInput;
};

export type QueryCollectionArgs = {
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type QueryProductArgs = {
  input: ProductQueryInput;
};

export type QueryVoucherArgs = {
  code: Scalars['String'];
};

export type QueryTaxRatesArgs = {
  force?: Maybe<Scalars['Boolean']>;
};

export enum OrdersQuery {
  All = 'ALL',
  ApprovedOnly = 'APPROVED_ONLY',
  UnapprovedOnly = 'UNAPPROVED_ONLY',
}

export type DateRangeInput = {
  greaterThanOrEqualTo?: Maybe<Scalars['DateTime']>;
  lessThanOrEqualTo?: Maybe<Scalars['DateTime']>;
};

export type FindBraintreeCustomerInput = {
  store: Scalars['String'];
  email: Scalars['String'];
};

export type SomeInput = {
  foo: Scalars['JSONObject'];
  bar?: Maybe<Scalars['JSONObject']>;
};

export type ProductQueryInput = {
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  seedUser: User;
  registerUser: User;
  updateUser: User;
  auth: UserAndTokenType;
  createCart?: Maybe<Cart>;
  updateCart?: Maybe<Cart>;
  updateCartStatus: Cart;
  approveCart: Cart;
  addItemsToCart?: Maybe<Cart>;
  updateCartItem?: Maybe<Cart>;
  removeItemsFromCart?: Maybe<Cart>;
  addAdjustmentToCart?: Maybe<Cart>;
  updateAdjustmentForCart?: Maybe<Cart>;
  removeAdjustmentFromCart?: Maybe<Cart>;
  sendMagicLinkForCart: Scalars['Boolean'];
  exchangeMagicLinkTokenForCart: Scalars['String'];
  updateTimeline?: Maybe<Timeline>;
  createCustomer?: Maybe<Customer>;
  addWebhook: Hook;
  updateWebhook: Hook;
  removeWebhook: Hook;
  testWebhook: Scalars['Boolean'];
  addFulfillmentToCart: Cart;
  updateFulfillmentForCart: Cart;
  temp__fulfillPrintful: Cart;
  temp__fulfillShipStation: Cart;
  createBraintreeClientToken?: Maybe<CreateBraintreeClientTokenResponseType>;
  chargeBraintree?: Maybe<Cart>;
  testPayment?: Maybe<Cart>;
  addPayment?: Maybe<Cart>;
  createCollection: Collection;
  updateCollection: Collection;
  createProduct: Product;
  updateProduct: Product;
  addShipmentToCart: Cart;
  updateShipmentForCart: Cart;
  createStore?: Maybe<Store>;
  updateStore?: Maybe<Store>;
  createVoucher: Voucher;
  updateVoucher: Voucher;
  applyVoucher?: Maybe<Cart>;
  removeVoucher?: Maybe<Cart>;
  validateAddress: Address;
};

export type MutationSeedUserArgs = {
  input: RegisterInput;
};

export type MutationRegisterUserArgs = {
  input: RegisterInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationAuthArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};

export type MutationCreateCartArgs = {
  input?: Maybe<CreateCartInput>;
};

export type MutationUpdateCartArgs = {
  input: UpdateCartInput;
};

export type MutationUpdateCartStatusArgs = {
  input: UpdateCartStatusInput;
};

export type MutationApproveCartArgs = {
  input: ApproveCartInput;
};

export type MutationAddItemsToCartArgs = {
  input: AddItemsToCartInput;
};

export type MutationUpdateCartItemArgs = {
  input: UpdateCartItemInput;
};

export type MutationRemoveItemsFromCartArgs = {
  input: RemoveItemsFromCartInput;
};

export type MutationAddAdjustmentToCartArgs = {
  input: AddAdjustmentToCartInput;
};

export type MutationUpdateAdjustmentForCartArgs = {
  input: UpdateAdjustmentForCartInput;
};

export type MutationRemoveAdjustmentFromCartArgs = {
  input: RemoveAdjustmentFromCartInput;
};

export type MutationSendMagicLinkForCartArgs = {
  input: SendMagicLinkForCartInput;
};

export type MutationExchangeMagicLinkTokenForCartArgs = {
  input: ExchangeMagicLinkTokenForCartInput;
};

export type MutationUpdateTimelineArgs = {
  input: UpdateTimelineInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationAddWebhookArgs = {
  input: AddWebhookInput;
};

export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

export type MutationRemoveWebhookArgs = {
  input: RemoveWebhookInput;
};

export type MutationTestWebhookArgs = {
  input: DestinationInput;
};

export type MutationAddFulfillmentToCartArgs = {
  input: AddFulfillmentToCartInput;
};

export type MutationUpdateFulfillmentForCartArgs = {
  input: UpdateFulfillmentForCartInput;
};

export type MutationTemp__FulfillPrintfulArgs = {
  input: PrintfulFulfillmentInput;
};

export type MutationTemp__FulfillShipStationArgs = {
  input: CreateShipStationOrderInput;
};

export type MutationCreateBraintreeClientTokenArgs = {
  input: CreateBraintreeClientTokenInput;
};

export type MutationChargeBraintreeArgs = {
  input: ChargeBraintreeInput;
};

export type MutationTestPaymentArgs = {
  id: Scalars['String'];
};

export type MutationAddPaymentArgs = {
  input: AddPaymentInput;
};

export type MutationCreateCollectionArgs = {
  input: CollectionInput;
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationAddShipmentToCartArgs = {
  input: AddShipmentToCartInput;
};

export type MutationUpdateShipmentForCartArgs = {
  input: UpdateShipmentForCartInput;
};

export type MutationCreateStoreArgs = {
  input?: Maybe<CreateStoreInput>;
};

export type MutationUpdateStoreArgs = {
  input?: Maybe<UpdateStoreInput>;
};

export type MutationCreateVoucherArgs = {
  input: CreateVoucherInput;
};

export type MutationUpdateVoucherArgs = {
  input: UpdateVoucherInput;
};

export type MutationApplyVoucherArgs = {
  input: ApplyOrRemoveVoucherInput;
};

export type MutationRemoveVoucherArgs = {
  input: ApplyOrRemoveVoucherInput;
};

export type MutationValidateAddressArgs = {
  input: AddressInput;
};

export type RegisterInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  roles: Array<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  id: Scalars['String'];
  data: UpdateUserData;
};

export type UpdateUserData = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type CreateCartInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  shippingAddress?: Maybe<AddressInput>;
  items?: Maybe<Array<CartItemInput>>;
  meta?: Maybe<Scalars['JSONObject']>;
  store: Scalars['String'];
};

export type AddressInput = {
  recipient: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  /** ISO 3166-1 Alpha 2 Country Code (https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements) */
  country: Scalars['String'];
};

export type CartItemInput = {
  quantity: Scalars['Float'];
  sku: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateCartInput = {
  id: Scalars['String'];
  data: UpdateCartPatchInput;
};

export type UpdateCartPatchInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  shippingAddress?: Maybe<AddressInput>;
  items?: Maybe<Array<CartItemInput>>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateCartStatusInput = {
  id: Scalars['String'];
  status: CartStatus;
  timeline?: Maybe<TimelineBasicInput>;
};

export type TimelineBasicInput = {
  text?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type ApproveCartInput = {
  id: Scalars['String'];
};

export type AddItemsToCartInput = {
  id: Scalars['String'];
  items: Array<CartItemInput>;
};

export type UpdateCartItemInput = {
  id: Scalars['String'];
  item: Scalars['String'];
  data: UpdateCartItemDataInput;
};

export type UpdateCartItemDataInput = {
  quantity?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type RemoveItemsFromCartInput = {
  id: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AddAdjustmentToCartInput = {
  id: Scalars['String'];
  data: AdjustmentInput;
};

export type AdjustmentInput = {
  type: AdjustmentType;
  amount?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type UpdateAdjustmentForCartInput = {
  id: Scalars['String'];
  adjustment: Scalars['String'];
  data: AdjustmentInput;
};

export type RemoveAdjustmentFromCartInput = {
  id: Scalars['String'];
  adjustment: Scalars['String'];
};

export type SendMagicLinkForCartInput = {
  id: Scalars['String'];
};

export type ExchangeMagicLinkTokenForCartInput = {
  id: Scalars['String'];
  token: Scalars['String'];
};

export type UpdateTimelineInput = {
  cart: Scalars['String'];
  timeline?: Maybe<Scalars['String']>;
  data: TimelineInput;
};

export type TimelineInput = {
  type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CreateCustomerInput = {
  email: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type AddWebhookInput = {
  event: HookEvent;
  data: DestinationInput;
};

export type DestinationInput = {
  url: Scalars['String'];
  method: DestinationMethod;
  headers?: Maybe<Scalars['JSONObject']>;
};

export type UpdateWebhookInput = {
  event: HookEvent;
  url: Scalars['String'];
  data: UpdateDestinationInput;
};

export type UpdateDestinationInput = {
  url: Scalars['String'];
  method: DestinationMethod;
  headers?: Maybe<Scalars['JSONObject']>;
};

export type RemoveWebhookInput = {
  event: HookEvent;
  url: Scalars['String'];
};

export type AddFulfillmentToCartInput = {
  id: Scalars['String'];
  data: FulfillmentInput;
};

export type FulfillmentInput = {
  source: Scalars['String'];
  status: FulfillmentStatus;
  meta?: Maybe<Scalars['JSONObject']>;
  items: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  internalMeta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateFulfillmentForCartInput = {
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  data: UpdateFulfillmentInput;
};

export type UpdateFulfillmentInput = {
  source?: Maybe<Scalars['String']>;
  status?: Maybe<FulfillmentStatus>;
  meta?: Maybe<Scalars['JSONObject']>;
  items?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  internalMeta?: Maybe<Scalars['JSONObject']>;
};

export type PrintfulFulfillmentInput = {
  id: Scalars['String'];
  items: Array<PrintfulFulfillmentItemInput>;
};

export type PrintfulFulfillmentItemInput = {
  id: Scalars['String'];
  image: Scalars['String'];
};

export type CreateShipStationOrderInput = {
  id: Scalars['String'];
  items: Array<CreateShipStationOrderItemInput>;
  tagIds: Array<Scalars['Float']>;
  advancedOptions?: Maybe<Scalars['JSONObject']>;
};

export type CreateShipStationOrderItemInput = {
  id: Scalars['String'];
  meta: CreateShipStationOrderItemMetaInput;
};

export type CreateShipStationOrderItemMetaInput = {
  fulfillmentSku: Scalars['String'];
  options: Array<CreateShipStationOrderItemOptionInput>;
};

export type CreateShipStationOrderItemOptionInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateBraintreeClientTokenInput = {
  id: Scalars['String'];
  merchantAccountId?: Maybe<Scalars['String']>;
};

export type ChargeBraintreeInput = {
  id: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type AddPaymentInput = {
  /** Cart ID */
  id: Scalars['String'];
  source: Scalars['String'];
  sourceId?: Maybe<Scalars['String']>;
  type?: Maybe<TransactionType>;
  amount?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CollectionInput = {
  slug: Scalars['String'];
  name: Scalars['String'];
  productIds: Array<Scalars['String']>;
};

export type UpdateCollectionInput = {
  id: Scalars['String'];
  data: CollectionInput;
};

export type CreateProductInput = {
  name: Scalars['String'];
  sku: Scalars['String'];
  type: ProductType;
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Scalars['String']>>;
  requiresApproval: Scalars['Boolean'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateProductInput = {
  id: Scalars['String'];
  data: UpdateProductDataInput;
};

export type UpdateProductDataInput = {
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  category?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Scalars['String']>>;
  requiresApproval?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type AddShipmentToCartInput = {
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  data: ShipmentInput;
};

export type ShipmentInput = {
  source: Scalars['String'];
  sourceId: Scalars['String'];
  status: ShipmentStatus;
  carrier: Scalars['String'];
  trackingNumber: Scalars['String'];
  notes: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateShipmentForCartInput = {
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  data: UpdateShipmentInput;
};

export type UpdateShipmentInput = {
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<ShipmentStatus>;
  carrier?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CreateStoreInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  cartURL: Scalars['String'];
  orderURL: Scalars['String'];
  orderEmail?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  meta: Scalars['JSONObject'];
};

export type UpdateStoreInput = {
  id: Scalars['String'];
  data: UpdateStoreDataInput;
};

export type UpdateStoreDataInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  cartURL?: Maybe<Scalars['String']>;
  orderURL?: Maybe<Scalars['String']>;
  orderEmail?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CreateVoucherInput = {
  code: Scalars['String'];
  type: VoucherDiscountType;
  discount: Scalars['Float'];
  applicableTo?: Maybe<VoucherApplicableToInput>;
  rules: Array<VoucherRuleInput>;
  startsAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  maxUses?: Maybe<Scalars['Float']>;
};

export type VoucherApplicableToInput = {
  method: VoucherApplicableToMethod;
  match: Scalars['JSONObject'];
};

export type VoucherRuleInput = {
  discount?: Maybe<Scalars['Float']>;
  match: Scalars['JSONObject'];
};

export type UpdateVoucherInput = {
  id: Scalars['String'];
  data: CreateVoucherInput;
};

export type ApplyOrRemoveVoucherInput = {
  id: Scalars['String'];
  code: Scalars['String'];
};

export type AddressFragment = { __typename?: 'Address' } & Pick<
  Address,
  'recipient' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'
>;

export type AdjustmentFragment = { __typename?: 'Adjustment' } & Pick<
  Adjustment,
  'id' | 'type' | 'amount' | 'meta'
>;

export type CartFragment = { __typename?: 'Cart' } & Pick<
  Cart,
  | 'id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'phone'
  | 'notes'
  | 'status'
  | 'meta'
  | 'paid'
  | 'paidAt'
  | 'store'
  | 'total'
  | 'adjustmentsTotal'
  | 'itemsTotal'
  | 'isEditable'
  | 'isPaid'
  | 'isApproved'
  | 'approvedBy'
  | 'approvedAt'
  | 'createdAt'
  | 'updatedAt'
> & {
    payments: Array<{ __typename?: 'Transaction' } & TransactionFragment>;
    refunds: Array<{ __typename?: 'Transaction' } & TransactionFragment>;
    shipments: Array<{ __typename?: 'Shipment' } & ShipmentFragment>;
    fulfillments: Array<{ __typename?: 'Fulfillment' } & FulfillmentFragment>;
    voucher?: Maybe<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    adjustments: Array<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    items: Array<{ __typename?: 'Item' } & ItemFragment>;
    billingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
    shippingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
  };

export type CartBasicFragment = { __typename?: 'Cart' } & Pick<
  Cart,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'approvedAt'
  | 'approvedBy'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'phone'
  | 'status'
  | 'paid'
  | 'paidAt'
  | 'total'
  | 'meta'
  | 'adjustmentsTotal'
  | 'itemsTotal'
  | 'isEditable'
  | 'isApproved'
  | 'store'
  | 'isPaid'
> & {
    payments: Array<{ __typename?: 'Transaction' } & TransactionFragment>;
    voucher?: Maybe<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    adjustments: Array<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    items: Array<{ __typename?: 'Item' } & ItemFragment>;
    shippingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
  };

export type FulfillmentFragment = { __typename?: 'Fulfillment' } & Pick<
  Fulfillment,
  | 'id'
  | 'items'
  | 'source'
  | 'status'
  | 'meta'
  | 'notes'
  | 'createdAt'
  | 'updatedAt'
>;

export type ItemFragment = { __typename?: 'Item' } & Pick<
  Item,
  | 'id'
  | 'sku'
  | 'category'
  | 'unitPrice'
  | 'quantity'
  | 'total'
  | 'name'
  | 'meta'
  | 'metaUpdatedAt'
  | 'addedAt'
  | 'isPaid'
  | 'isRefunded'
> & {
    product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
    payment?: Maybe<{ __typename?: 'Transaction' } & TransactionFragment>;
    refund?: Maybe<{ __typename?: 'Transaction' } & TransactionFragment>;
  };

export type ProductFragment = { __typename?: 'Product' } & Pick<
  Product,
  | 'id'
  | 'name'
  | 'sku'
  | 'type'
  | 'unitPrice'
  | 'category'
  | 'commodityCode'
  | 'upc'
  | 'meta'
  | 'requiresApproval'
  | 'createdAt'
  | 'updatedAt'
>;

export type ShipmentFragment = { __typename?: 'Shipment' } & Pick<
  Shipment,
  | 'id'
  | 'fulfillment'
  | 'source'
  | 'sourceId'
  | 'status'
  | 'carrier'
  | 'trackingNumber'
  | 'notes'
  | 'meta'
  | 'createdAt'
  | 'updatedAt'
>;

export type StoreFragment = { __typename?: 'Store' } & Pick<
  Store,
  | 'code'
  | 'name'
  | 'url'
  | 'cartURL'
  | 'orderURL'
  | 'orderEmail'
  | 'supportEmail'
  | 'meta'
>;

export type TimelineFragment = { __typename?: 'Timeline' } & Pick<
  Timeline,
  'id' | 'meta' | 'text' | 'type' | 'createdAt' | 'updatedAt'
> & {
    user?: Maybe<{ __typename?: 'User' } & UserFragment>;
    snapshot: { __typename?: 'TimelineSnapshot' } & Pick<
      TimelineSnapshot,
      | 'id'
      | 'createdAt'
      | 'updatedAt'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'fullName'
      | 'phone'
      | 'notes'
      | 'status'
      | 'meta'
      | 'paid'
      | 'paidAt'
      | 'total'
      | 'adjustmentsTotal'
      | 'itemsTotal'
      | 'isEditable'
      | 'isPaid'
      | 'store'
    > & {
        payments: Array<
          { __typename?: 'Transaction' } & Pick<
            Transaction,
            | 'id'
            | 'source'
            | 'sourceId'
            | 'sourceStatus'
            | 'type'
            | 'amount'
            | 'createdAt'
          >
        >;
        refunds: Array<
          { __typename?: 'Transaction' } & Pick<
            Transaction,
            | 'id'
            | 'source'
            | 'sourceId'
            | 'sourceStatus'
            | 'type'
            | 'amount'
            | 'createdAt'
          >
        >;
        fulfillments: Array<
          { __typename?: 'Fulfillment' } & Pick<
            Fulfillment,
            | 'id'
            | 'items'
            | 'source'
            | 'status'
            | 'meta'
            | 'notes'
            | 'createdAt'
            | 'updatedAt'
          >
        >;
        voucher?: Maybe<
          { __typename?: 'Adjustment' } & Pick<
            Adjustment,
            'id' | 'type' | 'amount' | 'meta'
          >
        >;
        adjustments: Array<
          { __typename?: 'Adjustment' } & Pick<
            Adjustment,
            'id' | 'type' | 'amount' | 'meta'
          >
        >;
        items: Array<
          { __typename?: 'Item' } & Pick<
            Item,
            | 'id'
            | 'sku'
            | 'category'
            | 'unitPrice'
            | 'quantity'
            | 'total'
            | 'name'
            | 'meta'
            | 'metaUpdatedAt'
            | 'addedAt'
            | 'isPaid'
            | 'isRefunded'
          > & {
              payment?: Maybe<
                { __typename?: 'Transaction' } & Pick<
                  Transaction,
                  | 'id'
                  | 'source'
                  | 'sourceId'
                  | 'sourceStatus'
                  | 'type'
                  | 'amount'
                  | 'createdAt'
                >
              >;
              refund?: Maybe<
                { __typename?: 'Transaction' } & Pick<
                  Transaction,
                  | 'id'
                  | 'source'
                  | 'sourceId'
                  | 'sourceStatus'
                  | 'type'
                  | 'amount'
                  | 'createdAt'
                >
              >;
            }
        >;
        billingAddress?: Maybe<
          { __typename?: 'Address' } & Pick<
            Address,
            | 'recipient'
            | 'line1'
            | 'line2'
            | 'city'
            | 'state'
            | 'postalCode'
            | 'country'
          >
        >;
        shippingAddress?: Maybe<
          { __typename?: 'Address' } & Pick<
            Address,
            | 'recipient'
            | 'line1'
            | 'line2'
            | 'city'
            | 'state'
            | 'postalCode'
            | 'country'
          >
        >;
      };
  };

export type TransactionFragment = { __typename?: 'Transaction' } & Pick<
  Transaction,
  | 'id'
  | 'source'
  | 'sourceId'
  | 'sourceStatus'
  | 'type'
  | 'amount'
  | 'createdAt'
>;

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'createdAt' | 'updatedAt' | 'name' | 'email' | 'roles' | 'isDisabled'
>;

export type VoucherFragment = { __typename?: 'Voucher' } & Pick<
  Voucher,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'code'
  | 'type'
  | 'discount'
  | 'startsAt'
  | 'expiresAt'
  | 'useCount'
  | 'maxUses'
  | 'isUsable'
> & {
    applicableTo?: Maybe<
      { __typename?: 'VoucherApplicableTo' } & Pick<
        VoucherApplicableTo,
        'method' | 'match'
      >
    >;
    rules: Array<
      { __typename?: 'VoucherRule' } & Pick<VoucherRule, 'discount' | 'match'>
    >;
  };

export type CreateCartMutationVariables = Exact<{
  input: CreateCartInput;
}>;

export type CreateCartMutation = { __typename?: 'Mutation' } & {
  createCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type UpdateCartMutationVariables = Exact<{
  input: UpdateCartInput;
}>;

export type UpdateCartMutation = { __typename?: 'Mutation' } & {
  updateCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type UpdateCartStatusMutationVariables = Exact<{
  input: UpdateCartStatusInput;
}>;

export type UpdateCartStatusMutation = { __typename?: 'Mutation' } & {
  updateCartStatus: { __typename?: 'Cart' } & CartFragment;
};

export type AddItemsToCartMutationVariables = Exact<{
  input: AddItemsToCartInput;
}>;

export type AddItemsToCartMutation = { __typename?: 'Mutation' } & {
  addItemsToCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type RemoveItemsFromCartMutationVariables = Exact<{
  input: RemoveItemsFromCartInput;
}>;

export type RemoveItemsFromCartMutation = { __typename?: 'Mutation' } & {
  removeItemsFromCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type UpdateCartItemMutationVariables = Exact<{
  input: UpdateCartItemInput;
}>;

export type UpdateCartItemMutation = { __typename?: 'Mutation' } & {
  updateCartItem?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type AddAdjustmentToCartMutationVariables = Exact<{
  input: AddAdjustmentToCartInput;
}>;

export type AddAdjustmentToCartMutation = { __typename?: 'Mutation' } & {
  addAdjustmentToCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type UpdateAdjustmentForCartMutationVariables = Exact<{
  input: UpdateAdjustmentForCartInput;
}>;

export type UpdateAdjustmentForCartMutation = { __typename?: 'Mutation' } & {
  updateAdjustmentForCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type CreateBraintreeClientTokenMutationVariables = Exact<{
  input: CreateBraintreeClientTokenInput;
}>;

export type CreateBraintreeClientTokenMutation = { __typename?: 'Mutation' } & {
  createBraintreeClientToken?: Maybe<
    { __typename?: 'CreateBraintreeClientTokenResponseType' } & Pick<
      CreateBraintreeClientTokenResponseType,
      'clientToken'
    >
  >;
};

export type ApplyVoucherMutationVariables = Exact<{
  input: ApplyOrRemoveVoucherInput;
}>;

export type ApplyVoucherMutation = { __typename?: 'Mutation' } & {
  applyVoucher?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type RemoveVoucherMutationVariables = Exact<{
  input: ApplyOrRemoveVoucherInput;
}>;

export type RemoveVoucherMutation = { __typename?: 'Mutation' } & {
  removeVoucher?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  auth: { __typename?: 'UserAndTokenType' } & Pick<
    UserAndTokenType,
    'token'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type RegisterUserMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterUserMutation = { __typename?: 'Mutation' } & {
  registerUser: { __typename?: 'User' } & UserFragment;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'User' } & UserFragment;
};

export type CreateVoucherMutationVariables = Exact<{
  input: CreateVoucherInput;
}>;

export type CreateVoucherMutation = { __typename?: 'Mutation' } & {
  createVoucher: { __typename?: 'Voucher' } & VoucherFragment;
};

export type UpdateVoucherMutationVariables = Exact<{
  input: UpdateVoucherInput;
}>;

export type UpdateVoucherMutation = { __typename?: 'Mutation' } & {
  updateVoucher: { __typename?: 'Voucher' } & VoucherFragment;
};

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type CreateProductMutation = { __typename?: 'Mutation' } & {
  createProduct: { __typename?: 'Product' } & ProductFragment;
};

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;

export type UpdateProductMutation = { __typename?: 'Mutation' } & {
  updateProduct: { __typename?: 'Product' } & ProductFragment;
};

export type AddFulfillmentToCartMutationVariables = Exact<{
  input: AddFulfillmentToCartInput;
}>;

export type AddFulfillmentToCartMutation = { __typename?: 'Mutation' } & {
  addFulfillmentToCart: { __typename?: 'Cart' } & CartFragment;
};

export type UpdateFulfillmentForCartMutationVariables = Exact<{
  input: UpdateFulfillmentForCartInput;
}>;

export type UpdateFulfillmentForCartMutation = { __typename?: 'Mutation' } & {
  updateFulfillmentForCart: { __typename?: 'Cart' } & CartFragment;
};

export type FulfillPrintfulMutationVariables = Exact<{
  input: PrintfulFulfillmentInput;
}>;

export type FulfillPrintfulMutation = { __typename?: 'Mutation' } & {
  temp__fulfillPrintful: { __typename?: 'Cart' } & CartFragment;
};

export type FulfillShipStationMutationVariables = Exact<{
  input: CreateShipStationOrderInput;
}>;

export type FulfillShipStationMutation = { __typename?: 'Mutation' } & {
  temp__fulfillShipStation: { __typename?: 'Cart' } & CartFragment;
};

export type ValidateAddressMutationVariables = Exact<{
  input: AddressInput;
}>;

export type ValidateAddressMutation = { __typename?: 'Mutation' } & {
  validateAddress: { __typename?: 'Address' } & AddressFragment;
};

export type AddPaymentMutationVariables = Exact<{
  input: AddPaymentInput;
}>;

export type AddPaymentMutation = { __typename?: 'Mutation' } & {
  addPayment?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type UpdateTimelineMutationVariables = Exact<{
  input: UpdateTimelineInput;
}>;

export type UpdateTimelineMutation = { __typename?: 'Mutation' } & {
  updateTimeline?: Maybe<{ __typename?: 'Timeline' } & TimelineFragment>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & UserFragment;
};

export type CartQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CartQuery = { __typename?: 'Query' } & {
  cart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type TimelinesQueryVariables = Exact<{
  cart: Scalars['String'];
}>;

export type TimelinesQuery = { __typename?: 'Query' } & {
  timelines: Array<{ __typename?: 'Timeline' } & TimelineFragment>;
};

export type CartAndTimelinesQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CartAndTimelinesQuery = { __typename?: 'Query' } & {
  cart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
  timelines: Array<{ __typename?: 'Timeline' } & TimelineFragment>;
};

export type CartsQueryVariables = Exact<{
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<CartStatus> | CartStatus>;
  limit?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['JSON']>;
}>;

export type CartsQuery = { __typename?: 'Query' } & {
  carts: Array<{ __typename?: 'Cart' } & CartFragment>;
};

export type CartsBasicQueryVariables = Exact<{
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<CartStatus> | CartStatus>;
  limit?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['JSON']>;
}>;

export type CartsBasicQuery = { __typename?: 'Query' } & {
  carts: Array<{ __typename?: 'Cart' } & CartBasicFragment>;
};

export type FulfillmentOrdersQueryVariables = Exact<{
  query?: Maybe<OrdersQuery>;
  paidAt?: Maybe<DateRangeInput>;
  status?: Maybe<Array<CartStatus> | CartStatus>;
  filter?: Maybe<Scalars['JSONObject']>;
  next?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  reverse?: Maybe<Scalars['Boolean']>;
}>;

export type FulfillmentOrdersQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrdersQueryResults' } & {
    results: Array<{ __typename?: 'Cart' } & CartFragment>;
    pagination: { __typename?: 'OrdersQueryResultsPagination' } & Pick<
      OrdersQueryResultsPagination,
      'next'
    >;
  };
};

export type ProductQueryVariables = Exact<{
  input: ProductQueryInput;
}>;

export type ProductQuery = { __typename?: 'Query' } & {
  product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
};

export type ProductsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsQuery = { __typename?: 'Query' } & {
  products: Array<{ __typename?: 'Product' } & ProductFragment>;
};

export type StoresQueryVariables = Exact<{ [key: string]: never }>;

export type StoresQuery = { __typename?: 'Query' } & {
  stores: Array<{ __typename?: 'Store' } & StoreFragment>;
};

export type VoucherQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type VoucherQuery = { __typename?: 'Query' } & {
  voucher: { __typename?: 'Voucher' } & VoucherFragment;
};

export type VouchersQueryVariables = Exact<{ [key: string]: never }>;

export type VouchersQuery = { __typename?: 'Query' } & {
  vouchers: Array<{ __typename?: 'Voucher' } & VoucherFragment>;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = { __typename?: 'Query' } & {
  users: Array<{ __typename?: 'User' } & UserFragment>;
};

export const TransactionFragmentDoc = gql`
  fragment Transaction on Transaction {
    id
    source
    sourceId
    sourceStatus
    type
    amount
    createdAt
  }
`;
export const ShipmentFragmentDoc = gql`
  fragment Shipment on Shipment {
    id
    fulfillment
    source
    sourceId
    status
    carrier
    trackingNumber
    notes
    meta
    createdAt
    updatedAt
  }
`;
export const FulfillmentFragmentDoc = gql`
  fragment Fulfillment on Fulfillment {
    id
    items
    source
    status
    meta
    notes
    createdAt
    updatedAt
  }
`;
export const AdjustmentFragmentDoc = gql`
  fragment Adjustment on Adjustment {
    id
    type
    amount
    meta
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    name
    sku
    type
    unitPrice
    category
    commodityCode
    upc
    meta
    requiresApproval
    createdAt
    updatedAt
  }
`;
export const ItemFragmentDoc = gql`
  fragment Item on Item {
    id
    sku
    category
    unitPrice
    quantity
    total
    name
    meta
    metaUpdatedAt
    addedAt
    isPaid
    isRefunded
    product {
      ...Product
    }
    payment {
      ...Transaction
    }
    refund {
      ...Transaction
    }
  }
  ${ProductFragmentDoc}
  ${TransactionFragmentDoc}
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    recipient
    line1
    line2
    city
    state
    postalCode
    country
  }
`;
export const CartFragmentDoc = gql`
  fragment Cart on Cart {
    id
    email
    firstName
    lastName
    fullName
    phone
    notes
    status
    meta
    paid
    paidAt
    store
    total
    adjustmentsTotal
    itemsTotal
    isEditable
    isPaid
    isApproved
    approvedBy
    approvedAt
    createdAt
    updatedAt
    payments {
      ...Transaction
    }
    refunds {
      ...Transaction
    }
    shipments {
      ...Shipment
    }
    fulfillments {
      ...Fulfillment
    }
    voucher {
      ...Adjustment
    }
    adjustments {
      ...Adjustment
    }
    items {
      ...Item
    }
    billingAddress {
      ...Address
    }
    shippingAddress {
      ...Address
    }
  }
  ${TransactionFragmentDoc}
  ${ShipmentFragmentDoc}
  ${FulfillmentFragmentDoc}
  ${AdjustmentFragmentDoc}
  ${ItemFragmentDoc}
  ${AddressFragmentDoc}
`;
export const CartBasicFragmentDoc = gql`
  fragment CartBasic on Cart {
    id
    createdAt
    updatedAt
    approvedAt
    approvedBy
    email
    firstName
    lastName
    fullName
    phone
    status
    paid
    paidAt
    total
    meta
    adjustmentsTotal
    itemsTotal
    isEditable
    isApproved
    store
    isPaid
    payments {
      ...Transaction
    }
    voucher {
      ...Adjustment
    }
    adjustments {
      ...Adjustment
    }
    items {
      ...Item
    }
    shippingAddress {
      ...Address
    }
  }
  ${TransactionFragmentDoc}
  ${AdjustmentFragmentDoc}
  ${ItemFragmentDoc}
  ${AddressFragmentDoc}
`;
export const StoreFragmentDoc = gql`
  fragment Store on Store {
    code
    name
    url
    cartURL
    orderURL
    orderEmail
    supportEmail
    meta
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    createdAt
    updatedAt
    name
    email
    roles
    isDisabled
  }
`;
export const TimelineFragmentDoc = gql`
  fragment Timeline on Timeline {
    id
    meta
    text
    type
    createdAt
    updatedAt
    user {
      ...User
    }
    snapshot {
      id
      createdAt
      updatedAt
      email
      firstName
      lastName
      fullName
      phone
      notes
      status
      meta
      paid
      paidAt
      total
      adjustmentsTotal
      itemsTotal
      isEditable
      isPaid
      store
      payments {
        id
        source
        sourceId
        sourceStatus
        type
        amount
        createdAt
      }
      refunds {
        id
        source
        sourceId
        sourceStatus
        type
        amount
        createdAt
      }
      fulfillments {
        id
        items
        source
        status
        meta
        notes
        createdAt
        updatedAt
      }
      voucher {
        id
        type
        amount
        meta
      }
      adjustments {
        id
        type
        amount
        meta
      }
      items {
        id
        sku
        category
        unitPrice
        quantity
        total
        name
        meta
        metaUpdatedAt
        addedAt
        isPaid
        isRefunded
        payment {
          id
          source
          sourceId
          sourceStatus
          type
          amount
          createdAt
        }
        refund {
          id
          source
          sourceId
          sourceStatus
          type
          amount
          createdAt
        }
      }
      billingAddress {
        recipient
        line1
        line2
        city
        state
        postalCode
        country
      }
      shippingAddress {
        recipient
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
  ${UserFragmentDoc}
`;
export const VoucherFragmentDoc = gql`
  fragment Voucher on Voucher {
    id
    createdAt
    updatedAt
    applicableTo {
      method
      match
    }
    rules {
      discount
      match
    }
    code
    type
    discount
    startsAt
    expiresAt
    useCount
    maxUses
    isUsable
  }
`;
export const CreateCartDocument = gql`
  mutation createCart($input: CreateCartInput!) {
    createCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type CreateCartMutationFn = Apollo.MutationFunction<
  CreateCartMutation,
  CreateCartMutationVariables
>;

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCartMutation,
    CreateCartMutationVariables
  >
) {
  return Apollo.useMutation<CreateCartMutation, CreateCartMutationVariables>(
    CreateCartDocument,
    baseOptions
  );
}
export type CreateCartMutationHookResult = ReturnType<
  typeof useCreateCartMutation
>;
export type CreateCartMutationResult = Apollo.MutationResult<CreateCartMutation>;
export type CreateCartMutationOptions = Apollo.BaseMutationOptions<
  CreateCartMutation,
  CreateCartMutationVariables
>;
export const UpdateCartDocument = gql`
  mutation updateCart($input: UpdateCartInput!) {
    updateCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateCartMutationFn = Apollo.MutationFunction<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >
) {
  return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(
    UpdateCartDocument,
    baseOptions
  );
}
export type UpdateCartMutationHookResult = ReturnType<
  typeof useUpdateCartMutation
>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;
export const UpdateCartStatusDocument = gql`
  mutation updateCartStatus($input: UpdateCartStatusInput!) {
    updateCartStatus(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateCartStatusMutationFn = Apollo.MutationFunction<
  UpdateCartStatusMutation,
  UpdateCartStatusMutationVariables
>;

/**
 * __useUpdateCartStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCartStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartStatusMutation, { data, loading, error }] = useUpdateCartStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartStatusMutation,
    UpdateCartStatusMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCartStatusMutation,
    UpdateCartStatusMutationVariables
  >(UpdateCartStatusDocument, baseOptions);
}
export type UpdateCartStatusMutationHookResult = ReturnType<
  typeof useUpdateCartStatusMutation
>;
export type UpdateCartStatusMutationResult = Apollo.MutationResult<UpdateCartStatusMutation>;
export type UpdateCartStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartStatusMutation,
  UpdateCartStatusMutationVariables
>;
export const AddItemsToCartDocument = gql`
  mutation addItemsToCart($input: AddItemsToCartInput!) {
    addItemsToCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type AddItemsToCartMutationFn = Apollo.MutationFunction<
  AddItemsToCartMutation,
  AddItemsToCartMutationVariables
>;

/**
 * __useAddItemsToCartMutation__
 *
 * To run a mutation, you first call `useAddItemsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToCartMutation, { data, loading, error }] = useAddItemsToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemsToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddItemsToCartMutation,
    AddItemsToCartMutationVariables
  >
) {
  return Apollo.useMutation<
    AddItemsToCartMutation,
    AddItemsToCartMutationVariables
  >(AddItemsToCartDocument, baseOptions);
}
export type AddItemsToCartMutationHookResult = ReturnType<
  typeof useAddItemsToCartMutation
>;
export type AddItemsToCartMutationResult = Apollo.MutationResult<AddItemsToCartMutation>;
export type AddItemsToCartMutationOptions = Apollo.BaseMutationOptions<
  AddItemsToCartMutation,
  AddItemsToCartMutationVariables
>;
export const RemoveItemsFromCartDocument = gql`
  mutation removeItemsFromCart($input: RemoveItemsFromCartInput!) {
    removeItemsFromCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type RemoveItemsFromCartMutationFn = Apollo.MutationFunction<
  RemoveItemsFromCartMutation,
  RemoveItemsFromCartMutationVariables
>;

/**
 * __useRemoveItemsFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemsFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemsFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemsFromCartMutation, { data, loading, error }] = useRemoveItemsFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemsFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemsFromCartMutation,
    RemoveItemsFromCartMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveItemsFromCartMutation,
    RemoveItemsFromCartMutationVariables
  >(RemoveItemsFromCartDocument, baseOptions);
}
export type RemoveItemsFromCartMutationHookResult = ReturnType<
  typeof useRemoveItemsFromCartMutation
>;
export type RemoveItemsFromCartMutationResult = Apollo.MutationResult<RemoveItemsFromCartMutation>;
export type RemoveItemsFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemsFromCartMutation,
  RemoveItemsFromCartMutationVariables
>;
export const UpdateCartItemDocument = gql`
  mutation updateCartItem($input: UpdateCartItemInput!) {
    updateCartItem(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<
  UpdateCartItemMutation,
  UpdateCartItemMutationVariables
>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartItemMutation,
    UpdateCartItemMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCartItemMutation,
    UpdateCartItemMutationVariables
  >(UpdateCartItemDocument, baseOptions);
}
export type UpdateCartItemMutationHookResult = ReturnType<
  typeof useUpdateCartItemMutation
>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartItemMutation,
  UpdateCartItemMutationVariables
>;
export const AddAdjustmentToCartDocument = gql`
  mutation addAdjustmentToCart($input: AddAdjustmentToCartInput!) {
    addAdjustmentToCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type AddAdjustmentToCartMutationFn = Apollo.MutationFunction<
  AddAdjustmentToCartMutation,
  AddAdjustmentToCartMutationVariables
>;

/**
 * __useAddAdjustmentToCartMutation__
 *
 * To run a mutation, you first call `useAddAdjustmentToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdjustmentToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdjustmentToCartMutation, { data, loading, error }] = useAddAdjustmentToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdjustmentToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdjustmentToCartMutation,
    AddAdjustmentToCartMutationVariables
  >
) {
  return Apollo.useMutation<
    AddAdjustmentToCartMutation,
    AddAdjustmentToCartMutationVariables
  >(AddAdjustmentToCartDocument, baseOptions);
}
export type AddAdjustmentToCartMutationHookResult = ReturnType<
  typeof useAddAdjustmentToCartMutation
>;
export type AddAdjustmentToCartMutationResult = Apollo.MutationResult<AddAdjustmentToCartMutation>;
export type AddAdjustmentToCartMutationOptions = Apollo.BaseMutationOptions<
  AddAdjustmentToCartMutation,
  AddAdjustmentToCartMutationVariables
>;
export const UpdateAdjustmentForCartDocument = gql`
  mutation updateAdjustmentForCart($input: UpdateAdjustmentForCartInput!) {
    updateAdjustmentForCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateAdjustmentForCartMutationFn = Apollo.MutationFunction<
  UpdateAdjustmentForCartMutation,
  UpdateAdjustmentForCartMutationVariables
>;

/**
 * __useUpdateAdjustmentForCartMutation__
 *
 * To run a mutation, you first call `useUpdateAdjustmentForCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdjustmentForCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdjustmentForCartMutation, { data, loading, error }] = useUpdateAdjustmentForCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdjustmentForCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdjustmentForCartMutation,
    UpdateAdjustmentForCartMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateAdjustmentForCartMutation,
    UpdateAdjustmentForCartMutationVariables
  >(UpdateAdjustmentForCartDocument, baseOptions);
}
export type UpdateAdjustmentForCartMutationHookResult = ReturnType<
  typeof useUpdateAdjustmentForCartMutation
>;
export type UpdateAdjustmentForCartMutationResult = Apollo.MutationResult<UpdateAdjustmentForCartMutation>;
export type UpdateAdjustmentForCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdjustmentForCartMutation,
  UpdateAdjustmentForCartMutationVariables
>;
export const CreateBraintreeClientTokenDocument = gql`
  mutation createBraintreeClientToken(
    $input: CreateBraintreeClientTokenInput!
  ) {
    createBraintreeClientToken(input: $input) {
      clientToken
    }
  }
`;
export type CreateBraintreeClientTokenMutationFn = Apollo.MutationFunction<
  CreateBraintreeClientTokenMutation,
  CreateBraintreeClientTokenMutationVariables
>;

/**
 * __useCreateBraintreeClientTokenMutation__
 *
 * To run a mutation, you first call `useCreateBraintreeClientTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreeClientTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreeClientTokenMutation, { data, loading, error }] = useCreateBraintreeClientTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBraintreeClientTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBraintreeClientTokenMutation,
    CreateBraintreeClientTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateBraintreeClientTokenMutation,
    CreateBraintreeClientTokenMutationVariables
  >(CreateBraintreeClientTokenDocument, baseOptions);
}
export type CreateBraintreeClientTokenMutationHookResult = ReturnType<
  typeof useCreateBraintreeClientTokenMutation
>;
export type CreateBraintreeClientTokenMutationResult = Apollo.MutationResult<CreateBraintreeClientTokenMutation>;
export type CreateBraintreeClientTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateBraintreeClientTokenMutation,
  CreateBraintreeClientTokenMutationVariables
>;
export const ApplyVoucherDocument = gql`
  mutation applyVoucher($input: ApplyOrRemoveVoucherInput!) {
    applyVoucher(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type ApplyVoucherMutationFn = Apollo.MutationFunction<
  ApplyVoucherMutation,
  ApplyVoucherMutationVariables
>;

/**
 * __useApplyVoucherMutation__
 *
 * To run a mutation, you first call `useApplyVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyVoucherMutation, { data, loading, error }] = useApplyVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyVoucherMutation,
    ApplyVoucherMutationVariables
  >
) {
  return Apollo.useMutation<
    ApplyVoucherMutation,
    ApplyVoucherMutationVariables
  >(ApplyVoucherDocument, baseOptions);
}
export type ApplyVoucherMutationHookResult = ReturnType<
  typeof useApplyVoucherMutation
>;
export type ApplyVoucherMutationResult = Apollo.MutationResult<ApplyVoucherMutation>;
export type ApplyVoucherMutationOptions = Apollo.BaseMutationOptions<
  ApplyVoucherMutation,
  ApplyVoucherMutationVariables
>;
export const RemoveVoucherDocument = gql`
  mutation removeVoucher($input: ApplyOrRemoveVoucherInput!) {
    removeVoucher(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type RemoveVoucherMutationFn = Apollo.MutationFunction<
  RemoveVoucherMutation,
  RemoveVoucherMutationVariables
>;

/**
 * __useRemoveVoucherMutation__
 *
 * To run a mutation, you first call `useRemoveVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVoucherMutation, { data, loading, error }] = useRemoveVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVoucherMutation,
    RemoveVoucherMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveVoucherMutation,
    RemoveVoucherMutationVariables
  >(RemoveVoucherDocument, baseOptions);
}
export type RemoveVoucherMutationHookResult = ReturnType<
  typeof useRemoveVoucherMutation
>;
export type RemoveVoucherMutationResult = Apollo.MutationResult<RemoveVoucherMutation>;
export type RemoveVoucherMutationOptions = Apollo.BaseMutationOptions<
  RemoveVoucherMutation,
  RemoveVoucherMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    auth(email: $email, password: $password) {
      token
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation registerUser($input: RegisterInput!) {
    registerUser(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, baseOptions);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateVoucherDocument = gql`
  mutation createVoucher($input: CreateVoucherInput!) {
    createVoucher(input: $input) {
      ...Voucher
    }
  }
  ${VoucherFragmentDoc}
`;
export type CreateVoucherMutationFn = Apollo.MutationFunction<
  CreateVoucherMutation,
  CreateVoucherMutationVariables
>;

/**
 * __useCreateVoucherMutation__
 *
 * To run a mutation, you first call `useCreateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVoucherMutation, { data, loading, error }] = useCreateVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVoucherMutation,
    CreateVoucherMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateVoucherMutation,
    CreateVoucherMutationVariables
  >(CreateVoucherDocument, baseOptions);
}
export type CreateVoucherMutationHookResult = ReturnType<
  typeof useCreateVoucherMutation
>;
export type CreateVoucherMutationResult = Apollo.MutationResult<CreateVoucherMutation>;
export type CreateVoucherMutationOptions = Apollo.BaseMutationOptions<
  CreateVoucherMutation,
  CreateVoucherMutationVariables
>;
export const UpdateVoucherDocument = gql`
  mutation updateVoucher($input: UpdateVoucherInput!) {
    updateVoucher(input: $input) {
      ...Voucher
    }
  }
  ${VoucherFragmentDoc}
`;
export type UpdateVoucherMutationFn = Apollo.MutationFunction<
  UpdateVoucherMutation,
  UpdateVoucherMutationVariables
>;

/**
 * __useUpdateVoucherMutation__
 *
 * To run a mutation, you first call `useUpdateVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVoucherMutation, { data, loading, error }] = useUpdateVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVoucherMutation,
    UpdateVoucherMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateVoucherMutation,
    UpdateVoucherMutationVariables
  >(UpdateVoucherDocument, baseOptions);
}
export type UpdateVoucherMutationHookResult = ReturnType<
  typeof useUpdateVoucherMutation
>;
export type UpdateVoucherMutationResult = Apollo.MutationResult<UpdateVoucherMutation>;
export type UpdateVoucherMutationOptions = Apollo.BaseMutationOptions<
  UpdateVoucherMutation,
  UpdateVoucherMutationVariables
>;
export const CreateProductDocument = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;
export type CreateProductMutationFn = Apollo.MutationFunction<
  CreateProductMutation,
  CreateProductMutationVariables
>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductMutation,
    CreateProductMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateProductMutation,
    CreateProductMutationVariables
  >(CreateProductDocument, baseOptions);
}
export type CreateProductMutationHookResult = ReturnType<
  typeof useCreateProductMutation
>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >(UpdateProductDocument, baseOptions);
}
export type UpdateProductMutationHookResult = ReturnType<
  typeof useUpdateProductMutation
>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const AddFulfillmentToCartDocument = gql`
  mutation addFulfillmentToCart($input: AddFulfillmentToCartInput!) {
    addFulfillmentToCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type AddFulfillmentToCartMutationFn = Apollo.MutationFunction<
  AddFulfillmentToCartMutation,
  AddFulfillmentToCartMutationVariables
>;

/**
 * __useAddFulfillmentToCartMutation__
 *
 * To run a mutation, you first call `useAddFulfillmentToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFulfillmentToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFulfillmentToCartMutation, { data, loading, error }] = useAddFulfillmentToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFulfillmentToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFulfillmentToCartMutation,
    AddFulfillmentToCartMutationVariables
  >
) {
  return Apollo.useMutation<
    AddFulfillmentToCartMutation,
    AddFulfillmentToCartMutationVariables
  >(AddFulfillmentToCartDocument, baseOptions);
}
export type AddFulfillmentToCartMutationHookResult = ReturnType<
  typeof useAddFulfillmentToCartMutation
>;
export type AddFulfillmentToCartMutationResult = Apollo.MutationResult<AddFulfillmentToCartMutation>;
export type AddFulfillmentToCartMutationOptions = Apollo.BaseMutationOptions<
  AddFulfillmentToCartMutation,
  AddFulfillmentToCartMutationVariables
>;
export const UpdateFulfillmentForCartDocument = gql`
  mutation updateFulfillmentForCart($input: UpdateFulfillmentForCartInput!) {
    updateFulfillmentForCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateFulfillmentForCartMutationFn = Apollo.MutationFunction<
  UpdateFulfillmentForCartMutation,
  UpdateFulfillmentForCartMutationVariables
>;

/**
 * __useUpdateFulfillmentForCartMutation__
 *
 * To run a mutation, you first call `useUpdateFulfillmentForCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFulfillmentForCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentForCartMutation, { data, loading, error }] = useUpdateFulfillmentForCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFulfillmentForCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFulfillmentForCartMutation,
    UpdateFulfillmentForCartMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateFulfillmentForCartMutation,
    UpdateFulfillmentForCartMutationVariables
  >(UpdateFulfillmentForCartDocument, baseOptions);
}
export type UpdateFulfillmentForCartMutationHookResult = ReturnType<
  typeof useUpdateFulfillmentForCartMutation
>;
export type UpdateFulfillmentForCartMutationResult = Apollo.MutationResult<UpdateFulfillmentForCartMutation>;
export type UpdateFulfillmentForCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateFulfillmentForCartMutation,
  UpdateFulfillmentForCartMutationVariables
>;
export const FulfillPrintfulDocument = gql`
  mutation FulfillPrintful($input: PrintfulFulfillmentInput!) {
    temp__fulfillPrintful(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type FulfillPrintfulMutationFn = Apollo.MutationFunction<
  FulfillPrintfulMutation,
  FulfillPrintfulMutationVariables
>;

/**
 * __useFulfillPrintfulMutation__
 *
 * To run a mutation, you first call `useFulfillPrintfulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFulfillPrintfulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fulfillPrintfulMutation, { data, loading, error }] = useFulfillPrintfulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFulfillPrintfulMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FulfillPrintfulMutation,
    FulfillPrintfulMutationVariables
  >
) {
  return Apollo.useMutation<
    FulfillPrintfulMutation,
    FulfillPrintfulMutationVariables
  >(FulfillPrintfulDocument, baseOptions);
}
export type FulfillPrintfulMutationHookResult = ReturnType<
  typeof useFulfillPrintfulMutation
>;
export type FulfillPrintfulMutationResult = Apollo.MutationResult<FulfillPrintfulMutation>;
export type FulfillPrintfulMutationOptions = Apollo.BaseMutationOptions<
  FulfillPrintfulMutation,
  FulfillPrintfulMutationVariables
>;
export const FulfillShipStationDocument = gql`
  mutation FulfillShipStation($input: CreateShipStationOrderInput!) {
    temp__fulfillShipStation(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type FulfillShipStationMutationFn = Apollo.MutationFunction<
  FulfillShipStationMutation,
  FulfillShipStationMutationVariables
>;

/**
 * __useFulfillShipStationMutation__
 *
 * To run a mutation, you first call `useFulfillShipStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFulfillShipStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fulfillShipStationMutation, { data, loading, error }] = useFulfillShipStationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFulfillShipStationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FulfillShipStationMutation,
    FulfillShipStationMutationVariables
  >
) {
  return Apollo.useMutation<
    FulfillShipStationMutation,
    FulfillShipStationMutationVariables
  >(FulfillShipStationDocument, baseOptions);
}
export type FulfillShipStationMutationHookResult = ReturnType<
  typeof useFulfillShipStationMutation
>;
export type FulfillShipStationMutationResult = Apollo.MutationResult<FulfillShipStationMutation>;
export type FulfillShipStationMutationOptions = Apollo.BaseMutationOptions<
  FulfillShipStationMutation,
  FulfillShipStationMutationVariables
>;
export const ValidateAddressDocument = gql`
  mutation validateAddress($input: AddressInput!) {
    validateAddress(input: $input) {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export type ValidateAddressMutationFn = Apollo.MutationFunction<
  ValidateAddressMutation,
  ValidateAddressMutationVariables
>;

/**
 * __useValidateAddressMutation__
 *
 * To run a mutation, you first call `useValidateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAddressMutation, { data, loading, error }] = useValidateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateAddressMutation,
    ValidateAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    ValidateAddressMutation,
    ValidateAddressMutationVariables
  >(ValidateAddressDocument, baseOptions);
}
export type ValidateAddressMutationHookResult = ReturnType<
  typeof useValidateAddressMutation
>;
export type ValidateAddressMutationResult = Apollo.MutationResult<ValidateAddressMutation>;
export type ValidateAddressMutationOptions = Apollo.BaseMutationOptions<
  ValidateAddressMutation,
  ValidateAddressMutationVariables
>;
export const AddPaymentDocument = gql`
  mutation addPayment($input: AddPaymentInput!) {
    addPayment(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type AddPaymentMutationFn = Apollo.MutationFunction<
  AddPaymentMutation,
  AddPaymentMutationVariables
>;

/**
 * __useAddPaymentMutation__
 *
 * To run a mutation, you first call `useAddPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMutation, { data, loading, error }] = useAddPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPaymentMutation,
    AddPaymentMutationVariables
  >
) {
  return Apollo.useMutation<AddPaymentMutation, AddPaymentMutationVariables>(
    AddPaymentDocument,
    baseOptions
  );
}
export type AddPaymentMutationHookResult = ReturnType<
  typeof useAddPaymentMutation
>;
export type AddPaymentMutationResult = Apollo.MutationResult<AddPaymentMutation>;
export type AddPaymentMutationOptions = Apollo.BaseMutationOptions<
  AddPaymentMutation,
  AddPaymentMutationVariables
>;
export const UpdateTimelineDocument = gql`
  mutation updateTimeline($input: UpdateTimelineInput!) {
    updateTimeline(input: $input) {
      ...Timeline
    }
  }
  ${TimelineFragmentDoc}
`;
export type UpdateTimelineMutationFn = Apollo.MutationFunction<
  UpdateTimelineMutation,
  UpdateTimelineMutationVariables
>;

/**
 * __useUpdateTimelineMutation__
 *
 * To run a mutation, you first call `useUpdateTimelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelineMutation, { data, loading, error }] = useUpdateTimelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTimelineMutation,
    UpdateTimelineMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateTimelineMutation,
    UpdateTimelineMutationVariables
  >(UpdateTimelineDocument, baseOptions);
}
export type UpdateTimelineMutationHookResult = ReturnType<
  typeof useUpdateTimelineMutation
>;
export type UpdateTimelineMutationResult = Apollo.MutationResult<UpdateTimelineMutation>;
export type UpdateTimelineMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimelineMutation,
  UpdateTimelineMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CartDocument = gql`
  query cart($id: String!) {
    cart(id: $id) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCartQuery(
  baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>
) {
  return Apollo.useQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    baseOptions
  );
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>
) {
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    baseOptions
  );
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const TimelinesDocument = gql`
  query timelines($cart: String!) {
    timelines(cart: $cart) {
      ...Timeline
    }
  }
  ${TimelineFragmentDoc}
`;

/**
 * __useTimelinesQuery__
 *
 * To run a query within a React component, call `useTimelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelinesQuery({
 *   variables: {
 *      cart: // value for 'cart'
 *   },
 * });
 */
export function useTimelinesQuery(
  baseOptions: Apollo.QueryHookOptions<TimelinesQuery, TimelinesQueryVariables>
) {
  return Apollo.useQuery<TimelinesQuery, TimelinesQueryVariables>(
    TimelinesDocument,
    baseOptions
  );
}
export function useTimelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimelinesQuery,
    TimelinesQueryVariables
  >
) {
  return Apollo.useLazyQuery<TimelinesQuery, TimelinesQueryVariables>(
    TimelinesDocument,
    baseOptions
  );
}
export type TimelinesQueryHookResult = ReturnType<typeof useTimelinesQuery>;
export type TimelinesLazyQueryHookResult = ReturnType<
  typeof useTimelinesLazyQuery
>;
export type TimelinesQueryResult = Apollo.QueryResult<
  TimelinesQuery,
  TimelinesQueryVariables
>;
export const CartAndTimelinesDocument = gql`
  query cartAndTimelines($id: String!) {
    cart(id: $id) {
      ...Cart
    }
    timelines(cart: $id) {
      ...Timeline
    }
  }
  ${CartFragmentDoc}
  ${TimelineFragmentDoc}
`;

/**
 * __useCartAndTimelinesQuery__
 *
 * To run a query within a React component, call `useCartAndTimelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartAndTimelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartAndTimelinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCartAndTimelinesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CartAndTimelinesQuery,
    CartAndTimelinesQueryVariables
  >
) {
  return Apollo.useQuery<CartAndTimelinesQuery, CartAndTimelinesQueryVariables>(
    CartAndTimelinesDocument,
    baseOptions
  );
}
export function useCartAndTimelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CartAndTimelinesQuery,
    CartAndTimelinesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CartAndTimelinesQuery,
    CartAndTimelinesQueryVariables
  >(CartAndTimelinesDocument, baseOptions);
}
export type CartAndTimelinesQueryHookResult = ReturnType<
  typeof useCartAndTimelinesQuery
>;
export type CartAndTimelinesLazyQueryHookResult = ReturnType<
  typeof useCartAndTimelinesLazyQuery
>;
export type CartAndTimelinesQueryResult = Apollo.QueryResult<
  CartAndTimelinesQuery,
  CartAndTimelinesQueryVariables
>;
export const CartsDocument = gql`
  query carts(
    $from: DateTime
    $to: DateTime
    $search: String
    $status: [CartStatus!]
    $limit: Float
    $skip: Float
    $sort: JSON
  ) {
    carts(
      from: $from
      limit: $limit
      search: $search
      skip: $skip
      sort: $sort
      status: $status
      to: $to
    ) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useCartsQuery__
 *
 * To run a query within a React component, call `useCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCartsQuery(
  baseOptions?: Apollo.QueryHookOptions<CartsQuery, CartsQueryVariables>
) {
  return Apollo.useQuery<CartsQuery, CartsQueryVariables>(
    CartsDocument,
    baseOptions
  );
}
export function useCartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartsQuery, CartsQueryVariables>
) {
  return Apollo.useLazyQuery<CartsQuery, CartsQueryVariables>(
    CartsDocument,
    baseOptions
  );
}
export type CartsQueryHookResult = ReturnType<typeof useCartsQuery>;
export type CartsLazyQueryHookResult = ReturnType<typeof useCartsLazyQuery>;
export type CartsQueryResult = Apollo.QueryResult<
  CartsQuery,
  CartsQueryVariables
>;
export const CartsBasicDocument = gql`
  query cartsBasic(
    $from: DateTime
    $to: DateTime
    $search: String
    $status: [CartStatus!]
    $limit: Float
    $skip: Float
    $sort: JSON
  ) {
    carts(
      from: $from
      limit: $limit
      search: $search
      skip: $skip
      sort: $sort
      status: $status
      to: $to
    ) {
      ...CartBasic
    }
  }
  ${CartBasicFragmentDoc}
`;

/**
 * __useCartsBasicQuery__
 *
 * To run a query within a React component, call `useCartsBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartsBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartsBasicQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCartsBasicQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CartsBasicQuery,
    CartsBasicQueryVariables
  >
) {
  return Apollo.useQuery<CartsBasicQuery, CartsBasicQueryVariables>(
    CartsBasicDocument,
    baseOptions
  );
}
export function useCartsBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CartsBasicQuery,
    CartsBasicQueryVariables
  >
) {
  return Apollo.useLazyQuery<CartsBasicQuery, CartsBasicQueryVariables>(
    CartsBasicDocument,
    baseOptions
  );
}
export type CartsBasicQueryHookResult = ReturnType<typeof useCartsBasicQuery>;
export type CartsBasicLazyQueryHookResult = ReturnType<
  typeof useCartsBasicLazyQuery
>;
export type CartsBasicQueryResult = Apollo.QueryResult<
  CartsBasicQuery,
  CartsBasicQueryVariables
>;
export const FulfillmentOrdersDocument = gql`
  query fulfillmentOrders(
    $query: OrdersQuery
    $paidAt: DateRangeInput
    $status: [CartStatus!]
    $filter: JSONObject
    $next: String
    $limit: Int
    $reverse: Boolean
  ) {
    orders(
      query: $query
      paidAt: $paidAt
      status: $status
      filter: $filter
      next: $next
      limit: $limit
      reverse: $reverse
    ) {
      results {
        ...Cart
      }
      pagination {
        next
      }
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useFulfillmentOrdersQuery__
 *
 * To run a query within a React component, call `useFulfillmentOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfillmentOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfillmentOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      paidAt: // value for 'paidAt'
 *      status: // value for 'status'
 *      filter: // value for 'filter'
 *      next: // value for 'next'
 *      limit: // value for 'limit'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useFulfillmentOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FulfillmentOrdersQuery,
    FulfillmentOrdersQueryVariables
  >
) {
  return Apollo.useQuery<
    FulfillmentOrdersQuery,
    FulfillmentOrdersQueryVariables
  >(FulfillmentOrdersDocument, baseOptions);
}
export function useFulfillmentOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FulfillmentOrdersQuery,
    FulfillmentOrdersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FulfillmentOrdersQuery,
    FulfillmentOrdersQueryVariables
  >(FulfillmentOrdersDocument, baseOptions);
}
export type FulfillmentOrdersQueryHookResult = ReturnType<
  typeof useFulfillmentOrdersQuery
>;
export type FulfillmentOrdersLazyQueryHookResult = ReturnType<
  typeof useFulfillmentOrdersLazyQuery
>;
export type FulfillmentOrdersQueryResult = Apollo.QueryResult<
  FulfillmentOrdersQuery,
  FulfillmentOrdersQueryVariables
>;
export const ProductDocument = gql`
  query product($input: ProductQueryInput!) {
    product(input: $input) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    baseOptions
  );
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    baseOptions
  );
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<
  ProductQuery,
  ProductQueryVariables
>;
export const ProductsDocument = gql`
  query products {
    products {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    baseOptions
  );
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >
) {
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    baseOptions
  );
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>;
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>;
export const StoresDocument = gql`
  query stores {
    stores {
      ...Store
    }
  }
  ${StoreFragmentDoc}
`;

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoresQuery(
  baseOptions?: Apollo.QueryHookOptions<StoresQuery, StoresQueryVariables>
) {
  return Apollo.useQuery<StoresQuery, StoresQueryVariables>(
    StoresDocument,
    baseOptions
  );
}
export function useStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoresQuery, StoresQueryVariables>
) {
  return Apollo.useLazyQuery<StoresQuery, StoresQueryVariables>(
    StoresDocument,
    baseOptions
  );
}
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>;
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>;
export type StoresQueryResult = Apollo.QueryResult<
  StoresQuery,
  StoresQueryVariables
>;
export const VoucherDocument = gql`
  query voucher($code: String!) {
    voucher(code: $code) {
      ...Voucher
    }
  }
  ${VoucherFragmentDoc}
`;

/**
 * __useVoucherQuery__
 *
 * To run a query within a React component, call `useVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVoucherQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVoucherQuery(
  baseOptions: Apollo.QueryHookOptions<VoucherQuery, VoucherQueryVariables>
) {
  return Apollo.useQuery<VoucherQuery, VoucherQueryVariables>(
    VoucherDocument,
    baseOptions
  );
}
export function useVoucherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VoucherQuery, VoucherQueryVariables>
) {
  return Apollo.useLazyQuery<VoucherQuery, VoucherQueryVariables>(
    VoucherDocument,
    baseOptions
  );
}
export type VoucherQueryHookResult = ReturnType<typeof useVoucherQuery>;
export type VoucherLazyQueryHookResult = ReturnType<typeof useVoucherLazyQuery>;
export type VoucherQueryResult = Apollo.QueryResult<
  VoucherQuery,
  VoucherQueryVariables
>;
export const VouchersDocument = gql`
  query vouchers {
    vouchers {
      ...Voucher
    }
  }
  ${VoucherFragmentDoc}
`;

/**
 * __useVouchersQuery__
 *
 * To run a query within a React component, call `useVouchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVouchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVouchersQuery({
 *   variables: {
 *   },
 * });
 */
export function useVouchersQuery(
  baseOptions?: Apollo.QueryHookOptions<VouchersQuery, VouchersQueryVariables>
) {
  return Apollo.useQuery<VouchersQuery, VouchersQueryVariables>(
    VouchersDocument,
    baseOptions
  );
}
export function useVouchersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VouchersQuery,
    VouchersQueryVariables
  >
) {
  return Apollo.useLazyQuery<VouchersQuery, VouchersQueryVariables>(
    VouchersDocument,
    baseOptions
  );
}
export type VouchersQueryHookResult = ReturnType<typeof useVouchersQuery>;
export type VouchersLazyQueryHookResult = ReturnType<
  typeof useVouchersLazyQuery
>;
export type VouchersQueryResult = Apollo.QueryResult<
  VouchersQuery,
  VouchersQueryVariables
>;
export const UsersDocument = gql`
  query users {
    users {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
