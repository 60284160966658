import React, { useState } from 'react';
import { Order } from 'models';
import { Button, Heading, Pane } from 'evergreen-ui';
import { Log } from './Log';

interface ChangeLogsProps {
  order: Order;
}

export const ChangeLogs: React.FC<ChangeLogsProps> = ({ order }) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  return (
    <>
      <Pane marginTop={40}>
        <Pane display="flex" paddingBottom={5}>
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600} fontWeight={300}>
              Change Logs
            </Heading>
          </Pane>
        </Pane>

        <Pane
          elevation={1}
          marginTop={5}
          borderRadius={6}
          display="flex"
          flexDirection="column"
          backgroundColor="#fdfdfd"
        >
          {order.timelines.length < 1 && (
            <Pane
              flex={1}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Heading
                size={100}
                marginTop={10}
                marginBottom={10}
                textAlign="center"
              >
                No notes.
              </Heading>
            </Pane>
          )}

          {order.timelines.map((log, i) => {
            if (!showAll && i > 1) return null;

            return <Log key={log.id} timeline={log} />;
          })}

          {order.timelines.length > 3 && (
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="#f9f9f9"
              paddingY={8}
            >
              <Button
                intent="none"
                appearance="minimal"
                height={24}
                marginRight={5}
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? 'Hide' : 'Show More'}
              </Button>
            </Pane>
          )}
        </Pane>
      </Pane>
    </>
  );
};
