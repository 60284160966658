import React from 'react';
import dateFormat from 'date-fns/format';
import { Order, OrderItem } from 'models';
import { FulfillmentStatusBadge } from 'components/FulfillmentStatusBadge';
import { Dialog, Pane, Table, Link } from 'evergreen-ui';
import PrintfulIcon from 'static/icons/printful-logo.png';
import TwinkleInTimeIcon from 'static/twinkleintime/logo-color.png';

export interface FulfillmentListProps {
  item: OrderItem;
  order: Order;
  onClose: () => void;
}

export const FulfillmentListDialog: React.FC<FulfillmentListProps> = ({
  item,
  order,
  onClose = () => {},
}) => {
  let fulfillments = order.fulfillments.filter((fulfillment) =>
    fulfillment.items.includes(item.id)
  );
  fulfillments = fulfillments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <Dialog
      isShown={true}
      hasCancel={false}
      title="Fulfillment Status History"
      onConfirm={onClose}
      onCloseComplete={onClose}
      confirmLabel="Close"
      width="80%"
      topOffset="6vmin"
    >
      <Pane display="flex">
        <Table flex={1}>
          <Table.Head>
            <Table.HeaderCell>Set Time</Table.HeaderCell>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              Status
            </Table.HeaderCell>
          </Table.Head>

          <Table.VirtualBody height={400}>
            {fulfillments.map((fulfillment) => (
              <Table.Row key={fulfillment.id}>
                <Table.Cell>
                  {dateFormat(
                    new Date(fulfillment.updatedAt),
                    'E, MMM do h:mm a'
                  )}
                </Table.Cell>

                {fulfillment.source !== 'SHIPSTATION' ? (
                  <Table.Cell>
                    <Link
                      target="_blank"
                      href={`https://www.printful.com/dashboard/default/orders?order_id=${fulfillment.meta.id}`}
                    >
                      {fulfillment.id}
                    </Link>
                  </Table.Cell>
                ) : (
                  <Table.Cell>{fulfillment.id}</Table.Cell>
                )}

                <Table.Cell
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <img
                    src={
                      fulfillment.source === 'PRINTFUL'
                        ? PrintfulIcon
                        : TwinkleInTimeIcon
                    }
                    height={fulfillment.source === 'PRINTFUL' ? 25 : 13}
                    alt=""
                    style={{ marginRight: 15 }}
                  />
                  <FulfillmentStatusBadge status={fulfillment.status} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.VirtualBody>
        </Table>
      </Pane>
    </Dialog>
  );
};

export default FulfillmentListDialog;
