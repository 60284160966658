import React from 'react';
import { connect } from 'redux-zero/react';
import { StoreProps } from 'lib/store';
import { Icon, Table } from 'evergreen-ui';
import type { OrdersPageFilters } from '../../types';

interface HeaderProps {
  filters: OrdersPageFilters;
  setFilters: (args: Partial<OrdersPageFilters>) => void;
}

export const Header: React.FC<HeaderProps> = ({ filters, setFilters }) => {
  const sortDateOrder = filters?.sort?.paidAt ?? -1;

  const sortArrow = (sortOrder: number) => (
    <Icon
      size={14}
      icon={sortOrder === 1 ? 'caret-up' : 'caret-down'}
      color="muted"
      marginTop={1}
      marginLeft={5}
    />
  );

  return (
    <Table.Head borderRadius={6} elevation={1}>
      <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
      <Table.TextHeaderCell>Order</Table.TextHeaderCell>
      <Table.HeaderCell
        flex={0}
        flexBasis={120}
        width={120}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          setFilters({ sort: { paidAt: sortDateOrder === -1 ? 1 : -1 } })
        }
      >
        Date {sortArrow(sortDateOrder)}
      </Table.HeaderCell>
      <Table.HeaderCell flex={0} flexBasis={140} width={140}>
        Amount
      </Table.HeaderCell>
      <Table.HeaderCell flex={0} flexBasis={120} width={120}>
        Due
      </Table.HeaderCell>
      <Table.HeaderCell
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexBasis={260}
        width={260}
        flex={0}
      >
        Status
      </Table.HeaderCell>
    </Table.Head>
  );
};

export default connect(
  (store: StoreProps) => ({ filters: store.ordersPage.ordersTable.filters }),
  () => ({
    setFilters: (state, filters) => ({
      ordersPage: {
        ordersTable: {
          filters: { ...state.ordersPage.ordersTable.filters, ...filters },
        },
      },
    }),
  })
)(Header);
