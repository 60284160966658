import React from 'react';
import type { FulfillmentItem } from 'models';
import { Item } from '../Item';
import { Container } from './Table.styles';

interface TableProps {
  fulfillments: FulfillmentItem[];
  setFulfillment: any;
}

export const Table: React.FC<TableProps> = ({
  fulfillments,
  setFulfillment,
}) => (
  <Container>
    {fulfillments.map((fulfillment) => (
      <Item
        {...fulfillment}
        key={fulfillment.item.id}
        setFulfillment={setFulfillment}
      />
    ))}
  </Container>
);
