import { BaseOrder, Order } from 'models';
import {
  ORDER_FILTERS,
  OrderFilterType,
} from 'components/OrderFilterSelect/filter-types';

export const filterOrders = (
  orders: BaseOrder[],
  filters: OrderFilterType[]
): BaseOrder[] =>
  orders.filter((order) => {
    if (!order?.paidAt) return false;

    let hasFilterProperty = true;

    const appliedFilters = ORDER_FILTERS.filter((filter) =>
      filters.includes(filter.type)
    );

    appliedFilters.forEach((filter) => {
      if (!filter.filterFunction(order as Order)) hasFilterProperty = false;
    });

    return hasFilterProperty;
  });
