import React, { useRef, useState } from 'react';
import { RoleAccess } from 'lib/auth';
import { Order } from 'models';
import { UserAgent } from './UserAgent';
import { EditAddressModal } from 'components/EditAddressModal';
import { Button, Heading, Pane, toaster, IconButton } from 'evergreen-ui';
import { Textarea } from './styles';

interface CustomerInfoProps {
  order: Order;
}

export const CustomerInfo: React.FC<CustomerInfoProps> = ({ order }) => {
  const shippingAddressRef = useRef<HTMLTextAreaElement>();
  const [isAddressModalOpen, setAddressModalOpen] = useState<boolean>(false);

  if (!order) return null;

  return (
    <>
      <Pane marginTop={40}>
        <Pane display="inline-flex" paddingBottom={5}>
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600} fontWeight={300}>
              Customer
            </Heading>
          </Pane>

          <RoleAccess permission="orders:edit">
            <Pane flex={1} display="flex" alignItems="center" marginLeft={16}>
              <Button height={28} onClick={() => setAddressModalOpen(true)}>
                Edit Info
              </Button>
            </Pane>
          </RoleAccess>
        </Pane>

        <Pane
          display="flex"
          marginTop={8}
          flexDirection="row"
          elevation={1}
          backgroundColor="#fbfbfb"
          borderRadius={6}
          padding={16}
        >
          <Pane
            flex={1}
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            marginBottom={5}
          >
            <Heading size={100} marginBottom={5}>
              Full Name
            </Heading>

            <Heading size={500}>{order.fullName}</Heading>
          </Pane>

          <Pane
            flex={1}
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            marginBottom={5}
          >
            <Heading size={100} marginBottom={5}>
              Device
            </Heading>

            <UserAgent userAgent={order?.meta?.userAgent} />
          </Pane>

          <Pane
            flex={1}
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            marginBottom={20}
          >
            <Heading size={100} marginBottom={5}>
              Contact
            </Heading>

            <Heading size={500} marginBottom={10}>
              {order.email}
            </Heading>
            <Heading size={500}>{order.getFormattedPhone()}</Heading>
          </Pane>

          <Pane
            flex={1}
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
            marginBottom={20}
          >
            <Pane
              flex={1}
              alignItems="center"
              display="flex"
              flexDirection="row"
              marginBottom={10}
            >
              <Heading size={100} marginRight={15}>
                Shipping Address
              </Heading>

              <IconButton
                height={24}
                appearance="minimal"
                icon="duplicate"
                onClick={(e) => {
                  shippingAddressRef?.current?.select();

                  document.execCommand('copy');
                  e.currentTarget.focus();

                  toaster.success('Copied to clipboard.');
                }}
              />
            </Pane>

            {order.shippingAddress && (
              <Textarea
                readOnly={true}
                ref={shippingAddressRef}
                name="shippingAddress"
                value={`${order.shippingAddress.recipient || ''}\n${
                  order.shippingAddress.line1
                }\n${
                  order.shippingAddress.line2
                    ? `${order.shippingAddress.line2}\n`
                    : ''
                }${order.shippingAddress.city}, ${
                  order.shippingAddress.state
                }, ${order.shippingAddress.postalCode}\n${
                  order.shippingAddress.country
                }`}
              />
            )}
          </Pane>
        </Pane>
      </Pane>

      {isAddressModalOpen && (
        <EditAddressModal
          order={order}
          onClose={() => setAddressModalOpen(false)}
        />
      )}
    </>
  );
};
