import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import formatDate from 'date-fns/format';
import { Order } from 'models';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { Loader } from 'components/Loader';
import {
  orderToWords,
  customerApprovedIcon,
  printErgency,
  shippingUpgrade,
  voucherCode,
  hasHangerFrame,
  isRushOrder,
  getShopIcon,
} from 'pages/orders/components/Table/utils';
import {
  Avatar,
  Heading,
  Pane,
  Position,
  Table,
  Text,
  Tooltip,
} from 'evergreen-ui';
import {
  OrdersTableFilters,
  OrdersTableFiltersProps,
} from './OrdersTableFilters';

export interface OrdersTableProps {
  loading: boolean;
  filters: OrdersTableFiltersProps['filters'];
  orders: Order[];
  onFilterApply: OrdersTableFiltersProps['onFilterApply'];
}

export const OrdersTable: React.FC<OrdersTableProps> = memo(
  ({ filters, loading, orders, onFilterApply }) => {
    const history = useHistory();

    return (
      <>
        <OrdersTableFilters filters={filters} onFilterApply={onFilterApply} />

        <Table borderRadius={6} paddingBottom="40px">
          <Table.Head borderRadius={6} elevation={1}>
            <Table.TextHeaderCell>Customer</Table.TextHeaderCell>
            <Table.TextHeaderCell>Order</Table.TextHeaderCell>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.HeaderCell flex={0} flexBasis={130} width={130}>
              Amount
            </Table.HeaderCell>
            <Table.HeaderCell flex={0} flexBasis={140} width={140}>
              Due
            </Table.HeaderCell>
            <Table.HeaderCell
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexBasis={140}
              width={140}
              flex={0}
            >
              Status
            </Table.HeaderCell>
          </Table.Head>

          <Table.Body>
            {loading && <Loader />}
            {!loading && orders.length === 0 && (
              <Pane
                is="section"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height={200}
                marginTop={10}
              >
                <Heading size={100}>No orders found.</Heading>
              </Pane>
            )}

            {!loading &&
              orders.length > 0 &&
              orders.map((order) => {
                const deliveryDueDate = order.getDeliveryDueDate();

                return (
                  <Table.Row
                    key={order.id}
                    isSelectable
                    onSelect={() => history.push(`/orders/${order.id}`)}
                  >
                    <Table.Cell
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Text>{getShopIcon(order)}</Text>
                      <Tooltip content={order.email} position={Position.LEFT}>
                        <Avatar name={order.fullName} size={28} />
                      </Tooltip>
                      <Text marginLeft={12} size={300} fontWeight={500}>
                        {order.fullName}
                      </Text>
                    </Table.Cell>

                    <Table.Cell display="flex" alignItems="center">
                      {orderToWords(order)}
                      {printErgency(order)}
                      {hasHangerFrame(order)}
                    </Table.Cell>

                    <Table.Cell display="flex" alignItems="center">
                      <Tooltip
                        content={formatDate(
                          new Date(order.paidAt),
                          'E, MMM do yyyy h:mm a'
                        )}
                        position={Position.TOP}
                      >
                        <Text size={300}>
                          {formatDate(
                            new Date(order.paidAt),
                            'E, MMM do h:mm a'
                          )}
                        </Text>
                      </Tooltip>
                    </Table.Cell>

                    <Table.Cell
                      display="flex"
                      alignItems="center"
                      flexBasis={130}
                      width={130}
                      flex={0}
                    >
                      {order.formattedTotal}
                      {voucherCode(order)}
                      {shippingUpgrade(order)}
                      {customerApprovedIcon(order)}
                      {isRushOrder(order)}
                    </Table.Cell>

                    <Table.Cell
                      display="flex"
                      alignItems="center"
                      flexBasis={140}
                      width={140}
                      flex={0}
                    >
                      <Text size={300}>
                        {deliveryDueDate
                          ? formatDate(deliveryDueDate, 'E, MMM do')
                          : '-'}
                      </Text>
                    </Table.Cell>

                    <Table.Cell
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      flexBasis={140}
                      width={140}
                      flex={0}
                    >
                      <OrderStatusBadge status={order.status} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </>
    );
  }
);

export default OrdersTable;
