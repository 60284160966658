import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { useQuery, useMutation } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import { client, EDIT_LINK, FETCH_LINK, FETCH_LINKS } from './api';
import {
  Button,
  Heading,
  Pane,
  Icon,
  TextInputField,
  Label,
  Spinner,
  toaster,
  Textarea,
  TextInput,
} from 'evergreen-ui';

export const SplitTestsPageEdit: React.FC<
  RouteComponentProps<{ id: string }>
> = ({ history, match: { params = {} } = {} }) => {
  const { data, loading }: any = useQuery<any>(FETCH_LINK, {
    client,
    variables: { id: params.id },
  });
  const [state, setState] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [editLink] = useMutation(EDIT_LINK, {
    client,
    variables: {
      id: params.id,
      input: { ...state },
    },
    refetchQueries: [{ query: FETCH_LINKS }],
  });

  const updateLink = (index, key, event) => {
    const data = [...state.links];
    data[index][key] =
      key === 'weight' ? parseInt(event.target.value) : event.target.value;

    setState({ ...state, links: data });
  };

  useEffect(() => {
    if (data?.link) {
      const { name, description, links, notes } = data.link;

      setState({ name, description, links, notes });
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>Split Tests | TwinkleInTime</title>
      </Helmet>

      {loading && !state && <Spinner />}
      {!loading && state && data && data.link && (
        <Fade key="SplitTests" bottom distance="15px" duration={400}>
          <Pane>
            <Pane display="flex" marginBottom={30}>
              <Pane flex={1} alignItems="center" display="flex">
                <Button
                  iconBefore="arrow-left"
                  onClick={() => history.push('/links')}
                >
                  Back
                </Button>
              </Pane>
              <Pane
                flex={1}
                alignItems="flex-end"
                justifyContent="flex-end"
                display="flex"
              >
                <Button
                  isLoading={isLoading}
                  appearance="primary"
                  intent="success"
                  onClick={async () => {
                    setLoading(true);
                    try {
                      await editLink();

                      toaster.success('Successfully add split test.');
                      history.push('/links');
                    } catch (e) {
                      toaster.danger(e.message);
                      setLoading(false);
                    }
                  }}
                >
                  Save
                </Button>
              </Pane>
            </Pane>
            <Pane display="flex" marginBottom={20}>
              <Pane flex={1} alignItems="center" display="flex">
                <Heading size={600} style={{ fontWeight: '400' }}>
                  Add Split Test
                </Heading>
              </Pane>
            </Pane>
            <Pane display="flex" flexDirection="row" flex={1}>
              <TextInputField
                flex={1}
                inputHeight={42}
                label="Name"
                placeholder="Name"
                value={state.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setState({ ...state, name: e.target.value })
                }
              />
            </Pane>
            <Pane display="flex" flexDirection="column" flex={1}>
              <Label htmlFor="description" marginBottom={4} display="block">
                Descripton
              </Label>
              <Textarea
                id="description"
                placeholder="Description"
                value={state.description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setState({ ...state, description: e.target.value })
                }
              />
            </Pane>
            <Pane display="flex" flexDirection="column" flex={1} marginTop={20}>
              <Label htmlFor="notes" marginBottom={4} display="block">
                Notes
              </Label>
              <Textarea
                id="notes"
                placeholder="Notes"
                value={state.notes}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setState({ ...state, notes: e.target.value })
                }
              />
            </Pane>
            <Heading size={500} marginTop={30} marginBottom={20}>
              Links
            </Heading>
            {state.links.map((_, index) => (
              <>
                <Pane
                  display="flex"
                  flexDirection="row"
                  flex={1}
                  marginBottom={10}
                >
                  <Pane display="flex" flex={1} paddingRight={5}>
                    <TextInput
                      flex={1}
                      height={42}
                      placeholder="https://twinkleintime.com/?blah=blah"
                      value={state.links[index].url}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateLink(index, 'url', e)
                      }
                    />
                  </Pane>
                  <Pane flex={0} flexBasis={40} paddingRight={5}>
                    <TextInput
                      flex={1}
                      height={42}
                      width="auto"
                      placeholder="50"
                      value={state.links[index].weight}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateLink(index, 'weight', e)
                      }
                    />
                  </Pane>
                  <Pane
                    flex={0}
                    flexBasis={30}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {false && (
                      <Icon
                        icon="cross"
                        cursor="pointer"
                        onClick={() =>
                          setState({
                            ...state,
                            links: [
                              ...state.links.filter((_, i) => i !== index),
                            ],
                          })
                        }
                      />
                    )}
                  </Pane>
                </Pane>
              </>
            ))}

            <Pane
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop={20}
            >
              <Button
                onClick={() =>
                  setState({
                    ...state,
                    links: [...state.links, { url: '', weight: '' }],
                  })
                }
              >
                Add Link
              </Button>
            </Pane>
          </Pane>
        </Fade>
      )}
    </>
  );
};
