import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Loader } from 'components/Loader';
import { fetchImageRetry } from './utils';
import { ImageContainer } from './Print.styles';

interface ImageProps {
  src: string;
  isRegenerating: boolean;
}

export const Image: React.FC<ImageProps> = ({
  src,
  isRegenerating = false,
}) => {
  const [error, setError] = useState<boolean>(false);
  const [hasLoaded, setLoaded] = useState<boolean>(false);
  const [retrySuccess, setRetrySuccess] = useState<boolean>(false);

  const onError = async () => {
    try {
      await fetchImageRetry(src, { maxTimeout: 3200, retries: 10 });

      setRetrySuccess(true);
    } catch (e) {
      setError(true);
      Sentry.withScope(function (scope) {
        scope.setTag('image', src);
        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureException(new Error('Error Rendering Image'));
      });
    }
  };

  if (isRegenerating) {
    return (
      <ImageContainer>
        <Loader />
      </ImageContainer>
    );
  }

  return (
    <ImageContainer hasLoaded={hasLoaded}>
      {!hasLoaded && !error && <Loader />}

      {error && (
        <h4>Oops, something borked. Message skeen with the order ID.</h4>
      )}

      <img
        height="620px"
        width="auto"
        src={src}
        alt={src}
        key={`${src}_${retrySuccess.toString()}`}
        onLoad={() => {
          setLoaded(true);
          setError(false);
        }}
        onError={onError}
        onContextMenu={(e) => e.preventDefault()}
      />
    </ImageContainer>
  );
};
