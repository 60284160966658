import React from 'react';

export const Logo: React.FC = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 600.000000 357.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,357.000000) scale(0.100000,-0.100000)"
      fill="#000044"
      stroke="none"
    >
      <path
        d="M2566 3495 c-165 -31 -330 -100 -476 -198 -79 -53 -250 -214 -250
-235 0 -33 35 -18 121 52 351 281 786 364 1204 229 316 -102 579 -329 740
-638 55 -106 61 -113 77 -97 28 28 -78 261 -179 397 -137 184 -347 345 -554
425 -142 55 -236 71 -424 76 -132 2 -201 -1 -259 -11z"
      />
      <path
        d="M1720 3426 c-377 -113 -637 -488 -899 -1295 l-57 -173 -25 38 c-47
71 -56 89 -84 164 -25 66 -29 89 -29 190 0 137 16 206 78 332 62 123 43 140
-56 52 -95 -84 -160 -194 -194 -325 -22 -85 -22 -243 0 -329 24 -91 95 -232
161 -319 l56 -74 -32 -81 c-86 -216 -215 -456 -323 -599 -61 -81 -174 -194
-223 -222 -18 -11 -33 -25 -33 -32 0 -54 219 2 353 91 160 106 335 350 440
613 l16 42 88 -65 c49 -37 122 -87 163 -112 l76 -46 17 -143 c36 -293 108
-494 213 -593 79 -75 149 -104 249 -105 97 0 153 24 214 90 67 72 85 169 56
293 -47 198 -152 338 -380 508 l-115 85 0 173 c0 240 18 814 30 975 30 383
121 602 339 816 28 27 51 55 51 62 0 20 -64 15 -150 -11z m-511 -1026 c-6 -79
-23 -449 -32 -733 l-2 -67 -100 77 c-55 43 -100 83 -100 88 0 10 239 734 241
731 1 0 -2 -44 -7 -96z m505 -1465 c114 -114 156 -237 112 -323 -49 -95 -178
-94 -247 1 -56 78 -80 178 -104 436 l-6 73 92 -63 c51 -35 120 -91 153 -124z"
      />
      <path
        d="M3360 2814 c0 -78 -2 -85 -17 -79 -103 42 -192 15 -232 -69 -37 -75
-23 -160 34 -216 28 -27 44 -34 87 -38 35 -3 67 1 91 11 20 9 37 12 37 6 0 -5
25 -9 55 -9 l55 0 0 240 0 240 -55 0 -55 0 0 -86z m-17 -172 c24 -21 23 -93
-1 -115 -55 -50 -134 -16 -134 56 0 22 7 46 17 57 28 31 83 32 118 2z"
      />
      <path
        d="M1712 2783 c-22 -18 -23 -21 -33 -424 -6 -223 -9 -502 -7 -620 l3
-214 30 -60 c39 -78 78 -105 153 -105 76 0 126 17 176 61 l42 37 28 -35 c37
-46 81 -63 158 -63 71 0 128 22 169 64 l27 26 16 -25 c52 -79 249 -90 329 -17
47 42 86 177 87 299 l0 52 32 -10 c18 -5 57 -9 85 -9 l53 0 0 -50 c0 -105 39
-216 96 -274 96 -96 275 -84 354 25 l27 38 24 -36 c41 -62 71 -77 158 -81 89
-4 154 17 187 60 l18 25 25 -30 c35 -41 79 -57 158 -57 73 0 135 22 172 59
l21 21 0 -35 c0 -34 -1 -35 -228 -170 -125 -75 -257 -161 -293 -193 -128 -110
-216 -235 -267 -374 -24 -64 -27 -88 -27 -193 0 -110 2 -125 27 -178 37 -80
126 -170 205 -209 112 -55 263 -50 385 11 158 80 293 240 359 427 50 141 61
225 67 504 l5 265 71 52 c257 189 378 343 437 558 25 94 28 241 5 328 -47 175
-177 306 -363 363 -72 23 -96 26 -185 22 -89 -3 -112 -8 -168 -34 -68 -32
-180 -126 -180 -150 0 -24 28 -27 57 -6 80 56 188 85 295 81 70 -3 94 -9 150
-37 83 -41 158 -120 189 -200 27 -69 36 -204 20 -284 -27 -129 -123 -275 -260
-397 l-71 -63 0 275 c0 233 -2 278 -15 291 -31 30 -179 33 -204 3 -5 -7 -14
-120 -20 -252 -13 -299 -32 -375 -97 -375 -56 0 -74 101 -74 411 0 152 -3 199
-14 214 -12 17 -27 20 -100 20 -64 0 -89 -4 -101 -16 -13 -13 -17 -60 -24
-276 -7 -247 -9 -263 -32 -309 -29 -58 -56 -65 -79 -19 -12 24 -16 84 -18 307
-4 320 1 305 -103 314 -72 7 -118 -6 -126 -35 -5 -18 -6 -18 -39 4 -118 80
-306 -4 -380 -171 -13 -30 -28 -54 -33 -54 -5 0 -22 15 -39 34 -30 34 -45 93
-38 149 7 58 -52 87 -149 73 -61 -10 -77 -36 -83 -134 -3 -57 0 -87 12 -116
28 -68 17 -297 -19 -366 -35 -67 -81 -38 -95 61 -5 35 -10 168 -10 295 0 182
-3 234 -14 243 -7 6 -40 14 -73 17 -74 8 -131 -8 -137 -39 -3 -12 -7 -121 -10
-243 -6 -236 -16 -308 -47 -349 -26 -32 -44 -32 -66 2 -16 24 -18 59 -21 309
-4 337 3 319 -123 319 -116 0 -113 6 -121 -228 -8 -248 -27 -364 -66 -409 -17
-20 -21 -21 -40 -9 -29 18 -31 94 -22 691 9 538 8 606 -5 620 -11 10 -41 16
-93 18 -64 3 -81 1 -100 -15z m1713 -769 c48 -19 92 -192 81 -319 -16 -190
-90 -280 -153 -186 -37 55 -48 108 -48 236 0 133 17 203 60 249 25 27 33 30
60 20z m872 -1091 c-5 -178 -9 -222 -31 -306 -58 -225 -187 -401 -334 -457
-65 -25 -115 -25 -168 -1 -132 60 -162 265 -68 454 36 73 124 185 182 235 42
35 410 281 421 282 2 0 1 -93 -2 -207z"
      />
      <path
        d="M2256 2750 c-63 -20 -116 -100 -116 -175 0 -57 31 -118 72 -143 42
-26 115 -29 158 -7 17 9 30 11 30 5 0 -6 24 -10 55 -10 l55 0 0 160 0 160 -50
0 c-38 0 -50 -4 -50 -15 0 -13 -5 -12 -28 5 -33 24 -86 33 -126 20z m126 -107
c28 -25 25 -107 -4 -127 -54 -38 -118 -2 -118 66 0 48 28 78 72 78 17 0 40 -7
50 -17z"
      />
      <path
        d="M2826 2750 c-16 -5 -39 -19 -52 -31 l-24 -22 0 21 c0 20 -5 22 -50
22 l-50 0 0 -160 0 -160 55 0 55 0 0 80 c0 97 17 146 54 156 48 12 56 -7 56
-127 l0 -109 55 0 55 0 0 133 c0 148 -7 168 -66 193 -37 16 -47 16 -88 4z"
      />
      <path
        d="M5809 2413 c-1 -5 -2 -55 -3 -113 -1 -127 -18 -181 -87 -284 l-49
-74 -87 88 c-65 65 -99 91 -128 99 -52 14 -171 13 -213 -2 l-33 -12 -4 -90
c-11 -226 -105 -426 -292 -624 -45 -49 -83 -95 -83 -101 0 -18 55 -5 107 25
94 56 203 148 252 213 64 84 127 211 150 302 l17 69 46 -47 c87 -90 86 -86 16
-160 -76 -82 -108 -148 -108 -224 0 -68 12 -103 48 -142 37 -39 81 -56 150
-56 110 0 218 81 262 196 26 71 27 197 1 271 l-18 53 24 22 c41 38 112 152
138 222 31 81 32 152 5 226 -33 89 -104 181 -111 143z m-260 -898 c1 -99 -21
-145 -68 -145 -33 0 -71 39 -71 72 0 36 31 94 78 141 56 57 60 52 61 -68z"
      />
      <path
        d="M2164 1028 c-17 -26 121 -99 270 -142 257 -75 534 -52 774 65 78 38
102 54 102 69 0 25 0 25 -117 -5 -170 -44 -276 -58 -453 -58 -178 0 -298 15
-456 58 -105 29 -110 29 -120 13z"
      />
    </g>
  </svg>
);
