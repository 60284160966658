import React, { useState } from 'react';
import { Order } from 'models';
import {
  Dialog,
  Pane,
  TextareaField,
  TextInputField,
  toaster,
} from 'evergreen-ui';

interface CancelOrderModalProps {
  order: Order;
  onClose: () => void;
}

export const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  order,
  onClose = () => {},
}) => {
  const [reason, setReason] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  return (
    <Dialog
      width={520}
      isShown={true}
      intent="danger"
      title="Cancel Order"
      confirmLabel={isLoading ? 'Loading...' : 'Cancel Order'}
      isConfirmLoading={isLoading}
      onCloseComplete={onClose}
      onConfirm={async () => {
        setLoading(true);

        const res = await order.cancel(reason, comments);

        if (!res.ok) {
          toaster.danger(res.error?.message);
          setLoading(false);
          return;
        }

        toaster.success('Order has been cancelled.');

        setLoading(false);
        onClose();
      }}
    >
      <Pane display="flex" flexDirection="column">
        <TextInputField
          width="100%"
          inputHeight={42}
          label="Reason"
          placeholder="Reason"
          marginBottom={12}
          value={reason}
          disabled={isLoading}
          onChange={(e) => setReason(e.target.value)}
        />

        <TextareaField
          width="100%"
          label="Comments"
          placeholder="Comments"
          rows={6}
          value={comments}
          disabled={isLoading}
          onChange={(e) => setComments(e.target.value)}
        />
      </Pane>
    </Dialog>
  );
};
