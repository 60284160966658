import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import {
  Alert,
  Pane,
  Paragraph,
  Button,
  IconButton,
  ArrowDownIcon,
} from 'evergreen-ui';

interface Props {
  onClick: () => void;
}

export const OtherPendingCancelAlert: React.FC<Props> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(true);
  const pathname = useHistory().location.pathname;
  const ready = pathname.includes('/ready');

  if (!isOpen) {
    return null;
  }

  return (
    <Pane marginTop={10}>
      <Alert
        appearance="card"
        intent="warning"
        title="Another Order Is Pending Cancellation"
        paddingRight={36}
      >
        <Paragraph marginRight={36} marginTop={5}>
          This customer has requested to cancel a previous order. They may be
          trying to cancel a full-price order before it is fulfilled and replace
          it with a discounted order.{' '}
          {ready
            ? "Go to this order's page to see other orders by this customer."
            : 'Check the Other Orders section below for orders with a status labeled: '}
          {!ready && <OrderStatusBadge status="PENDING_CANCEL" />}
        </Paragraph>
        <Button style={{ marginTop: '10px' }} onClick={onClick}>
          {ready ? 'Go to Order Page' : 'Go to Other Orders'}
          {ready ? null : <ArrowDownIcon marginLeft={8} size={14} />}
        </Button>
        <IconButton
          icon="cross"
          cursor="pointer"
          position="absolute"
          top={12}
          right={12}
          onClick={() => setIsOpen(false)}
        />
      </Alert>
    </Pane>
  );
};
