import React, { useState } from 'react';
import { CustomPrintEditorModal } from 'components/CustomPrintEditorModal';
import { composePrintMeta } from 'models/OrderItem/utils/composePrintMeta';
import { formatCurrency } from 'lib/utils';
import { RoleAccess } from 'lib/auth';
import { Product } from 'lib/api/ecommerce/schema';
import { Order } from 'models';
import { OrderPageCartTableItem } from './Item';
import EditShippingModal from './Item/modals/EditShipping';
import { AddItemsModal } from 'components/AddItemsModal';
import {
  Button,
  Heading,
  IconButton,
  Menu,
  Pane,
  Popover,
  Position,
  Table,
  toaster,
} from 'evergreen-ui';

interface OrderTableProps {
  order: Order;
}

export const OrderTable: React.FC<OrderTableProps> = ({ order }) => {
  const [error, setError] = useState<string>(null!);
  const [isAddingItem, setIsAddingItem] = useState<boolean>(false);
  const [isAddItemsModalOpen, setAddItemsModalOpen] = useState<boolean>(false);
  const [isShippingModalOpen, setShippingModalOpen] = useState<boolean>(false);
  const [activeProduct, setActiveProduct] = useState<any>(null!);
  const [isCustomPrintModalOpen, setCustomPrintModalOpen] = useState<boolean>(
    false
  );

  return (
    <>
      <Pane marginTop={40}>
        <Pane paddingBottom={15} display="inline-flex" flexDirection="row">
          <Pane>
            <Heading size={600} fontWeight={300}>
              Order
            </Heading>
          </Pane>
          <Pane marginLeft={16}>
            <RoleAccess permission="orders:items-edit">
              <Button height={32} onClick={() => setAddItemsModalOpen(true)}>
                Add Item
              </Button>
            </RoleAccess>
          </Pane>
        </Pane>

        <Pane
          display="flex"
          flexDirection="column"
          elevation={1}
          borderRadius={6}
          backgroundColor="#fdfdfd"
        >
          <Pane
            flex={1}
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
          >
            <Table width="100%">
              <Table.Head>
                <Table.TextHeaderCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                >
                  Print
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Product</Table.TextHeaderCell>
                <Table.TextHeaderCell>Quantity</Table.TextHeaderCell>
                <Table.TextHeaderCell>SKU</Table.TextHeaderCell>
                <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                <Table.HeaderCell justifyContent="flex-end">
                  Price
                </Table.HeaderCell>
                <Table.HeaderCell width={48} flex="none" />
              </Table.Head>

              <Table.Body>
                {order.items.map((item) => (
                  <OrderPageCartTableItem
                    key={item.id}
                    item={item}
                    order={order}
                  />
                ))}

                {order.getDiscount() && (
                  <Table.Row>
                    <Table.TextCell>
                      Coupon ({order.getDiscount()?.meta?.code.toUpperCase()})
                    </Table.TextCell>

                    <Table.Cell />
                    <Table.Cell />

                    <Table.Cell justifyContent="flex-end">
                      -
                      {formatCurrency(
                        Math.abs(order?.getDiscount()?.amount || 0)
                      )}
                    </Table.Cell>

                    <Table.Cell width={48} flex="none"></Table.Cell>
                  </Table.Row>
                )}

                {/* Shipping */}
                <Table.Row>
                  <Table.TextCell>
                    {order.shippingName || order.shippingMethod} Shipping
                  </Table.TextCell>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell justifyContent="flex-end">
                    {formatCurrency(order?.getShipping()?.amount ?? 0)}
                  </Table.Cell>
                  <Table.Cell width={48} flex="none">
                    <RoleAccess permission="orders:items-edit">
                      <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item
                                onSelect={() => setShippingModalOpen(true)}
                              >
                                Edit Shipping
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <IconButton
                          icon="more"
                          height={24}
                          appearance="minimal"
                        />
                      </Popover>
                    </RoleAccess>
                  </Table.Cell>
                </Table.Row>

                {/* Tax */}
                <Table.Row>
                  <Table.TextCell>Tax</Table.TextCell>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell justifyContent="flex-end">
                    {formatCurrency(order.taxAmount)} {` `} (
                    {(order.taxRate * 100).toFixed(2)}%)
                  </Table.Cell>
                  <Table.Cell width={48} flex="none" />
                </Table.Row>
              </Table.Body>
            </Table>
          </Pane>

          {/* Totals */}
          <Pane display="flex" padding={12}>
            <Pane
              flex={1}
              alignItems="flex-end"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Heading size={400}>Paid: {order.formattedPaid}</Heading>
              <Heading size={400}>Total: {order.formattedTotal}</Heading>
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      {isCustomPrintModalOpen && (
        <CustomPrintEditorModal
          order={order as any}
          product={activeProduct}
          onClose={() => setCustomPrintModalOpen(false)}
          onComplete={async (printMeta) => {
            const composedPrintMeta = composePrintMeta(printMeta);

            const res = await order.addItems([
              {
                quantity: 1,
                sku: activeProduct.sku,
                meta: composedPrintMeta,
              },
            ]);

            if (!res.ok) {
              setIsAddingItem(false);
              setError(
                'Error adding item to cart. Make sure an item is selected below and the order status is set to DRAFT.'
              );
              return;
            }

            setActiveProduct(null);
            setCustomPrintModalOpen(false);
            toaster.success(
              `Successfully added ${activeProduct.sku} to order.`
            );
            setAddItemsModalOpen(false);
            setIsAddingItem(false);
          }}
        />
      )}

      {isAddItemsModalOpen && (
        <AddItemsModal
          error={error}
          loading={isAddingItem}
          onClose={() => setAddItemsModalOpen(false)}
          onComplete={async (product: Product, quantity: number) => {
            setIsAddingItem(true);

            if (!product.meta?.isStaticPrint) {
              setActiveProduct(product);

              setCustomPrintModalOpen(true);
            } else {
              const res = await order.addItems([
                { quantity: 1, meta: null, sku: product.sku },
              ]);
              if (!res.ok) {
                setIsAddingItem(false);
                setError(
                  'Error adding item to cart. Make sure an item is selected below and the order status is set to DRAFT.'
                );
                return;
              }

              toaster.success(`Successfully added ${product.sku} to order.`);
              setAddItemsModalOpen(false);
              setIsAddingItem(false);
            }
          }}
        />
      )}

      {isShippingModalOpen && (
        <EditShippingModal
          order={order}
          onClose={() => setShippingModalOpen(false)}
        />
      )}
    </>
  );
};
