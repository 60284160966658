import { Type } from 'class-transformer';
import { Transaction } from 'lib/api/ecommerce/schema';

export class BaseTransaction {
  public id!: Transaction['id'];
  public source!: Transaction['source'];
  public amount!: Transaction['amount'];
  public type!: Transaction['type'];

  @Type(() => Date)
  public createdAt;
}

export default BaseTransaction;
