import { useMemo } from 'react';
import { plainToClass } from 'class-transformer';
import { useCartAndTimelinesQuery } from 'lib/api/ecommerce/schema';
import { Order } from 'models';

interface UseOrderAndTimelinesByIdResult {
  order: Order | null;
  loading: boolean;
  error: any;
  refetch: any;
}

export function useOrderAndTimelinesById(
  id: string
): UseOrderAndTimelinesByIdResult {
  const { data, loading, error, refetch } = useCartAndTimelinesQuery({
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
    ssr: false,
  });

  const order = useMemo(() => {
    if (data) {
      const order = plainToClass(Order, {
        ...data.cart,
        timelines: data.timelines,
      });

      return order;
    }

    return null;
  }, [data]);

  return { order, loading, error, refetch };
}
