import React, { useRef, useState } from 'react';
import { debounce } from 'debounce';
import { connect } from 'redux-zero/react';
import { StoreProps } from 'lib/store';
import { IconButton, Pane, SearchInput, Select } from 'evergreen-ui';
import { OrderStatusSelect } from 'components/OrderStatusSelect';
import type { OrdersPageFilters } from '../../types';
import {
  OrderFilterSelect,
  OrderFilterStoreSelect,
  OrderFilterType,
} from 'components/OrderFilterSelect';

interface TableFiltersProps {
  filters: OrdersPageFilters;
  orderCount: number;
  onRefresh: () => void;
  setFilters: (args: Partial<OrdersPageFilters>) => void;
}

export const TableFilters: React.FC<TableFiltersProps> = ({
  filters,
  orderCount,
  onRefresh,
  setFilters,
}) => {
  const { limit, orderType, search, skip, status } = filters;

  const [searchState, setSearch] = useState(search);
  const setFiltersDebounce = useRef<any>(debounce((v) => setFilters(v), 800))
    .current;

  return (
    <Pane alignItems="center" display="flex">
      <SearchInput
        marginRight={12}
        placeholder="Search orders..."
        value={searchState}
        onChange={(e) => {
          setSearch(e.target.value.toLowerCase());
          setFiltersDebounce({ search: e.target.value.toLowerCase() });
        }}
      />

      <Pane>
        <OrderStatusSelect
          title={
            !status.includes('all') ? `Status (${status.length})` : 'Status'
          }
          isMultiSelect
          hasFilter={false}
          selected={status}
          onChange={(selected) => setFilters({ status: selected })}
        />
      </Pane>

      <Pane alignItems="center" display="flex">
        <Pane marginRight={10}>
          <OrderFilterSelect
            title="Filters"
            hasFilter={false}
            isMultiSelect
            exclude={[OrderFilterType.MULTIPLE_ORDERS]}
            selected={orderType as any}
            onChange={(selected) =>
              setFilters({
                orderType: selected as OrdersPageFilters['orderType'],
              })
            }
          />
        </Pane>

        <Pane marginRight={10}>
          <OrderFilterStoreSelect
            title="Filter by Store"
            hasFilter={false}
            isMultiSelect
            exclude={[OrderFilterType.MULTIPLE_ORDERS]}
            selected={orderType as any}
            onChange={(selected) =>
              setFilters({
                orderType: selected as OrdersPageFilters['orderType'],
              })
            }
          />
        </Pane>

        <Select
          marginRight={10}
          value={limit}
          onChange={(e) => setFilters({ limit: parseInt(e.target.value) })}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={250}>250</option>
          <option value={500}>500</option>
        </Select>

        <IconButton
          icon="refresh"
          iconSize={13}
          marginRight={10}
          onClick={() => onRefresh()}
        />

        <IconButton
          icon="arrow-left"
          marginRight={10}
          disabled={skip === 0}
          onClick={() => setFilters({ skip: skip - limit })}
        />

        <IconButton
          icon="arrow-right"
          disabled={limit > orderCount}
          onClick={() => setFilters({ skip: skip + limit })}
        />
      </Pane>
    </Pane>
  );
};

export default connect(
  (store: StoreProps) => ({ filters: store.ordersPage.ordersTable.filters }),
  () => ({
    setFilters: (state, filters) => ({
      ordersPage: {
        ordersTable: {
          filters: { ...state.ordersPage.ordersTable.filters, ...filters },
        },
      },
    }),
  })
)(TableFilters);
