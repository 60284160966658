import React from 'react';
import isEqual from 'react-fast-compare';
import { Pane, Select } from 'evergreen-ui';
import { formatDate } from 'lib/utils';

export class DateForm extends React.Component<any, any> {
  private months = [
    { value: 0, name: 'Month' },
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' },
  ];

  public shouldComponentUpdate(nextProps) {
    return (
      !isEqual(this.props.print.subject, nextProps.print.subject) ||
      !isEqual(this.props.print.subject.date, nextProps.print.subject.date) ||
      !isEqual(this.props.print.timezone, nextProps.print.timezone) ||
      !isEqual(this.props.print.theme, nextProps.print.theme)
    );
  }

  private getMonthDayYearFromISOString(ISODate) {
    const date = new Date(ISODate);

    return {
      day: date.getDate() + 1,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  private createYears(year) {
    const years: any[] = [];

    for (let i = 500; i >= 0; i--) {
      years.push({ name: year - i, value: year - i });
    }

    return years.reverse();
  }

  private createDays(year, month) {
    const numDays = new Date(year, month, 0).getDate();

    const days: any[] = [];
    for (let d = 1; d <= numDays; d++) {
      days.push({ name: d, value: d });
    }

    return days;
  }

  private handleDateChange({ target: { value, name } }) {
    const { print, handlePrintChange, storeTagLine } = this.props;
    const { address, lat, lon } = print.subject;

    const latDirection = lat > 0 ? 'N' : 'S';
    const lngDirection = lon > 0 ? 'E' : 'W';

    const date = { ...print.subject.date };

    date[name] = parseInt(value);

    handlePrintChange({
      subject: { date },
      theme: {
        tagline: `${storeTagLine}\n${formatDate(date)}\n${address}\n${Math.abs(
          lat
        ).toFixed(3)}°${latDirection} / ${Math.abs(lon).toFixed(
          3
        )}°${lngDirection}`,
      },
    });
  }

  public render() {
    const { handeDateChange = () => {}, print } = this.props;
    const { subject } = print;
    const { date } = subject;
    let { month, day, year } = date;

    // backwards shit
    if (typeof date === 'string') {
      const monthDayYear = this.getMonthDayYearFromISOString(date);

      month = monthDayYear.month;
      day = monthDayYear.day;
      year = monthDayYear.year;

      handeDateChange({ month, day, year });
    }

    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    return (
      <>
        <Pane width="100%" marginBottom={5} marginTop={5}>
          <Select
            name="month"
            width="100%"
            value={month}
            options={this.months}
            onChange={this.handleDateChange.bind(this)}
          >
            {this.months.map((month) => (
              <option key={month.name} value={month.value}>
                {month.name}
              </option>
            ))}
          </Select>
        </Pane>
        <Pane display="flex" flexDirection="row" width="100%">
          <Pane marginRight={5} width="100%">
            <Select
              name="day"
              width="100%"
              value={day}
              onChange={this.handleDateChange.bind(this)}
            >
              {this.createDays(
                year !== '' ? year : currentYear,
                month !== '' ? month : currentMonth
              ).map((day) => (
                <option key={`day-${day.name}`} value={day.name}>
                  {day.name}
                </option>
              ))}
            </Select>
          </Pane>
          <Pane width="100%">
            <Select
              name="year"
              width="100%"
              value={year}
              onChange={this.handleDateChange.bind(this)}
            >
              {this.createYears(currentYear + 250).map((year) => (
                <option key={`year-${year.name}`} value={year.name}>
                  {year.name}
                </option>
              ))}
            </Select>
          </Pane>
        </Pane>
      </>
    );
  }
}
