import retry from 'async-retry';
import redaxios from 'redaxios';

interface FetchImageRetryOptions {
  maxTimeout?: number;
  retries?: number;
}

export const fetchImageRetry = async (
  imageSrc: string,
  opts: FetchImageRetryOptions = {
    maxTimeout: 3000,
    retries: 10,
  }
): Promise<boolean> => {
  return await retry(
    async (bail) => {
      const res = await redaxios.get(imageSrc);

      if (res.status === 403) {
        bail(new Error('Error loading image.'));
      }

      return true;
    },
    { maxTimeout: opts.maxTimeout, retries: opts.retries }
  );
};
