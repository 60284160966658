import React, { useState } from 'react';
import { OrderStatus } from 'models';
import { useFulfillment } from '../../FulfillmentProvider';
import { QuickFulfillButton } from './QuickFulfillButton';
import { FulfillOrderModal } from 'components/FulfillOrderModal';
import { EditAddressModal } from 'components/EditAddressModal';
import {
  Button,
  Heading,
  IconButton,
  Menu,
  Pane,
  Position,
  Popover,
  toaster,
} from 'evergreen-ui';

export const TopBar: React.FC = () => {
  const {
    isNextOrderSameCustomer,
    numOrders,
    order,
    orderIndex,
    onNextOrder,
    onPrevOrder,
  } = useFulfillment();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState<boolean>(false);
  const [
    isFulfillmentSlideOverOpen,
    setFulfillmentSlideOverOpen,
  ] = useState<boolean>(false);

  const setStatus = async (
    status: string,
    closeMenu: () => void
  ): Promise<void> => {
    closeMenu();
    setLoading(true);

    if (!order) return;

    const res = await order.updateOrderStatus(status as OrderStatus);

    if (!res.ok) {
      toaster.success(res.error?.message, { duration: 2 });

      return;
    }

    toaster.success('Order status updated.', { duration: 2 });
    setLoading(false);
  };

  if (!order) return null;

  return (
    <>
      <Pane
        display="flex"
        alignItems="center"
        flexDirection="row"
        zIndex={1}
        flexShrink={0}
        borderBottom="1px solid #eee"
        backgroundColor="white"
      >
        <Pane padding={16} display="flex" flexDirection="row">
          <>
            <Button
              intent="success"
              appearance="primary"
              marginRight={16}
              onClick={() => setFulfillmentSlideOverOpen(true)}
              disabled={isLoading || order?.isFulfilled}
            >
              Fulfill
            </Button>

            <QuickFulfillButton order={order} />

            <Popover
              position={Position.BOTTOM_RIGHT}
              content={({ close }) => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => setStatus(OrderStatus.Draft, close)}
                    >
                      Draft
                    </Menu.Item>

                    <Menu.Item
                      onSelect={() => setStatus(OrderStatus.Hold, close)}
                    >
                      Hold
                    </Menu.Item>

                    {order.store !== 'andalways' && (
                      <Menu.Item
                        onSelect={() =>
                          setStatus(OrderStatus.CustomerHold, close)
                        }
                      >
                        Customer Hold
                      </Menu.Item>
                    )}

                    <Menu.Item
                      onSelect={() => setStatus(OrderStatus.Fulfilled, close)}
                    >
                      Fulfilled
                    </Menu.Item>

                    <Menu.Item
                      onSelect={() => setStatus(OrderStatus.Cancelled, close)}
                    >
                      Cancelled
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() =>
                        setStatus(OrderStatus.PendingCancel, close)
                      }
                    >
                      Pending Cancel
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              )}
            >
              <Button
                marginRight={10}
                isLoading={isLoading}
                iconAfter="caret-down"
              >
                Status
              </Button>
            </Popover>

            <Button
              marginRight={10}
              onClick={() => window.open(order.grooveUri, '_blank')}
            >
              Support Tickets
            </Button>

            <Button onClick={() => setAddressModalOpen(true)}>
              Edit Address
            </Button>

            <Heading size={300} marginLeft={20} marginTop={8}>
              {orderIndex + 1} / {numOrders}
            </Heading>
          </>
        </Pane>

        <Pane
          display="flex"
          flex={1}
          padding={16}
          justifySelf="flex-end"
          justifyContent="flex-end"
        >
          {isNextOrderSameCustomer && (
            <Heading size={300} marginRight={20} marginTop={8}>
              Next order is same customer!
            </Heading>
          )}

          <IconButton
            icon="arrow-left"
            marginRight={10}
            disabled={orderIndex === 0}
            onClick={() => onPrevOrder()}
          />

          {isNextOrderSameCustomer ? (
            <IconButton
              appearance="primary"
              intent="warning"
              icon="arrow-right"
              onClick={() => onNextOrder()}
            />
          ) : (
            <IconButton icon="arrow-right" onClick={() => onNextOrder()} />
          )}
        </Pane>
      </Pane>

      {isFulfillmentSlideOverOpen && (
        <FulfillOrderModal
          order={order}
          onClose={() => setFulfillmentSlideOverOpen(false)}
        />
      )}

      {isAddressModalOpen && (
        <EditAddressModal
          order={order}
          onClose={() => setAddressModalOpen(false)}
        />
      )}
    </>
  );
};
