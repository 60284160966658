import React from 'react';
import { Badge } from 'evergreen-ui';
import { FulfillmentStatus } from 'lib/api/ecommerce/schema';

interface FulfillmentStatusBadgeProps {
  status: string;
  style?: any;
}

export const FulfillmentStatusBadge: React.FC<FulfillmentStatusBadgeProps> = ({
  status,
  style,
}) => {
  let color = 'teal';
  let statusName = 'draft';

  switch (status) {
    case FulfillmentStatus.Pending:
      color = 'teal';
      break;
    case FulfillmentStatus.Fulfilled:
      color = 'blue';
      break;
    case FulfillmentStatus.Cancelled:
      color = 'red';
      break;
    default:
      color = 'yellow';
  }

  statusName = status;

  return (
    <Badge color={color as any} marginRight={8} style={style}>
      {statusName || 'Waiting Fulfillment'}
    </Badge>
  );
};
