import React, { useMemo } from 'react';
import { Order, OrderItem } from 'models';
import { FulfillmentStatus } from 'lib/api/ecommerce/schema';
import { Dialog, Heading, Pane, toaster } from 'evergreen-ui';

type Props = {
  item: OrderItem;
  order: Order;
  onClose: () => void;
};

export const CancelFulfillmentDialog: React.FC<Props> = ({
  item,
  order,
  onClose,
}) => {
  const fulfillment = useMemo(
    () =>
      order.fulfillments.find((fulfillment) =>
        fulfillment.items.includes(item.id)
      ),
    [item, order]
  );

  return (
    <Dialog
      isShown={true}
      intent="danger"
      onCloseComplete={() => onClose()}
      onConfirm={async () => {
        if (!fulfillment) {
          toaster.danger('Fulfillment not found.');
          return;
        }

        const itemCount = fulfillment.items.length;
        const newItems = fulfillment.items.filter((fulfillmentItem) => {
          return fulfillmentItem !== item.id;
        });

        let data;

        if (itemCount <= 1) {
          data = { status: FulfillmentStatus.Cancelled };
        } else {
          data = { items: newItems };
        }

        const res = await order.updateFulfillment(fulfillment.id, data);

        if (!res.ok) {
          toaster.danger(res.error?.message);
          return;
        }

        toaster.success('Successfully cancelled fulfullment.');
        return onClose();
      }}
      confirmLabel="Cancel Fulfillment"
      hasHeader={false}
    >
      <Pane
        height={200}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading size={700}>Are you sure?</Heading>
        <Heading size={300} marginTop={15}>
          Cancel Fulfillment
        </Heading>
      </Pane>
    </Dialog>
  );
};

export default CancelFulfillmentDialog;
