import React, { useEffect } from 'react';
import {
  Route,
  Redirect,
  Switch,
  withRouter,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { Layout } from 'components/Layout';

import { LoginPage } from 'pages/login';
import { OrderPage } from 'pages/order';
import { OrdersPage } from 'pages/orders';
import { ProductPage } from 'pages/product';
import { ProductsPage } from 'pages/products';
import { ProfilePage } from 'pages/profile';
import { FulfillmentPage } from 'pages/fulfillment';
import { SplitTestsPage } from 'pages/split-tests';
import { SplitTestsPageAdd } from 'pages/split-tests/add';
import { SplitTestsPageEdit } from 'pages/split-tests/edit';
import { VoucherPage } from 'pages/voucher';
import { VouchersPage } from 'pages/vouchers';
import { CreateOrderPage } from 'pages/create-order';
import { UsersPage } from 'pages/users';
import { UserPage } from 'pages/user';

const PrivateRoute: React.FC<RouteProps> = ({ component, ...options }) => {
  const { user } = useAuth();

  return (
    <Route {...options} component={user.isLoggedIn ? component : LoginPage} />
  );
};

const Routes: React.FC<RouteComponentProps> = ({ location }) => {
  const { checkValidAuth, user } = useAuth();

  useEffect(() => {
    checkValidAuth();
  }, [location, checkValidAuth]);

  if (!user.isLoggedIn) return <LoginPage />;

  return (
    <Switch location={location}>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/" exact render={() => <Redirect to="/orders" />} />

      <Layout>
        <PrivateRoute path="/order/create" exact component={CreateOrderPage} />
        <PrivateRoute path="/orders" exact component={OrdersPage} />
        <PrivateRoute path="/orders/:id" exact component={OrderPage} />
        <PrivateRoute path="/product/:sku?" exact component={ProductPage} />
        <PrivateRoute path="/products" exact component={ProductsPage} />
        <PrivateRoute path="/profile" exact component={ProfilePage} />
        <PrivateRoute path="/fulfillment" exact component={FulfillmentPage} />
        <PrivateRoute path="/links" exact component={SplitTestsPage} />
        <PrivateRoute path="/links/add" exact component={SplitTestsPageAdd} />
        <PrivateRoute path="/links/:id" exact component={SplitTestsPageEdit} />
        <PrivateRoute path="/voucher/:code?" exact component={VoucherPage} />
        <PrivateRoute path="/vouchers" exact component={VouchersPage} />
        <PrivateRoute path="/users" exact component={UsersPage} />
        <PrivateRoute path="/user/:id?" exact component={UserPage} />
      </Layout>
    </Switch>
  );
};

export default withRouter(Routes);
