import React from 'react';
import config from 'config';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { useVoucherQuery } from 'lib/api/ecommerce/schema';
import { Loader } from 'components/Loader';
import { Form } from './Form';

interface VoucherPageProps extends RouteComponentProps<{ code: string }> {}

export const VoucherPage: React.FC<VoucherPageProps> = ({
  match: { params },
}) => {
  const { data, loading } = useVoucherQuery({
    skip: !params.code,
    variables: { code: params.code },
  });

  return (
    <>
      <Helmet>
        <title>Add & Edit Voucher | {config.siteName}</title>
      </Helmet>

      {loading && <Loader />}
      {!loading && <Form voucher={data?.voucher} />}
    </>
  );
};
