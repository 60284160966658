import React, { useState, useEffect } from 'react';
import config from 'config';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { useUsersQuery } from 'lib/api/ecommerce/schema';
import { RoleAccess } from 'lib/auth';
import { Loader } from 'components/Loader';
import { UserForm } from './UserForm';

interface UserPageProps extends RouteComponentProps<{ id: string }> {}

export const UserPage: React.FC<UserPageProps> = ({ match: { params } }) => {
  const [name, setName] = useState('');
  const { data = {}, loading } = useUsersQuery({
    fetchPolicy: 'network-only',
  });
  const users = (Object.values(data)[0] as Array<any>) || [];
  const user = users.find((u) => u.id === params.id);

  useEffect(() => {
    setName(user?.name || '');
  }, [user]);

  return (
    <>
      <Helmet>
        <title>
          {name} | {config.siteName}
        </title>
      </Helmet>
      <RoleAccess permission="users:list">
        {loading && <Loader />}
        {!loading && <UserForm user={user} />}
      </RoleAccess>
    </>
  );
};
