import { object, string, array } from 'yup';
import { formatYupErrors } from 'lib/utils/formatYupErrors';

export const UserFormValidation = object().shape({
  name: string().required('Required'),
  email: string().required('Required').email('Invalid email'),
  roles: array().required('Required'),
});

export const UserFormValidationPW = object().shape({
  name: string().required('Required'),
  email: string().required('Required').email('Invalid email'),
  password: string().required('Required'),
  roles: array().required('Required'),
});

export const validate = async (values, user) => {
  let errors = null;

  try {
    if (user) {
      await UserFormValidation.validate(values, { abortEarly: false });
    } else {
      await UserFormValidationPW.validate(values, { abortEarly: false });
    }
  } catch (error) {
    if (error.name === 'ValidationError') errors = formatYupErrors(error);
  }

  return errors;
};
