import React, { useState } from 'react';
import { formatCurrency } from 'lib/utils';
import { Order } from 'models';
import {
  Button,
  Card,
  Heading,
  Pane,
  Paragraph,
  Table,
  Text,
  toaster,
} from 'evergreen-ui';

type Props = { order: Order; onClose: () => void };

export const Shipping: React.FC<Props> = ({ order, onClose = () => {} }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedShipping, setSelectedShipping] = useState<any>({
    name: order.getShipping().meta.name,
    key: order.shippingMethod,
  });

  return (
    <>
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16}>
          <Heading size={600}>Update Shipping</Heading>
          <Paragraph size={400}>Choose a shipping option below</Paragraph>
        </Pane>
      </Pane>
      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Text size={300} marginTop={10}>
          Do not forget to charge the customer for the appropriate amount in the
          processor dashboard.
        </Text>
        <Card
          marginTop={10}
          backgroundColor="white"
          elevation={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Table flex={1}>
            <Table.Body>
              {order.getAvailableShippingMethods().map((rate) => {
                return (
                  <Table.Row
                    key={rate.name}
                    isSelectable
                    isSelected={
                      selectedShipping && selectedShipping.key === rate.method
                    }
                    isHighlighted={
                      selectedShipping && selectedShipping.key === rate.method
                    }
                    onSelect={() =>
                      setSelectedShipping({
                        name: rate.name,
                        key: rate.method,
                        amount: rate.price,
                      })
                    }
                  >
                    <Table.Cell>{rate.name}</Table.Cell>
                    <Table.Cell>{formatCurrency(rate.price)}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Card>
        <Button
          disabled={!selectedShipping || loading}
          marginTop={14}
          appearance="primary"
          isLoading={loading}
          intent="success"
          onClick={async () => {
            setLoading(true);

            const res = await order.updateShipping({
              amount: selectedShipping.amount,
              meta: {
                method: selectedShipping.key,
              },
            });

            if (!res.ok) {
              toaster.danger(res.error?.message);
              setLoading(false);
              return;
            }

            toaster.success('Shipping successfully updated.');
            setLoading(false);

            return onClose();
          }}
        >
          Update Shipping
        </Button>
      </Pane>
    </>
  );
};

export default Shipping;
