import React, { useState, useReducer } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Order } from 'models';
import { get } from 'object-path';
import { Dialog, Pane, TextInputField, TextInput, toaster } from 'evergreen-ui';
import { SelectContainer } from './EditAddressModal.styles';

type RegionFormat = 'short' | 'full' | undefined;

interface EditAddressModalProps {
  order: Order;
  onClose: () => void;
}

export const EditAddressModal: React.FC<EditAddressModalProps> = ({
  order,
  onClose = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(order?.email);
  const [phone, setPhone] = useState(order?.phone);
  const [firstName, setFirstName] = useState(order?.firstName);
  const [lastName, setLastName] = useState(order?.lastName);
  const [address, setAddress] = useReducer(
    addressReducer,
    order?.shippingAddress
  );
  const [regionFormat, setRegionFormat] = useState<RegionFormat>(() => {
    if (get(order, 'shippingAddress.country', '').toLowerCase() === 'pr')
      return 'full';

    return 'short';
  });

  return (
    <Dialog
      isShown={true}
      width={380}
      title="Edit General Info"
      shouldCloseOnOverlayClick={false}
      isConfirmLoading={isLoading}
      confirmLabel={isLoading ? 'Loading...' : 'Save'}
      onCloseComplete={onClose}
      onConfirm={async () => {
        setIsLoading(true);

        const shippingAddress = address;
        delete shippingAddress.__typename; // meh

        const res = await order.updateOrder({
          email,
          firstName,
          lastName,
          phone,
          shippingAddress,
        });

        if (!res.ok) {
          setIsLoading(false);
          toaster.danger(res.error?.message);

          return;
        }

        setIsLoading(false);
        toaster.success('Order saved.');

        return onClose();
      }}
    >
      <Pane display="flex">
        <TextInputField
          label="First Name"
          placeholder="First Name"
          inputWidth="100%"
          width="100%"
          inputHeight={34}
          disabled={isLoading}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Pane>
      <Pane display="flex" marginTop={-12}>
        <TextInputField
          label="Last Name"
          placeholder="Last Name"
          inputWidth="100%"
          width="100%"
          inputHeight={34}
          disabled={isLoading}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Pane>
      <Pane display="flex" marginTop={-12}>
        <TextInputField
          label="Email"
          placeholder="Email Address"
          width="100%"
          inputHeight={34}
          disabled={isLoading}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Pane>
      <Pane display="flex" marginTop={-12}>
        <TextInputField
          label="Phone Number"
          placeholder="Phone Number"
          width="100%"
          inputHeight={34}
          disabled={isLoading}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </Pane>
      <Pane display="flex" marginTop={-12}>
        <TextInputField
          label="Shipping Information"
          placeholder="Recipient Name"
          width="100%"
          inputHeight={34}
          disabled={isLoading}
          value={address?.recipient}
          onChange={(e) =>
            setAddress({
              type: 'updateField',
              payload: { recipient: e.target.value },
            })
          }
        />
      </Pane>
      <Pane display="flex" marginTop={-12}>
        <TextInputField
          label={null}
          placeholder="Street Address"
          inputWidth="100%"
          width="100%"
          inputHeight={34}
          disabled={isLoading}
          value={address?.line1}
          onChange={(e) =>
            setAddress({
              type: 'updateField',
              payload: { line1: e.target.value },
            })
          }
        />
      </Pane>
      <Pane display="flex" marginTop={-12} marginBottom={10}>
        <TextInput
          label={null}
          placeholder="Line 2"
          width="100%"
          height={34}
          disabled={isLoading}
          value={address?.line2}
          onChange={(e) =>
            setAddress({
              type: 'updateField',
              payload: { line2: e.target.value },
            })
          }
        />
      </Pane>
      <Pane display="flex" marginBottom={10}>
        <TextInput
          label={null}
          placeholder="City / Town"
          width="100%"
          height={34}
          disabled={isLoading}
          value={address?.city}
          onChange={(e) =>
            setAddress({
              type: 'updateField',
              payload: { city: e.target.value },
            })
          }
        />
      </Pane>
      <Pane display="flex" marginBottom={10}>
        <SelectContainer>
          <RegionDropdown
            country={address?.country}
            countryValueType="short"
            defaultOptionLabel="Select State"
            value={address?.state || ''}
            valueType={regionFormat}
            onChange={(val) =>
              setAddress({ type: 'updateField', payload: { state: val } })
            }
          />
        </SelectContainer>
        <TextInput
          label={null}
          placeholder="Zip Code"
          width="100%"
          height={34}
          marginLeft={10}
          disabled={isLoading}
          value={address?.postalCode}
          onChange={(e) =>
            setAddress({
              type: 'updateField',
              payload: { postalCode: e.target.value },
            })
          }
        />
      </Pane>
      <Pane display="flex" marginBottom={10}>
        <SelectContainer>
          <CountryDropdown
            defaultOptionLabel="Select Country"
            valueType="short"
            disabled={isLoading}
            value={address?.country}
            onChange={(val) => {
              setAddress({ type: 'updateField', payload: { country: val } });

              if (val.toLowerCase() === 'pr') setRegionFormat('full');
              else setRegionFormat('short');
            }}
          />
        </SelectContainer>
      </Pane>
    </Dialog>
  );
};

function addressReducer(state, action) {
  switch (action.type) {
    case 'updateField':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}
