import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Body = styled.div`
  flex-grow: 1;
  margin: 2em 3em 4em 240px;
`;
