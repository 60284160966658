import React from 'react';
import { useHistory } from 'react-router-dom';
import { User } from 'lib/api/ecommerce/schema';
import { Avatar, Badge, Table } from 'evergreen-ui';

interface BodyProps {
  users: User[];
}

export const Body: React.FC<BodyProps> = ({ users }) => {
  const history = useHistory();

  return (
    <Table>
      {users.map((user) => (
        <Table.Row
          key={user.id}
          height={60}
          isSelectable
          onSelect={() => history.push(`/user/${user.id}`)}
        >
          <Table.Cell display="flex" alignItems="center">
            <Avatar name={user.name} size={28} marginRight="8px" />
            {user.name}
          </Table.Cell>
          <Table.Cell display="flex" alignItems="center">
            {user.email}
          </Table.Cell>
          <Table.Cell display="flex" alignItems="center">
            {user.isDisabled ? (
              <Badge color="red">Disabled</Badge>
            ) : (
              <Badge color="green">Enabled</Badge>
            )}
          </Table.Cell>
          <Table.Cell
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexBasis={320}
            flexShrink={0}
            flexGrow={0}
          >
            {user.roles.map((role) => (
              <Badge key={role} marginLeft="8px" color="blue">
                {role}
              </Badge>
            ))}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  );
};
