import { setContext } from '@apollo/client/link/context';
import config from 'config';

export const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${config.splitTestsApiKey}`,
    },
  };
});
