import { plainToClass, Transform, Type, Expose } from 'class-transformer';
import * as Sentry from '@sentry/react';
import phoneFormatter from 'phone-number-formats';
import { get } from 'object-path';
import { formatCurrency } from 'lib/utils';
import {
  Adjustment,
  AdjustmentType,
  Cart,
  CartStatus,
  ApprovedBy,
} from 'lib/api/ecommerce/schema';
import Timeline, { NotesTimeline } from '../Timeline';
import Transaction from '../Transaction';
import { OrderItem } from '../OrderItem';
import { AddOn } from '../OrderItem/AddOn';
import { CustomPrint } from '../OrderItem/CustomPrint';
import { StaticPrint } from '../OrderItem/StaticPrint';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  getAvailableShippingMethods,
  getDeliveryDueDate,
  getProductionDateEstimate,
  getOrderAdjustment,
  getHasFrame,
  getHasUnframed,
  getHasHangerFrame,
} from './utils';

/**
 * Base Order
 */
export class BaseOrder {
  public id!: Cart['id'];
  public email!: Cart['email'];
  public uuid!: Cart['uuid'];
  public firstName!: Cart['firstName'];
  public lastName!: Cart['lastName'];
  public fullName!: Cart['fullName'];
  public phone!: Cart['phone'];
  public billingAddress!: Cart['billingAddress'];
  public shippingAddress!: Exclude<Cart['shippingAddress'], null | undefined>;
  public fulfillments!: Cart['fulfillments'];
  public shipments!: Cart['shipments'];
  public meta!: Cart['meta'];
  public notes!: Cart['notes'];
  public store!: Cart['store'];
  public status!: Cart['status'];
  public paid!: Cart['paid'];
  public total!: Cart['total'];
  public adjustmentsTotal!: Cart['adjustmentsTotal'];
  public itemsTotal!: Cart['itemsTotal'];
  public isPaid!: Cart['isPaid'];
  public isEditable!: Cart['isEditable'];
  public isApproved!: Cart['isApproved'];
  public approvedBy!: Cart['approvedBy'];
  public adjustments!: Cart['adjustments'];
  public customer!: Cart['customer'];
  public voucher!: Cart['voucher'];
  public history!: Cart['history'];

  @Type(() => Transaction)
  public payments!: Transaction[];

  @Type(() => Transaction)
  public refunds!: Transaction[];

  @Type(() => Date)
  public createdAt!: Date;

  @Type(() => Date)
  public updatedAt!: Date;

  @Type(() => Date)
  public paidAt!: Date;

  @Type(() => Date)
  public approvedAt!: Date;

  @Expose()
  @Transform((items, order) => {
    let modelItems: any = [];

    items?.forEach((item) => {
      if (item.category === 'addons') {
        item = plainToClass(AddOn, item);
      } else if (item?.product?.meta?.isStaticPrint) {
        item = plainToClass(StaticPrint, item);
      } else {
        item = plainToClass(CustomPrint, item);
      }

      const itemFulfillments = order.fulfillments?.filter((fulfillment) => {
        return fulfillment?.items?.includes(item.id);
      });

      // additional properties
      item.fulfillments = itemFulfillments;
      item.orderId = order.id;

      modelItems.push(item);
    });

    return modelItems;
  })
  public items!: OrderItem[];

  @Expose()
  @Transform((timelines) => {
    return timelines?.map((timeline, i) => {
      const prevSnapshot = timelines[i + 1]?.snapshot;

      return plainToClass(Timeline, { ...timeline, prevSnapshot });
    });
  })
  public timelines!: Timeline[];

  get braintreeMerchantId(): string {
    let merchantId;

    // TODO: fetch stores from backend
    switch (this.store) {
      case 'twinkle':
        merchantId = process.env.REACT_APP_BRAINTREE_TWINKLE_MERCHANT_ID;
        break;
      case 'overourmoon':
        merchantId = process.env.REACT_APP_BRAINTREE_OOM_MERCHANT_ID;
        break;
      case 'andalways':
        merchantId = process.env.REACT_APP_BRAINTREE_AA_MERCHANT_ID;
        break;
      default:
    }

    return merchantId;
  }

  get grooveUri(): string {
    const query = encodeURIComponent(this.email as string);

    return `https://twinkleintime.groovehq.com/search/${query}`;
  }

  get isCancelled(): boolean {
    return this.status === CartStatus.Cancelled;
  }

  // check meta.isPreapproved flag for backwards compatibility
  get isCustomerApproved(): boolean {
    return (
      this.meta?.isPreapproved ||
      (this.isApproved && this.approvedBy === ApprovedBy.Customer)
    );
  }

  get isInternational(): boolean {
    const country = this?.shippingAddress?.country;

    return !!(country?.toLowerCase() !== 'us');
  }

  get isFulfilled(): boolean {
    return this.status === CartStatus.Fulfilled;
  }

  get isRushOrder(): boolean {
    return this.items.some((item) => /RSH-ORDER/gm.test(item.sku));
  }

  get shippingState(): string {
    return this.shippingAddress?.state.toUpperCase();
  }

  get shippingMethod(): string | null {
    if (!this.getShipping()) return null;

    return get(this.getShipping(), 'meta.method', null);
  }

  get shippingName(): string | null {
    if (!this.getShipping()) return null;

    let name = get(this.getShipping(), 'meta.name', '');
    let method = get(this.getShipping(), 'meta.method', '');

    if (method.toLowerCase() === 'fedex-hd') {
      name = 'Priority';
    }

    return name.toUpperCase();
  }

  get subtotal(): number {
    return this.itemsTotal || 0;
  }

  get formattedTotal(): string {
    return formatCurrency(this.total);
  }

  get formattedPaid(): string {
    return formatCurrency(this.paid);
  }

  get tax(): Adjustment | undefined {
    return getOrderAdjustment(this, AdjustmentType.Tax);
  }

  get taxAmount() {
    if (this.tax) {
      return get(this.tax, 'amount', 0);
    }

    return 0;
  }

  get taxRate(): number {
    if (this.tax) {
      return get(this.tax, 'meta.rate', 0);
    }

    return 0;
  }

  getDiscount(): Adjustment | undefined {
    return getOrderAdjustment(this, AdjustmentType.Discount);
  }

  getShipping(): Adjustment {
    return getOrderAdjustment(this, AdjustmentType.Shipping)!;
  }

  getFormattedPhone(): string | null | undefined {
    let phoneNum = this.phone;

    try {
      phoneNum = new phoneFormatter(this.phone).format({ type: 'domestic' })
        .string;
    } catch (err) {
      Sentry.captureException(err);
    }

    return phoneNum;
  }

  getPrints(): OrderItem[] {
    return this.items.filter((item) => item.isPrint);
  }

  getQuantity(): number {
    let total = 0;

    this.getPrints().forEach((print) => {
      total += print.quantity;
    });

    return total;
  }

  getPosterQuantity(): number {
    let total = 0;

    this.getPrints().forEach((print) => {
      if (!print.sku.includes('DIGITAL')) {
        total += print.quantity;
      }
    });

    return total;
  }

  getDigitalQuantity(): number {
    let total = 0;

    this.getPrints().forEach((print) => {
      if (print.sku.includes('DIGITAL')) {
        total++;
      }
    });

    return total;
  }

  getHasFrame(): boolean {
    return getHasFrame(this.items);
  }

  getHasUnframed(): boolean {
    return getHasUnframed(this.items);
  }

  getHasHangerFrame(): boolean {
    return getHasHangerFrame(this.items);
  }

  getCancellation(): Maybe<Timeline> {
    return this.getLatestTimelineByFilter(
      (timeline) =>
        timeline?.type === 'STATUS_UPDATED' &&
        timeline?.snapshot.status === CartStatus.Cancelled
    );
  }

  getPendingCancellation(): Maybe<Timeline> {
    return this.getLatestTimelineByFilter(
      (timeline) =>
        timeline?.type === 'STATUS_UPDATED' &&
        timeline?.snapshot.status === CartStatus.PendingCancel
    );
  }

  getDeliveryDueDate(): Date | null {
    const shipping = this.getShipping();

    if (shipping?.meta?.deliveryDate) {
      return new Date(shipping.meta.deliveryDate);
    }

    return getDeliveryDueDate(this);
  }

  getProductionDateEstimate() {
    return getProductionDateEstimate(this.paidAt);
  }

  getAvailableShippingMethods() {
    return getAvailableShippingMethods(this);
  }

  getNotes(): NotesTimeline[] {
    const notes = (this.timelines || [])
      .filter((timeline) => timeline.type === 'NOTE')
      .map((timeline) => {
        const { meta, ...rest } = timeline;

        return { message: meta.note, ...rest };
      }) as NotesTimeline[];

    // backwards compatibility until db is migrated
    if (this?.notes) {
      notes.push({
        message: this?.notes,
        user: { name: 'Admin', email: 'admin@andalways.com' },
      } as any);
    }

    return notes;
  }

  getFirstNote(): NotesTimeline {
    return this.getNotes()?.[0] ?? null;
  }

  private getLatestTimelineByFilter(filterFn: (timeline: Timeline) => boolean) {
    const filtered = (this.timelines || []).filter(filterFn);

    return filtered.sort((a, b) => a.createdAt - b.createdAt)?.[0];
  }
}

export default BaseOrder;
