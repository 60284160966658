import React from 'react';
import { get } from 'object-path';
import { useUsersQuery } from 'lib/api/ecommerce/schema';
import { Table as VirtualBody } from 'evergreen-ui';
import { Loader } from 'components/Loader';
import { Body } from './Body';
import { Header } from './Header';

export const Table: React.FC = () => {
  const { data = {}, loading } = useUsersQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;

  return (
    <VirtualBody borderRadius={6} marginTop="25px">
      <Header />
      <Body users={get(data, 'users', [])} />
    </VirtualBody>
  );
};
