import styled from 'styled-components';

export const PrintWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  }
`;

export const DownloadPDF = styled.div`
  margin-top: 10px;

  a {
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: white;
    background-image: linear-gradient(to bottom, #0788de, #116ab8);
    box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3),
      inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06);
    padding: 0.5rem 1rem;
    border-radius: 4px;

    &:hover {
      color: white !important;
    }
  }
`;

export const DownloadPNG = styled.div`
  a {
    margin-top: 18px;
    display: inline-block;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #bbb;
    background-color: transparent;
    border-radius: 4px;
    font-size: 0.9rem;
  }
`;

interface ImageContainerProps {
  hasLoaded: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  img {
    display: ${(props) => (props.hasLoaded ? 'block' : 'none')};
  }
`;
