import dayjs from 'dayjs';
import { shippingHolidays } from 'config/shipping';
import { fulfillmentWeekends } from 'config/fulfillment';
import { isSaturday, isSunday, isWeekend } from './isDates';

export const getProductionDateEstimate = (orderDate: Date): Date => {
  // includes 24 hour grace period + next production day
  const productionDate = dayjs(orderDate).add(2, 'day');

  if (isWeekend(productionDate)) {
    let offset = 0;

    if (isSaturday(productionDate) && !fulfillmentWeekends.isFulfillingSaturday)
      offset = 2;

    if (isSunday(productionDate) && !fulfillmentWeekends.isFulfillingSunday)
      offset = 1;

    productionDate.add(offset, 'day');
  }

  shippingHolidays.forEach((holiday) => {
    if (productionDate.isSame(dayjs(holiday))) {
      productionDate.add(1, 'day');
    }
  });

  return productionDate.toDate();
};

export default getProductionDateEstimate;
