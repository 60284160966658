import React from 'react';
import { Order } from 'models';
import { useOrderFulfillment } from 'lib/api/ecommerce/hooks';
import { Alert, Dialog, Heading, Pane, toaster } from 'evergreen-ui';
import { Table } from './components/Table';

interface FulfillOrderModalProps {
  order: Order;
  onClose: () => void;
}

export const FulfillOrderModal: React.FC<FulfillOrderModalProps> = ({
  order,
  onClose,
}) => {
  const {
    isLoading,
    fulfillments,
    setFulfillment,
    submitFulfillment,
  } = useOrderFulfillment(order);

  const onSubmit = async () => {
    const res = await submitFulfillment();

    if (!res.ok) {
      toaster.danger(res.error?.message || 'Something bad happened', {
        duration: 5,
      });
      return;
    }

    toaster.success('Order submitted for fulfillment', {
      duration: 5,
    });
    onClose();
  };

  return (
    <Dialog
      width={640}
      isShown={true}
      intent="success"
      hasHeader={false}
      onCloseComplete={onClose}
      isConfirmLoading={isLoading}
      isConfirmDisabled={!fulfillments.length || isLoading}
      confirmLabel={isLoading ? 'Loading...' : 'Fulfill'}
      onConfirm={onSubmit}
    >
      <Pane display="flex" flexDirection="column">
        <Pane
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Heading size={700} marginTop={20} marginBottom={20}>
            Select Fulfillment
          </Heading>
        </Pane>

        {(fulfillments.length === 0 || order.isFulfilled) && (
          <Alert title="All items have been fulfilled. If you are attempting to submit a reprint, close this modal and cancel fulfillment in the table below and resubmit. " />
        )}

        <Pane
          flex={1}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="row"
        >
          <Table fulfillments={fulfillments} setFulfillment={setFulfillment} />
        </Pane>
      </Pane>
    </Dialog>
  );
};
