import { boolean, object, string, number } from 'yup';
import { formatYupErrors } from 'lib/utils/formatYupErrors';
import { ProductType } from 'lib/api/ecommerce/schema';

export const ProductFormValidation = object().shape({
  category: string().required('Required'),
  commodityCode: string().nullable(),
  name: string().min(3).required('Required'),
  type: string().oneOf(Object.values(ProductType)).required('Required'),
  sku: string().min(3).required('Required'),
  unitPrice: number().min(1).required('Required'),
  upc: string().nullable(),
  requiresApproval: boolean().required('Required'),
});

export const validate = async (values) => {
  let errors = null;

  try {
    await ProductFormValidation.validate(values, { abortEarly: false });
  } catch (error: any) {
    if (error.name === 'ValidationError') errors = formatYupErrors(error);
  }

  return errors;
};
