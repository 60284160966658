import React from 'react';
import isEqual from 'react-fast-compare';
import { Checkbox, Pane, Select } from 'evergreen-ui';

export class DesignForm extends React.Component<any> {
  public shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.print, nextProps.print);
  }

  public render() {
    const { preset, labels, subject } = this.props.print;

    return (
      <Pane width="100%">
        <Pane width="100%">
          <Select
            name="month"
            width="100%"
            value={preset}
            onChange={(e: any) =>
              this.props.handlePrintChange({ preset: e?.target.value })
            }
          >
            {[
              { name: 'Modern Blue', value: 'modern-blue' },
              { name: 'Modern Black', value: 'modern-black' },
            ].map((preset) => (
              <option key={preset.name} value={preset.value}>
                {preset.name}
              </option>
            ))}
          </Select>
        </Pane>
        <Pane width="100%">
          <Checkbox
            label="Show Monogram"
            name="labels.monogram"
            onChange={() =>
              this.props.handlePrintChange({
                labels: { monogram: !labels.monogram },
              })
            }
            checked={labels.monogram}
          >
            Show Monogram
          </Checkbox>
        </Pane>
        <Pane width="100%">
          <Checkbox
            label="Show Stars"
            name="subject.stars"
            onChange={() =>
              this.props.handlePrintChange({
                subject: { stars: !subject.stars },
              })
            }
            checked={subject.stars}
          >
            Show Stars
          </Checkbox>
        </Pane>
      </Pane>
    );
  }
}
