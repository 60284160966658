import React, { useState, useEffect } from 'react';
import * as Grid from 'react-awesome-styled-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { RoleAccess } from 'lib/auth';
import {
  Voucher,
  VoucherDiscountType,
  useUpdateVoucherMutation,
  useCreateVoucherMutation,
} from 'lib/api/ecommerce/schema';
import { BasicForm } from './BasicForm';
import { VoucherPageFormValidation } from './validation';
import { formatArguments, parseArguments } from './utils';
import {
  Alert,
  Button,
  Heading,
  Pane,
  toaster,
  TextInputField,
} from 'evergreen-ui';

interface VoucherPageFormProps {
  voucher: Voucher | undefined;
}

interface VoucherPageFormState {
  id?: string;
  code: string;
  type: string;
  discount: number;
  rules: any[];
  startsAt: Date;
  expiresAt: Date | null;
  maxUses: number;
}

export const Form: React.FC<VoucherPageFormProps> = ({ voucher = null }) => {
  const history = useHistory();

  const [error, setError] = useState<boolean>();
  const [voucherType, setVoucherType] = useState<string>('basic');
  const [createVoucher, { loading: isCreating }] = useCreateVoucherMutation();
  const [mutateVoucher, { loading: isMutating }] = useUpdateVoucherMutation();
  const [form, setForm] = useState<VoucherPageFormState>(
    Object.assign(
      {},
      {
        code: '',
        discount: 0,
        type: VoucherDiscountType.Percent,
        startsAt: new Date(),
        expiresAt: null,
        rules: [],
        maxUses: 0,
      },
      voucher ? parseArguments(voucher) : {}
    )
  );

  const loading = isMutating || isCreating;
  const isNewVoucher = !voucher?.id;

  useEffect(() => {
    if (voucher && voucher?.rules.length > 0) setVoucherType('bogo');
  }, [voucher]);

  return (
    <Fade key="SplitTest" left distance="30px" duration={500}>
      <>
        <Pane display="flex" marginBottom={40}>
          <Pane flex={1} alignItems="center" display="flex">
            <Button
              iconBefore="arrow-left"
              onClick={() => history.push('/vouchers')}
            >
              Back
            </Button>
          </Pane>

          <Pane alignItems="center" display="flex">
            {!voucher?.rules.length && (
              <RoleAccess permission="vouchers:edit">
                <Button
                  isLoading={loading}
                  appearance="primary"
                  intent="success"
                  onClick={async () => {
                    if (!(await VoucherPageFormValidation.isValid(form))) {
                      return setError(true);
                    }

                    try {
                      const input = formatArguments({ ...form }) as any;
                      const submit: any = input.id
                        ? mutateVoucher
                        : createVoucher;

                      let { id, ...rest } = input;
                      const rules = rest?.rules ?? null;

                      if (rules) {
                        rules.forEach((rule) => {
                          delete rule.__typename;
                        });
                        rest.rules = rules;
                      }

                      rest.type =
                        rest.type === 'PERCENT' ||
                        rest.type === VoucherDiscountType.Percent
                          ? VoucherDiscountType.Percent
                          : VoucherDiscountType.Amount;

                      const data = !voucher
                        ? { input: { ...rest } }
                        : { input: { id, data: rest } };

                      await submit({ variables: { ...data } });

                      toaster.success(
                        `Voucher ${form.code} ${
                          !voucher ? 'created' : 'saved'
                        }.`,
                        { duration: 3 }
                      );

                      history.push('/vouchers');
                    } catch (e) {
                      toaster.danger(e.message);
                    }
                  }}
                >
                  Save Voucher
                </Button>
              </RoleAccess>
            )}
          </Pane>
        </Pane>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={8} offset={{ lg: 2 }}>
              <Pane display="flex" marginBottom={20}>
                <Heading size={700} style={{ fontWeight: '600' }}>
                  {isNewVoucher ? 'Add ' : 'Edit '} Voucher
                </Heading>
              </Pane>

              <Pane
                elevation={1}
                backgroundColor="#fdfdfd"
                padding={22}
                borderRadius="8px"
              >
                {error && (
                  <Pane display="flex" marginBottom={15}>
                    <Alert
                      width="100%"
                      intent="danger"
                      title="Code and discount fields are required."
                    />
                  </Pane>
                )}

                <Pane display="flex" marginBottom={20}>
                  <Heading size={500} style={{ fontWeight: '600' }}>
                    Voucher Information
                  </Heading>
                </Pane>

                <Pane display="flex">
                  <TextInputField
                    hint="Must be more than 3 characters"
                    required
                    label="Code"
                    placeholder="Code"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={loading}
                    value={form.code}
                    onChange={(e) =>
                      setForm({ ...form, code: e.target.value.toUpperCase() })
                    }
                  />
                </Pane>

                {voucherType === 'basic' && (
                  <BasicForm
                    voucher={form}
                    loading={loading}
                    setForm={setForm}
                  />
                )}

                <Pane display="flex" marginBottom={20}>
                  <Heading size={500} style={{ fontWeight: '600' }}>
                    Voucher Details
                  </Heading>
                </Pane>

                <Pane display="flex">
                  <TextInputField
                    hint="Max number of uses"
                    label="Max Uses"
                    placeholder="Max Uses"
                    width="100%"
                    inputWidth="100%"
                    inputHeight={40}
                    disabled={loading}
                    value={form.maxUses || ''}
                    onChange={(e) =>
                      setForm({ ...form, maxUses: e.target.value })
                    }
                  />
                </Pane>
                <Pane display="flex">
                  <DatePicker
                    selected={form.startsAt}
                    onChange={(date) => setForm({ ...form, startsAt: date })}
                    dateFormat="M/d/yyyy"
                    customInput={
                      <TextInputField
                        label="Start Date"
                        placeholder="Start Date"
                        width="90%"
                        inputHeight={40}
                        marginRight={5}
                        disabled={loading}
                      />
                    }
                  />
                  <DatePicker
                    selected={form.expiresAt || ''}
                    onChange={(date) => setForm({ ...form, expiresAt: date })}
                    dateFormat="M/d/yyyy"
                    customInput={
                      <TextInputField
                        label="Expires"
                        placeholder="Expiration Date"
                        inputWidth="100%"
                        width="100%"
                        inputHeight={40}
                        disabled={loading}
                      />
                    }
                  />
                </Pane>
              </Pane>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </>
    </Fade>
  );
};
