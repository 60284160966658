import React, { useCallback, useEffect, useState } from 'react';
import { plainToClass } from 'class-transformer';
import { User } from 'models';
import { useMeLazyQuery } from 'lib/api/ecommerce/schema';
import { AuthContext, AuthProviderContext } from './AuthContext';

export const AuthProvider: React.FC = ({ children }) => {
  const [fetchAuth, { data, error, loading, called }] = useMeLazyQuery();
  const [user, setUser] = useState<User>(new User());

  const logout = () => {
    user.logout();
    setUser(new User());
  };

  const setLoggedUser = (userData) => {
    const userModel = plainToClass(User, { ...userData });

    userModel.isLoggedIn = true;
    setUser(userModel);
  };

  const checkValidAuth = useCallback(() => {
    if (user.isTokenExpired()) {
      window.location.href = '/login';
    }
  }, [user]);

  useEffect(() => {
    checkValidAuth();

    if (!called) {
      fetchAuth();
    }
  }, [called, fetchAuth, checkValidAuth]);

  useEffect(() => {
    if (data) {
      setLoggedUser(data?.me);
    }
  }, [data]);

  const context: AuthContext = {
    user,
    error,
    isReady: !loading,
    checkValidAuth,
    logout,
  };

  return (
    <AuthProviderContext.Provider value={context}>
      {children}
    </AuthProviderContext.Provider>
  );
};
