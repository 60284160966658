import React from 'react';
import { get } from 'object-path';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import {
  Avatar,
  Heading,
  Menu,
  Pane,
  Popover,
  Position,
  Text,
} from 'evergreen-ui';

export const UserInfo: React.FC = () => {
  const history = useHistory();
  const { user, logout } = useAuth();

  if (!user.isLoggedIn) return null;

  return (
    <Popover
      position={Position.RIGHT}
      content={
        <Menu>
          <Menu.Group>
            <Menu.Item
              icon="user"
              onSelect={() => {
                history.push('/profile');
              }}
            >
              Profile
            </Menu.Item>
          </Menu.Group>
          <Menu.Group>
            <Menu.Item
              icon="circle-arrow-left"
              onSelect={() => {
                logout();
                history.push('/login');
              }}
            >
              Logout
            </Menu.Item>
          </Menu.Group>
        </Menu>
      }
    >
      <Pane
        padding={16}
        flex={0}
        display="flex"
        alignItems="center"
        cursor="pointer"
        background="#ffffff"
        borderTop="1px solid #eeeeee"
      >
        <Avatar
          name={get(user, 'name', 'AndAlways')}
          size={34}
          marginRight={16}
        />
        <Pane>
          <Heading size={400}>{get(user, 'name', 'AndAlways')}</Heading>
          <Text size={300}>{get(user, 'email')}</Text>
        </Pane>
      </Pane>
    </Popover>
  );
};
