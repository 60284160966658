import React, { useState } from 'react';
import dateFormat from 'date-fns/format';
import { IChange } from 'json-diff-ts';
import { Timelime } from 'models';
import { formatCurrency } from 'lib/utils';
import {
  Avatar,
  Pane,
  Paragraph,
  Position,
  Tooltip,
  ArrowRightIcon,
  Strong,
  Badge,
} from 'evergreen-ui';
import { OrderStatusBadge } from 'components/OrderStatusBadge';

interface LogProps {
  timeline: Timelime;
}

const renderDiffRow = (row: IChange) => {
  let jsx;

  switch (row.key) {
    case 'payments':
    case 'adjustments':
    case 'shippingAddress':
      jsx = (row.changes || []).map((row) => renderDiffRow(row));
      break;
    case 'total':
    case 'paid':
    case 'itemsTotal':
      jsx = (
        <>
          <Strong size={400}>{row.key}</Strong>: {formatCurrency(row.oldValue)}{' '}
          <ArrowRightIcon size={10} /> {formatCurrency(row.value)}
        </>
      );
      break;
    case 'items':
      jsx = (
        <>
          <Strong size={400}>{row.key}</Strong>:{' '}
          <Badge color="neutral">
            {row?.changes?.[0]?.value?.sku || 'n/a'}
          </Badge>
        </>
      );
      break;
    case 'status':
      jsx = (
        <>
          <Strong size={400}>{row.key}</Strong>:{' '}
          <OrderStatusBadge status={row.oldValue} />{' '}
          <ArrowRightIcon size={10} /> <OrderStatusBadge status={row.value} />
        </>
      );
      break;
    default:
      jsx = (
        <>
          <Strong size={400}>{row.key}</Strong>: {row.oldValue}{' '}
          <ArrowRightIcon size={10} /> {row.value}
        </>
      );
  }

  return <Paragraph>{jsx}</Paragraph>;
};

export const Log: React.FC<LogProps> = ({ timeline }) => {
  const [showDiff, setShowDiff] = useState<boolean>(false);

  const diff = timeline.getDiff();
  const isClickable =
    !['CART_PAYMENT', 'NOTE'].includes(timeline.type) && timeline.prevSnapshot;

  return (
    <Pane
      padding={12}
      display="flex"
      flexDirection="row"
      borderBottom="1px solid #eee"
      cursor={isClickable ? 'pointer' : 'default'}
      onClick={() => {
        if (isClickable) {
          setShowDiff(!showDiff);
        }
      }}
    >
      <Pane display="flex" flexBasis={40} flexGrow={0}>
        <Tooltip
          position={Position.LEFT}
          content={timeline.user?.name || 'Customer'}
        >
          <Avatar name={timeline.user?.name || 'Customer'} size={28} />
        </Tooltip>
      </Pane>
      <Pane flex={1} paddingX={14}>
        <Paragraph marginBottom={5} style={{ whiteSpace: 'pre-wrap' }}>
          {timeline.text}
        </Paragraph>

        {showDiff && diff.map((row) => renderDiffRow(row))}

        <Paragraph size={300} color="#8f95b2" marginTop={6}>
          {timeline.user?.name || 'Customer'} - {` `}
          {timeline?.createdAt &&
            dateFormat(
              new Date(timeline?.createdAt),
              "MMMM do yyyy 'at' h:mm a"
            )}
        </Paragraph>
      </Pane>
    </Pane>
  );
};
