import styled from 'styled-components';

export const SelectContainer = styled.div`
  width: 100%;
  display: inline-flex;
  position: relative;
  height: 40px;

  &:before {
    content: '>';
    position: absolute;
    top: calc(50% - 4px);
    right: 10px;
    width: 10px;
    height: 10px;
    transform: rotate(90deg);
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    box-shadow: rgba(67, 90, 111, 0.14) 0px 0px 0px 1px inset,
      rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: linear-gradient(rgb(255, 255, 255), rgb(244, 245, 247)) white;
    text-decoration: none;
    outline: none;
    flex: 1 1 0%;
    padding-right: 28px;
    padding-left: 10px;
    font-size: 1.1em;
  }
`;
