import React from 'react';
import dateFormat from 'date-fns/format';
import { Order } from 'models';
import { FulfillmentOptions } from 'config/fulfillment';
import { FulfillmentFragment as Fulfillment } from 'lib/api/ecommerce/schema';
import { FulfillmentStatusBadge } from 'components/FulfillmentStatusBadge';
import { Pane, Link, Table } from 'evergreen-ui';

interface FulfillmentRowProps {
  order: Order;
  fulfillment: Fulfillment;
}

export const FulfillmentRow: React.FC<FulfillmentRowProps> = ({
  fulfillment,
}) => {
  const isPrintful =
    fulfillment.source.toLowerCase() === FulfillmentOptions.PRINTFUL;

  const source = isPrintful ? (
    <Link
      target="_blank"
      href={`https://www.printful.com/dashboard/default/orders?order_id=${fulfillment.meta.id}`}
    >
      {fulfillment.source}
    </Link>
  ) : (
    'TWINKLE IN TIME'
  );

  return (
    <Pane>
      <Table.Row>
        <Table.TextCell>
          <strong>{source}</strong>
        </Table.TextCell>

        <Table.TextCell>
          {dateFormat(new Date(fulfillment.createdAt), 'MMM do yyyy h:mm:ss a')}
        </Table.TextCell>
        <Table.TextCell>
          {dateFormat(new Date(fulfillment.updatedAt), 'MMM do yyyy h:mm:ss a')}
        </Table.TextCell>
        <Table.TextCell>{fulfillment.id}</Table.TextCell>

        <Table.Cell justifyContent="flex-end">
          <FulfillmentStatusBadge status={fulfillment.status} />
        </Table.Cell>
      </Table.Row>
    </Pane>
  );
};
