import React, { useMemo } from 'react';
import { Button, SelectMenu, SelectMenuItem } from 'evergreen-ui';

interface MultiSelectProps {
  exclude?: (string | number)[];
  selected: (string | number)[];
  buttonTitle: string;
  menuTitle?: string;
  options: SelectMenuItem[];
  onChange: (
    allSelected: (string | number)[],
    changedValue?: string | number
  ) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  exclude = [],
  options = [],
  selected = [],
  buttonTitle = 'Select',
  menuTitle = 'Select',
  onChange = () => {},
}) => {
  const onSelect = (item: SelectMenuItem) =>
    onChange([...(selected as any), item.value], item.value);

  const onDeselect = (item: SelectMenuItem) => {
    const deselectedItemIndex = selected.indexOf(item.value as string);
    const selectedItems = selected.filter((_, i) => i !== deselectedItemIndex);

    onChange(selectedItems, item.value);
  };

  // exclude options
  options = useMemo(
    () => options.filter(({ value }) => !exclude.includes(value)),
    [exclude, options]
  );

  return (
    <SelectMenu
      hasFilter={true}
      isMultiSelect={true}
      options={options}
      selected={selected as string[]}
      title={menuTitle}
      onSelect={onSelect}
      onDeselect={onDeselect}
    >
      <Button iconAfter="caret-down">
        {selected.length === 0 && 'All '}
        {buttonTitle}
        {selected.length > 0 && ` (${selected.length})`}
      </Button>
    </SelectMenu>
  );
};
