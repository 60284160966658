import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { useMutation } from '@apollo/client';
import { ADD_LINK, FETCH_LINKS, client } from './api';
import {
  Button,
  Heading,
  Pane,
  Icon,
  TextInputField,
  Label,
  toaster,
  Textarea,
  TextInput,
} from 'evergreen-ui';

type State = {
  name: string;
  description: string;
  notes: string;
  links: any;
};

export const SplitTestsPageAdd: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<State>({
    name: '',
    description: '',
    notes: '',
    links: [{ url: '', weight: '' }],
  });
  const [addLink] = useMutation<any>(ADD_LINK, {
    client: client,
    variables: { input: { ...state } },
    refetchQueries: [{ query: FETCH_LINKS }],
  });

  const updateLink = (index, key, event) => {
    const data = [...state.links];
    data[index][key] =
      key === 'weight' ? parseInt(event.target.value) : event.target.value;

    setState({ ...state, links: data });
  };

  return (
    <>
      <Helmet>
        <title>Split Tests | TwinkleInTime</title>
      </Helmet>

      <Fade key="SplitTest" left distance="30px" duration={500}>
        <Pane>
          <Pane display="flex" marginBottom={30}>
            <Pane flex={1} alignItems="center" display="flex">
              <Button
                iconBefore="arrow-left"
                onClick={() => history.push('/links')}
              >
                Back
              </Button>
            </Pane>
            <Pane
              flex={1}
              alignItems="flex-end"
              justifyContent="flex-end"
              display="flex"
            >
              <Button
                isLoading={loading}
                appearance="primary"
                intent="success"
                onClick={async () => {
                  setLoading(true);
                  try {
                    await addLink();

                    toaster.success('Successfully add split test.');
                    history.push('/links');
                  } catch (e) {
                    toaster.danger(e.message);
                    setLoading(false);
                  }
                }}
              >
                Add Split Test
              </Button>
            </Pane>
          </Pane>
          <Pane display="flex" marginBottom={20}>
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={600} style={{ fontWeight: '400' }}>
                Add Split Test
              </Heading>
            </Pane>
          </Pane>
          <Pane display="flex" flexDirection="row" flex={1}>
            <TextInputField
              flex={1}
              inputHeight={42}
              label="Name"
              placeholder="Name"
              value={state.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setState({ ...state, name: e.target.value })
              }
            />
          </Pane>
          <Pane display="flex" flexDirection="column" flex={1}>
            <Label htmlFor="description" marginBottom={4} display="block">
              Descripton
            </Label>
            <Textarea
              id="description"
              placeholder="Description"
              value={state.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setState({ ...state, description: e.target.value })
              }
            />
          </Pane>
          <Pane display="flex" flexDirection="column" flex={1} marginTop={20}>
            <Label htmlFor="notes" marginBottom={4} display="block">
              Notes
            </Label>
            <Textarea
              id="notes"
              placeholder="Notes"
              value={state.notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setState({ ...state, notes: e.target.value })
              }
            />
          </Pane>
          <Heading size={500} marginTop={30} marginBottom={20}>
            Links
          </Heading>
          {state.links.map((_, index) => (
            <>
              <Pane
                display="flex"
                flexDirection="row"
                flex={1}
                marginBottom={10}
              >
                <Pane display="flex" flex={1} paddingRight={5}>
                  <TextInput
                    flex={1}
                    height={42}
                    placeholder="https://twinkleintime.com/?blah=blah"
                    value={state.links[index].url}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateLink(index, 'url', e)
                    }
                  />
                </Pane>
                <Pane flex={0} flexBasis={40} paddingRight={5}>
                  <TextInput
                    flex={1}
                    height={42}
                    width="auto"
                    placeholder="50"
                    value={state.links[index].weight}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateLink(index, 'weight', e)
                    }
                  />
                </Pane>
                <Pane
                  flex={0}
                  flexBasis={30}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    icon="cross"
                    cursor="pointer"
                    onClick={() =>
                      setState({
                        ...state,
                        links: [...state.links.filter((_, i) => i !== index)],
                      })
                    }
                  />
                </Pane>
              </Pane>
            </>
          ))}

          <Pane
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop={20}
          >
            <Button
              onClick={() =>
                setState({
                  ...state,
                  links: [...state.links, { url: '', weight: '' }],
                })
              }
            >
              Add Link
            </Button>
          </Pane>
        </Pane>
      </Fade>
    </>
  );
};
