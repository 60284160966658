import React from 'react';
import { SideSheet } from 'evergreen-ui';
import { Shipping } from './utils';
import { Order } from 'models';

type Props = { order: Order; onClose: () => void };

const EditShippingModal: React.FC<Props> = ({ order, onClose = () => {} }) => (
  <SideSheet
    isShown={true}
    onCloseComplete={() => onClose()}
    containerProps={{ display: 'flex', flex: '1', flexDirection: 'column' }}
  >
    <Shipping order={order} onClose={() => onClose()} />
  </SideSheet>
);

export default EditShippingModal;
