import React from 'react';
import dateFormat from 'date-fns/format';
import { Order } from 'models';
import { ShippingStatusBadge } from 'components/ShippingStatusBadge';
import { Heading, Pane, Table, Text, Link } from 'evergreen-ui';

interface ShipmentsProps {
  order: Order;
}

export const Shipments: React.FC<ShipmentsProps> = ({ order }) => {
  const { shipments } = order;

  const getCarrierLink = (carrier, number) => {
    switch (carrier) {
      case 'usps':
        return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${number}`;
      case 'fedex':
        return `https://www.fedex.com/fedextrack/?trknbr=${number}`;
      case 'ups':
        return `https://www.ups.com/track?loc=en_US&tracknum=${number}`;
      case 'dhl':
        return `https://www.dhl.com/en/express/tracking.html?brand=DHL&AWB=${number}`;
      case 'ontrac':
        return `https://www.ontrac.com/trackingresults.asp?tracking_number=${number}`;
      case 'purolator':
        return `https://www.purolator.com/en/shipping/tracker?pins=${number}`;
      case 'lasership':
        return `https://t.lasership.com/Track/${number}`;
      case 'asendia-usa':
        return `https://a1.asendiausa.com/tracking/?trackingnumber=${number}`;
      case 'australia-post':
        return `https://auspost.com.au/mypost/track/#/details/${number}`;
      default:
        return `https://track.aftership.com/${carrier}/${number}`;
    }
  };

  if (!order) return null;

  return (
    <>
      <Pane marginTop={40}>
        <Pane display="flex" paddingBottom={10}>
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600} fontWeight={300}>
              Shipments
            </Heading>
          </Pane>
        </Pane>

        <Pane
          display="flex"
          flexDirection="row"
          elevation={1}
          marginTop={5}
          borderRadius={6}
          backgroundColor="#fdfdfd"
        >
          {shipments.length < 1 && (
            <Pane
              flex={1}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Heading
                size={100}
                marginTop={10}
                marginBottom={10}
                textAlign="center"
              >
                Awaiting shipments...
              </Heading>
            </Pane>
          )}

          {shipments.length > 0 && (
            <Table flex={1} borderRadius={6}>
              <Table.Head>
                <Table.HeaderCell flexBasis={150} width={150} flex={0}>
                  Courier
                </Table.HeaderCell>
                <Table.HeaderCell>Tracking</Table.HeaderCell>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell>Last Updated</Table.HeaderCell>
                <Table.HeaderCell
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  flexBasis={140}
                  width={140}
                  flex={0}
                >
                  Status
                </Table.HeaderCell>
              </Table.Head>

              <Table.Body>
                {shipments.map((shipment) => (
                  <Table.Row key={shipment.id}>
                    <Table.Cell flexBasis={150} width={150} flex={0}>
                      <Heading size={400}>{shipment.carrier}</Heading>
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        href={getCarrierLink(
                          shipment.carrier,
                          shipment.trackingNumber
                        )}
                        target="_blank"
                      >
                        {shipment.trackingNumber}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {dateFormat(
                        new Date(shipment.createdAt),
                        'E, MMM do yyyy h:mm a'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {dateFormat(
                        new Date(shipment.updatedAt),
                        'E, MMM do yyyy h:mm a'
                      )}
                    </Table.Cell>
                    <Table.Cell
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      flexBasis={140}
                      width={140}
                      flex={0}
                    >
                      <Text>
                        <ShippingStatusBadge status={shipment.status} />
                      </Text>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Pane>
      </Pane>
    </>
  );
};
