import React from 'react';
import { SideSheet } from 'evergreen-ui';
import { OrderItem, Order } from 'models';
import { RemoveFrame } from './utils';

export type RemoveFrameModalProps = {
  item: OrderItem;
  order: Order;
  onClose: () => void;
};

export const RemoveFrameModal: React.FC<RemoveFrameModalProps> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const removeFrameClose = () => {
    onClose();
  };

  return (
    <SideSheet
      isShown={true}
      onCloseComplete={() => onClose()}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
      }}
    >
      <RemoveFrame item={item} order={order} onClose={removeFrameClose} />
    </SideSheet>
  );
};

export default RemoveFrameModal;
