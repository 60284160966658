import { FulfillmentPrinterDirectories } from 'config/fulfillment';
import { CustomPrint, Order, StaticPrint } from '../..';
import type { PrinterJobFile } from 'lib/api/printing/schema';

export function composePrinterFiles(
  items: (CustomPrint | StaticPrint)[],
  order: Order,
  quantity?: number
): Partial<PrinterJobFile>[] {
  const files: Partial<PrinterJobFile>[] = [];

  items.forEach((item) => {
    const itemQuantity = quantity ? quantity : item.quantity;
    for (let i = 0; i < itemQuantity; i++) {
      const fileName = item.getFulfillmentPrintFilename(
        order,
        i + 1,
        quantity ? quantity : item.quantity
      );

      files.push({
        fileName,
        url: item.getPrintUrl(),
        directory: getPrinterDirectory(item, order),
        meta: {
          item: item.id,
          color: (item as CustomPrint)?.printColor,
          preview: item.getPreviewUrl(),
        },
      });
    }
  });

  return files;
}

// Currently only sending 18x24 star/moon maps to CUSTOM_PRINTS directory
function getPrinterDirectory(item: CustomPrint | StaticPrint, order: Order) {
  const { width, height } = item.printSize;

  if (item instanceof CustomPrint && width === 18 && height === 24) {
    return FulfillmentPrinterDirectories.CUSTOM_PRINTS;
  }

  return `${width}x${height}`; // i.e. "12x16" directory
}
