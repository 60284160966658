import React, { useState } from 'react';
import { get } from 'object-path';
import { withRouter } from 'react-router-dom';
import { Button, Heading, Pane, Link, Text, Icon, Table } from 'evergreen-ui';

export const LinkRow: React.FunctionComponent<any> = ({ link, history }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Pane
      display="flex"
      flexDirection="column"
      padding={16}
      background="white"
      borderRadius={3}
      elevation={1}
      marginBottom="1rem"
    >
      <Pane
        flex={1}
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
      >
        <Heading size={700} marginBottom={8}>
          {get(link, 'name', '')}
          <Link
            target="_blank"
            href={`https://${get(link, 'jumpPage', '')}`}
            marginLeft={10}
          >
            <Icon size={12} icon="link" />
          </Link>
        </Heading>
        <Link target="_blank" href={`https://${get(link, 'jumpPage', '')}`}>
          <Text size={500} color="#66788A">
            https://{get(link, 'jumpPage', 'no link')}
          </Text>
        </Link>
        <Text size={400} color="#66788A" marginTop={15}>
          {get(link, 'notes', 'description')}
        </Text>
      </Pane>
      {expanded && (
        <Pane
          flex={1}
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
        >
          <Table marginTop={20} marginBottom={10} flex={1} width="100%" border>
            <Table.Body flex={1}>
              {link.links.map((row) => (
                <Table.Row key={row.url} flex={1}>
                  <Table.TextCell flex={1}>{row.url}</Table.TextCell>
                  <Table.TextCell flex={0} flexBasis={80}>
                    {row.weight}%
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Pane>
      )}
      <Pane flex={1} display="flex" marginTop={20}>
        <Button
          marginRight={10}
          height={24}
          onClick={() => history.push(`/links/${link.id}`)}
        >
          Edit
        </Button>
        <Button height={24} onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Close' : 'View'} Links
        </Button>
      </Pane>
    </Pane>
  );
};

export default withRouter(LinkRow);
