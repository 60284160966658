import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { RoleAccess, RoleAccessPermissions } from 'lib/auth';
import { Logo as LogoImage } from 'components/Logo';
import { UserInfo } from './UserInfo';
import { Container, Logo, Nav, NavItem } from './Sidebar.styles';
import { Pane } from 'evergreen-ui';

interface SidebarLinks {
  name: string;
  href: string;
  exact: boolean;
  permission: RoleAccessPermissions;
}

const links: SidebarLinks[] = [
  {
    name: 'Orders',
    href: '/orders',
    exact: false,
    permission: 'orders:list',
  },
  {
    name: 'Fulfillment',
    href: '/fulfillment',
    exact: false,
    permission: 'fulfillment:list',
  },
  {
    name: 'Vouchers',
    href: '/vouchers',
    exact: false,
    permission: 'vouchers:list',
  },
  {
    name: 'Products',
    href: '/products',
    exact: false,
    permission: 'products:list',
  },
  {
    name: 'Split Tests',
    href: '/links',
    exact: false,
    permission: 'split-tests:list',
  },
  {
    name: 'Users',
    href: '/users',
    exact: false,
    permission: 'users:list',
  },
];

export const Sidebar: React.FC = memo(() => (
  <Container>
    <Pane
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDirection="column"
      flex={1}
      paddingTop={10}
      paddingBottom={20}
      paddingRight={20}
    >
      <Logo>
        <LogoImage />
      </Logo>

      <Nav>
        {links.map((link) => (
          <RoleAccess key={link.href} permission={link.permission}>
            <NavItem>
              <NavLink
                to={link.href}
                exact={link.exact || false}
                activeClassName="active-route"
              >
                {link.name}
              </NavLink>
            </NavItem>
          </RoleAccess>
        ))}
      </Nav>
    </Pane>
    <UserInfo />
  </Container>
));
