import React from 'react';
import shippingRates from 'config/shipping';
import { get, has } from 'object-path';
import { Pane, SelectField } from 'evergreen-ui';
import { getHasFrame } from 'models/Order/utils';

interface CreateOrderShippingMethodFormProps {
  form: any;
  onChange: (e: any) => void;
}

export const CreateOrderShippingMethodForm: React.FC<CreateOrderShippingMethodFormProps> = ({
  form,
  onChange,
}) => {
  const handleChange = (shippingKey: string) => {
    const country = form.shippingAddress?.country?.toLowerCase();
    const method = shippingRates.find(
      (rate) => rate.key === shippingKey
    ) as any;

    let rate = has(method, ['rates', country])
      ? get(method, ['rates', country])
      : get(method, ['rates', 'other']);

    rate = getHasFrame(form.items) ? rate?.framed : rate?.unframed;

    onChange({
      name: method.name,
      method: method.method,
      price: rate.price,
    });
  };

  return (
    <Pane display="flex" width="100%">
      <SelectField
        label="Shipping Method"
        name="shippingMethod"
        width="100%"
        inputHeight={40}
        value={form.shippingMethod.key}
        onChange={(e) => handleChange(e.target.value)}
      >
        {shippingRates.map((rate) => (
          <option value={rate.key}>{rate.name} Shipping</option>
        ))}
      </SelectField>
    </Pane>
  );
};
