import React from 'react';
import Routes from 'routes';
import { useAuth } from 'lib/auth';
import { BrowserRouter } from 'react-router-dom';
import { AppLoader } from 'components/AppLoader/AppLoader';

export const App: React.FC = () => {
  const { isReady } = useAuth();

  if (!isReady) {
    return <AppLoader />;
  }

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};
