import redaxios from 'redaxios';
import { BaseOrder, CustomPrint, Order, StaticPrint } from 'models';

export const prefetchPrintImages = (order: BaseOrder | Order): void => {
  const prints = order.getPrints() as (CustomPrint | StaticPrint)[];

  if (!prints.length) return;

  prints.forEach((print) => {
    redaxios.get(print.getPreviewUrl()).catch(() => {});
  });
};
