import React from 'react';
import { useQuery } from '@apollo/client';
import { client, FETCH_LINKS } from './api';
import { Pane } from 'evergreen-ui';
import { Loader } from 'components/Loader';
import LinkRow from './link';

export const LinksList: React.FC = () => {
  const { data: { links = [] } = {}, loading } = useQuery<any>(FETCH_LINKS, {
    variables: {},
    client: client,
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;
  if (!links || links.length < 1) return <>No links found.</>;

  return (
    <>
      <Pane marginTop={24}>
        {links.map((link) => (
          <LinkRow key={link.jumpPage} link={link} />
        ))}
      </Pane>
    </>
  );
};

export default LinksList;
