import gql from 'graphql-tag';

export const FETCH_LINK = gql`
  query($id: String!) {
    link(id: $id) {
      id
      name
      description
      notes
      createdAt
      updatedAt
      jumpPage
      links {
        url
        weight
      }
    }
  }
`;

export const FETCH_LINKS = gql`
  query {
    links {
      id
      name
      description
      notes
      createdAt
      updatedAt
      jumpPage
      links {
        url
        weight
      }
    }
  }
`;

export const ADD_LINK = gql`
  mutation($input: LinkInput!) {
    addLink(input: $input) {
      id
      name
      description
      notes
      createdAt
      updatedAt
      jumpPage
      links {
        url
        weight
      }
    }
  }
`;

export const EDIT_LINK = gql`
  mutation($id: String!, $input: LinkInput!) {
    editLink(id: $id, input: $input) {
      id
      name
      description
      notes
      createdAt
      updatedAt
      jumpPage
      links {
        url
        weight
      }
    }
  }
`;
