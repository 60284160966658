import React from 'react';
import differenceInHours from 'date-fns/differenceInHours';
import { Order, BaseOrder, OrderStatus } from 'models';
import { Badge } from 'evergreen-ui';

interface OrderReadinessBadgeProps {
  hrsGracePeriod?: number;
  order: Order | BaseOrder;
}

const NOT_READY_BADGE_COLOR = 'neutral';
const READY_BADGE_COLOR = 'green';
const READY_BADGE_TEXT = 'ready';

export const OrderReadinessBadge: React.FC<OrderReadinessBadgeProps> = ({
  hrsGracePeriod = 22,
  order,
}) => {
  const hrsRemaining = differenceInHours(new Date(), order.paidAt);
  const isReady = hrsRemaining > hrsGracePeriod;
  const isOrderFulfilledOrCancelled = [
    OrderStatus.Cancelled,
    OrderStatus.Fulfilled,
    OrderStatus.PartiallyFulfilled,
  ].includes(order.status);

  if (isOrderFulfilledOrCancelled) return null;

  let text = (hrsGracePeriod - hrsRemaining).toString() + ' hrs';
  let color = NOT_READY_BADGE_COLOR;

  if (isReady) {
    text = READY_BADGE_TEXT;
    color = READY_BADGE_COLOR;
  }

  return (
    <Badge color={color as any} display="inline-flex" margin={8}>
      {text}
    </Badge>
  );
};
