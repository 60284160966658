import React from 'react';
import { Badge } from 'evergreen-ui';
import { OrderStatus } from 'models';

interface OrderStatusBadgeProps {
  status: string;
  style?: any;
  onClick?: () => void;
}

export const OrderStatusBadge: React.FC<OrderStatusBadgeProps> = ({
  status,
  style,
  onClick = () => {},
}) => {
  let color = 'teal';
  let statusName = 'draft';

  switch (status) {
    case OrderStatus.Draft:
      color = 'teal';
      status = OrderStatus.Draft;
      break;
    case OrderStatus.Hold:
      color = 'neutral';
      status = OrderStatus.Hold;
      break;
    case OrderStatus.CustomerHold:
      color = 'neutral';
      status = OrderStatus.CustomerHold;
      break;
    case OrderStatus.Ready:
      color = 'green';
      status = OrderStatus.Ready;
      break;
    case OrderStatus.PartiallyFulfilled:
      color = 'orange';
      status = OrderStatus.PartiallyFulfilled;
      break;
    case OrderStatus.Fulfilled:
      color = 'blue';
      status = 'FULFILLED';
      break;
    case OrderStatus.Shipping:
      color = 'yellow';
      status = 'IN-TRANSIT';
      break;
    case OrderStatus.Cancelled:
      color = 'red';
      status = OrderStatus.Cancelled;
      break;
    case OrderStatus.PendingCancel:
      color = 'yellow';
      status = OrderStatus.PendingCancel;
      break;
    case OrderStatus.Completed:
      color = 'purple';
      status = 'DELIVERED';
      break;
    default:
      color = 'neutral';
  }

  statusName = status;

  return (
    <Badge color={color as any} marginRight={8} onClick={onClick} style={style}>
      {statusName || 'unknown'}
    </Badge>
  );
};
