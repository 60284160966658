import React, { useState } from 'react';
import { Pane, Heading, Paragraph, Button, toaster } from 'evergreen-ui';
import { OrderItem, Order } from 'models';

type Props = {
  item: OrderItem;
  order: Order;
  onClose: () => void;
};

export const RemovePoster: React.FC<Props> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16}>
          <Heading size={600}>Remove {item.isPrint && 'Poster'}</Heading>
          <Paragraph size={400}>
            Are you sure you want to remove{item.isPrint && ' poster'}?
          </Paragraph>
        </Pane>
      </Pane>

      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Button
          disabled={loading}
          marginTop={14}
          appearance="primary"
          isLoading={loading}
          intent="danger"
          onClick={async () => {
            setLoading(true);

            const res = await order.removeItems([item.id]);

            if (!res.ok) {
              toaster.danger(res.error?.message);
              setLoading(false);
              return;
            }

            toaster.success('Item successfully removed.');
            setLoading(false);

            return onClose();
          }}
        >
          Remove {item.isPrint && 'Poster'}
        </Button>
        <Button
          disabled={loading}
          marginTop={14}
          marginLeft={14}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </Pane>
    </>
  );
};

export default RemovePoster;
