import React, { useEffect, useMemo, useState } from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import differenceInHours from 'date-fns/differenceInHours';
import { Order } from 'models';
import { connect } from 'redux-zero/react';
import { RoleAccess } from 'lib/auth';
import { StoreProps } from 'lib/store';
import { useFulfillmentOrders } from 'lib/api/ecommerce/hooks';
import { Button, Heading, Pane } from 'evergreen-ui';
import { FulfillmentSlideOver } from 'components/FulfillmentSlideOver';
import { OrdersTable, OrdersTableFilters } from 'components/OrdersTable';
import type {
  OrdersTableFiltersProps,
  OrdersTableQuery,
} from 'components/OrdersTable/OrdersTableFilters';
import { OrdersQuery } from 'lib/api/ecommerce/schema';
import { groupOrdersByCustomer } from 'lib/utils/groupOrdersByCustomer';

export interface FulfillmentPageProps {
  ordersTable: { query: OrdersTableQuery; filters: OrdersTableFilters };
  onFilterApply: OrdersTableFiltersProps['onFilterApply'];
}

export const FulfillmentPage: React.FC<FulfillmentPageProps> = ({
  ordersTable,
  onFilterApply,
}) => {
  const [isSlideOverOpen, setSlideOverOpen] = useState<boolean>(false);
  const { orders, pagination, loading, fetch } = useFulfillmentOrders(
    ordersTable.query
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const numOrders = orders.length;
  /**
   * - Backend does not support `filter` params on unapproved orders query
   * - Run `hours since order paidAt` filter on client
   */
  const filteredOrders = useMemo((): Order[] => {
    let filtered = orders;

    if (ordersTable.query.query === OrdersQuery.UnapprovedOnly) {
      filtered = orders.filter(
        (order) =>
          differenceInHours(new Date(), order.paidAt) >=
          parseInt(ordersTable.filters.filter.hoursAgo)
      );
    }

    return groupOrdersByCustomer(filtered);
  }, [orders, ordersTable]);

  return (
    <>
      <Helmet>
        <title>Fulfillment | {config.siteName}</title>
      </Helmet>

      <Fade key="Queue" left distance="30px" duration={500}>
        <Pane>
          <Pane display="flex">
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={700} fontWeight={300}>
                Fulfillment ({numOrders}
                {!!pagination?.next && '+'})
              </Heading>
            </Pane>
            <Pane
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <RoleAccess permission="fulfillment:submit">
                <Button
                  disabled={numOrders < 1}
                  marginLeft={12}
                  appearance="primary"
                  onClick={() => setSlideOverOpen(true)}
                >
                  Start Fulfillment
                </Button>
              </RoleAccess>
            </Pane>
          </Pane>

          <Pane display="flex" flexGrow={0} flexDirection="column">
            <OrdersTable
              loading={loading}
              orders={filteredOrders}
              filters={ordersTable.filters}
              onFilterApply={(data) => {
                onFilterApply(data);
                fetch();
              }}
            />
          </Pane>
        </Pane>
      </Fade>

      {isSlideOverOpen && (
        <FulfillmentSlideOver
          orders={filteredOrders}
          onClose={() => {
            setSlideOverOpen(false);
            fetch();
          }}
        />
      )}
    </>
  );
};

export default connect<StoreProps>(
  ({ fulfillmentPage }) => ({
    ordersTable: fulfillmentPage.ordersTable,
  }),
  () => ({
    onFilterApply: (_, { query, filters }) => ({
      fulfillmentPage: {
        ordersTable: { query, filters },
      },
    }),
  })
)(FulfillmentPage);
