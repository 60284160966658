import React from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import * as Grid from 'react-awesome-styled-grid';
import { useMeQuery } from 'lib/api/ecommerce/schema';
import { Loader } from 'components/Loader/';
import { Form } from './Form';

export const ProfilePage: React.FC = () => {
  const { data, loading } = useMeQuery();

  return (
    <>
      <Helmet>
        <title>Profile | {config.siteName}</title>
      </Helmet>

      <Fade key="AccountPageFade" left distance="30px" duration={500}>
        <>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col lg={8} offset={{ lg: 2 }}>
                {loading && <Loader />}
                {!loading && <Form user={data?.me} />}
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        </>
      </Fade>
    </>
  );
};
