import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { LogoIcon } from 'components/LogoIcon';
import { quotes } from './quotes';
import { Button, Heading, TextInputField, toaster } from 'evergreen-ui';
import {
  Container,
  Header,
  Form,
  FormContainer,
  LogoContainer,
} from './LoginPage.styles';

interface LoginPageState {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [{ email, password }, setForm] = useState<LoginPageState>({
    email: '',
    password: '',
  });
  const [quote] = useState<string>(
    quotes[Math.floor(Math.random() * Math.floor(quotes.length))]
  );

  useEffect(() => {
    if (user.isLoggedIn) {
      history.push('/');
    }
  }, [history, user]);

  return (
    <Container>
      <Fade bottom distance="20px" duration={400}>
        <Header>
          <LogoContainer>
            <LogoIcon />
          </LogoContainer>
          <Heading size={500} marginBottom={30}>
            "{quote}"
          </Heading>
        </Header>
        <FormContainer>
          <Form onSubmit={(e) => e.preventDefault()}>
            <TextInputField
              inputWidth="100%"
              label="Email Address"
              name="email"
              placeholder="name@andalways.com"
              inputHeight={48}
              value={email}
              isInvalid={hasErrors}
              onChange={(e) => setForm({ password, email: e.target.value })}
            />
            <TextInputField
              inputWidth="100%"
              label="Password"
              name="password"
              placeholder="Password"
              type="password"
              inputHeight={48}
              value={password}
              isInvalid={hasErrors}
              onChange={(e) => setForm({ email, password: e.target.value })}
            />
            <Button
              isLoading={isLoading}
              appearance="primary"
              height={38}
              marginTop={10}
              type="submit"
              width="100%"
              onClick={async () => {
                setIsLoading(true);
                setHasErrors(false);

                const res = await user.login({ email, password });

                if (!res.ok) {
                  setIsLoading(false);
                  setHasErrors(true);
                  toaster.danger('Sorry, invalid credentials.');
                  return;
                }

                window.location.href = '/orders';
              }}
            >
              {isLoading ? null : 'Login'}
            </Button>
          </Form>
        </FormContainer>
      </Fade>
    </Container>
  );
};
