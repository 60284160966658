import React from 'react';
import isEqual from 'react-fast-compare';
import { Pane, Select } from 'evergreen-ui';

export class ZodiacForm extends React.Component<any> {
  public shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.print, nextProps.print);
  }

  public render() {
    const { zodiac } = this.props.print;

    return (
      <Pane width="100%">
        <Select
          name="subject.zodiac"
          width="100%"
          value={zodiac}
          onChange={(e: any) =>
            this.props.handlePrintChange({
              subject: { zodiac: e?.target.value },
            })
          }
        >
          {[
            { name: 'Capricorn', value: 'capricorn' },
            { name: 'Aquarius', value: 'aquarius' },
            { name: 'Pisces', value: 'pisces' },
            { name: 'Aries', value: 'aries' },
            { name: 'Taurus', value: 'taurus' },
            { name: 'Gemini', value: 'gemini' },
            { name: 'Cancer', value: 'cancer' },
            { name: 'Leo', value: 'leo' },
            { name: 'Virgo', value: 'virgo' },
            { name: 'Libra', value: 'libra' },
            { name: 'Scorpio', value: 'scorpio' },
            { name: 'Sagittarius', value: 'sagittarius' },
          ].map((zodiac) => (
            <option key={zodiac.name} value={zodiac.value}>
              {zodiac.name}
            </option>
          ))}
        </Select>
      </Pane>
    );
  }
}
