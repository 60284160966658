import React from 'react';
import { Table as StyledTable } from 'evergreen-ui';
import { VoucherFragment as Voucher } from 'lib/api/ecommerce/schema';
import { Body } from './Body';
import { Header } from './Header';

interface TableProps {
  vouchers: Voucher[];
}

export const Table: React.FC<TableProps> = ({ vouchers }) => (
  <StyledTable borderRadius={6} marginTop="25px">
    <Header />
    <Body vouchers={vouchers} />
  </StyledTable>
);
