import React, { useState } from 'react';
import config from 'config';
import { CustomPrintEditorModal } from 'components/CustomPrintEditorModal';
import { AddItemsModal } from 'components/AddItemsModal';
import { Button, Heading, IconButton, Pane, Table } from 'evergreen-ui';
import { CreateOrderPageFormState } from './Form';
import { composePrintMeta } from 'models/OrderItem/utils/composePrintMeta';
import { Product } from 'lib/api/ecommerce/schema';
import { CustomPrint, OrderItemProduct } from 'models';

interface ItemsTableProps {
  order: CreateOrderPageFormState;
  setItems: (items: any) => void;
}

export const ItemsTable: React.FC<ItemsTableProps> = ({
  order,
  setItems = () => {},
}) => {
  const [isMapEditorOpen, setMapEditorOpen] = useState<boolean>(false);
  const [isAddItemsModalOpen, setAddItemsModalOpen] = useState<boolean>(false);
  const [activePrintItem, setActivePrintItem] = useState<any>(null!);

  return (
    <>
      <Pane
        display="flex"
        justifyContent="space-between"
        marginTop={24}
        marginBottom={16}
      >
        <Heading size={600} style={{ fontWeight: '400' }}>
          Items
        </Heading>

        <Button onClick={() => setAddItemsModalOpen(true)}>Add Item</Button>
      </Pane>

      {order.items.length > 0 && (
        <Pane
          display="flex"
          flexDirection="column"
          elevation={1}
          borderRadius={6}
          backgroundColor="#fdfdfd"
          marginBottom={5}
        >
          <Table width="100%">
            <Table.Head>
              <Table.TextHeaderCell flexBasis={90} flexShrink={0} flexGrow={0}>
                Print
              </Table.TextHeaderCell>
              <Table.TextHeaderCell>SKU</Table.TextHeaderCell>
              <Table.TextHeaderCell>Quantity</Table.TextHeaderCell>
              <Table.HeaderCell
                justifyContent="flex-end"
                flexBasis={100}
                flexShrink={0}
                flexGrow={0}
              >
                Actions
              </Table.HeaderCell>
            </Table.Head>

            <Table.Body>
              {order.items.map((item, i) => (
                <Table.Row key={`${item.sku}-${i}`} minHeight={80}>
                  <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                    {item.meta?.featuredImage?.src && (
                      <img
                        alt={`Print ${item.id}`}
                        src={item.meta?.featuredImage?.src}
                        style={{ width: '100%', height: '70px' }}
                      />
                    )}
                  </Table.TextCell>
                  <Table.TextCell>{item.sku}</Table.TextCell>
                  <Table.TextCell>{item.quantity}</Table.TextCell>
                  <Table.Cell
                    justifyContent="flex-end"
                    flexBasis={100}
                    flexShrink={0}
                    flexGrow={0}
                  >
                    {item.sku.indexOf('P12X16') > -1 ||
                      item.sku.indexOf('P18X24') > -1 ||
                      (item.sku.indexOf('P24X36') > -1 && (
                        <IconButton
                          icon="edit"
                          onClick={() => {
                            setActivePrintItem(item as OrderItemProduct);
                            setMapEditorOpen(true);
                          }}
                        />
                      ))}
                    <IconButton
                      icon="cross"
                      marginLeft={5}
                      onClick={() => {
                        const items = [...order.items];

                        items.splice(i, 1);

                        setItems(items);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Pane>
      )}

      {isMapEditorOpen && (
        <CustomPrintEditorModal
          order={order as any}
          print={activePrintItem as CustomPrint}
          onClose={() => setMapEditorOpen(false)}
          onComplete={(printMeta) => {
            const { meta, ...rest } = activePrintItem;

            setItems([
              ...order.items,
              { ...rest, meta: composePrintMeta(printMeta) },
            ]);
            setMapEditorOpen(false);
            setActivePrintItem(null);
          }}
        />
      )}

      {isAddItemsModalOpen && (
        <AddItemsModal
          onClose={() => setAddItemsModalOpen(false)}
          onComplete={(product: Product) => {
            const { sku, meta } = product;

            if (!product.meta?.isStaticPrint) {
              setActivePrintItem({
                quantity: 1,
                sku,
                meta:
                  order.store === 'twinkle'
                    ? config.starmaps.defaults
                    : config.moonmaps.defaults,
              });

              setMapEditorOpen(true);
            } else {
              setItems([...order.items, { quantity: 1, sku, meta }]);
            }

            setAddItemsModalOpen(false);
          }}
        />
      )}
    </>
  );
};
