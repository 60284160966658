import React from 'react';
import { SideSheet } from 'evergreen-ui';
import { OrderItem, Order } from 'models';
import { RemovePoster } from './utils';

type Props = {
  item: OrderItem;
  order: Order;
  onClose: () => void;
};

export const RemovePosterModal: React.FC<Props> = ({
  item,
  order,
  onClose = () => {},
}) => {
  return (
    <SideSheet
      isShown={true}
      onCloseComplete={() => onClose()}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
      }}
    >
      <RemovePoster item={item} order={order} onClose={onClose} />
    </SideSheet>
  );
};

export default RemovePosterModal;
