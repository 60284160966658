import React, { useCallback } from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router';
import { connect } from 'redux-zero/react';
import { BaseOrder } from 'models';
import { get } from 'object-path';
import { RoleAccess } from 'lib/auth';
import { StoreProps } from 'lib/store';
import { prepareOrderArguments } from 'lib/utils';
import { useOrdersBasic } from 'lib/api/ecommerce/hooks';
import { Heading, Button, IconButton, Pane } from 'evergreen-ui';
import { Loader } from 'components/Loader';
import { Table } from './components/Table';
import { TableFilters } from './components/TableFilters';
import { filterOrders } from './filter';
import type { OrdersPageFilters } from './types';

interface OrdersPageProps {
  filters: OrdersPageFilters;
  setFilters: (filter: Partial<OrdersPageFilters>) => void;
  setPreservedOrders: (preservedOrders: BaseOrder[]) => void;
}

export const OrdersPage: React.FC<OrdersPageProps> = ({
  filters,
  setFilters,
  setPreservedOrders,
}) => {
  const history = useHistory();
  const { orders = [], loading, refetch } = useOrdersBasic({
    variables: prepareOrderArguments({ ...filters }) as any,
    onCompleted: (data) => setPreservedOrders(data.carts),
  });

  const onRefresh = useCallback(() => {
    refetch(
      Object.assign(
        {},
        { ...filters },
        get(filters, 'status').includes('all') ? { status: null } : {}
      )
    );
  }, [filters, refetch]);

  const filteredOrders = filterOrders(orders, filters.orderType);
  const numOrders = filteredOrders.length;

  return (
    <>
      <Helmet>
        <title>Orders | {config.siteName}</title>
      </Helmet>

      <Fade key="OrdersPage" left distance="20px" duration={500}>
        <Pane>
          <Pane display="flex">
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={700} fontWeight={300}>
                Orders
              </Heading>
              <RoleAccess permission="orders:create">
                <Button
                  height={32}
                  marginLeft={14}
                  iconBefore="plus"
                  onClick={() => history.push('/order/create')}
                >
                  Create Order
                </Button>
              </RoleAccess>
            </Pane>

            <TableFilters orderCount={numOrders} onRefresh={onRefresh} />
          </Pane>

          {loading && <Loader />}
          {!loading && numOrders < 1 && (
            <Pane
              is="section"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height={200}
              marginTop={30}
            >
              <Heading size={100}>No orders found.</Heading>
            </Pane>
          )}

          {!loading && numOrders > 0 && (
            <Pane display="flex" flexDirection="column" flexGrow={0}>
              <Table orders={filteredOrders} />

              <Pane
                display="flex"
                alignItems="center"
                justifyContent="column"
                marginTop={20}
              >
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  marginLeft={10}
                >
                  <Heading size={100}>{numOrders} orders</Heading>
                </Pane>
                <Pane
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <IconButton
                    icon="refresh"
                    iconSize={13}
                    marginRight={10}
                    onClick={onRefresh}
                  />
                  <IconButton
                    icon="arrow-left"
                    marginRight={10}
                    disabled={filters.skip === 0}
                    onClick={() =>
                      setFilters({ skip: filters.skip - filters.limit })
                    }
                  />
                  <IconButton
                    icon="arrow-right"
                    disabled={filters.limit > numOrders}
                    onClick={() =>
                      setFilters({ skip: filters.skip + filters.limit })
                    }
                  />
                </Pane>
              </Pane>
            </Pane>
          )}
        </Pane>
      </Fade>
    </>
  );
};

export default connect<StoreProps>(
  (store) => ({
    filters: store.ordersPage.ordersTable.filters,
  }),
  () => ({
    setFilters: (state, filters) => ({
      ordersPage: {
        ordersTable: {
          filters: { ...state.ordersPage.ordersTable.filters, ...filters },
        },
      },
    }),
    setPreservedOrders: (_, orders) => ({
      preservedOrders: orders,
    }),
  })
)(OrdersPage);
