import createStore from 'redux-zero';
import { BaseOrder, OrderStatus } from 'models';
import { FulfillmentOptions, FulfillmentPods } from 'config/fulfillment';
import { getQueryParameter } from 'lib/utils/getQueryParameter';
import { OrdersQuery } from 'lib/api/ecommerce/schema';
import { DEFAULT_FILTERS } from 'components/OrdersTable/OrdersTableFilters';
import type { OrdersPageFilters } from 'pages/orders/types';
import type { FulfillmentPageProps } from 'pages/fulfillment/FulfillmentPage';

export interface StoreProps {
  fulfillment: {
    latest: {
      pod?: FulfillmentPods;
      source: FulfillmentOptions;
    };
  };
  fulfillmentPage: { ordersTable: FulfillmentPageProps['ordersTable'] };
  ordersPage: { ordersTable: { filters: OrdersPageFilters } };
  preservedOrders: BaseOrder[];
}

const store = createStore<StoreProps>({
  fulfillment: {
    latest: {
      pod: FulfillmentPods.POD_2,
      source: FulfillmentOptions.ANDALWAYS,
    },
  },
  fulfillmentPage: {
    ordersTable: {
      query: {
        query: OrdersQuery.ApprovedOnly,
        status: [OrderStatus.Draft],
        filter: undefined,
        next: undefined,
        limit: 350,
        reverse: false,
      },
      filters: {
        ...DEFAULT_FILTERS,
      },
    },
  },
  ordersPage: {
    ordersTable: {
      filters: {
        orderType: [],
        limit: 50,
        skip: 0,
        sort: { paidAt: -1 },
        status: ['all'],
        search: getQueryParameter('s'),
      },
    },
  },
  preservedOrders: [],
});

export default store;
