import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoleAccess } from 'lib/auth';
import { Order, OrderStatus } from 'models';
import { OrderSidesheet } from 'components/OrderSidesheet';
import { FulfillOrderModal } from 'components/FulfillOrderModal';
import { SendReviewRequestButton } from 'components/SendReviewRequestButton';
import {
  Button,
  IconButton,
  Pane,
  Popover,
  Position,
  Menu,
  toaster,
} from 'evergreen-ui';
import { CancelOrderModal } from 'components/CancelOrderModal';

interface TopButtonsProps {
  order: Order;
  onPrevOrder: () => void;
  onNextOrder: () => void;
  orderIndex: number;
  numOrders: number;
}

export const TopButtons: React.FC<TopButtonsProps> = ({
  order,
  onPrevOrder,
  onNextOrder,
  orderIndex,
  numOrders,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSidesheetOpen, setSidesheetOpen] = useState<boolean>(false);
  const [isCancelOrderModalOpen, setCancelOrderModalOpen] = useState<boolean>(
    false
  );
  const [
    isFulfillSlideOverOpen,
    setFulfillmentSlideOverOpen,
  ] = useState<boolean>(false);

  const onStatusSelect = useCallback(
    async (status, closeMenu) => {
      closeMenu();
      setIsLoading(true);

      const res = await order.updateOrderStatus(status);

      if (!res.ok) {
        toaster.danger(res.error?.message, { duration: 2 });
      } else {
        toaster.success('Order status updated.', { duration: 2 });
      }

      setIsLoading(false);
    },
    [order]
  );

  if (!order) return null;

  return (
    <>
      <Pane display="flex">
        <Pane flex={1} alignItems="center" display="flex">
          <Button
            height={30}
            marginRight={20}
            iconBefore="arrow-left"
            onClick={() => history.push('/orders')}
          >
            Back
          </Button>
        </Pane>

        <Pane justifyContent="flex-end" alignItems="center" display="flex">
          <SendReviewRequestButton order={order} />

          <Button
            marginRight={12}
            onClick={() => window.open(order.grooveUri, '_blank')}
          >
            View Support Tickets
          </Button>

          <Button marginRight={10} onClick={() => setSidesheetOpen(true)}>
            View Prints
          </Button>

          <RoleAccess permission="orders:edit">
            <Popover
              position={Position.BOTTOM_RIGHT}
              content={({ close }) => (
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      onSelect={() => onStatusSelect(OrderStatus.Draft, close)}
                    >
                      Draft
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() => onStatusSelect(OrderStatus.Hold, close)}
                    >
                      Hold
                    </Menu.Item>
                    {order.store !== 'andalways' && (
                      <Menu.Item
                        onSelect={() =>
                          onStatusSelect(OrderStatus.CustomerHold, close)
                        }
                      >
                        Customer Hold
                      </Menu.Item>
                    )}
                    <Menu.Item
                      onSelect={() => onStatusSelect(OrderStatus.Ready, close)}
                    >
                      Ready
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() => {
                        close();
                        setCancelOrderModalOpen(true);
                      }}
                    >
                      Cancelled
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() =>
                        onStatusSelect(OrderStatus.PendingCancel, close)
                      }
                    >
                      Pending Cancel
                    </Menu.Item>
                    <Menu.Item
                      onSelect={() =>
                        onStatusSelect(OrderStatus.Fulfilled, close)
                      }
                    >
                      Fulfilled
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              )}
            >
              <Button
                iconAfter="caret-down"
                isLoading={isLoading}
                marginRight={10}
              >
                Status
              </Button>
            </Popover>
          </RoleAccess>

          <RoleAccess permission="orders:fulfill">
            <Button
              appearance="primary"
              intent="success"
              disabled={order.isFulfilled || order.isCancelled}
              onClick={() => setFulfillmentSlideOverOpen(true)}
            >
              Fulfill
            </Button>
          </RoleAccess>

          <IconButton
            icon="arrow-left"
            marginLeft={10}
            marginRight={10}
            disabled={orderIndex === 0}
            onClick={() => onPrevOrder()}
          />
          <IconButton
            icon="arrow-right"
            disabled={orderIndex === numOrders - 1}
            onClick={() => onNextOrder()}
          />
        </Pane>
      </Pane>

      {isFulfillSlideOverOpen && (
        <FulfillOrderModal
          order={order}
          onClose={() => setFulfillmentSlideOverOpen(false)}
        />
      )}

      {isSidesheetOpen && (
        <OrderSidesheet order={order} onClose={() => setSidesheetOpen(false)} />
      )}

      {isCancelOrderModalOpen && (
        <CancelOrderModal
          order={order}
          onClose={() => setCancelOrderModalOpen(false)}
        />
      )}
    </>
  );
};
