import React from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { Button, Heading, Pane } from 'evergreen-ui';
import { LinksList } from './List';

export const SplitTestsPage: React.FC<any> = ({ history }) => (
  <>
    <Helmet>
      <title>Split Tests | {config.siteName}</title>
    </Helmet>

    <Fade key="SplitTests" left distance="30px" duration={500}>
      <Pane>
        <Pane display="flex" marginBottom={10}>
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={700} fontWeight={300}>
              Split Tests
            </Heading>
          </Pane>
          <Pane
            flex={1}
            alignItems="flex-end"
            justifyContent="flex-end"
            display="flex"
          >
            <Button onClick={() => history.push('/links/add')}>
              Add Split Test
            </Button>
          </Pane>
        </Pane>
        <Pane height="80vh" display="flex" flexDirection="column" flexGrow={0}>
          <LinksList />
        </Pane>
      </Pane>
    </Fade>
  </>
);
