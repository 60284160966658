import React from 'react';
import config from 'config';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { useProductQuery } from 'lib/api/ecommerce/schema';
import { RoleAccess } from 'lib/auth';
import { Loader } from 'components/Loader';
import { Form } from './Form';

interface ProductPageProps extends RouteComponentProps<{ sku: string }> {}

export const ProductPage: React.FC<ProductPageProps> = ({
  match: { params = {} },
}) => {
  const { sku } = params;

  const { data, loading } = useProductQuery({
    skip: !sku,
    variables: { input: { sku } },
  });

  return (
    <>
      <Helmet>
        <title>Add & Edit Product | {config.siteName}</title>
      </Helmet>

      <RoleAccess permission="products:list">
        {loading && <Loader />}
        {!loading && <Form product={data?.product} />}
      </RoleAccess>
    </>
  );
};
