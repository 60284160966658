export interface Config {
  authCacheKey: string;
  authLastLoggedCacheKey: string;
  isProduction: boolean;
  sentryDsn: string;
  siteName: string;
  splitTestsApiUri: string;
  splitTestsApiKey: string;
  starmaps: { defaults: any };
  moonmaps: { defaults: any };
}

const config: Config = {
  isProduction: process.env.NODE_ENV === 'production',
  authCacheKey: 'tit_tok',
  authLastLoggedCacheKey: 'tit_last_login',
  sentryDsn:
    'https://6898bf43f654452bb0af6108ca07334e@o399801.ingest.sentry.io/5468678',
  siteName: 'AndAlways Dashboard',
  splitTestsApiUri: 'https://deployments.twinkleintime.com',
  splitTestsApiKey: '7T#Ie3lkT2', // maximum security
  starmaps: {
    defaults: {
      subject: {
        kind: 'stars',
        address: '',
        lat: 40.713,
        lon: -74.006,
        date: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate(),
          zone: 'America/New_York',
        },
        primaryColor: '#223849',
        secondaryColor: '#ffffff',
        hideConstellations: false,
        hideGraticule: false,
        whiteBackground: true,
        shape: 'circle',
      },
      theme: {
        divider: '',
        tagline: `A Twinkle In Time\nSeptember 15, 1990 \nNew York, NY, USA\n40.713°N / 74.006°W`,
        headline: '',
        kind: 'legend',
        primaryColor: '#ffffff',
        secondaryColor: '#223849',
      },
    },
  },
  moonmaps: {
    defaults: {
      subject: {
        kind: 'moon',
        address: '',
        lat: 40.713,
        lon: -74.006,
        date: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          day: new Date().getDate(),
          zone: 'America/New_York',
        },
      },
      theme: {
        divider: '',
        tagline: `Over Our Moon\nSeptember 15, 1990 \nNew York, NY, USA\n40.713°N / 74.006°W`,
        headline: '',
        kind: 'legend',
        primaryColor: '#051c2c',
        secondaryColor: '#ffffff',
      },
    },
  },
};

export default config;
