import React from 'react';
import { Badge } from 'evergreen-ui';

interface ShippingStatusBadgeProps {
  status: string;
}

export const ShippingStatusBadge: React.FC<ShippingStatusBadgeProps> = ({
  status,
}) => {
  let color = 'neutral';
  let statusName;

  if (!status) {
    color = 'neutral';
    statusName = '';
  } else {
    switch (status) {
      case 'delivered':
        color = 'green';
        break;
      case 'outForDelivery':
        color = 'blue';
        break;
      case 'inTransit':
        color = 'yellow';
        break;
      case 'infoReceived':
        color = 'teal';
        break;
      case 'exception':
        color = 'orange';
        break;
      default:
        color = 'neutral';
    }

    statusName = status;
  }

  return (
    <Badge color={color as any} marginRight={8}>
      {statusName || 'unknown'}
    </Badge>
  );
};
