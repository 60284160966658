export enum FulfillmentOptions {
  PRINTFUL = 'printful',
  ANDALWAYS = 'andalways',
}

export enum FulfillmentPods {
  POD_1 = 'Pod 1',
  POD_2 = 'Pod 2',
  POD_3 = 'Pod 3',
}

export enum FulfillmentPrinterDirectories {
  CUSTOM_PRINTS = 'Dark-BG',
}

/**
 * Shipstation
 * this isnt scalable
 * should use full HEX code anyways
 */
export enum ShipstationSkuColorCodes {
  '#fff' = 'W',
  '#a10' = 'M',
  '#831' = 'M',
  '#000' = 'B',
  '#223' = 'BL',
  '#1c2' = 'BL',
  '#051' = 'BL',
  'nebu' = 'NB',
  'kids' = 'KA',
}

export enum ShipstationShippingTags {
  PRIORITY = 69831,
  EXPRESS = 71659,
  FEDEX_HD = 69831,
}

export enum ShipstationPodTags {
  POD_1 = 104902,
  POD_2 = 104903,
  POD_3 = 106237,
}

export const ShipstationFrameTag = 68758;
export const ShipstationHangerFrameTag = 106591;
export const ShipstationRushOrderTag = 111367;

export const fulfillmentWeekends = {
  isFulfillingSaturday: false,
  isFulfillingSunday: false,
};

/*
 *  2022 Fulfillment Holidays
 */
export const fulfillmentHolidays: Date[] = [
  new Date(2021, 0, 1), // New Year's Day
  new Date(2021, 0, 20), // Martin Luther King Jr. Day
  new Date(2021, 1, 17), // President's Day
  new Date(2021, 4, 25), // Memorial Day
  new Date(2021, 6, 3), // Independence Day
  new Date(2021, 8, 6), // Labor Day
  new Date(2021, 9, 11), // Columbus Day
  new Date(2021, 10, 11), // Veterans Day
  new Date(2021, 10, 25), // Thanksgiving Day
  new Date(2020, 11, 24), // Christmas Eve
  new Date(2020, 11, 25), // Christmas Day
  // new Date(2020, 11, 26), // day after Christmas Day
  // new Date(2020, 11, 31), // New Years Eve
];

export const fulfillmentWestCoastStates: string[] = [
  'AZ',
  'CA',
  'HI',
  'AK',
  'NV',
  'ID',
  'WA',
  'OR',
  'MT',
  'WY',
  'CO',
  'NM',
  'TX',
  'UT',
];
