import BaseOrderItem, { OrderItem } from '..';
import _omit from 'lodash/omit';

export function composePrintMeta(
  printMeta: Record<string, any>,
  print?: OrderItem | BaseOrderItem
): any {
  const { width, height } = print?.printSize || {};

  // TODO: make generic for new prints
  let printKey = 'star_map';
  let url = process.env.REACT_APP_PRINT_CLIENT_URL_OLD;
  if (print?.sku.toUpperCase().includes('ZODIAC')) {
    printKey = 'zodiac';
    url = process.env.REACT_APP_PRINT_CLIENT_URL + printKey;
  }

  return {
    'andalways:print': {
      url,
      width: width ?? printMeta?.width ?? printMeta?.size?.width,
      height: height ?? printMeta?.height ?? printMeta?.size?.height,
      options: _omit(printMeta, ['width', 'height']),
    },
  };
}
