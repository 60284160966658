import React, { useState } from 'react';
import { formatCurrency } from 'lib/utils';
import _sortBy from 'lodash/sortBy';
import {
  Product,
  ProductFragment,
  useProductsQuery,
} from 'lib/api/ecommerce/schema';
import {
  Alert,
  Button,
  Dialog,
  Pane,
  Spinner,
  Text,
  TextInputField,
} from 'evergreen-ui';
import { ProductList, ProductListItem } from './AddItemsModal.styles';

interface AddItemsModalProps {
  error?: string;
  loading?: boolean;
  onClose: () => void;
  onComplete: (product: Product, quantity: number) => void | Promise<void>;
  onFilter?: (product: Product) => void;
}

export const AddItemsModal: React.FC<AddItemsModalProps> = ({
  error = null,
  loading = false,
  onClose = () => {},
  onComplete = () => {},
  onFilter,
}) => {
  const [search, setSearch] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<Product>(null!);

  const {
    data: { products = [] } = {},
    loading: fetchingProducts,
  } = useProductsQuery();

  const nonDeprecatedProducts = products.filter(
    (product) => !product.name.includes('DEPRECATED')
  );

  const sortProducts = (products: ProductFragment[]) => {
    const prints: ProductFragment[] = [];
    const framedPrints: ProductFragment[] = [];
    const frames: ProductFragment[] = [];
    const addons: ProductFragment[] = [];
    const extras: ProductFragment[] = [];

    for (let i = 0; i < products.length; i++) {
      if (products[i].category === 'prints') {
        prints.push(products[i]);
      } else if (products[i].category === 'framed-prints') {
        framedPrints.push(products[i]);
      } else if (products[i].category === 'frames') {
        frames.push(products[i]);
      } else if (products[i].category === 'addons') {
        addons.push(products[i]);
      } else {
        extras.push(products[i]);
      }
    }

    return prints.concat(framedPrints, frames, addons, extras);
  };

  const sortedProducts = sortProducts(nonDeprecatedProducts);

  const addableProducts = onFilter
    ? sortedProducts.filter(onFilter)
    : sortedProducts;

  return (
    <Dialog
      isShown={true}
      width={380}
      title="Add Item To Order"
      shouldCloseOnOverlayClick={false}
      confirmLabel={loading ? 'Loading...' : 'Submit'}
      isConfirmLoading={loading || fetchingProducts}
      onConfirm={() => onComplete(selectedProduct, 1)}
      onCloseComplete={onClose}
    >
      {error && (
        <Pane>
          <Alert
            appearance="card"
            intent="danger"
            title={error}
            marginBottom={24}
          />
        </Pane>
      )}

      <Pane display="flex" marginTop={-12}>
        <TextInputField
          placeholder="Search Products"
          inputWidth="100%"
          width="100%"
          inputHeight={34}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Pane>

      {fetchingProducts && (
        <Pane>
          <Spinner marginX="auto" marginY={20} />
        </Pane>
      )}

      {!fetchingProducts && (
        <>
          <Pane display="flex">
            <ProductList>
              {_sortBy(addableProducts, ['name'])
                .filter((product) => {
                  if (!!product.meta.isStaticPrint) {
                    return !!product.meta.isVariant;
                  }

                  return true;
                })
                .filter((product) =>
                  search.length > 0
                    ? `${product.name} ${product.sku}`
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) > -1
                    : true
                )
                .map((product) => {
                  const isSelected = selectedProduct?.sku === product.sku;

                  return (
                    <ProductListItem
                      key={product.sku}
                      isHidden={selectedProduct && !isSelected}
                    >
                      <Text>
                        {product.name} - {formatCurrency(product.unitPrice)}
                      </Text>
                      <Button
                        height={20}
                        appearance={isSelected ? 'primary' : 'default'}
                        onClick={() => {
                          setSelectedProduct(isSelected ? null! : product);
                        }}
                      >
                        {!isSelected ? 'Select' : 'Selected'}
                      </Button>
                    </ProductListItem>
                  );
                })}
            </ProductList>
          </Pane>
        </>
      )}
    </Dialog>
  );
};
