import React, { useState } from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { RouteComponentProps } from 'react-router-dom';
import { useVouchersQuery } from 'lib/api/ecommerce/schema';
import { Loader } from 'components/Loader';
import { Table } from './Table';
import { Filters } from './Table/Filters';
import { Heading, Pane } from 'evergreen-ui';

export const VouchersPage: React.FC<RouteComponentProps> = () => {
  const [searchInput, setSearchInput] = useState('');
  const { data, loading } = useVouchersQuery({
    fetchPolicy: 'network-only',
  });

  const vouchers = (data?.vouchers || []).filter((voucher) => {
    const discountStr = voucher.rules.length > 0 ? 'BOGO' : voucher.discount;
    const searchableStr = (voucher.code + discountStr).toLowerCase();

    return searchableStr.indexOf(searchInput.toLowerCase()) > -1;
  });

  return (
    <>
      <Helmet>
        <title>Vouchers | {config.siteName}</title>
      </Helmet>

      <Fade left distance="30px" duration={500}>
        <Pane>
          <Pane display="flex">
            <Pane flex={1} alignItems="center" display="flex">
              <Heading size={700} fontWeight={300}>
                Vouchers
              </Heading>
            </Pane>

            <Filters
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          </Pane>

          <Pane display="flex" flexDirection="column" flexGrow={0}>
            {loading && <Loader />}
            {!loading && <Table vouchers={vouchers} />}
          </Pane>
        </Pane>
      </Fade>
    </>
  );
};
