import React from 'react';
import { FulfillmentOptions, FulfillmentPods } from 'config/fulfillment';
import { CustomPrint, OrderItemTypes, StaticPrint } from 'models';
import { get } from 'object-path';
import dayjs from 'dayjs';
import { Container, ImageContainer } from './Item.styles';
import { Heading, Pane, Select, Paragraph } from 'evergreen-ui';

interface ItemProps {
  item: CustomPrint | StaticPrint;
  pod?: string;
  source: string;
  setFulfillment: (payload: any) => void;
}

const GenericItem = ({ item, source, setFulfillment }: ItemProps) => (
  <Pane display="flex" flexDirection="row">
    <Pane flex={1} display="flex">
      <Pane flexBasis={90}>
        <ImageContainer></ImageContainer>
      </Pane>
      <Pane flex={1} marginLeft={16} padding={12}>
        <Heading size={500}>{item.name}</Heading>
      </Pane>
    </Pane>
    <Pane flex={0} flexBasis={200} textAlign="right">
      <Select
        value={source}
        onChange={(e) => setFulfillment({ item, source: e.target?.value })}
      >
        <option value="">Don&apos;t Fulfill</option>
        <option value={FulfillmentOptions.ANDALWAYS}>AndAlways</option>
      </Select>
    </Pane>
  </Pane>
);

const PrintItem: React.FC<ItemProps> = ({
  item,
  pod,
  source,
  setFulfillment,
}) => {
  const customPrintInfo = () => {
    const printMsg = get(item, 'printMeta.theme.headline', '');

    let date = (item as CustomPrint)?.printMeta?.subject?.date;

    if (date) {
      const { month, day, year } = date;
      date = dayjs(new Date(year, month - 1, day)).format('MMMM DD, YYYY');
    }

    return (
      <Pane>
        <Heading size={500}>{item.name}</Heading>
        <Heading size={300} marginTop={5}>
          Color: {(item as CustomPrint).printShade?.toUpperCase()}
        </Heading>
        <Heading size={300} marginTop={5}>
          Poster Date: {date}
        </Heading>
        <Paragraph size={300} marginTop={5}>
          {printMsg.substring(0, 100)}
        </Paragraph>
      </Pane>
    );
  };

  return (
    <Pane display="flex" flexDirection="row">
      <Pane flex={1} display="flex">
        <Pane flexBasis={90}>
          <ImageContainer>
            <img src={item.getPreviewUrl()} alt="" />
          </ImageContainer>
        </Pane>
        <Pane flex={1} marginLeft={16} padding={12}>
          <Pane>
            {item.type !== OrderItemTypes.custom_print && (
              <Heading size={500}>{item.name}</Heading>
            )}
            {item.type === OrderItemTypes.custom_print && customPrintInfo()}
          </Pane>

          <Heading size={300} marginTop={5}>
            Quantity: {item.quantity}
          </Heading>
        </Pane>
      </Pane>

      {item.isFulfillable && !item.isDigital && (
        <Pane flex={0} flexBasis={200} textAlign="right">
          <Select
            value={source}
            onChange={(e) => setFulfillment({ item, source: e.target.value })}
          >
            <option value="">Don&apos;t Fulfill</option>
            {Object.values(FulfillmentOptions).map((fulfiller) => {
              const formattedName =
                fulfiller.charAt(0).toUpperCase() + fulfiller.slice(1);

              return (
                <option key={formattedName} value={fulfiller}>
                  {formattedName}
                </option>
              );
            })}
          </Select>

          {source === FulfillmentOptions.ANDALWAYS && (
            <Select
              marginTop={5}
              value={pod}
              onChange={(e) =>
                setFulfillment({ item, source, pod: e.target.value })
              }
            >
              <option value="">Select Pod</option>
              {Object.values(FulfillmentPods).map((pod) => (
                <option key={`pod-${pod}`} value={pod}>
                  {pod}
                </option>
              ))}
            </Select>
          )}
        </Pane>
      )}
    </Pane>
  );
};

export const Item: React.FC<ItemProps> = (props) => (
  <Container>
    {props.item.isPrint ? <PrintItem {...props} /> : <GenericItem {...props} />}
  </Container>
);
