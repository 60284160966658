import styled from 'styled-components';

export const LocationWrapper = styled.div`
  position: relative;
`;

export const SuggestionsContainer = styled.div`
  z-index: 999;
  display: block;
  margin-bottom: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  z-index: 100;
  background-color: #fff;
  margin-top: -7px;
  border-top: none;
  cursor: pointer;
`;

export const Suggestion = styled.div`
  padding: 1em;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
  ${(props: { active?: boolean }) =>
    props.active &&
    `
      color: #fff;
      background-color: #f4364c;
    `};
`;
