import React, { useMemo } from 'react';
import { Button, SelectMenu } from 'evergreen-ui';
import { ORDER_FILTERS, OrderFilterType } from './filter-types';

/**
 * DEPRECATED
 */

interface OrderFilterSelectProps {
  exclude?: OrderFilterType[];
  hasFilter?: boolean;
  isMultiSelect?: boolean;
  selected: OrderFilterType[];
  title?: string;
  onChange: (selected: string[]) => void;
}

interface OrderFilterSelectItem {
  label: string;
  value: string;
}

export const OrderFilterSelect: React.FC<OrderFilterSelectProps> = ({
  exclude,
  hasFilter = false,
  isMultiSelect = false,
  selected = [],
  title = 'Filters',
  onChange = () => {},
}) => {
  const options = useMemo(() => {
    let filters = ORDER_FILTERS.filter((filter) => !filter.storeFilter);

    if (exclude) {
      filters = filters.filter(({ type }) => !exclude.includes(type));
    }

    return filters.map(({ label, type }) => ({ label, value: type }));
  }, [exclude]);

  const onSelect = (item: OrderFilterSelectItem) =>
    onChange([...(selected as any), item.value]);

  const onDeselect = (item: OrderFilterSelectItem) => {
    const deselectedItemIndex = (selected as any).indexOf(item.value);
    const selectedItems = (selected as any).filter(
      (_, i) => i !== deselectedItemIndex
    );

    onChange(selectedItems);
  };

  return (
    <SelectMenu
      isMultiSelect={isMultiSelect}
      hasFilter={hasFilter}
      title={title}
      options={options}
      selected={selected}
      onSelect={onSelect}
      onDeselect={onDeselect}
    >
      <Button marginLeft={15} iconAfter="caret-down">
        Filters
      </Button>
    </SelectMenu>
  );
};
