import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Grid from 'react-awesome-styled-grid';
import Fade from 'react-reveal/Fade';
import dateFormat from 'date-fns/format';
import {
  useRegisterUserMutation,
  useUpdateUserMutation,
} from 'lib/api/ecommerce/schema';
import { roles } from 'lib/auth/roles-permissions';
import { validate } from './validation';
import { User, cleanUserArgs, cleanUserArgsNoPassword } from './utils';
import {
  Avatar,
  Badge,
  Button,
  CaretDownIcon,
  CaretUpIcon,
  Heading,
  Pane,
  SelectMenu,
  TextInputField,
  toaster,
} from 'evergreen-ui';

interface FormProps {
  user?: User | null;
}

interface FormState extends Partial<User> {}

export const UserForm: React.FC<FormProps> = ({ user }) => {
  const history = useHistory();
  const sortedRoles = Object.keys(roles).sort();
  const [options] = useState(
    sortedRoles.map((label) => ({
      label,
      value: label,
    }))
  );
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [addUser, { loading: isAdding }] = useRegisterUserMutation();
  const [updateUser, { loading: isUpdating }] = useUpdateUserMutation();
  const [errors, setErrors] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(user ? true : false);
  const [selectActive, setSelectActive] = useState<boolean>(false);
  const [form, setForm] = useState<FormState>(
    Object.assign(
      {},
      {
        name: '',
        email: '',
        password: '',
        roles: [],
        isDisabled: false,
      },
      user
    )
  );

  useEffect(() => {
    setSelectedRoles(user ? user?.roles : []);
  }, [user]);

  const userRoles = (
    <>
      {selectedRoles?.length === 0 ? (
        <span
          style={{
            fontSize: '14px',
            color: '#D14343',
          }}
        >
          User must have at least one role!
        </span>
      ) : (
        selectedRoles?.map((role) => (
          <Badge key={role} marginRight="8px" color="blue">
            {role}
          </Badge>
        ))
      )}
    </>
  );

  const isLoading = isUpdating || isAdding;

  return (
    <Fade key="User" left distance="30px" duration={500}>
      <>
        <Pane display="flex" marginBottom={40}>
          <Pane flex={1} alignItems="center">
            <Button
              iconBefore="arrow-left"
              onClick={() => history.push('/users')}
            >
              Back
            </Button>
          </Pane>
          <Pane alignItems="center" display="flex">
            {isDisabled ? (
              <Button onClick={() => setIsDisabled(false)}>Edit User</Button>
            ) : (
              <Button
                intent="success"
                appearance="primary"
                isLoading={isLoading}
                onClick={async () => {
                  const errors = await validate(form, user);

                  if (errors) {
                    return setErrors(errors);
                  }

                  try {
                    const input = !form.password
                      ? { ...cleanUserArgsNoPassword(form) }
                      : { ...cleanUserArgs(form) };
                    const submit = input.id ? updateUser : addUser;

                    let { id, ...rest } = input;

                    const data: any = !user
                      ? { input: { ...rest } }
                      : { input: { id, data: rest } };

                    await submit({ variables: { ...data } });

                    toaster.success(
                      `User ${form.name} ${!user ? 'added' : 'saved'}.`,
                      {
                        duration: 3,
                      }
                    );

                    history.push('/users');
                  } catch (e) {
                    toaster.danger(e.message);
                  }
                }}
              >
                {user ? 'Save User' : 'Add User'}
              </Button>
            )}
          </Pane>
        </Pane>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={8} offset={{ lg: 2 }}>
              <Pane
                padding={22}
                elevation={1}
                borderRadius="8px"
                backgroundColor="#fdfdfd"
              >
                <Pane display="flex" alignItems="center" marginBottom={20}>
                  <Avatar name={form.name} size={64} />
                  <Heading
                    size={600}
                    style={{ fontWeight: '400' }}
                    marginLeft={20}
                  >
                    {form.name}
                  </Heading>
                </Pane>

                <Pane color="#425A70" marginBottom="24px">
                  <div style={{ margin: '0 0 8px 0' }}>
                    <label
                      style={{
                        fontSize: '14px',
                        margin: '0 8px 8px 0',
                        fontWeight: 500,
                      }}
                    >
                      Created:
                    </label>
                    <span>
                      {user
                        ? dateFormat(
                            new Date(user?.createdAt),
                            "MMMM do yyyy 'at' h:mm a"
                          )
                        : 'TBD'}
                    </span>
                  </div>
                  <div>
                    <label
                      style={{
                        fontSize: '14px',
                        margin: '0 8px 8px 0',
                        fontWeight: 500,
                      }}
                    >
                      Last Updated:
                    </label>
                    <span>
                      {user
                        ? dateFormat(
                            new Date(user?.updatedAt),
                            "MMMM do yyyy 'at' h:mm a"
                          )
                        : 'TBD'}
                    </span>
                  </div>
                </Pane>
                <Pane display="flex">
                  <TextInputField
                    required
                    label="Name"
                    width="100%"
                    placeholder="Name"
                    inputWidth="100%"
                    inputHeight={40}
                    disabled={isDisabled}
                    isInvalid={!!errors?.name}
                    validationMessage={errors?.name?.message}
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </Pane>
                <Pane display="flex">
                  <TextInputField
                    required
                    label="Email"
                    placeholder="Email"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={isDisabled}
                    isInvalid={!!errors?.email}
                    validationMessage={errors?.email?.message}
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </Pane>
                <Pane display="flex">
                  <TextInputField
                    type="password"
                    autoComplete="new-password"
                    required={user ? false : true}
                    label={user ? 'Change Password' : 'Create Password'}
                    placeholder="Password"
                    inputWidth="100%"
                    width="100%"
                    inputHeight={40}
                    disabled={isDisabled}
                    isInvalid={!!errors?.password}
                    validationMessage={errors?.password?.message}
                    value={form.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                  />
                </Pane>
                <Pane>
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#425A70',
                      display: 'block',
                    }}
                  >
                    Status
                  </label>
                  <Pane
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {form.isDisabled ? (
                      <Badge color="red">Disabled</Badge>
                    ) : (
                      <Badge color="green">Enabled</Badge>
                    )}
                    <Button
                      appearance="primary"
                      intent={!form.isDisabled ? 'danger' : 'success'}
                      disabled={isDisabled}
                      style={{ marginTop: '12px' }}
                      onClick={() =>
                        setForm({ ...form, isDisabled: !form.isDisabled })
                      }
                    >
                      {form.isDisabled ? 'Enable User' : 'Disable User'}
                    </Button>
                  </Pane>
                </Pane>
                <Pane marginTop="12px">
                  <label
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: '#425A70',
                      display: 'block',
                    }}
                  >
                    Role(s)
                  </label>
                  <Pane
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <p>{userRoles}</p>
                    <SelectMenu
                      isMultiSelect
                      title="Select one or more roles"
                      options={options}
                      selected={selectedRoles}
                      hasFilter={false}
                      onSelect={(item) => {
                        const selected = [...selectedRoles, item.value];
                        setSelectedRoles(selected.sort());
                        setForm({ ...form, roles: selected });
                      }}
                      onDeselect={(item) => {
                        const deselectedItemIndex = selectedRoles.indexOf(
                          item.value
                        );
                        const selected = selectedRoles.filter(
                          (_item, i) => i !== deselectedItemIndex
                        );
                        setSelectedRoles(selected);
                        setForm({ ...form, roles: selected });
                      }}
                      onOpen={() => setSelectActive(true)}
                      onClose={() => setSelectActive(false)}
                    >
                      <Button disabled={isDisabled} appearance="primary">
                        <span style={{ margin: '0 8px 0 0' }}>
                          {selectedRoles?.length === 0
                            ? 'Select role(s)...'
                            : 'Add/remove role(s)'}
                        </span>
                        {selectActive ? <CaretUpIcon /> : <CaretDownIcon />}
                      </Button>
                    </SelectMenu>
                  </Pane>
                </Pane>
              </Pane>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </>
    </Fade>
  );
};
