import { shippingRates } from 'config/shipping';
import { get, has } from 'object-path';
import { BaseOrder } from '../..';

interface AvailableShippingMethod {
  name: string;
  method: string;
  price: number;
}

export const getAvailableShippingMethods = (
  order: BaseOrder
): AvailableShippingMethod[] => {
  const availableMethods: any = [];
  const orderShippingCountry = get(
    order,
    'shippingAddress.country',
    ''
  ).toLowerCase();

  shippingRates.forEach((method) => {
    let rate = has(method, ['rates', orderShippingCountry])
      ? get(method, ['rates', orderShippingCountry])
      : get(method, ['rates', 'other']);

    rate = order.getHasFrame() ? rate?.framed : rate?.unframed;

    if (rate) {
      availableMethods.push({
        name: method.name,
        method: method.method,
        price: rate.price,
      });
    }
  });

  return availableMethods;
};
