import React, { useState, useRef, useEffect } from 'react';
import config from 'config';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { connect } from 'redux-zero/react';
import { BaseOrder } from 'models';
import { useOrderAndTimelinesById } from 'lib/api/ecommerce/hooks';
import { Pane } from 'evergreen-ui';
import { Loader } from 'components/Loader';
import { OrderPaidMismatchAlert } from 'components/OrderPaidMismatchAlert';
import { OtherPendingCancelAlert } from 'components/OtherPendingCancelAlert';
import { CancellationAlert } from './components/CancellationAlert';
import {
  CustomerInfo,
  DigitalPrintAlert,
  Notes,
  OrderTable,
  Payments,
  Shipments,
  Fulfillments,
  Header,
  TopButtons,
  OtherOrders,
  ChangeLogs,
} from './components';

interface OrderPageProps extends RouteComponentProps<{ id: string }> {
  preservedOrders: BaseOrder[];
  onClose: () => void;
}

export const OrderPage: React.FC<OrderPageProps> = ({
  match: { params },
  preservedOrders,
  onClose = () => {},
}) => {
  const history = useHistory();
  const otherOrdersRef = useRef<HTMLDivElement>(null);
  const [showAlert, setShowAlert] = useState(false);
  const { order, loading } = useOrderAndTimelinesById(params.id);

  const handleScroll = () => {
    if (otherOrdersRef.current) {
      otherOrdersRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // for navigation arrows
  const [orderIndex, setOrderIndex] = useState<number>(0);

  useEffect(() => {
    setOrderIndex(preservedOrders.findIndex((o) => o.id === order?.id));
  }, [loading, preservedOrders, order, setOrderIndex]);

  const numOrders = preservedOrders.length;
  const isFirstOrder = orderIndex === 0;
  const isLastOrder = orderIndex === preservedOrders.length - 1;

  const onPrevOrder = () => {
    setOrderIndex(isFirstOrder ? orderIndex : orderIndex - 1);
    history.push(`/orders/${preservedOrders[orderIndex - 1].id}`);
  };

  const onNextOrder = () => {
    if (isLastOrder) {
      onClose();
    } else {
      setOrderIndex(orderIndex + 1);
      history.push(`/orders/${preservedOrders[orderIndex + 1].id}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Order | {config.siteName}</title>
      </Helmet>

      {loading && <Loader />}
      {!loading && !order && 'Order not found.'}

      {!loading && order && (
        <Fade key="OrderPage" left distance="20px" duration={500}>
          <TopButtons
            order={order}
            onPrevOrder={onPrevOrder}
            onNextOrder={onNextOrder}
            orderIndex={orderIndex}
            numOrders={numOrders}
          />
          <Header order={order} />

          <Pane display="flex" flexDirection="row" alignItems="flex-start">
            <Pane flex={1}>
              {showAlert && <OtherPendingCancelAlert onClick={handleScroll} />}
              <OrderPaidMismatchAlert order={order} />
              <DigitalPrintAlert order={order} />
              <CancellationAlert order={order} />
              <CustomerInfo order={order} />
              <Notes order={order} />
              <OrderTable order={order} />
              <Payments order={order} />
              <Shipments order={order} />
              <Fulfillments order={order} />
              <OtherOrders
                ref={otherOrdersRef}
                order={order}
                setShowAlert={setShowAlert}
              />
              <ChangeLogs order={order} />
            </Pane>
          </Pane>
        </Fade>
      )}
    </>
  );
};

export default connect((store) => ({
  preservedOrders: store.preservedOrders,
}))(OrderPage);
