import { BaseOrder } from '../..';

export function getIsMultiColorFileSuffix(order: BaseOrder): string {
  let hasDarkPrint = false;
  let hasWhitePrint = false;

  const prints = order.getPrints();

  prints.forEach((print: any) => {
    if (print.printShade === 'dark') {
      hasDarkPrint = true;
    }

    if (print.printShade === 'white') {
      hasWhitePrint = true;
    }
  });

  return hasWhitePrint && hasDarkPrint ? '_multi' : '';
}
