import { BaseOrder, OrderStatus } from 'models';

export const hasPrevPendingCancellationOrder = (
  order: BaseOrder,
  prevOrders: BaseOrder[]
) => {
  return prevOrders
    .slice(0, 10)
    .filter(
      (o) => o.isPaid && o.id !== order.id && new Date(o.paidAt) < order.paidAt
    )
    .some((o) => o.status === OrderStatus.PendingCancel);
};
