import React from 'react';
import { OrderStatus } from 'models';
import { Button, SelectMenu } from 'evergreen-ui';

interface OrderStatusSelectProps {
  hasFilter?: boolean;
  isMultiSelect?: boolean;
  selected?: string[];
  title?: string;
  onChange: (selected: string[]) => void;
}

interface StatusItem {
  label: string;
  value: OrderStatus | 'all';
}

const StatusOptions: StatusItem[] = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: OrderStatus.Draft },
  { label: 'Hold', value: OrderStatus.Hold },
  { label: 'Customer Hold', value: OrderStatus.CustomerHold },
  { label: 'Cancelled', value: OrderStatus.Cancelled },
  { label: 'Pending Cancellation', value: OrderStatus.PendingCancel },
  { label: 'Partially Fulfilled', value: OrderStatus.PartiallyFulfilled },
  { label: 'Fulfilled', value: OrderStatus.Fulfilled },
];

export const OrderStatusSelect: React.FC<OrderStatusSelectProps> = ({
  hasFilter = false,
  isMultiSelect = false,
  selected = [],
  title = 'Status',
  onChange = () => {},
}) => {
  const onSelectItem = (item: StatusItem) => {
    if (item.value === 'all') return onChange(['all']);

    const newSelected = [...(selected as any), item.value];
    let selectedItems = newSelected;

    if (selectedItems.length > 1 && selectedItems.includes('all'))
      selectedItems = selectedItems.filter((item) => item !== 'all');

    onChange(selectedItems);
  };

  const onDeselectItem = (item: StatusItem) => {
    const deselectedItemIndex = (selected as any).indexOf(item.value);
    const selectedItems = (selected as any).filter(
      (_, i) => i !== deselectedItemIndex
    );

    if (selectedItems.length === 0) return onChange(['all']);

    onChange(selectedItems);
  };

  return (
    <SelectMenu
      hasFilter={hasFilter}
      isMultiSelect={isMultiSelect}
      options={StatusOptions}
      selected={selected}
      title={title}
      onSelect={onSelectItem}
      onDeselect={onDeselectItem}
    >
      <Button iconAfter="caret-down">{title}</Button>
    </SelectMenu>
  );
};
