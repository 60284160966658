import React, { memo, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Order } from 'models';
import { useOrderFulfillment } from 'lib/api/ecommerce/hooks';
import { Button, toaster } from 'evergreen-ui';

interface QuickFulfillButtonProps {
  order: Order;
}

export const QuickFulfillButton: React.FC<QuickFulfillButtonProps> = memo(
  ({ order }) => {
    const { submitFulfillment, isLoading } = useOrderFulfillment(order);

    const onSubmit = useCallback(async () => {
      const res = await submitFulfillment();

      if (!res.ok) {
        toaster.danger(res.error?.message || 'Something bad happened', {
          duration: 5,
        });

        return;
      }

      toaster.success('Order submitted for fulfillment', {
        duration: 5,
      });
    }, [submitFulfillment]);

    useHotkeys(
      'm',
      () => {
        if (!isLoading && !order.isFulfilled) {
          onSubmit();
        }
      },
      [order, submitFulfillment, isLoading]
    );

    return (
      <Button
        marginRight={16}
        appearance="primary"
        onClick={() => onSubmit()}
        disabled={isLoading || order.isFulfilled}
      >
        Quick Fulfill
      </Button>
    );
  }
);
