import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Inner = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`;

export const PrintWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 500px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;

  * {
    box-sizing: border-box;
  }
`;
