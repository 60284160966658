import { get } from 'object-path';
import type { Order } from 'models';
import type { OrdersPageFilters } from 'pages/orders/types';

const DARK_PRINT_COLORS = [
  '#000000',
  '#223849',
  '#1c2832',
  '#a10459',
  '#83164d',
];

const LIGHT_PRINT_COLORS = ['#ffffff', '#FFFFFF'];

type PrepareOrderArguments = Partial<OrdersPageFilters>;

export const prepareOrderArguments = (
  args: PrepareOrderArguments
): PrepareOrderArguments => {
  Object.keys(args).forEach((k) => args[k] == null && delete args[k]);

  if (args?.status?.includes('all')) delete args.status;

  return args;
};

export function formatDate(value: any, hideYear: boolean = false): string {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (typeof value === 'string') {
    const { month, day, year } = getMonthDayYearFromISOString(value);

    if (hideYear) return `${months[month - 1]} ${day}`;

    return `${months[month - 1]} ${day}, ${year}`;
  }

  const { day, month, year } = value;

  return `${months[month - 1]} ${day}, ${year}`;
}

export const formatCurrency = (value: number): string => {
  if (typeof value !== 'number') throw new Error('value must be a number');

  return `$${(value / 100).toFixed(2)}`;
};

export function getMonthDayYearFromISOString(
  ISODate: string
): { month: number; day: number; year: number; hour: number } {
  return getMonthDayYearFromDate(new Date(ISODate));
}

export function getMonthDayYearFromDate(
  date: Date
): { month: number; day: number; year: number; hour: number } {
  return {
    hour: date.getHours() + 1,
    month: date.getMonth() + 1,
    day: date.getDate(),
    year: date.getFullYear(),
  };
}

export const getIsMultiColorFileSuffix = (order: Order) => {
  let hasDarkPoster = false;
  let hasLightPoster = false;

  order.getPrints().forEach((item) => {
    if (item.isPrint) return;

    const meta = item.printMeta;

    if (DARK_PRINT_COLORS.includes(meta.theme.primaryColor)) {
      hasDarkPoster = true;
    }

    if (LIGHT_PRINT_COLORS.includes(meta.theme.primaryColor))
      hasLightPoster = true;
  });

  return hasDarkPoster && hasLightPoster ? '_multi' : '';
};

export function concatPrintMessages(order: Order): string {
  const messages: string[] = order?.getPrints().map((item) => {
    const meta = item.printMeta;

    if (!get(meta, 'theme', null)) return '';

    return get(meta, 'theme.headline', '');
  });

  return messages.join(' ');
}
