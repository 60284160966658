import React, { useState } from 'react';
import { format as FormatDate } from 'date-fns';
import { Order } from 'models';
import { RoleAccess } from 'lib/auth';
import { Alert, Button, Pane, Paragraph, toaster } from 'evergreen-ui';
import { CartStatus } from 'lib/api/ecommerce/schema';

interface CancellationAlertProps {
  order: Order;
}

const CancelledAlert = (order: Order) => {
  const cancellation = order.getCancellation();

  const { createdAt, meta = {} } = cancellation || {};
  const { reason, comments } = meta;

  return (
    <Pane marginTop={10}>
      <Alert
        marginBottom={24}
        appearance="card"
        intent="danger"
        title={`Cancelled - ${
          createdAt
            ? FormatDate(new Date(createdAt), 'E, MMM do yyyy h:mm a')
            : 'n/a'
        }`}
      >
        {reason && <Paragraph marginTop={5}>Reason: {reason}</Paragraph>}
        {comments && (
          <Paragraph marginTop={5}>
            <i>{comments}</i>
          </Paragraph>
        )}
      </Alert>
    </Pane>
  );
};

const PendingCancelAlert = (order: Order) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const cancellation = order.getPendingCancellation();

  const { createdAt, meta = {} } = cancellation || {};
  const { reason, comments } = meta;

  return (
    <Pane marginTop={10}>
      <Alert
        marginBottom={24}
        appearance="card"
        intent="warning"
        title={`Pending Cancellation - ${
          createdAt
            ? FormatDate(new Date(createdAt), 'E, MMM do yyyy h:mm a')
            : 'n/a'
        }`}
      >
        {reason && <Paragraph marginTop={5}>Reason: {reason}</Paragraph>}
        {comments && (
          <Paragraph marginTop={5}>
            <i>{comments}</i>
          </Paragraph>
        )}
        <RoleAccess permission="orders:approve-cancellation">
          <Button
            intent="warning"
            appearance="primary"
            marginTop={16}
            isLoading={isLoading}
            onClick={async () => {
              setLoading(true);

              const res = await order.cancel(reason, comments);

              if (!res.ok) {
                toaster.danger(res.error?.message, { duration: 2 });
              } else {
                toaster.success('Order has been cancelled.', { duration: 2 });
              }

              setLoading(false);
            }}
            disabled={isLoading}
          >
            Approve Cancellation
          </Button>
        </RoleAccess>
      </Alert>
    </Pane>
  );
};

export const CancellationAlert: React.FC<CancellationAlertProps> = ({
  order,
}) => {
  const isCancelled = order.status === CartStatus.Cancelled;
  const isPendingCancel = order.status === CartStatus.PendingCancel;

  if (!isPendingCancel && !isCancelled) {
    return null;
  }

  return isPendingCancel ? PendingCancelAlert(order) : CancelledAlert(order);
};
