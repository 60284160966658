import { useCallback, useEffect, useState } from 'react';
import { Order, FulfillmentItem, CustomPrint, StaticPrint } from 'models';
import { FulfillmentOptions } from 'config/fulfillment';
import store from 'lib/store';

interface UseOrderFulfillmentResult {
  error: string;
  isLoading: boolean;
  fulfillments: FulfillmentItem[];
  setFulfillment: (item: FulfillmentItem) => void;
  submitFulfillment: () => Promise<any>; // fix any
}

const PrintfulItems = ['P24X36'];

function composeFulfillments(order: Order) {
  const { fulfillment } = store.getState();

  const items = order.items.filter(
    (item) => !item.isAddOn && !item.isFulfilled
  ) as (CustomPrint | StaticPrint)[];

  return items.map((item) => {
    const isPrintfulItem = PrintfulItems.some((i) => item.sku.includes(i));

    return {
      item,
      pod: fulfillment.latest.pod,
      source: isPrintfulItem
        ? FulfillmentOptions.PRINTFUL
        : fulfillment.latest.source,
    };
  });
}

export function useOrderFulfillment(order: Order): UseOrderFulfillmentResult {
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fulfillments, setFulfillments] = useState<FulfillmentItem[]>(
    composeFulfillments(order)
  );

  const setFulfillment = useCallback(
    ({ item, source, pod }: FulfillmentItem) => {
      let items: FulfillmentItem[] = [];

      // keep order and force pod for all AA fulfilled items
      fulfillments.forEach((fulfillment) => {
        if (fulfillment.item.id === item.id) {
          items.push({ item, source, pod });
        } else {
          items.push({ ...fulfillment, pod: pod || fulfillment.pod });
        }
      });

      setFulfillments(items);
      store.setState({ fulfillment: { latest: { source, pod } } });
    },
    [fulfillments]
  );

  const submitFulfillment = async () => {
    setError(null);
    setIsLoading(true);

    const res = await order.fulfill(fulfillments);

    if (!res.ok) {
      setError(res.error?.message);
    }

    setIsLoading(false);

    return res;
  };

  useEffect(() => {
    setFulfillments(composeFulfillments(order));
  }, [order]);

  return {
    error,
    isLoading,
    fulfillments,
    setFulfillment,
    submitFulfillment,
  };
}
