import { useMemo } from 'react';
import { plainToClass } from 'class-transformer';
import {
  CartsBasicQueryVariables,
  useCartsBasicQuery,
} from 'lib/api/ecommerce/schema';
import { BaseOrder } from 'models';

interface UseOrdersBasicResult {
  orders: BaseOrder[];
  error: any;
  loading: boolean;
  refetch: any;
}

interface UseOrdersBasicArgs {
  variables: CartsBasicQueryVariables;
  onCompleted: (data: any) => void;
}

export function useOrdersBasic({
  variables,
  onCompleted,
}: UseOrdersBasicArgs): UseOrdersBasicResult {
  const { data, loading, error, refetch } = useCartsBasicQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables,
    onCompleted,
  });

  const orders = useMemo(() => {
    if (data) {
      return plainToClass(BaseOrder, data.carts || []);
    }

    return [];
  }, [data]);

  return { orders, loading, error, refetch };
}
