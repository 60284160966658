export const quotes: string[] = [
  'I get enough exercise pushing my luck.',
  'Believe you can and you’re halfway there.',
  'The cure for boredom is curiosity. There is no cure for curiosity.',
  'Age is of no importance unless you’re a cheese.',
  'A clear conscience is a sure sign of a bad memory.',
  'Always remember that you are unique  –  just like everybody else.',
  'A day without sunshine is like, you know, night.',
  'Be happy  –  it drives people crazy.',
  'I didn’t fail the test. I just found 100 ways to do it wrong.',
  'Too much of a good thing can be wonderful.',
  "Sometimes when I close my eyes....I can't see.",
  'Congratulations on getting through the easiest part of life.',
  'Before you marry a person, you should first make them use a computer with slow Internet to see who they really are.',
  'Never follow anyone else’s path. Unless you’re in the woods and you’re lost and you see a path. Then by all means follow that path.',
  'I’m not superstitious, but I am a little stitious.',
  'There is no sunrise so beautiful that it is worth waking me up to see it.',
  'I never feel more alone than when I’m trying to put sunscreen on my back.',
  'I’m at a place in my life when errands are starting to count as going out.',
  'As you get older, three things happen. The first is your memory goes, and I can’t remember the other two.',
  'I never forget a face — but in your case, I’ll be glad to make an exception.',
  'Why do they call it rush hour when nothing moves?',
  'Never put off till tomorrow what you can do the day after tomorrow just as well.',
  'Insanity runs in my family. It practically gallops.',
  'I have a lot of growing up to do. I realized that the other day inside my fort.',
  'Trying is the first step toward failure.',
  'Life is short. Smile while you still have teeth.',
  'When nothing goes right, go left.',
  "My alone time is for everyone's safety.",
  "Friends don't let friends do stupid things...alone.",
  'People say nothing is impossible, but I do nothing everyday.',
  'Life is too short to remove the USB safely.',
  "Zombies eat brains, you're safe.",
  'Surround your self with tacos, not negativity.',
  'The difference between genius and stupidity is; genius has its limits.',
  'A mathematician is a device for turning coffee into theorems.',
];
