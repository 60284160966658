import React, { useState } from 'react';
import { FulfillmentPods } from 'config/fulfillment';
import store from 'lib/store';
import { Order, CustomPrint, StaticPrint } from 'models';
import { Dialog, Pane, Select, Text, Heading, toaster } from 'evergreen-ui';

interface PrinterSubmissionModalProps {
  item: CustomPrint | StaticPrint;
  order: Order;
  onClose: () => void;
}

export const PrinterSubmissionModal: React.FC<PrinterSubmissionModalProps> = ({
  item,
  order,
  onClose = () => {},
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pod, setPod] = useState<FulfillmentPods>(() => {
    const { fulfillment } = store.getState();

    return fulfillment.latest.pod || FulfillmentPods.POD_2;
  });
  const [quantity, setQuantity] = useState<number>(item.quantity);

  const setAmounts = () => {
    const amounts: number[] = [];

    for (let i = 1; i < 100; i++) {
      amounts.push(i);
    }

    return amounts;
  };

  const onSubmit = async () => {
    setLoading(true);

    const res = await order.sendItemsToPrinter([item], pod, quantity);

    if (!res.ok) {
      setLoading(false);
      toaster.danger(res.error?.message || 'Error submitting print.');
      return;
    }

    toaster.success(`Successfully submitted to ${pod}`);
    onClose();
  };

  return (
    <Dialog
      width={380}
      isShown={true}
      title="Send to Printer"
      shouldCloseOnOverlayClick={false}
      confirmLabel={isLoading ? 'Loading...' : 'Submit'}
      isConfirmLoading={isLoading}
      onConfirm={onSubmit}
      onCloseComplete={onClose}
    >
      <Pane>
        <Heading>Pod</Heading>
      </Pane>
      <Pane display="flex">
        <Select
          width="100%"
          height={52}
          marginTop={5}
          value={pod}
          onChange={(e) => setPod(e.target.value as FulfillmentPods)}
        >
          <option value="">Select Pod</option>

          {Object.values(FulfillmentPods).map((pod) => (
            <option value={pod}>{pod}</option>
          ))}
        </Select>
      </Pane>

      <Pane>
        <Heading marginTop={20}>Quantity</Heading>
      </Pane>
      <Pane>
        <Select
          width="100%"
          height={52}
          marginTop={5}
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        >
          {setAmounts().map((amount) => (
            <option value={amount}>{amount}</option>
          ))}
        </Select>
      </Pane>

      <Pane marginTop={16}>
        <Text size={300} color="muted">
          The print will be sent directly to the fulfillment pod and not sent to
          ShipStation.
        </Text>
      </Pane>
    </Dialog>
  );
};
