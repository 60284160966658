import React from 'react';
import { VoucherDiscountType } from 'lib/api/ecommerce/schema';
import { Pane, TextInputField, SegmentedControl } from 'evergreen-ui';

interface BasicFormProps {
  voucher: any;
  loading: boolean;
  setForm: (values: any) => void;
}

export const BasicForm: React.FC<BasicFormProps> = ({
  voucher,
  loading,
  setForm,
}) => {
  const isPercentage = voucher.type === VoucherDiscountType.Percent;
  const hint = isPercentage ? 'Number between 0 and 100' : 'Any whole number';
  const label = `${isPercentage ? 'Percent' : 'Fixed'} Discount (${
    isPercentage ? '%' : '$'
  })`;

  return (
    <>
      <Pane display="flex" flexDirection="row">
        <TextInputField
          required
          hint={hint}
          label={label}
          placeholder="Discount"
          width="100%"
          flex={1}
          type="number"
          inputHeight={40}
          disabled={loading}
          value={voucher.discount || ''}
          onChange={(e) => setForm({ ...voucher, discount: e.target.value })}
        />
        <SegmentedControl
          flex={0}
          flexBasis={120}
          marginTop={24}
          paddingLeft={8}
          height={40}
          width="100%"
          options={[
            { label: '%', value: VoucherDiscountType.Percent },
            { label: '$', value: VoucherDiscountType.Amount },
          ]}
          value={voucher.type}
          onChange={(value) => setForm({ ...voucher, type: value })}
        />
      </Pane>
    </>
  );
};
