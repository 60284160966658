import React from 'react';
import { Table } from 'evergreen-ui';

export const Header: React.FC = () => (
  <Table.Head borderRadius={6} elevation={1}>
    <Table.TextHeaderCell>Code</Table.TextHeaderCell>
    <Table.TextHeaderCell>Type</Table.TextHeaderCell>
    <Table.HeaderCell flex={0} flexBasis={280} width={280}>
      Discount
    </Table.HeaderCell>
    <Table.TextHeaderCell>Created</Table.TextHeaderCell>
    <Table.TextHeaderCell>Expires</Table.TextHeaderCell>
    <Table.HeaderCell
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      Used
    </Table.HeaderCell>
  </Table.Head>
);
