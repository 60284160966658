import { VoucherDiscountType } from 'lib/api/ecommerce/schema';

export const formatArguments = ({
  id,
  code,
  type,
  discount,
  startsAt,
  expiresAt,
  maxUses,
  rules,
}: any): any => {
  if (!code) return {};

  let theDiscount;

  if (rules && rules.length > 0) theDiscount = 0;
  else
    theDiscount =
      type === VoucherDiscountType.Amount
        ? parseInt(discount) * 100
        : parseFloat(discount) / 100;

  return {
    id: id || null,
    code: code.toUpperCase(),
    type,
    discount: theDiscount,
    startsAt: startsAt || new Date().toISOString(),
    expiresAt: expiresAt,
    maxUses: parseInt(maxUses),
    rules,
  };
};

export const parseArguments = ({
  id,
  code,
  type,
  discount,
  startsAt,
  expiresAt,
  maxUses,
  rules,
}: any): any => {
  if (!code) return {};

  return {
    id: id || null,
    code,
    type,
    discount:
      type === VoucherDiscountType.Amount
        ? parseInt(discount) / 100
        : parseFloat(discount) * 100,
    startsAt: new Date(startsAt),
    expiresAt: expiresAt ? new Date(expiresAt) : undefined,
    maxUses,
    rules,
  };
};
