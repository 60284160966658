import React, { useState } from 'react';
import useInterval from 'use-interval';
import { useFulfillment } from '../../../FulfillmentProvider';
import { CustomPrintEditorModal } from 'components/CustomPrintEditorModal';
import { Button, Pane, toaster } from 'evergreen-ui';
import { Image } from './Image';
import { PrintWrapper } from './Print.styles';
import { CustomPrint } from 'models/OrderItem/CustomPrint';

export const Print: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEditorModalOpen, setEditorModalOpen] = useState<boolean>(false);
  const [isRegeneratingPrint, setRegeneratingPrint] = useState<boolean>(false);
  const {
    numPrints,
    order,
    print,
    printIndex,
    onNextPrint,
    onPrevPrint,
  } = useFulfillment();

  useInterval(
    async () => {
      if (!order || !print) return;

      const printFileExists = await order?.printFileExists(print.id);

      if (printFileExists) {
        setRegeneratingPrint(false);
      }
    },
    isRegeneratingPrint ? 1000 : null
  );

  if (!order || !print) return null;

  const isFirstPrint = printIndex === 0;
  const isLastPrint = printIndex + 1 === numPrints;

  return (
    <>
      <Pane
        flex="1"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="column"
        overflowY="scroll"
      >
        {!isRegeneratingPrint && numPrints > 1 && (
          <Pane marginBottom={10}>
            <Button
              marginRight={10}
              iconBefore="arrow-left"
              disabled={isFirstPrint}
              onClick={() => onPrevPrint()}
            >
              Prev
            </Button>
            <Button
              iconAfter="arrow-right"
              disabled={isLastPrint}
              onClick={() => onNextPrint()}
            >
              Next
            </Button>
          </Pane>
        )}

        <PrintWrapper>
          <Image
            isRegeneratingPrint={isRegeneratingPrint}
            setRegeneratingPrint={setRegeneratingPrint}
            setEditorModalOpen={setEditorModalOpen}
          />
        </PrintWrapper>
      </Pane>

      {isEditorModalOpen && (
        <CustomPrintEditorModal
          print={print as CustomPrint}
          order={order}
          loading={isLoading}
          onClose={() => setEditorModalOpen(false)}
          onComplete={async (printMeta) => {
            setLoading(true);

            const meta = (print as CustomPrint).composePrintMeta(printMeta);
            const res = await order.updateItem(print.id, { meta });

            if (!res.ok) {
              toaster.danger(res.error?.message);
              setLoading(false);
              return;
            }

            toaster.success('Poster successfully updated.');

            setLoading(false);
            setEditorModalOpen(false);
            setRegeneratingPrint(true);
          }}
        />
      )}
    </>
  );
};
