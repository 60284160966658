import React from 'react';
import { TransactionFragment, TransactionType } from 'lib/api/ecommerce/schema';
import { Pane, TextInputField, SelectField } from 'evergreen-ui';

type TransactionKeys = keyof Omit<TransactionFragment, '__typename'>;

interface CreateOrderPaymentFormProps {
  loading: boolean;
  payment: Partial<TransactionFragment>;
  onChange: (e: Record<TransactionKeys, string>) => void;
}

export const CreateOrderPaymentForm: React.FC<CreateOrderPaymentFormProps> = ({
  payment,
  loading,
  onChange,
}) => {
  const handleChange = (e) => {
    const name: TransactionKeys = e.target.name.split('.')[1];
    const value: string = e.target.value;

    onChange({ [name]: value } as Record<TransactionKeys, string>);
  };

  return (
    <>
      <Pane display="flex" flexDirection="row" width="100%">
        <Pane display="flex" marginTop={0} width="100%">
          <SelectField
            label="Payment Source"
            name="payment.source"
            width="100%"
            inputHeight={40}
            disabled={loading}
            value={payment?.source}
            onChange={handleChange}
          >
            <option value="AMAZON">Amazon</option>
            <option value="ETSY">Etsy</option>
          </SelectField>
        </Pane>

        <Pane display="flex" width="100%" marginLeft={10}>
          <TextInputField
            label="Payment Source ID"
            placeholder="Source ID"
            name="payment.sourceId"
            inputWidth="100%"
            width="100%"
            inputHeight={40}
            disabled={loading}
            onChange={handleChange}
            value={payment.sourceId}
          />
        </Pane>
      </Pane>

      <Pane display="flex" flexDirection="row" width="100%" marginTop={-14}>
        <Pane display="flex" width="100%">
          <TextInputField
            label="Amount"
            placeholder="0.0"
            name="payment.amount"
            inputWidth="100%"
            width="100%"
            inputHeight={40}
            disabled={loading}
            onChange={handleChange}
            value={payment.amount}
          />
        </Pane>
        <Pane display="flex" width="100%" marginLeft={10}>
          <SelectField
            label="Payment Type"
            name="payment.source"
            width="100%"
            inputHeight={40}
            disabled={loading}
            value={payment?.source}
            onChange={handleChange}
          >
            <option value={TransactionType.AndroidPay}>Android Pay</option>
            <option value={TransactionType.ApplePay}>Apple Pay</option>
            <option value={TransactionType.CreditCard}>Credit Card</option>
            <option value={TransactionType.Masterpass}>Masterpass</option>
            <option value={TransactionType.Paypal}>PayPal</option>
            <option value={TransactionType.SamsungPay}>Samsung Pay</option>
            <option value={TransactionType.UsBankAccount}>Bank Account</option>
            <option value={TransactionType.VenmoAccount}>Venmo</option>
            <option value={TransactionType.VisaCheckout}>Visa</option>
            <option value={TransactionType.Unknown}>Unknown</option>
          </SelectField>
        </Pane>
      </Pane>
    </>
  );
};
